import { useAppDispatch, useAppSelector } from "./../../../../hooks";
import {
  deleteInstanceAsync,
  getInstancesAsync,
} from "./../../../../store/instances/instancesSlice";
import {
  handleInstanceDeleteHideModal,
  handleInstanceDeleteShowModal,
  selectInstance,
  selectInstanceBackupsLoading,
  selectInstanceLoading,
  selectShowDeleteModal,
} from "./../../../../store/instances/instanceSlice";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { InstancesUrl } from "./../../../../utils/urls";
import { DeleteModal } from "./../../../modals/QuestionModal";
import { Button, Card, Typography } from "djuno-design";

const InstanceDeleteTab = () => {
  const instance = useAppSelector(selectInstance);
  const instanceLoading = useAppSelector(selectInstanceLoading);
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowDeleteModal);
  const instanceBackupsLoading = useAppSelector(selectInstanceBackupsLoading);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleDeleteInstance = async () => {
    if (instance) {
      setLoading(true);
      const action = await dispatch(deleteInstanceAsync({ id: instance.id }));
      if (action.type === "instances/delete/fulfilled") {
        dispatch(getInstancesAsync({ withoutLoading: false }));
        navigate(InstancesUrl);
        handleClose();
      }
      setLoading(false);
    }
  };

  const handleClose = () => {
    dispatch(handleInstanceDeleteHideModal());
  };

  return (
    <div>
      <Card title="Delete">
        <div className="mt-2 grid gap-4 w-full">
          <Typography.Text className="!text-sm">
            Deleting your server will stop any existing processes and destroy
            the disk and backups.
          </Typography.Text>
          <Typography.Text className="!text-sm">
            The data won't be retrievable_snapshots of the server remain intact.
          </Typography.Text>

          <Button
            onClick={() => {
              if (instance) dispatch(handleInstanceDeleteShowModal(instance));
            }}
            uiType="danger"
            loading={instanceLoading}
            disabled={instanceLoading || loading}
          >
            Delete instance
          </Button>
        </div>
      </Card>
      <DeleteModal
        isOpen={isOpen}
        title="Delete Instance"
        onClose={handleClose}
        description=""
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        loading={loading || instanceBackupsLoading}
        onConfirm={handleDeleteInstance}
        confirmString={instance?.name}
      />
    </div>
  );
};

export default InstanceDeleteTab;
