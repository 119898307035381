import Button from "../../buttons/Button";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as UserTeamIcon } from "./../../../assets/icons/user-team.svg";
import InviteUserModal from "./InviteUserModal";
import {
  selectTeams,
  selectTeamsLoading,
  getTeamsAsync,
  openInviteModal,
  selectActionLoading,
  deleteTeamUserAsync,
  inviteUserAsync,
} from "../../../store/settings/teams/teamsSlice";
import NotData from "../../layouts/NotData";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import Dropdown from "../../general/Dropdown";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as ResendIcon } from "./../../../assets/icons/arrow-path.svg";
import { DeleteModal } from "../../modals/QuestionModal";
import Text from "../../general/Text";
import { selectOnStageEnv } from "../../../store/auth/authSlice";
import { Team } from "../../../types/team";
import { Helmet } from "react-helmet";
import Countdown from "../../general/Countdown";

const SettingsTeamsTab = () => {
  const [deleteUserId, setDeleteUserId] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const teams = useAppSelector(selectTeams);
  const loading = useAppSelector(selectTeamsLoading);
  const actionLoading = useAppSelector(selectActionLoading);
  const selectedEnv = useAppSelector(selectOnStageEnv);

  useEffect(() => {
    dispatch(getTeamsAsync());
  }, [dispatch, teams.length]);

  const inviteUserHandler = useCallback(
    (team: Team) => {
      return () => {
        dispatch(inviteUserAsync({ Email: team.Email }));
      };
    },
    [dispatch]
  );

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Teams</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex transition-all duration-500 items-center">
        <div className="flex items-center gap-2">
          <Text>Teams</Text>
          <Text type="neutral-alert">{selectedEnv?.Name}</Text>
        </div>
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2">
          <Button
            type="primary"
            buttonProps={{
              onClick: () => dispatch(openInviteModal()),
            }}
            buttonClassName="group"
          >
            Invite user
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <Table loading={loading}>
          <TableHead>
            <TableRow>
              <TableTH lable="Email address" />
              {/* <TableTH lable="Account created" /> */}
              <TableTH lable="Status" />
              <TableTH lable="" />
            </TableRow>
          </TableHead>
          <TableBody>
            {teams.map((team, i) => (
              <TableRow key={i}>
                <TableTD>
                  <div className="flex items-center flex-row justify-between space-x-2">
                    <div className="-mr- w-6 h-6 bg-gray-200 bg-gray-300/30 dark:bg-gray-500/20 rounded-full ring-1 ring-gray-400 dark:ring-gray-600 flex items-center text-center justify-center">
                      <UserTeamIcon className="w-5 h-5 text-slate-400 dark:text-slate-400" />
                    </div>
                    <Text className="text-xs md:text-sm">{team.Email}</Text>
                  </div>
                </TableTD>
                {/* <TableTD>
                  <Text className="text-xs md:text-sm">
                    {timeAgo(team.CreatedAt)}
                  </Text>
                </TableTD> */}
                <TableTD>
                  <div className="flex">
                    <Text
                      type={
                        team.Status === 1 ? "success-alert" : "warning-alert"
                      }
                      className="text-sm"
                    >
                      {team.Status === 1 ? "Accepted" : "Pending"}
                    </Text>
                  </div>
                </TableTD>
                <TableTD className="w-10 ">
                  <div className="w-8 flex justify-center items-center">
                    <Dropdown
                      positionClassName="!w-40"
                      menu={[
                        {
                          key: "1",
                          label: (
                            <Countdown
                              seconds={5}
                              timerPosition="right"
                              className="p-2"
                              timerRender={({ formatedTime, timeLeft }) => {
                                if (timeLeft === 0) return null;
                                return (
                                  <Text type="subtext" className="text-xs">
                                    {formatedTime}
                                  </Text>
                                );
                              }}
                              onClick={() => {
                                inviteUserHandler(team)();
                              }}
                            >
                              <div className="flex items-center gap-1">
                                <ResendIcon className="w-4" />
                                Resend
                              </div>
                            </Countdown>
                          ),
                          disabled: team.Status === 1,
                          className: "!p-0",
                        },
                        { type: "divider" },
                        {
                          key: "end",
                          label: (
                            <div className="flex items-center gap-1">
                              <ArchiveIcon className="w-4" />
                              Delete
                            </div>
                          ),
                          danger: true,
                          onClick: (_i, close) => {
                            close();
                            setDeleteUserId(team.Id);
                          },
                        },
                      ]}
                      type="simple"
                    >
                      <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                        <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                      </div>
                    </Dropdown>
                  </div>
                </TableTD>
              </TableRow>
            ))}
            {teams.length === 0 && (
              <TableRow withoutHoverStyle={true}>
                <TableTD colSpan={4} className="!border-0">
                  <NotData text="You have not inveted any user yet" />
                </TableTD>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <InviteUserModal />

      <DeleteModal
        title="Delete User"
        isOpen={deleteUserId !== null}
        onClose={() => setDeleteUserId(null)}
        onConfirm={() => {
          deleteUserId &&
            dispatch(deleteTeamUserAsync({ id: deleteUserId })).then(
              (action) => {
                if (action.type === "teams/user/delete/fulfilled") {
                  dispatch(getTeamsAsync());
                } else {
                }
                setDeleteUserId(null);
              }
            );
        }}
        loading={actionLoading}
        confirmString={teams.find((team) => team.Id === deleteUserId)?.Email}
        confirmButtonType="danger"
      />
    </>
  );
};

export default SettingsTeamsTab;
