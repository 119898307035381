import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "./../../../hooks";
import {
  getAllRpcAsync,
  selectActionLoading,
  selectAllRpc,
  selectLoading,
  deleteRpcAsync,
} from "./../../../store/rpc/rpcsSlice";
import { useEffect, useState } from "react";
import {
  Button,
  EmptyState,
  Flex,
  SimpleTable,
  Tag,
  Typography,
  Dropdown,
} from "djuno-design";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as DetailsIcon } from "./../../../assets/icons/bars-3-bottom-left.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { useNavigate } from "react-router-dom";
import {
  RpcEndpointCreateUrl,
  RpcEndpontGetStartedUrl,
} from "./../../../utils/urls";
import { DeleteModal } from "./../../../components/modals/QuestionModal";
import { Rpc } from "./../../../types/rpc";
import { useSearch } from "./../../../providers/SearchProvider";
import Highlighter from "react-highlight-words";

const RpcEndpointsTab = () => {
  const [deleteRpcId, setDeleteRpcId] = useState<number | null>(null);
  const allRpc = useAppSelector(selectAllRpc);
  const [filteredRpc, setFilteredRpc] = useState<Rpc[]>([]);
  const loading = useAppSelector(selectLoading);
  const actionLoading = useAppSelector(selectActionLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    dispatch(getAllRpcAsync());
  }, [dispatch]);

  useEffect(() => {
    const lookedUpFiles = allRpc.filter((rpc) =>
      rpc.RpcName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredRpc(lookedUpFiles);
  }, [dispatch, searchValue, allRpc]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | RPC Endpoints</title>
        <meta
          name="description"
          content="RPC-endpoints, or Remote Procedure Call endpoints, are a fundamental aspect of distributed computing systems, enabling communication between different components or services across a network"
        />
      </Helmet>
      <div className="">
        <Flex items="center" justify="between">
          <Flex items="center" className="flex-1">
            {/* <Text className="font-medium text-lg">Endpoints</Text> */}
          </Flex>
          <Flex items="center" className="gap-2">
            <Button
              uiSize="medium"
              uiType="light"
              onClick={() => dispatch(getAllRpcAsync())}
              className="group"
              tooltip={{ content: "Refresh" }}
            >
              <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
            <Button
              uiType="primary"
              onClick={() => navigate(RpcEndpointCreateUrl)}
              className="group"
            >
              Create Endpoint
              <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
          </Flex>
        </Flex>

        <Flex className="w-full mt-5">
          <SimpleTable loading={loading}>
            <SimpleTable.Head>
              <SimpleTable.Row>
                <SimpleTable.TH>Name</SimpleTable.TH>
                <SimpleTable.TH>Status</SimpleTable.TH>
                <SimpleTable.TH>Network</SimpleTable.TH>
                <SimpleTable.TH></SimpleTable.TH>
              </SimpleTable.Row>
            </SimpleTable.Head>
            <SimpleTable.Body>
              {filteredRpc.map((rpc, i) => (
                <SimpleTable.Row
                  key={i}
                  onClick={() => navigate(RpcEndpontGetStartedUrl(rpc.Id))}
                >
                  <SimpleTable.TD className="cursor-pointer">
                    <div className="flex items-center flex-row space-x-3">
                      <div className="w-6 h-6 rounded-full ring-0 flex items-center text-center justify-center">
                        <img
                          src={rpc.Icon}
                          alt={rpc.RpcName}
                          className="w-5 h-5"
                        />
                      </div>
                      <Highlighter
                        highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                        className="whitespace-nowrap max-w-[110px] md:max-w-none text-ellipsis overflow-hidden text-sm font-medium"
                        searchWords={[searchValue]}
                        autoEscape={true}
                        textToHighlight={rpc.RpcName}
                      />
                    </div>
                  </SimpleTable.TD>
                  <SimpleTable.TD className="cursor-pointer">
                    <Flex>
                      <Tag
                        color={
                          rpc.RPCNodeStatus === "RUNNING"
                            ? "success"
                            : rpc.RPCNodeStatus === "INPROGRESS"
                            ? "processing"
                            : "warning"
                        }
                      >
                        {rpc.RPCNodeStatus}
                      </Tag>
                    </Flex>
                  </SimpleTable.TD>

                  <SimpleTable.TD className="cursor-pointer">
                    <Typography.Text className="!text-xs md:!text-sm">
                      {rpc.NetworkType}
                    </Typography.Text>
                  </SimpleTable.TD>

                  <SimpleTable.TD className="w-32 gap-1">
                    <div className="h-full w-full inline-flex items-center justify-center gap-1">
                      <div className="w-8 flex justify-center items-center">
                        <Dropdown
                          anchor="bottom end"
                          itemsClassName="!w-40"
                          menu={[
                            {
                              key: "1",
                              label: (
                                <div className="flex items-center gap-1">
                                  <DetailsIcon className="w-4" />
                                  Get details
                                </div>
                              ),
                              onClick: () =>
                                navigate(RpcEndpontGetStartedUrl(rpc.Id)),
                              disabled: loading,
                            },
                            {
                              type: "divider",
                            },
                            {
                              key: "end",
                              label: (
                                <div className="flex items-center gap-1">
                                  <ArchiveIcon className="w-4" />
                                  Delete
                                </div>
                              ),
                              danger: true,
                              onClick: (_, close) => {
                                close();
                                setDeleteRpcId(rpc.Id);
                              },
                              disabled:
                                loading || rpc.RPCNodeStatus !== "RUNNING",
                            },
                          ]}
                        >
                          <Button
                            uiType="icon"
                            uiSize="small"
                            className="!px-2"
                          >
                            <MoreIcon className="w-4 h-4" />
                          </Button>
                        </Dropdown>
                      </div>
                      <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                    </div>
                  </SimpleTable.TD>
                </SimpleTable.Row>
              ))}

              {allRpc.length === 0 && (
                <SimpleTable.Row withoutHoverStyle>
                  <SimpleTable.TD colSpan={4} className="!border-0">
                    <EmptyState text="You have not created any endpoint yet" />
                  </SimpleTable.TD>
                </SimpleTable.Row>
              )}
            </SimpleTable.Body>
          </SimpleTable>
        </Flex>
        <DeleteModal
          title="Delete RPC Endpoint"
          isOpen={deleteRpcId !== null}
          onClose={() => setDeleteRpcId(null)}
          description=""
          confirmButtonText="Confirm"
          confirmButtonType="danger"
          confirmButtonClassName="w-full"
          onConfirm={() => {
            deleteRpcId &&
              dispatch(deleteRpcAsync({ id: deleteRpcId })).then((action) => {
                if (action.type === "rpc/delete/fulfilled") {
                  dispatch(getAllRpcAsync());
                } else {
                }
                setDeleteRpcId(null);
              });
          }}
          loading={actionLoading}
          confirmString={allRpc.find((rpc) => rpc.Id === deleteRpcId)?.RpcName}
        />
      </div>
    </>
  );
};
export default RpcEndpointsTab;
