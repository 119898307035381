import { Outlet, useNavigate, useParams } from "react-router-dom";
import { TabOptions } from "../../types/tabs";
import {
  Web3AuthEndpointsUrl,
  Web3EndpoindApiKeysUrl,
  Web3EndpoindSettingUrl,
  Web3EndpoindUsersUrl,
} from "../../utils/urls";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getWeb3AuthEndpointAsync,
  selectWeb3AuthEndpoint,
  selectWeb3AuthEndpointLoading,
} from "../../store/web3-auth/web3AuthEndpointSlice";
import classNames from "classnames";
import { ReactComponent as RightArrow } from "./../../assets/icons/arrow-right.svg";
import { Helmet } from "react-helmet";
import { NotExist } from "../../components/layouts/NotData";
import { ReactComponent as KeyIcon } from "./../../assets/icons/key.svg";
import { ReactComponent as SettingIcon } from "./../../assets/icons/setting.svg";
import { ReactComponent as UsersIcon } from "./../../assets/icons/users.svg";
import { Tabs, Typography } from "djuno-design";

const Web3AuthEndpintPage = () => {
  const { endpoindId } = useParams();
  const endpoint = useAppSelector(selectWeb3AuthEndpoint);
  const endpointLoading = useAppSelector(selectWeb3AuthEndpointLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isExist, setIsExist] = useState<boolean>(true);

  useEffect(() => {
    if (endpoint === null && endpoindId) {
      dispatch(getWeb3AuthEndpointAsync(endpoindId)).then((action) => {
        if (action && action.type === "web3-auth/endpoint/rejected") {
          setIsExist(false);
        }
      });
    }
  }, [dispatch, endpoindId, endpoint]);

  const Web3EndpointPageMenus: TabOptions = useMemo(
    () => [
      {
        label: (
          <div className="flex items-center gap-1">
            <UsersIcon className="w-3" />
            Users
          </div>
        ),
        url: Web3EndpoindUsersUrl(endpoindId),
        testId: "1th-tab",
      },
      {
        label: (
          <div className="flex items-center gap-1">
            <SettingIcon className="w-3" />
            Settings
          </div>
        ),
        url: Web3EndpoindSettingUrl(endpoindId),
        testId: "2th-tab",
      },
      {
        label: (
          <div className="flex items-center gap-1">
            <KeyIcon className="w-3" />
            Access Keys
          </div>
        ),
        url: Web3EndpoindApiKeysUrl(endpoindId),
        testId: "3th-tab",
      },
    ],
    [endpoindId]
  );

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | Web3-auth ({endpoint?.Name || ""})
        </title>
        <meta
          name="description"
          content="web3-auth refers to authentication mechanisms used within Web3 applications, which are decentralized applications built on blockchain technology. Web3-auth facilitates user authentication and authorization processes within these decentralized applications"
        />
      </Helmet>
      <div className="flex items-center justify-between h-20">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <div className="flex items-center gap-2">
            <RightArrow
              onClick={() => navigate(Web3AuthEndpointsUrl)}
              className="rotate-180 w-5 h-5 hover:scale-110 transition-all duration-500 text-slate-800 dark:text-slate-100 cursor-pointer"
            />
            <div
              className={classNames(
                " dark:text-slate-100 min-w-[200px] h-8 rounded-md px-2 flex items-center",
                {
                  "bg-slate-300 dark:bg-slate-50/10 animate-pulse":
                    endpointLoading,
                }
              )}
            >
              <Typography.Text className="font-medium">
                {endpoint?.Name}
              </Typography.Text>
            </div>
          </div>
        </div>
        <div className="mr-6 w-96"></div>
      </div>
      {isExist ? (
        <div className="mx-6">
          <Tabs
            options={Web3EndpointPageMenus.map((o) => ({
              label: o.label,
              url: o.url,
              testId: o.testId,
            }))}
          />
          <div className="py-6">
            <Outlet />
          </div>
        </div>
      ) : (
        <NotExist url={() => navigate(Web3AuthEndpointsUrl)} />
      )}
    </>
  );
};
export default Web3AuthEndpintPage;
