import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  selectExpressionValue,
  handleChangeExpressionValue,
  selectActiveExpressionTabIndex,
  handleChangeActiveExpressionTabIndex,
  selectBacktestValue,
  handleChangeBacktestValue,
  selectTargetBlockValue,
  handleChangeTargetBlockValue,
  selectExpressionValidateLoading,
  validateExpressionAsync,
  selectEnableForTestExpression,
  getBacktestsExpressionAsync,
  selectBacktestsResultsLoading,
  selectBacktestsResults,
} from "../../../../store/block-event/blockEventCreateSlice";
import { useCallback } from "react";
import Loading from "../../../general/Loading";
import { ReactComponent as CheckIcon } from "./../../../../assets/icons/check.svg";
import { ReactComponent as CloseIcon } from "./../../../../assets/icons/close.svg";
import {
  JsonViewer,
  Typography,
  Textarea,
  Input,
  // Tabs,
  Accordion,
  Button,
} from "djuno-design";
import Tabs from "../../../general/Tabs";

const CreateExpressionStep = () => {
  const expressionValue = useAppSelector(selectExpressionValue);
  const expressionValidateLoading = useAppSelector(
    selectExpressionValidateLoading
  );
  const enableForTest = useAppSelector(selectEnableForTestExpression);
  const activeExpressionTabIndex = useAppSelector(
    selectActiveExpressionTabIndex
  );
  const dispatch = useAppDispatch();

  const handleChangeExpression = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    dispatch(handleChangeExpressionValue(event.target.value));
  };

  const handleTestExpression = useCallback(() => {
    if (expressionValue) {
      dispatch(validateExpressionAsync()).then((action) => {
        if (action.type === "block-event/validate-expression/fulfilled") {
          dispatch(handleChangeActiveExpressionTabIndex(1));
          dispatch(getBacktestsExpressionAsync());
        }
      });
    }
  }, [dispatch, expressionValue]);

  return (
    <div className="w-full overflow-x-auto min-h-[calc(100%-6rem)]">
      <div className="flex flex-col md:flex-row w-full">
        <div className="w-full md:w-1/2 pl-0.5">
          <div className="md:mr-8">
            <div className="flex flex-col">
              <Textarea
                value={expressionValue}
                onChange={handleChangeExpression}
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
                label="Create Expression"
                containerClassName="w-full"
              />

              <Button
                uiType="primary"
                disabled={!expressionValue || !enableForTest}
                onClick={handleTestExpression}
                loading={expressionValidateLoading}
                className="!justify-center !w-full !mt-4"
              >
                Test Expression
              </Button>
            </div>
            <Documents />
          </div>
        </div>
        <div className="w-full md:w-1/2 flex flex-col md:border-l dark:border-dark-2">
          <div className="dark:border-dark-2">
            <div className="md:ml-8">
              {/* TODO */}
              <Tabs
                options={[
                  {
                    label: <Typography.Text>Block Expression</Typography.Text>,
                  },
                  { label: <Typography.Text>Test expression</Typography.Text> },
                ]}
                tabType="creamy"
                onChange={(i) => {
                  console.log("tab index", i);
                  dispatch(handleChangeActiveExpressionTabIndex(i));
                }}
                // onChange={(selectedOption) => {
                //   console.log("Selected option:", selectedOption);

                //   const index = options.findIndex(
                //     (option) => option === selectedOption
                //   );

                //   if (index !== -1) {
                //     dispatch(handleChangeActiveExpressionTabIndex(index)); // Pass only the index to Redux
                //   }
                // }}
                selectedIndex={activeExpressionTabIndex}
              />

              <CommonSection />
            </div>
            <div className="m-8"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommonSection = () => {
  const activeExpressionTabIndex = useAppSelector(
    selectActiveExpressionTabIndex
  );
  const targetBlockValue = useAppSelector(selectTargetBlockValue);
  const dispatch = useAppDispatch();

  const exampleJson = {
    block_number: 38733960,
    topic: "raw-avalanchec-mainnet",
    chain: "avalanchec-mainnet",
    block: {
      baseFeePerGas: "0x5f9ed8fd3",
      difficulty: "0x1",
      extraData:
        "0x00000000001940d8000000000032dd2b000000000000000000000000001b8ebe00000000000990fc00000000000f09580000000000000000000000000000000000000000000000000000000000000000",
      gasLimit: "0xe4e1c0",
      gasUsed: "0x46f0b1",
      hash: "0x42a9043d4a8b0d57a37dba1ec579aadca2bdeea44ec9383c05aae8d4b534abbc",
      logsBloom:
        "0x002800004900004801200002b04200290402000410002120a004c0020440100009241000401000c280500500401c000000000900000a34c1802822c0002021800021a0000050441c04000309286292a810000448014a000000000000800018000125011002081c00004800000001080800210800020a1400802080162008020081000000218000001000400c00002080151244910000400839000048414204842280000014200492000010440c0044003400000c0000488102009000200020800060005b3040204000e00100014a100420420081000020104181c0020241201000308284000808a0202100000000820000030000080000420010000020420202",
      miner: "0x0100000000000000000000000000000000000000",
      mixHash:
        "0x0000000000000000000000000000000000000000000000000000000000000000",
      nonce: "0x0000000000000000",
      number: "0x24f0888",
      parentHash:
        "0x7a3654a7b66a5244c5993af3f8d49fb3c3ce68964485bbd589af6d9fa4ff33c1",
      receiptsRoot:
        "0xfac9d39506a4b83318363868776ae3b376f5829f8042d3c9ba2f7cc7ac10c54a",
      sha3Uncles:
        "0x1dcc4de8dec75d7aab85b567b6ccd41ad312451b948a7413f0a142fd40d49347",
      size: "0x5ba6",
      stateRoot:
        "0x386650ab5da4fddc5ca6023d4924c45bc5ed34f398e6089d25d4f7f3b0e5fdbd",
      timestamp: "0x6571aeb3",
      totalDifficulty: "0x24f0888",
      transactions: [],
      transactionsRoot:
        "0x51a9e1bf857cab881219d25df802d658aef74392d95a31401da34caf2223439d",
      uncles: [],
    },
  };
  return (
    <>
      <div className="mt-4">
        <Typography.Text uiType="secondary" className="!text-sm">
          Continue building the expression with the target block and sample
          response from the expression. The final step is to test your
          expression.
        </Typography.Text>
      </div>
      <div className="mt-8">
        <Typography.Text className="!text-sm">Target Block</Typography.Text>
        <div className="flex w-full gap-2 my-2 items-center">
          <div className="flex-grow">
            <Input
              value={targetBlockValue}
              onChange={(e: any) =>
                dispatch(handleChangeTargetBlockValue(e.target.value))
              }
              type="number"
            />
          </div>
          {activeExpressionTabIndex === 0 && (
            <Button uiType="primary">Get Data</Button>
          )}
        </div>
      </div>

      {/* Additional div for activeExpressionTabIndex === 0 */}
      {activeExpressionTabIndex === 0 && (
        <div className="bg-standard-200 flex-grow">
          <div className="h-full overflow-auto w-full rounded-lg mt-5">
            <JsonViewer value={exampleJson} />
          </div>
        </div>
      )}
      {activeExpressionTabIndex === 1 && <BacktestsSection />}
    </>
  );
};

export const BacktestsSection = () => {
  const dispatch = useAppDispatch();
  const backtestValue = useAppSelector(selectBacktestValue);
  const backtestsResultsLoading = useAppSelector(selectBacktestsResultsLoading);
  const backtestsResults = useAppSelector(selectBacktestsResults);

  const handleInputChange = (e: any) => {
    let value = e.target.value;
    if (Number(value) >= 10) value = 10;
    if (Number(value) <= 1) value = 1;
    dispatch(handleChangeBacktestValue(value));
  };

  const handleInputChangeRange = (e: any) => {
    let value = e.target.value;
    dispatch(handleChangeBacktestValue(Number(value) + 1));
  };

  return (
    <div className="flex-grow mt-4">
      <Typography.Text>Backtest from target block</Typography.Text>
      <div className="wrapper w-full h-30 flex items-center relative mt-1">
        <input
          type="text"
          value={backtestValue}
          onChange={handleInputChange}
          className="appearance-none block w-16 h-14 px-2 text-center bg-white dark:bg-dark-3 border dark:border-dark-2 text-slate-800 dark:text-slate-100 rounded-md"
        />
        <div className="range flex flex-1 items-center h-14 px-2">
          <input
            type="range"
            className="w-full cursor-pointer outline-none"
            value={backtestValue - 1}
            onChange={handleInputChangeRange}
            min="0"
            max="9"
            step="1"
          />
        </div>
      </div>
      <div className="flex flex-col mt-5">
        {backtestsResultsLoading && (
          <Loading className="min-h-[120px]" borderSize={2} />
        )}
        {!backtestsResultsLoading && (
          <ul className="mt-2 border dark:border-dark-2 rounded-lg bg-white dark:bg-dark-3">
            {backtestsResults.map((backtest, index) => (
              <li
                key={index}
                className="p-4 border-b last:border-b-0 dark:border-dark-2"
              >
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-2">
                    {backtest.evaluation && (
                      <CheckIcon className="w-3 aspect-square text-green-500" />
                    )}
                    {!backtest.evaluation && (
                      <CloseIcon className="w-3 aspect-square text-red-500" />
                    )}
                    <Typography.Text className="!text-sm">
                      {backtest.block_number}
                    </Typography.Text>
                  </div>
                  <div className="flex items-center"></div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

const Documents = () => {
  return (
    <>
      <div className="mt-6">
        <Typography.Text className="!mb-1 !text-sm">Legend</Typography.Text>
        <Accordion
          items={[
            {
              label: "Target data from the block",
              panel: (
                <div className="text-md space-y-4 p-4 ListItem_list-item-child__kMxMj ListItem_list-item-child--border__aiQDj dark:border-dark-2">
                  <div>
                    <div className="first:pt-0 last:pb-0 py-4 list-none flex items-center border-b border last:border-b-0">
                      <span className=" text-standard font-mono text-sm px-1 rounded">
                        block_baseFeePerGas
                      </span>
                      <span className="mx-2 text-md  flex-grow ">
                        <Typography.Text>
                          From block baseFeePerGas field
                        </Typography.Text>
                      </span>
                    </div>
                    <div className="first:pt-0 last:pb-0 py-4 list-none flex items-center border-b border last:border-b-0">
                      <span className="text-standard font-mono text-sm px-1 rounded">
                        block_difficulty
                      </span>
                      <span className="mx-2 text-md  flex-grow">
                        <Typography.Text>
                          From block difficulty field
                        </Typography.Text>
                      </span>
                    </div>
                    <div className="first:pt-0 last:pb-0 py-4 list-none flex items-center border-b border last:border-b-0">
                      <span className=" text-standard font-mono text-sm px-1 rounded">
                        <Typography.Text>
                          {" "}
                          From block extraData field
                        </Typography.Text>
                      </span>
                      <span className="mx-2 text-md  flex-grow">
                        <Typography.Text>
                          From block extraData field
                        </Typography.Text>
                      </span>
                    </div>
                  </div>
                </div>
              ),
            },
            {
              label: "Target data from the transaction",
              panel: (
                <div className="text-md space-y-4 p-4 ListItem_list-item-child__kMxMj ListItem_list-item-child--border__aiQDj">
                  <div>
                    <div className="first:pt-0 last:pb-0 py-4 list-none flex items-center border-b border last:border-b-0">
                      <span className=" text-standard font-mono text-sm px-1 rounded">
                        <Typography.Text> tx_blockHash</Typography.Text>
                      </span>
                      <span className="mx-2 text-md  flex-grow">
                        <Typography.Text>
                          From transaction blockHash field
                        </Typography.Text>
                      </span>
                    </div>
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>

      <div className="mt-6 mb-10">
        <div className="inline-flex items-center font-medium hover:underline cursor-pointer text-md">
          <Typography.Text className="!text-sm">
            View docs on modifiers, operators, and comparators
          </Typography.Text>
        </div>
        <ul className="mt-2 border dark:border-dark-2 rounded-lg bg-white dark:bg-dark-3">
          <li className=" p-4 border-b last:border-b-0 dark:border-dark-2">
            <div className="flex justify-between items-center">
              <span className="text-md">
                <Typography.Text>Modifiers</Typography.Text>
              </span>
              <div className="flex items-center">
                <span className="border  bg-gray-50 shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> +</Typography.Text>
                </span>
                <span className="border bg-gray-50  shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> -</Typography.Text>
                </span>
                <span className="border bg-gray-50  shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> /</Typography.Text>
                </span>
                <span className="border  bg-gray-50 shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> *</Typography.Text>
                </span>
                <span className="border bg-gray-50  shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> |</Typography.Text>
                </span>
                <span className="border  bg-gray-50 shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> ^</Typography.Text>
                </span>
                <span className="border bg-gray-50  shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> **</Typography.Text>
                </span>
                <span className="border bg-gray-50  shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text>%</Typography.Text>
                </span>
                <span className="border bg-gray-50  shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> &#62;&#62;</Typography.Text>
                </span>
                <span className="border bg-gray-50  shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> &#60;&#60;</Typography.Text>
                </span>
              </div>
            </div>
          </li>

          <li className=" p-4 border-b last:border-b-0 dark:border-dark-2">
            <div className="flex justify-between">
              <span className="text-md">
                <Typography.Text>Comparators</Typography.Text>
              </span>
              <div className="flex items-center">
                <span className="border bg-gray-50 shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> &#62;</Typography.Text>
                </span>
                <span className="border bg-gray-50  shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> &#62;=</Typography.Text>
                </span>
                <span className="border bg-gray-50  shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> &#60;</Typography.Text>
                </span>
                <span className="border bg-gray-50  shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> &#60;=</Typography.Text>
                </span>
                <span className="border bg-gray-50  shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> ==</Typography.Text>
                </span>
                <span className="border  bg-gray-50 shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> ^</Typography.Text>
                </span>
                <span className="border  bg-gray-50 shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text>!=</Typography.Text>
                </span>
              </div>
            </div>
          </li>
          <li className=" p-4 border-b last:border-b-0 dark:border-dark-2">
            <div className="flex justify-between">
              <span className="text-md">
                <Typography.Text>Regex Comparators</Typography.Text>
              </span>
              <div className="flex items-center">
                <span className="border bg-gray-50  shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> =~</Typography.Text>
                </span>
                <span className="border bg-gray-50  shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> !~</Typography.Text>
                </span>
              </div>
            </div>
          </li>
          <li className=" p-4 border-b last:border-b-0 dark:border-dark-2">
            <div className="flex justify-between">
              <span className="text-md">
                <Typography.Text>Logical ops</Typography.Text>
              </span>
              <div className="flex items-center">
                <span className="border  bg-gray-50 shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> ||</Typography.Text>
                </span>
                <span className="border bg-gray-50  shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> &&</Typography.Text>
                </span>
              </div>
            </div>
          </li>
          <li className=" p-4 border-b last:border-b-0 dark:border-dark-2">
            <div className="flex justify-between">
              <div className="flex flex-col mr-2">
                <Typography.Text>Numeric constants</Typography.Text>
                <Typography.Text> As 64-bit floating point</Typography.Text>
              </div>
              <div className="flex flex-col ">
                <span className="border  bg-gray-50 shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text>12345.678</Typography.Text>
                </span>
              </div>
            </div>
          </li>
          <li className=" p-4 border-b last:border-b-0 dark:border-dark-2">
            <div className="flex justify-between">
              <div className="flex flex-col mr-2">
                <Typography.Text>String constants</Typography.Text>
                <Typography.Text> Single quotes</Typography.Text>
              </div>
              <div className="flex flex-col ">
                <span className="border bg-gray-50 shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> 'foo-bar'</Typography.Text>
                </span>
              </div>
            </div>
          </li>
          <li className=" p-4 border-b last:border-b-0 dark:border-dark-2">
            <div className="flex justify-between">
              <div className="flex flex-col mr-2">
                <Typography.Text>Parenthesis</Typography.Text>
                <Typography.Text>
                  {" "}
                  To control order of evaluation
                </Typography.Text>
              </div>
              <div className="flex flex-col ">
                <div className="flex">
                  <span className="border shadow-sm bg-gray-50 rounded text-sm p-1 mx-1 whitespace-nowrap flex items-center justify-center dark:border-dark-2 dark:bg-dark-2">
                    <Typography.Text> &#x208D;</Typography.Text>
                  </span>
                  <span className="border shadow-sm bg-gray-50 rounded text-sm p-1 mx-1 whitespace-nowrap flex items-center justify-center dark:border-dark-2 dark:bg-dark-2">
                    <Typography.Text> &#x208E;</Typography.Text>
                  </span>
                </div>
              </div>
            </div>
          </li>
          <li className=" p-4 border-b last:border-b-0 dark:border-dark-2">
            <div className="flex justify-between">
              <div className="flex flex-col mr-2">
                <Typography.Text>Arrays</Typography.Text>
                <Typography.Text>
                  Anything separated by "," within parenthesis
                </Typography.Text>
              </div>
              <div className="flex flex-col ">
                <span className="border bg-gray-50 shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap font-mono dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> (1,2,'foo')</Typography.Text>
                </span>
              </div>
            </div>
          </li>
          <li className=" p-4 border-b last:border-b-0 dark:border-dark-2">
            <div className="flex justify-between">
              <span className="text-md mr-2">
                <Typography.Text>(1,2,'foo')</Typography.Text>
              </span>
              <div className="flex items-center">
                {" "}
                <span className="border bg-gray-50 shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap font-mono dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text>!</Typography.Text>
                </span>
                <span className="border bg-gray-50 shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap font-mono dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text>-</Typography.Text>
                </span>
                <span className="border bg-gray-50 shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap font-mono dark:border-dark-2 dark:bg-dark-2">
                  <Typography.Text> ~</Typography.Text>
                </span>
              </div>
            </div>
          </li>
          <li className=" p-4 border-b last:border-b-0 dark:border-dark-2">
            <div className="flex justify-between items-center">
              <span className="text-md mr-2">
                <Typography.Text>Null coalescence</Typography.Text>
              </span>
              <span className="border bg-gray-50 shadow-sm  rounded text-sm py-0.5 px-1 mx-1 whitespace-nowrap font-mono dark:border-dark-2 dark:bg-dark-2">
                <Typography.Text> ??</Typography.Text>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};
export default CreateExpressionStep;
