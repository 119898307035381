import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import {
  getS3WidgetAsync,
  selectS3EndTime,
  selectS3StartTime,
} from "../../../../../store/s3/overviewSlice";
import GraphChart, {
  GraphChartMonitor,
} from "../../../../general/charts/GraphChart";
import { ReactComponent as ArrowsPointingOutIcon } from "./../../../../../assets/icons/arrows-pointing-out.svg";
import { ReactComponent as ArrowDownTrayIcon } from "./../../../../../assets/icons/arrow-down-tray.svg";
import { captureAndDownloadImage } from "../../../../../utils";
import { S3MetricsWidget, S3WidgetType } from "../../../../../types/s3";
import {
  cn,
  Dropdown,
  DropdownElement,
  Flex,
  Loading,
  Modal,
  Typography,
} from "djuno-design";

const GraphWidget: React.FunctionComponent<{
  chartType: S3WidgetType;
  chartCode: number;
  data: S3MetricsWidget | null;
  dataLoading: boolean;
  title?: string;
  chartDateFormat?: string;
  modalDateFormat?: string;
  showMonitor?: boolean;
  type?: "line" | "area";
}> = ({
  chartType,
  chartCode,
  data,
  dataLoading,
  title,
  chartDateFormat,
  modalDateFormat,
  showMonitor = true,
  type,
}) => {
  const [fullScreen, setFullScreen] = useState(false);
  const dispatch = useAppDispatch();
  const startTime = useAppSelector(selectS3StartTime);
  const endTime = useAppSelector(selectS3EndTime);

  useEffect(() => {
    dispatch(
      getS3WidgetAsync({ key: chartType, n: chartCode, startTime, endTime })
    );
  }, [chartCode, chartType, dispatch, startTime, endTime]);

  const downloadDropdoemItems: Array<DropdownElement> = [
    // {
    //   key: "1",
    //   label: "Download as CSV",
    //   onClick: () => {},
    // },
    {
      key: "2",
      label: "Download as PNG",
      onClick: (_, close) => {
        close();
        captureAndDownloadImage(`chart-${chartType}`);
      },
    },
  ];
  return (
    <div className="flex flex-col w-full col-span-2 lg:col-span-1 rounded-lg border dark:border-dark-2 bg-white dark:bg-dark-3 p-5 pb-3 group">
      <div className="flex items-center gap-3 mb-2 justify-between">
        <Typography.Text className="!text-sm">{title}</Typography.Text>
        <div className="flex items-center gap-2">
          <div className="w-5 h-5">
            <ArrowsPointingOutIcon
              onClick={() => setFullScreen(true)}
              className="text-slate-400 dark:text-slate-400 w-4 hover:scale-110 duration-150 cursor-pointer hover:text-slate-700 hidden group-hover:!block"
            />
          </div>
          <div className="w-5 h-5">
            <Dropdown
              menu={downloadDropdoemItems}
              anchor="bottom end"
              itemsClassName="!w-40"
            >
              <ArrowDownTrayIcon className="text-slate-400 dark:text-slate-400 w-4 hover:scale-110 duration-150 cursor-pointer hover:text-slate-700" />
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="flex w-full gap-10 pt-3 h-36" id={`chart-${chartType}`}>
        {dataLoading && (
          <Flex justify="center" items="center" className="!w-full !h-full">
            <Loading borderSize={2} />
          </Flex>
        )}
        {!dataLoading && (
          <>
            <div
              className={cn("", {
                "w-full ": !showMonitor,
                "w-1/2": showMonitor,
              })}
            >
              <GraphChart
                data={data}
                dateFormat={chartDateFormat || "HH:mm"}
                type={type}
              />
            </div>
            {showMonitor && (
              <div className="flex flex-col justify-start text-sm font-semibold h-36 overflow-y-auto w-1/2">
                <GraphChartMonitor data={data} />
              </div>
            )}
          </>
        )}
      </div>
      <Modal
        isOpen={fullScreen}
        onClose={() => setFullScreen(false)}
        containerClassName=""
        title={title}
      >
        <div className="flex flex-col mt-5 overflow-y-auto">
          <div className="w-full h-[70vh]">
            <GraphChart
              data={data}
              dateFormat={modalDateFormat || "MM/DD HH:mm"}
              type={type}
            />
          </div>
          {showMonitor && <GraphChartMonitor data={data} />}
        </div>
      </Modal>
    </div>
  );
};

export default GraphWidget;
