import Text, { A } from "../../general/Text";
import Card from "../../general/Card";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArrowDownCircleIcon } from "./../../../assets/icons/arrow-down-circle.svg";
import { ReactComponent as CreditCardIcon } from "./../../../assets/icons/credit-card.svg";
import { Helmet } from "react-helmet";
import Button from "../../buttons/Button";
import Table, {
  TableBody,
  TableHead,
  TableRow,
  TableTD,
  TableTH,
} from "../../general/Table";
import Dropdown from "../../general/Dropdown";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  //createPaymentMethodAsync,
  deletePaymentMethodAsync,
  downlodInvoiceAsync,
  getBillingInformationAsync,
  getCloudCreditAsync,
  getPlansAsync,
  getSubscriptionsAsync,
  handleChangeInvoicePagination,
  handleConfigPayNowModal,
  handleShowBillingInformationModal,
  handleShowTopupModal,
  invoicesAsync,
  makeZeroOrderAsync,
  paymentMethodsAsync,
  selectBillingInformation,
  selectBillingInformationLoading,
  selectCloudCredit,
  selectCloudCreditLoading,
  selectCurrentUsage,
  selectInvoices,
  selectInvoicesLoading,
  selectInvoicesPagination,
  selectPaymentMethodActionLoading,
  selectPaymentMethods,
  selectPaymentMethodsLoading,
  selectPlanGroups,
  selectPlanGroupsLoading,
  selectSubscriptions,
  selectSubscriptionsLoading,
  setAsDefaultPaymentMethodAsync,
} from "../../../store/billing/billingSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import Loading from "../../general/Loading";
import NotData, { EmptyList } from "../../layouts/NotData";
import Pagination from "../../general/Pagination";
import classNames from "classnames";
import Modal from "../../modals/Modal";
import CardDetailsBox from "./CardDetailsBox";
import {
  BillingIntentResponse,
  IPaymentMethod,
  IPlanGroup,
} from "../../../types/billing";
import toast from "react-hot-toast";
import { CustomToast } from "../../general/Toast";
import BillingInformationModal from "./BillingInformationModal";
import TopupModal from "./TopupModal";
import { formatTimestamp, lastDayOfMonth } from "../../../utils/date";
import { UniversalPlansBox } from "../../plans/UniversalPlansBox";
import { Web3StoragePlansBox } from "../../plans/Web3StoragePlansBox";
import { Web3AuthPlansBox } from "../../plans/Web3AuthPlansBox";
import { WorkflowPlansBox } from "../../plans/WorkflowPlansBox";
import { BlockEventsPlansBox } from "../../plans/BlockEventsPlansBox";
import { getCountryFlag } from "../../../utils";
import { selectUser } from "../../../store/auth/authSlice";
import { DeleteModal } from "../../modals/QuestionModal";
import { currency } from "../../../utils/currency";
import InvoicePayNowModal from "./InvoicePayNowModal";

const SettingBillingTab = () => {
  const [deletePaymentMethod, setDeletePaymentMethod] =
    useState<IPaymentMethod | null>(null);

  //download
  const [downloadLoadingIds, setDownloadLoadingIds] = useState<number[]>([]);

  const handleDownloadInvoice = useCallback((invoiceId: number) => {
    setDownloadLoadingIds((prev) => [...prev, invoiceId]);
    dispatch(downlodInvoiceAsync({ invoiceNumber: invoiceId })).then(() => {
      setDownloadLoadingIds((prev) => prev.filter((id) => id !== invoiceId));
    });
  }, []);

  const isInvoiceLoading = useMemo(() => {
    return (invoiceId: number) => downloadLoadingIds.includes(invoiceId);
  }, [downloadLoadingIds]);

  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);

  const currentUsage = useAppSelector(selectCurrentUsage);

  const cloudCredit = useAppSelector(selectCloudCredit);
  const cloudCreditLoading = useAppSelector(selectCloudCreditLoading);

  const paymentMethods = useAppSelector(selectPaymentMethods);

  const paymentMethodsLoading = useAppSelector(selectPaymentMethodsLoading);
  const paymentMethodActionLoading = useAppSelector(
    selectPaymentMethodActionLoading
  );
  const [openCardDetailModal, setOpenCardDetailModal] = useState(false);

  const billingInformation = useAppSelector(selectBillingInformation);
  const billingInformationLoading = useAppSelector(
    selectBillingInformationLoading
  );

  const invoices = useAppSelector(selectInvoices);
  const invoicesPagination = useAppSelector(selectInvoicesPagination);
  const invoicesLoading = useAppSelector(selectInvoicesLoading);

  const subscriptions = useAppSelector(selectSubscriptions);
  const subscriptionsLoading = useAppSelector(selectSubscriptionsLoading);

  const planGroups = useAppSelector(selectPlanGroups);
  const planGroupsLoading = useAppSelector(selectPlanGroupsLoading);

  const [rescalingPlanGruop, setRescalingPlanGruop] = useState<
    undefined | IPlanGroup
  >(undefined);

  useEffect(() => {
    dispatch(getCloudCreditAsync());
  }, [dispatch]);

  useEffect(() => {
    dispatch(paymentMethodsAsync());
  }, [dispatch]);

  //get invoices data
  useEffect(() => {
    dispatch(invoicesAsync());
  }, [dispatch, invoicesPagination.PageNumber]);

  //get invoices data if there is pending data
  useEffect(() => {
    let invoices_interval: NodeJS.Timeout | undefined;
    const statuses = Array.from(new Set(invoices.map((i) => i.InvoiceStatus)));
    if (statuses.includes("pending")) {
      invoices_interval = setInterval(() => {
        dispatch(
          invoicesAsync({
            withoutLoading: true,
          })
        );
      }, 8000);
    } else {
      if (invoices_interval) clearInterval(invoices_interval);
    }
    return () => {
      if (invoices_interval) {
        clearInterval(invoices_interval);
      }
    };
  }, [dispatch, invoices]);

  useEffect(() => {
    dispatch(getSubscriptionsAsync());
    dispatch(getPlansAsync());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBillingInformationAsync());
  }, [dispatch]);

  const handleBeforInitPaymentMethod = async () => {
    return dispatch(makeZeroOrderAsync()).then((action) => {
      if (action.type === "billing/make-zero-order/fulfilled") {
        const orderToken = (action.payload as BillingIntentResponse).Token;
        return orderToken;
      } else {
        return undefined;
      }
    });
  };

  const handleAfterInitPaymentMethod = async (orderToken: string) => {
    setOpenCardDetailModal(false);
    toast.success(() => CustomToast("New payment details added successfully"));
    dispatch(paymentMethodsAsync());
  };

  const handleSetAsDefault = useCallback(
    (paymentMethodId: number) => {
      dispatch(setAsDefaultPaymentMethodAsync(paymentMethodId)).then(
        (action) => {
          if (action.type === "billing/set-as-default/fulfilled") {
            dispatch(paymentMethodsAsync());
          }
        }
      );
    },
    [dispatch]
  );

  const getPlanGroupByPlanId = useCallback(
    (planId: number) => {
      return planGroups.find((pg) =>
        pg.PlanDetail.map((p) => p.Id).includes(planId)
      );
    },
    [planGroups]
  );

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Billing</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="w-full flex flex-col gap-10">
        <div className="w-full grid md:grid-cols-2 gap-8">
          <div className="col-span-1 ">
            <div className="flex flex-col gap-4 p-6 rounded-xl bg-white dark:bg-dark-3 border dark:border-dark-2 h-full">
              <Text className="font-medium text-lg">Billing overview</Text>
              <div className="flex items-center gap-3">
                <Text type="subtext" className="text-sm">
                  Next payment on
                </Text>
                <Text
                  type="primary-alert"
                  className="font-medium !rounded-full"
                >
                  {/* Mar 12, 2024 */}
                  {
                    formatTimestamp(
                      lastDayOfMonth().getTime() / 1000,
                      "MMM DD, YYYY"
                    ).datetime
                  }
                </Text>
              </div>
              <div className="flex items-center gap-3">
                <Text type="subtext" className="text-sm">
                  Current usage
                </Text>
                <Text className="font-medium text-lg">${currentUsage}</Text>
              </div>
              <div className="flex items-center gap-5">
                <A type="subtext" className="text-sm" href="#Invoice History">
                  Past invoices
                </A>
                <A
                  type="subtext"
                  className="text-sm"
                  href="#Subscription and plans"
                >
                  Subscriptions
                </A>
              </div>
            </div>
          </div>
          <div className="col-span-1 ">
            <div className="flex flex-col gap-1 p-6 rounded-xl bg-white dark:bg-dark-3 border dark:border-dark-2 h-full">
              <Text className="font-medium text-lg">Credit balance</Text>
              <Text className="font-normal text-sm">
                Top up to get 10% discount on every service you use
              </Text>
              <div className="flex flex-col items-center gap-2 p-3 rounded-lg bg-primary-50 dark:bg-dark-2">
                <Text className="font-medium text-base">
                  Your current balance
                </Text>
                <div className="flex items-end justify-center">
                  <Text
                    className="font-semibold text-2xl min-w-[100px] text-center"
                    loading={cloudCreditLoading}
                  >
                    {currency(cloudCredit?.Balance)}
                  </Text>
                </div>
                <div className="flex items-center justify-center">
                  <Button
                    buttonProps={{
                      onClick: () => dispatch(handleShowTopupModal(true)),
                    }}
                  >
                    Top up
                  </Button>
                </div>
              </div>
            </div>
            <TopupModal />
          </div>
        </div>

        {/* payment methods */}
        <Card
          title="Payment Method"
          setting={
            <Button
              buttonProps={{ onClick: () => setOpenCardDetailModal(true) }}
            >
              Add new card
            </Button>
          }
        >
          <div className="mt-0">
            <div className=" flex flex-col gap-5">
              {paymentMethodsLoading && (
                <Loading className="h-[100px]" borderSize={2} />
              )}

              {!paymentMethodsLoading &&
                paymentMethods.length > 0 &&
                paymentMethods.map((paymentMethod, index) => {
                  return (
                    <div
                      className={classNames(
                        "flex items-center justify-between gap-2 pt-4 border-slate-200 dark:border-dark-2",
                        {
                          "border-t ": index > 0,
                        }
                      )}
                      key={index}
                    >
                      <div className="flex items-center gap-0.5">
                        {/* CardImage */}
                        <Text className="text-sm font-medium">
                          {paymentMethod.Brand || "Card"}
                        </Text>
                        <Text className="text-sm">ending in</Text>
                        <Text className="text-sm font-medium">
                          {paymentMethod.LastFour}
                        </Text>
                        {paymentMethod.IsDefault && (
                          <Text className="text-xs ml-2" type="primary-alert">
                            default
                          </Text>
                        )}
                      </div>
                      <div className="w-8 flex justify-center items-center">
                        <Dropdown
                          positionClassName="!w-40"
                          menu={[
                            {
                              key: "setAsDefault",
                              label: "Set as default",
                              onClick(_, close) {
                                close();
                                handleSetAsDefault(paymentMethod.Id);
                              },
                              loading: paymentMethodActionLoading,
                              disabled: paymentMethod.IsDefault,
                            },
                            {
                              type: "divider",
                            },
                            {
                              key: "delete",
                              label: "Delete",
                              danger: true,
                              onClick(item, close) {
                                close();
                                setDeletePaymentMethod(paymentMethod);
                              },
                              disabled: paymentMethod.IsDefault,
                            },
                          ]}
                          type="simple"
                        >
                          <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                            <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                          </div>
                        </Dropdown>
                      </div>
                    </div>
                  );
                })}
            </div>
            {!paymentMethodsLoading && paymentMethods.length === 0 && (
              <EmptyList
                text={
                  <div className="flex flex-col items-center gap-2">
                    No information found
                    <Button buttonProps={{ disabled: paymentMethodsLoading }}>
                      Add Card
                    </Button>
                  </div>
                }
              />
            )}
          </div>

          <Modal
            isOpen={openCardDetailModal}
            onClose={() => setOpenCardDetailModal(false)}
            title="Add new payment method"
            contentClassName="max-w-lg"
          >
            <CardDetailsBox
              beforInitFn={handleBeforInitPaymentMethod}
              afterInitFn={handleAfterInitPaymentMethod}
              savingLoading={false}
              initLoading={paymentMethodsLoading || paymentMethodActionLoading}
              username={user?.FullName}
            />
          </Modal>
        </Card>

        {/* Billing Information */}
        <Card
          title="Billing Information"
          setting={
            <Button
              buttonProps={{
                onClick: () =>
                  dispatch(handleShowBillingInformationModal(true)),
              }}
            >
              Update
            </Button>
          }
        >
          <div className="flex flex-col gap-2 mt-3">
            <div className="grid grid-cols-12 gap-5">
              <div className="flex flex-col col-span-12 md:col-span-6 xl:col-span-4 gap-2">
                <Text className="text-base font-normal">
                  Aditional Information
                </Text>
                <Text className="text-sm" type="subtext">
                  Provide additional billing information such as company name ,
                  address , or VAT number.
                </Text>
              </div>
              {billingInformation && !billingInformationLoading && (
                <div className="flex flex-col col-span-12 md:col-span-3 gap-2">
                  {/* <Text className="text-sm">mohammad sayadi</Text> */}
                  <Text className="text-sm">
                    {billingInformation?.AddressLine1}
                  </Text>
                  <Text className="text-sm">
                    {billingInformation?.AddressLine2}
                  </Text>
                  <Text className="text-sm">
                    {`${
                      billingInformation && billingInformation.State
                        ? billingInformation?.State + ","
                        : ""
                    }  ${billingInformation?.ZipCode || ""} ${
                      billingInformation?.City || ""
                    }`}
                  </Text>
                  <div className="flex items-center gap-1">
                    <img
                      alt={billingInformation?.Country.Name}
                      src={getCountryFlag(billingInformation.Country.Iso3)}
                      className="w-4 h-4"
                    />
                    <Text className="text-sm">
                      {billingInformation?.Country.Name}
                    </Text>
                  </div>
                  <div className="col-span-1">
                    <Text className="text-sm">
                      {billingInformation?.VATNumber}
                    </Text>
                  </div>
                </div>
              )}
              {billingInformation === null && billingInformationLoading && (
                <Loading className="h-[100px]" borderSize={2} />
              )}
            </div>
          </div>
          <BillingInformationModal />
        </Card>

        {/* Invoice History */}
        <Card
          title="Invoice History"
          description="View or download your past invoices."
        >
          <div className="flex flex-col gap-2 mt-3">
            <Table loading={invoicesLoading}>
              <TableHead>
                <TableRow>
                  <TableTH lable="Date" />
                  <TableTH lable="Amout" />
                  <TableTH lable="Status" />
                  <TableTH lable="Description" />
                  <TableTH lable="" />
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((invoice, i) => {
                  return (
                    <TableRow key={i}>
                      <TableTD>
                        <Text className="text-sm">
                          {formatTimestamp(invoice.Date, "MMMM, YYYY").datetime}
                        </Text>
                      </TableTD>
                      <TableTD>
                        <div className="flex items-center gap-1">
                          <Text className="text-sm">
                            {currency(invoice.Amount)}
                          </Text>
                          {invoice.IsPending && (
                            <Text className="text-xs" type="warning-alert">
                              Pending
                            </Text>
                          )}
                        </div>
                      </TableTD>
                      <TableTD>
                        <div className="flex">
                          <Text
                            className="text-xs"
                            type={
                              invoice.InvoiceStatus === "ORDER_COMPLETED"
                                ? "success-alert"
                                : "neutral-alert"
                            }
                          >
                            {invoice.InvoiceStatus}
                          </Text>
                        </div>
                      </TableTD>
                      <TableTD>
                        <Text className="text-sm">
                          {invoice.InvoiceDescription}
                        </Text>
                      </TableTD>
                      <TableTD className="flex justify-end">
                        <div className="flex justify-end items-center gap-1">
                          {invoice.InvoiceStatus !== "ORDER_COMPLETED" &&
                            invoice.Actions.length > 0 && (
                              <Button
                                type="icon"
                                size="small"
                                startIcon={
                                  <CreditCardIcon className="w-4 h-4 md:w-5 md:h-5" />
                                }
                                buttonProps={{
                                  onClick: (e: any) => {
                                    e.stopPropagation();
                                    dispatch(handleConfigPayNowModal(invoice));
                                  },
                                }}
                                tooltip="Pay now"
                                loading={false}
                              />
                            )}

                          <Button
                            type="icon"
                            size="small"
                            startIcon={
                              <ArrowDownCircleIcon className="w-4 h-4 md:w-5 md:h-5" />
                            }
                            buttonProps={{
                              onClick: (e) => {
                                e.stopPropagation();
                                handleDownloadInvoice(invoice.Id);
                              },
                            }}
                            tooltip="Download"
                            loading={isInvoiceLoading(invoice.Id)}
                          />
                        </div>
                      </TableTD>
                    </TableRow>
                  );
                })}
                {invoices.length === 0 && (
                  <TableRow withoutHoverStyle={true}>
                    <TableTD colSpan={5} className="!border-0">
                      <NotData text="You don't have any invoice yet" />
                    </TableTD>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <div className="flex justify-end mt-3">
              <Pagination
                loading={invoicesLoading}
                limit={invoicesPagination.PageSize}
                offset={
                  (invoicesPagination.PageNumber - 1) *
                  invoicesPagination.PageSize
                }
                total={
                  invoicesPagination.Total !== null
                    ? invoicesPagination.Total
                    : 10
                }
                onPageChange={(offset: number, limit: number) => {
                  const newPageNumber = Math.floor(offset / limit) + 1;
                  dispatch(
                    handleChangeInvoicePagination({
                      pageNumber: newPageNumber,
                    })
                  );
                }}
              />
            </div>
          </div>
        </Card>

        {/* Subscription */}
        <Card
          title="Subscription and plans"
          // description=""
        >
          <div className="flex flex-col gap-2 mt-3">
            <Table loading={subscriptionsLoading || planGroupsLoading}>
              <TableHead>
                <TableRow>
                  <TableTH lable="Group" />
                  <TableTH lable="Plan" />
                  <TableTH lable="Date" />
                  <TableTH lable="" />
                </TableRow>
              </TableHead>

              <TableBody>
                {subscriptions
                  .map((subscription) => {
                    const planGroup = getPlanGroupByPlanId(subscription.PlanId);
                    return { planGroup, subscription }; // Return both `planGroup` and `subscription`
                  })
                  .filter(({ planGroup }) => planGroup) // Filter out any undefined planGroup
                  .sort((a, b) => {
                    const nameA = a?.planGroup?.PlanGroupName || "";
                    const nameB = b?.planGroup?.PlanGroupName || "";
                    return nameA.localeCompare(nameB); // Sort alphabetically
                  })
                  .map(({ planGroup, subscription }, i) => (
                    <TableRow key={i}>
                      <TableTD>
                        <Text className="text-sm">
                          {planGroup?.PlanGroupName}
                        </Text>
                      </TableTD>
                      <TableTD>
                        <Text className="text-sm">
                          {
                            planGroup?.PlanDetail.find(
                              (pd) => pd.Id === subscription.PlanId
                            )?.Name
                          }
                        </Text>
                      </TableTD>
                      <TableTD>
                        <Text className="text-sm">
                          {formatTimestamp(subscription.UpdatedAt).datetime}
                        </Text>
                      </TableTD>
                      <TableTD className="w-10 ">
                        <div className="w-8 flex justify-center items-center">
                          <Dropdown
                            positionClassName="!w-40"
                            menu={[
                              {
                                key: "1",
                                label: (
                                  <div className="flex items-center gap-1">
                                    <EditIcon className="w-4" />
                                    Update
                                  </div>
                                ),
                                onClick: (_, close) => {
                                  close();
                                  setRescalingPlanGruop(planGroup);
                                },
                              },
                            ]}
                            type="simple"
                          >
                            <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                              <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                            </div>
                          </Dropdown>
                        </div>
                      </TableTD>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Modal
              isOpen={!!rescalingPlanGruop}
              onClose={() => setRescalingPlanGruop(undefined)}
              title={`Update subscription plan`}
              contentClassName="!max-w-4xl"
            >
              <div className="mt-5">
                {rescalingPlanGruop?.PlanGroupId === 1 && <UniversalPlansBox />}
                {rescalingPlanGruop?.PlanGroupId === 2 && (
                  <Web3StoragePlansBox />
                )}
                {rescalingPlanGruop?.PlanGroupId === 3 && <Web3AuthPlansBox />}
                {rescalingPlanGruop?.PlanGroupId === 4 && <WorkflowPlansBox />}
                {rescalingPlanGruop?.PlanGroupId === 5 && (
                  <BlockEventsPlansBox />
                )}
              </div>
            </Modal>
          </div>
        </Card>
        <InvoicePayNowModal />
        <DeleteModal
          title="Delete a Payment"
          isOpen={deletePaymentMethod !== null}
          onClose={() => setDeletePaymentMethod(null)}
          onConfirm={() => {
            deletePaymentMethod &&
              dispatch(
                deletePaymentMethodAsync({
                  paymentId: deletePaymentMethod.Id,
                })
              ).then((action) => {
                if (action.type === "billing/delete/payment/fulfilled") {
                  dispatch(paymentMethodsAsync());
                  setDeletePaymentMethod(null);
                }
              });
          }}
          loading={paymentMethodsLoading}
          confirmString={
            paymentMethods?.find((p) => p.Id === deletePaymentMethod?.Id)
              ?.LastFour
          }
          confirmButtonType="danger"
        />
      </div>
    </>
  );
};

export default SettingBillingTab;
