import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getFilesApi,
  getFileApi,
  createFolderApi,
  uploadFileApi,
  downloadFileApi,
} from "../../apis/filesAPI";
import {
  ConflictTypes,
  IpfsCreateFolderDataType,
  IpfsFile,
  IpfsFilesStateType,
  IpfsUploadFileDataType,
} from "../../types/ipfs-file";
import { fileParentFinder } from "../../utils/file";
import { IThunkRejectValue, RootState } from "../../types";
import type { PayloadAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { changeIpfsActiveStatus } from "./ipfsPublicSlice";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

const initialState: IpfsFilesStateType = {
  loading: false,
  files: [],
  selectedFile: null,
  selectLoading: false,
  breadCrumbItems: [{ title: "My Drive" }],
  showCreateFolderModal: false,
  newFolderNameOrFile: null,
  createFolderLoading: false,
  conflictType: null,
  conflictModalLoading: false,
  hasConflict: false,
  downloadLoading: false,
};

export const fetchFilesAysnc = createAsyncThunk<
  { fileList: IpfsFile[] },
  undefined,
  IThunkRejectValue
>(
  "files/fetchFiles",
  async (_, { getState, fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const { ipfsFiles } = getState() as RootState;
      const url = fileParentFinder(ipfsFiles.breadCrumbItems);
      const response = await getFilesApi(url);
      console.log({ response });
      const { NodeStatus, Result } = response.data;
      const fileList = Result || [];

      dispatch(changeIpfsActiveStatus(NodeStatus === 2));

      return fulfillWithValue({ fileList });
    } catch (e) {
      console.log({ e });
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getFileAysnc = createAsyncThunk<
  any,
  { fileId: number },
  IThunkRejectValue
>("files/getFile", async ({ fileId }) => {
  const { data } = await getFileApi(fileId);
  return data;
});

export const createFolderAsync = createAsyncThunk<
  any,
  IpfsCreateFolderDataType,
  IThunkRejectValue
>(
  "files/folder/create",
  async (formData, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await createFolderApi(formData);
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const uploadFileAsync = createAsyncThunk<
  any,
  IpfsUploadFileDataType,
  IThunkRejectValue
>("files/file/upload", async (formData, { rejectWithValue, dispatch }) => {
  try {
    const response = await uploadFileApi(
      formData.file,
      formData.path,
      formData.strategy
    );
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const downloadFileAsync = createAsyncThunk<
  any,
  { file: IpfsFile; path: string },
  IThunkRejectValue
>("files/file/download", async ({ file, path }, { rejectWithValue }) => {
  try {
    const response = await downloadFileApi(file, path);
    // const blob = new Blob([response.data]);
    // saveAs(blob, `${file.name}`);

    // console.log("downloadFileAsync:", response);
    const blob = new Blob([response.data], { type: "application/zip" });
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.setAttribute("download", `${file.name}.zip`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // const blob = new Blob([response.data]);
    // var _file = new File([blob], "hello world.zip", {
    //   type: "application/zip",
    // });
    // saveAs(_file);

    // const zip = new JSZip();
    // zip.file("response2.zip", response.data);
    // zip
    //   .generateAsync({ type: "blob" })
    //   .then((content) => {
    //     // Save the generated zip file
    //     saveAs(content, "example.zip");
    //   })
    //   .catch((error) => {
    //     console.error("Error generating zip file:", error);
    //   });

    // Assuming responseData contains the binary data from the API
    // const responseDataUint8Array = new Uint8Array(response.data);
    // JSZip.loadAsync(responseDataUint8Array)
    //   .then((zip) => {
    //     // Iterate through each file in the ZIP archive
    //     Object.keys(zip.files).forEach((filename) => {
    //       // Extract the contents of each file
    //       zip.files[filename].async("uint8array").then((fileData) => {
    //         // Handle the fileData as needed
    //         console.log(`File ${filename}:`, fileData);
    //       });
    //     });
    //   })
    //   .catch((error) => {
    //     console.error("Error loading ZIP file:", error);
    //   });

    // const blob = new Blob([response.data], {
    //   type: "application/zip",
    // });
    // saveAs(blob, "your_file_name.zip");

    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

const filesSlice = createSlice({
  name: "files",
  initialState: initialState,
  reducers: {
    clearFiles(state) {
      state.files = initialState.files;
      state.loading = initialState.loading;
    },
    selectFile(state, action) {
      state.selectedFile = action.payload.file;
    },
    clearSelectedFile(state) {
      state.selectedFile = null;
    },
    changeSelectingLoading(state, action) {
      state.selectLoading = action.payload;
    },
    updateBreadCrumb(state, action: PayloadAction<{ title: string }>) {
      state.breadCrumbItems = [
        ...state.breadCrumbItems,
        {
          title: action.payload.title,
        },
      ];
    },
    clearBreadCrumb(state) {
      state.breadCrumbItems = initialState.breadCrumbItems;
    },
    breadCrumbRedirect(state, action) {
      const breadCrumbIndex = action.payload;
      if (typeof breadCrumbIndex !== "undefined") {
        state.breadCrumbItems = state.breadCrumbItems.slice(
          0,
          breadCrumbIndex + 1
        );
      }
    },
    handleHideCreateFolderModal: (state) => {
      state.showCreateFolderModal = false;
    },
    handleShowCreateFolderModal: (state) => {
      state.showCreateFolderModal = true;
    },
    handleShowConflictModal: (
      state,
      action: PayloadAction<{ type: ConflictTypes }>
    ) => {
      state.hasConflict = true;
      state.conflictType = action.payload.type;
    },
    handleHideConflictModal: (state) => {
      state.hasConflict = false;
      state.conflictType = null;
      state.newFolderNameOrFile = null;
    },
    handleChangeNewFolderNameOrFile: (
      state,
      action: PayloadAction<{ name: string | File | null }>
    ) => {
      state.newFolderNameOrFile = action.payload.name;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilesAysnc.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchFilesAysnc.fulfilled, (state, action) => {
        state.files = action.payload.fileList;
        state.loading = false;
        // state.activeStatus = false;
      })
      .addCase(fetchFilesAysnc.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload.message), {
            duration: 5000,
          });
      });

    builder
      .addCase(getFileAysnc.fulfilled, (state, action) => {
        state.selectedFile = action.payload;
        state.selectLoading = false;
      })
      .addCase(getFileAysnc.pending, (state, action) => {
        state.selectLoading = true;
      })
      .addCase(getFileAysnc.rejected, (state, { payload }) => {
        state.selectLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload.message));
      });

    // builder
    //   .addCase(deleteFile.fulfilled, (state, action) => {
    //     state.selectedFile = null;
    //     state.error = false;
    //   })
    //   .addCase(deleteFile.pending, (state, action) => {
    //     state.error = false;
    //   })
    //   .addCase(deleteFile.rejected, (state, action) => {
    //     state.selectedFile = null;
    //     state.error = true;
    //   });

    builder
      .addCase(createFolderAsync.pending, (state) => {
        state.createFolderLoading = true;
      })
      .addCase(createFolderAsync.fulfilled, (state) => {
        state.createFolderLoading = false;
      })
      .addCase(createFolderAsync.rejected, (state, { payload }) => {
        state.createFolderLoading = false;
        if (payload?.message)
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(downloadFileAsync.pending, (state) => {
        state.downloadLoading = true;
      })
      .addCase(downloadFileAsync.fulfilled, (state) => {
        state.downloadLoading = false;
      })
      .addCase(downloadFileAsync.rejected, (state, { payload }) => {
        state.downloadLoading = false;
        if (payload?.message)
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(uploadFileAsync.pending, (state) => {
        state.conflictModalLoading = true;
      })
      .addCase(uploadFileAsync.fulfilled, (state) => {
        state.conflictModalLoading = false;
      })
      .addCase(uploadFileAsync.rejected, (state, { payload }) => {
        state.conflictModalLoading = false;
        if (payload?.message)
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectLoading = (state: RootState) => state.ipfsFiles.loading;

export const selectSelectingLoading = (state: RootState) =>
  state.ipfsFiles.selectLoading;

export const selectFiles = (state: RootState) => state.ipfsFiles.files;

export const selectSelectedFile = (state: RootState) =>
  state.ipfsFiles.selectedFile;

export const selectBreadCrumbItems = (state: RootState) =>
  state.ipfsFiles.breadCrumbItems;

export const selectCreateFolderLoading = (state: RootState) =>
  state.ipfsFiles.createFolderLoading;

export const selectShowCreateFolderModal = (state: RootState) =>
  state.ipfsFiles.showCreateFolderModal;

export const selectShowConflictModal = (state: RootState) =>
  state.ipfsFiles.hasConflict;

export const selectNewFolderNameOrFile = (state: RootState) =>
  state.ipfsFiles.newFolderNameOrFile;

export const selectConflictType = (state: RootState) =>
  state.ipfsFiles.conflictType;
export const selectConflictModalLoading = (state: RootState) =>
  state.ipfsFiles.conflictModalLoading;

export const selectIpfsDownloadLoading = (state: RootState) =>
  state.ipfsFiles.downloadLoading;

export const {
  clearFiles,
  selectFile,
  changeSelectingLoading,
  clearSelectedFile,
  updateBreadCrumb,
  breadCrumbRedirect,
  handleShowCreateFolderModal,
  handleHideCreateFolderModal,
  handleShowConflictModal,
  handleHideConflictModal,
  handleChangeNewFolderNameOrFile,
  clearBreadCrumb,
} = filesSlice.actions;

export default filesSlice.reducer;
