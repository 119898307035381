import {
  INodeData,
  INodeOptionsValue,
  INodeParams,
  INodeParamsType,
  IVariableBody,
} from "../../../../types/workflows";

import lodash from "lodash";
import * as Yup from "yup";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../../../buttons/Button";
import Text from "../../../general/Text";
import { InfoTooltip } from "../../../general/Tooltip";
import { Formik } from "formik";
import useScriptRef from "../../../../hooks/useScriptRef";
import {
  Box,
  FormControl,
  OutlinedInput,
  Popper,
  Switch,
  // TextField,
} from "@mui/material";
import OptionParamsResponse from "./OptionParamsResponse";
import AsyncSelectWrapper from "./AsyncSelectWrapper";
import {
  convertDateStringToDateObject,
  getFileName,
  getFolderName,
} from "../../../../utils/wfHelper";
import DateCustomInput from "./DateCustomInput";
import { DarkCodeEditor } from "./editor/DarkCodeEditor";
import { LightCodeEditor } from "./editor/LightCodeEditor";
import { ReactComponent as UploadIcon } from "./../../../../assets/icons/arrow-up-tray.svg";
import { ChangeEvent, useCallback, useEffect, useRef } from "react";
import ArrayInputParameters from "./ArrayInputParameters";
import Input from "../../../inputs/Input";
import { styled } from "@mui/material/styles";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { useDjunoDesign } from "djuno-design";

interface IInputParameters {
  params: INodeParams[];
  paramsType: INodeParamsType;
  initialValues: Record<string, any>;
  nodeParamsValidation: Record<string, Yup.Schema>;
  nodeFlowData: INodeData | null;
  valueChanged: (
    formValues: Record<string, any>,
    paramsType: INodeParamsType
  ) => void;
  onSubmit: (
    formValues: Record<string, any>,
    paramsType: INodeParamsType
  ) => void;
  setVariableSelectorState: (
    variableSelectorState: boolean,
    body?: IVariableBody
  ) => void;
  onEditVariableDialogOpen: (
    input: any,
    values: Record<string, any>,
    arrayItemBody: any
  ) => void;
}

const StyledPopper = styled(Popper)({
  boxShadow:
    "0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)",
  borderRadius: "0.3rem",
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
      backgroundColor: "red",
    },
  },
});

const InputParameters: React.FC<IInputParameters> = ({
  params,
  paramsType,
  initialValues,
  nodeParamsValidation,
  nodeFlowData,
  valueChanged,
  onSubmit,
  setVariableSelectorState,
  onEditVariableDialogOpen,
  ...others
}) => {
  const scriptedRef = useScriptRef();

  const {
    theme: { mode },
  } = useDjunoDesign();

  const onChanged = (values: any) => {
    const updateValues = values;
    updateValues.submit = null;
    valueChanged(updateValues, paramsType);
  };

  const onMouseUp = (e: any, inputName: string) => {
    const cursorPosition = e.target.selectionEnd;
    const textBeforeCursorPosition = e.target.value.substring(
      0,
      cursorPosition
    );
    const textAfterCursorPosition = e.target.value.substring(
      cursorPosition,
      e.target.value.length
    );
    const path = `${paramsType}.${inputName}`;
    const body = {
      textBeforeCursorPosition,
      textAfterCursorPosition,
      path,
      paramsType,
    };
    setVariableSelectorState(true, body);
  };

  const onAddArrayItem = (
    values: Record<string, any>,
    arrayItems: any,
    arrayName: string
  ) => {
    const updateValues = {
      ...values,
      [arrayName]: arrayItems,
    };
    valueChanged(updateValues, paramsType);
  };

  const handleFolderUpload = (
    e: ChangeEvent<HTMLInputElement>,
    setFieldValue: any,
    values: Record<string, any>,
    inputName: string
  ) => {
    setVariableSelectorState(false);
    if (!e.target.files) return;
    const files = e.target.files;
    const reader = new FileReader();

    function readFile(fileIndex: number, base64Array: string[]) {
      if (fileIndex >= files.length) {
        setFieldValue(inputName, JSON.stringify(base64Array));
        const overwriteValues = {
          ...values,
          [inputName]: JSON.stringify(base64Array),
        };
        onChanged(overwriteValues);
        return;
      }
      const file = files[fileIndex];
      reader.onload = (evt) => {
        if (!evt?.target?.result) {
          return;
        }
        const { result } = evt.target;
        const value = result + `,filepath:${file.webkitRelativePath}`;
        base64Array.push(value);
        readFile(fileIndex + 1, lodash.cloneDeep(base64Array));
      };
      reader.readAsDataURL(file);
    }
    readFile(0, []);
  };

  const handleFileUpload = (
    e: ChangeEvent<HTMLInputElement>,
    setFieldValue: any,
    values: Record<string, any>,
    inputName: string
  ) => {
    setVariableSelectorState(false);
    if (!e.target.files) return;

    const file = e.target.files[0];
    const { name } = file;

    const reader = new FileReader();
    reader.onload = (evt) => {
      if (!evt?.target?.result) {
        return;
      }
      const { result } = evt.target;

      const value = result + `,filename:${name}`;
      setFieldValue(inputName, value);
      const overwriteValues = {
        ...values,
        [inputName]: value,
      };
      onChanged(overwriteValues);
    };
    reader.readAsDataURL(file);
  };

  const findMatchingOptions = (
    options: INodeOptionsValue[] = [],
    value: string
  ) => options.find((option) => option.name === value);

  const getDefaultOptionValue = () => "";

  //file and folder inputs
  const fileRef = useRef<HTMLInputElement>(null);
  const folderRef = useRef<HTMLInputElement>(null);

  const setFloderSettings = useCallback(() => {
    if (folderRef.current !== null) {
      folderRef.current.setAttribute("directory", "");
      folderRef.current.setAttribute("webkitdirectory", "");
    }
  }, []);
  useEffect(() => {
    setFloderSettings();
  }, [setFloderSettings]);

  const handleClickOnUploadFileOrFolder = (type: string) => {
    if (type === "file") {
      fileRef.current?.click();
    }
    if (type === "folder") {
      folderRef.current?.click();
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape(nodeParamsValidation)}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          setVariableSelectorState(false);
          try {
            // if (scriptedRef.current) {
            values.submit = true;
            setStatus({ success: true });
            setSubmitting(false);
            onSubmit(values, paramsType);
            // }
          } catch (err) {
            console.error(err);
            // if (scriptedRef.current) {
            const error = err as unknown as { message: string };
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
            // }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          values,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit} {...others}>
              {params.map((input) => {
                if (input.type === "file" || input.type === "folder") {
                  const inputName = input.name;
                  return (
                    <FormControl
                      key={inputName}
                      fullWidth
                      sx={{ mb: 1, mt: 1 }}
                      error={Boolean(errors[inputName])}
                    >
                      <div className="flex flex-col my-4">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-1">
                            <Text className="text-xs">{input.label}</Text>
                            {input.description && (
                              <InfoTooltip
                                content={input.description}
                                clickable
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {input.type === "file" && (
                        <Text className="text-xs mb-1">
                          {values[inputName]
                            ? getFileName(values[inputName])
                            : "Choose a file to upload"}
                        </Text>
                      )}

                      {input.type === "folder" && (
                        <Text className="text-xs">
                          {values[inputName]
                            ? getFolderName(values[inputName])
                            : "Choose a folder to upload"}
                        </Text>
                      )}

                      <Button
                        type="light"
                        buttonClassName="!w-full"
                        buttonProps={{
                          onClick: (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleClickOnUploadFileOrFolder(input.type);
                          },
                        }}
                      >
                        <UploadIcon className="w-4 h-4" />
                        {input.type === "folder"
                          ? "Upload Folder"
                          : "Upload File"}
                      </Button>
                      {input.type === "file" && (
                        <input
                          type="file"
                          ref={fileRef}
                          hidden
                          onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleFileUpload(
                              e,
                              setFieldValue,
                              values,
                              inputName
                            );
                          }}
                        />
                      )}
                      {input.type === "folder" && (
                        <input
                          ref={folderRef}
                          type="file"
                          hidden
                          onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleFolderUpload(
                              e,
                              setFieldValue,
                              values,
                              inputName
                            );
                          }}
                        />
                      )}
                      {errors[inputName] && (
                        <span
                          style={{
                            color: "red",
                            fontSize: "0.7rem",
                            fontStyle: "italic",
                          }}
                        >
                          {errors[inputName] as string}
                        </span>
                      )}
                    </FormControl>
                  );
                }

                if (input.type === "json" || input.type === "code") {
                  const inputName = input.name;

                  return (
                    <FormControl
                      key={inputName}
                      fullWidth
                      sx={{ mb: 1, mt: 1 }}
                      error={Boolean(errors[inputName])}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-1">
                          <Text className="text-xs">{input.label}</Text>
                          {input.description && (
                            <InfoTooltip
                              content={input.description}
                              clickable
                            />
                          )}
                        </div>
                        <Button
                          size="small"
                          buttonProps={{
                            onClick: (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onEditVariableDialogOpen(
                                input,
                                values,
                                undefined
                              );
                            },
                          }}
                          buttonClassName="mb-1"
                        >
                          Edit Variables
                        </Button>
                      </div>
                      <div className="border-2 h-[200px] max-h-[200px] overflow-x-hidden overflow-y-auto rounded-lg dark:border-dark-2 ">
                        {mode === "dark" ? (
                          <DarkCodeEditor
                            value={values[inputName] || ""}
                            onValueChange={(code) => {
                              setFieldValue(inputName, code);
                            }}
                            placeholder={input.placeholder}
                            type={input.type}
                            onMouseUp={(e) => onMouseUp(e, inputName)}
                            onBlur={(e) => {
                              const overwriteValues = {
                                ...values,
                                [inputName]: e.target.value,
                              };
                              onChanged(overwriteValues);
                              onMouseUp(e, inputName);
                            }}
                            style={{
                              fontSize: "0.875rem",
                              minHeight: "200px",
                              width: "100%",
                            }}
                          />
                        ) : (
                          <LightCodeEditor
                            value={values[inputName] || ""}
                            onValueChange={(code: any) => {
                              setFieldValue(inputName, code);
                            }}
                            placeholder={input.placeholder}
                            type={input.type}
                            onMouseUp={(e) => onMouseUp(e, inputName)}
                            onBlur={(e) => {
                              const overwriteValues = {
                                ...values,
                                [inputName]: e.target.value,
                              };
                              onChanged(overwriteValues);
                              onMouseUp(e, inputName);
                            }}
                            style={{
                              fontSize: "0.875rem",
                              minHeight: "200px",
                              width: "100%",
                            }}
                          />
                        )}
                      </div>
                      {errors[inputName] && (
                        <span
                          style={{
                            color: "red",
                            fontSize: "0.7rem",
                            fontStyle: "italic",
                          }}
                        >
                          {errors[inputName] as string}
                        </span>
                      )}
                    </FormControl>
                  );
                }

                if (input.type === "date") {
                  const inputName = input.name;

                  return (
                    <FormControl
                      key={inputName}
                      fullWidth
                      sx={{ mb: 1, mt: 1 }}
                      error={Boolean(errors[inputName])}
                    >
                      <div className="flex items-center gap-1">
                        <Text className="text-xs">{input.label}</Text>
                        {input.description && (
                          <InfoTooltip content={input.description} clickable />
                        )}
                      </div>
                      <DatePicker
                        customInput={
                          <DateCustomInput isDarkMode={mode === "dark"} />
                        }
                        selected={
                          convertDateStringToDateObject(values[inputName]) ||
                          null
                        }
                        showTimeInput
                        isClearable
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        onChange={(date: Date) => {
                          const value = date ? date.toISOString() : null;
                          setVariableSelectorState(false);
                          setFieldValue(inputName, value);
                          const overwriteValues = {
                            ...values,
                            [inputName]: value,
                          };
                          onChanged(overwriteValues);
                        }}
                      />
                      {errors[inputName] && (
                        <span
                          style={{
                            color: "red",
                            fontSize: "0.7rem",
                            fontStyle: "italic",
                          }}
                        >
                          {errors[inputName] as string}
                        </span>
                      )}
                    </FormControl>
                  );
                }

                if (
                  input.type === "string" ||
                  input.type === "password" ||
                  input.type === "number"
                ) {
                  const inputName = input.name;

                  return (
                    <FormControl
                      key={inputName}
                      fullWidth
                      sx={{ mb: 1, mt: 1 }}
                      error={Boolean(errors[inputName])}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-1">
                          <Text className="text-xs">{input.label}</Text>
                          {input.description && (
                            <InfoTooltip
                              content={input.description}
                              clickable
                            />
                          )}
                        </div>
                        {(input.type === "string" ||
                          input.type === "number") && (
                          <Button
                            size="small"
                            buttonProps={{
                              onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onEditVariableDialogOpen(
                                  input,
                                  values,
                                  undefined
                                );
                              },
                            }}
                            buttonClassName="mb-1"
                          >
                            Edit Variables
                          </Button>
                        )}
                      </div>
                      <OutlinedInput
                        id={inputName}
                        type={
                          input.type === "string" || input.type === "number"
                            ? "text"
                            : input.type
                        }
                        placeholder={input.placeholder}
                        multiline={!!input.rows}
                        maxRows={input.rows || 0}
                        minRows={input.rows || 0}
                        value={values[inputName] || ""}
                        name={inputName}
                        onBlur={(e) => {
                          handleBlur(e);
                          onChanged(values);
                          onMouseUp(e, inputName);
                        }}
                        onMouseUp={(e) => onMouseUp(e, inputName)}
                        onChange={handleChange}
                        sx={{
                          p: 0,
                          borderRadius: 2,
                          backgroundColor:
                            mode === "dark"
                              ? "rgb(32,36,37)"
                              : "rgb(241,245,249)",
                          border:
                            mode === "dark"
                              ? "solid 2px rgb(32,36,37)"
                              : `solid 2px rgb(241,245,249)`,
                          "&:focus": {
                            borderColor:
                              mode === "dark"
                                ? "rgb(71,85,105)"
                                : "rgb(226,232,240)",
                          },
                          "&:hover": { outline: "0" },
                          color: mode === "dark" ? "white" : "black",
                        }}
                        size="small"
                        // className="bg-secondary-100 focus:ring-0 text-sm rounded-lg block w-full p-0 dark:bg-dark-2 outline-none disabled:cursor-not-allowed disabled:bg-secondary-200 dark:disabled:bg-gray-700 dark:disabled:text-secondary-400 disabled:text-secondary-500 disabled:border-secondary-300 disabled:dark:border-gray-600"
                      />
                      {errors[inputName] && (
                        <span
                          style={{
                            color: "red",
                            fontSize: "0.7rem",
                            fontStyle: "italic",
                          }}
                        >
                          {errors[inputName] as string}
                        </span>
                      )}
                    </FormControl>
                  );
                }

                if (input.type === "boolean") {
                  const inputName = input.name;

                  return (
                    <FormControl
                      key={inputName}
                      fullWidth
                      sx={{ mb: 1, mt: 1 }}
                      error={Boolean(errors[inputName])}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-1 text-xs">
                          <Text className="text-xs">{input.label}</Text>
                          {input.description && (
                            <InfoTooltip
                              content={input.description}
                              clickable
                            />
                          )}
                        </div>
                      </div>
                      <Switch
                        checked={!!values[inputName]}
                        onChange={(event) => {
                          setVariableSelectorState(false);
                          setFieldValue(inputName, event.target.checked);
                          const overwriteValues = {
                            ...values,
                            [inputName]: event.target.checked,
                          };
                          onChanged(overwriteValues);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </FormControl>
                  );
                }

                if (input.type === "asyncOptions") {
                  const inputName = input.name;
                  return (
                    <FormControl
                      key={inputName}
                      fullWidth
                      sx={{ mb: 1, mt: 1 }}
                    >
                      <AsyncSelectWrapper
                        title={input.label}
                        description={input.description}
                        value={values[inputName]}
                        loadMethod={input.loadMethod}
                        loadFromDbCollections={
                          input.loadFromDbCollections || []
                        }
                        nodeFlowData={nodeFlowData}
                        error={JSON.stringify(errors[inputName])}
                        onChange={(selection) => {
                          const value = selection ? selection.name : "";
                          setFieldValue(inputName, value);
                          const overwriteValues = {
                            ...values,
                            [inputName]: value,
                          };
                          onChanged(overwriteValues);
                        }}
                        onMenuOpen={() => setVariableSelectorState(false)}
                        onSetError={() => {
                          const value = "";
                          setFieldValue(inputName, value);
                        }}
                      />
                    </FormControl>
                  );
                }

                if (input.type === "options") {
                  const inputName = input.name;
                  return (
                    <div className="flex flex-col my-4" key={inputName}>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-1 text-xs">
                          <Text className="text-xs">{input.label}</Text>
                          {input.description && (
                            <InfoTooltip
                              content={input.description}
                              clickable
                            />
                          )}
                        </div>
                      </div>
                      <Autocomplete
                        id={inputName}
                        freeSolo
                        onOpen={() => setVariableSelectorState(false)}
                        options={input.options || []}
                        value={
                          findMatchingOptions(
                            input.options,
                            values[inputName]
                          ) || getDefaultOptionValue()
                        }
                        onChange={(e, selection) => {
                          const value =
                            selection && typeof selection !== "string"
                              ? selection.name
                              : "";
                          setFieldValue(inputName, value);
                          const overwriteValues = {
                            ...values,
                            [inputName]: value,
                          };
                          onChanged(overwriteValues);
                        }}
                        onInputChange={(e, value) => {
                          if (!value) setFieldValue(inputName, "");
                        }}
                        onBlur={handleBlur}
                        PopperComponent={StyledPopper}
                        renderInput={(params) => (
                          <div ref={params.InputProps.ref}>
                            <Input inputProps={params.inputProps} />
                          </div>
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            <div className="flex flex-col">
                              <Text className="text-sm">{option.label}</Text>
                              <Text className="text-xs" type="subtext">
                                {option.description}
                              </Text>
                            </div>
                          </Box>
                        )}
                        sx={{
                          p: 0,
                          color:
                            mode === "dark" ? "rgb(75,85,99)" : "rgb(51,65,85)",
                        }}
                        size="small"
                        // className="text-sm block w-full p-0disabled:cursor-not-allowed disabled:bg-secondary-200 dark:disabled:bg-gray-700 dark:disabled:text-secondary-400 disabled:text-secondary-500 disabled:border-secondary-300 disabled:dark:border-gray-600 !text-slate-700 dark:!text-slate-200"
                      />
                      {errors[inputName] && (
                        <span
                          style={{
                            color: "red",
                            fontSize: "0.7rem",
                            fontStyle: "italic",
                          }}
                        >
                          {errors[inputName] as string}
                        </span>
                      )}
                      <OptionParamsResponse
                        value={values[inputName]}
                        options={input.options || []}
                      />
                    </div>
                  );
                }

                if (input.type === "array") {
                  const arrayParamItems = input.arrayParams;
                  const templateArray = input.array;
                  const inputName = input.name;
                  const arrayItemsValues = values[inputName] || [];

                  return (
                    <div className="flex flex-col my-4" key={inputName}>
                      <div className="flex items-center justify-between mb-3">
                        <div className="flex items-center gap-1 text-xs">
                          <Text className="text-xs">{input.label}</Text>
                          {input.description && (
                            <InfoTooltip
                              content={input.description}
                              clickable
                            />
                          )}
                        </div>
                      </div>
                      <ArrayInputParameters
                        initialValues={arrayItemsValues}
                        arrayParams={arrayParamItems}
                        paramsType={paramsType}
                        arrayGroupName={inputName}
                        errors={
                          errors[inputName] ? (errors[inputName] as []) : []
                        }
                        onArrayInputChange={(updateInitialValues) => {
                          setFieldValue(inputName, updateInitialValues);
                        }}
                        onArrayInputBlur={(updateInitialValues) => {
                          setFieldValue(inputName, updateInitialValues);
                          const overwriteValues = {
                            ...values,
                            [inputName]: updateInitialValues,
                          };
                          onChanged(overwriteValues);
                        }}
                        onArrayItemRemove={(updateInitialValues) => {
                          setFieldValue(inputName, updateInitialValues);
                          const overwriteValues = {
                            ...values,
                            [inputName]: updateInitialValues,
                          };
                          onChanged(overwriteValues);
                        }}
                        onArrayItemMouseUp={(variableState, body) => {
                          if (body)
                            setVariableSelectorState(variableState, body);
                          else setVariableSelectorState(variableState);
                        }}
                        onEditVariableDialogOpen={(
                          arrayItemInput,
                          arrayItemValues,
                          arrayItemIndex
                        ) => {
                          const arrayItemBody = {
                            arrayItemInput,
                            arrayItemValues,
                            arrayItemIndex,
                            initialValues: arrayItemsValues,
                          };
                          onEditVariableDialogOpen(
                            input,
                            values,
                            arrayItemBody
                          );
                        }}
                      />
                      {templateArray && (
                        <Box key={inputName} sx={{ mb: 2 }}>
                          <Button
                            type="light"
                            buttonClassName="!w-full"
                            buttonProps={{
                              onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setVariableSelectorState(false);
                                let newObj: Record<string, any> = {};
                                if (
                                  input.default &&
                                  typeof input.default === "object" &&
                                  Array.isArray(input.default) &&
                                  input.default.length
                                ) {
                                  newObj = input.default[0];
                                } else {
                                  for (
                                    let i = 0;
                                    i < templateArray.length;
                                    i += 1
                                  ) {
                                    newObj[templateArray[i].name] =
                                      templateArray[i].default || "";
                                  }
                                }
                                arrayItemsValues.push(newObj);
                                onAddArrayItem(
                                  values,
                                  arrayItemsValues,
                                  inputName
                                );
                              },
                            }}
                          >
                            Add {input.label}
                          </Button>
                        </Box>
                      )}
                    </div>
                  );
                }

                return null;
              })}

              <div className="">
                <Button
                  buttonClassName="!w-full !p-0"
                  contentClassName="w-full "
                  type="primary"
                  buttonProps={{
                    type: "submit",
                    disabled: isSubmitting || Object.keys(errors).length > 0,
                  }}
                >
                  Continue
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default InputParameters;
