import { Link, useNavigate } from "react-router-dom";
import Button from "../buttons/Button";
import Input from "../inputs/Input";
import { ForgotPasswordUrl, SignInUrl } from "../../utils/urls";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ResetPasswordSchema, validatePassword } from "../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  resetPasswordAsync,
  selectLoginLoading,
} from "../../store/auth/authSlice";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { ToastClasses } from "../modals/alerts";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { PasswordErrors } from "../../types";

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoginLoading);
  const { token } = useParams();
  const navigate = useNavigate();
  const [password_error, setPasswordErrors] = useState<PasswordErrors>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(ResetPasswordSchema),
  });

  const selectedPassword = watch("password");

  const onSubmit = (data: any) => {
    if (token) {
      dispatch(
        resetPasswordAsync({
          password: data.password,
          passwordConfirmation: data.passwordConfirmation,
          token,
        })
      ).then((action) => {
        if (action.type === "auth/reset-password/fulfilled") {
          toast.success("Your password was changed. Please sign in", {
            className: ToastClasses,
            duration: 5000,
          });
          setTimeout(() => navigate(SignInUrl), 4000);
        }
      });
    }
  };

  useEffect(() => {
    setPasswordErrors(validatePassword(selectedPassword));
  }, [selectedPassword]);

  return (
    <>
      <div className="text-slate-900 dark:text-slate-100 font-semibold text-lg mb-7 text-center">
        Reset your account
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="gap-1 flex flex-col">
          <div className="gap-5 flex flex-col">
            <Input
              label="Password"
              inputProps={{ type: "password", ...register("password") }}
              required
              placeholder="●●●●●●"
              error={errors.password?.message}
              hint={
                <div className="flex gap-1 flex-wrap">
                  <span
                    className={classNames("whitespace-nowrap", {
                      "text-success": !password_error?.length,
                    })}
                  >
                    8-64 chars
                  </span>
                  |
                  <span
                    className={classNames("whitespace-nowrap", {
                      "text-success": !password_error?.lowercase,
                    })}
                  >
                    1 lowercase
                  </span>
                  |
                  <span
                    className={classNames("whitespace-nowrap", {
                      "text-success": !password_error?.uppercase,
                    })}
                  >
                    1 uppercase
                  </span>
                  |
                  <span
                    className={classNames("whitespace-nowrap", {
                      "text-success": !password_error?.number,
                    })}
                  >
                    1 number
                  </span>{" "}
                  |
                  <span
                    className={classNames("whitespace-nowrap", {
                      "text-success": !password_error?.special_char,
                    })}
                  >
                    1 special char
                  </span>
                </div>
              }
              labelClassName="!items-start"
            />

            <Input
              label="Password confirm"
              inputProps={{
                type: "password",
                ...register("passwordConfirmation"),
              }}
              required
              placeholder="●●●●●●"
              error={errors.passwordConfirmation?.message}
            />
          </div>

          <div className="flex flex-col mt-4">
            <Button
              type="primary"
              buttonProps={{ type: "submit", disabled: loading }}
              buttonClassName="!w-full"
              loading={loading}
            >
              Reset password
            </Button>
            <div className="flex gap-1 mt-2 justify-center">
              <Link
                to={SignInUrl}
                className="text-sm text-sky-500 hover:text-sky-600"
              >
                Go back to sign in
              </Link>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default ResetPassword;
