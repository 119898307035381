import {
  createInstanceAutomatedBackupAsync,
  deleteInstanceBackupWorkflowProcessAsync,
  getInstanceSnapshotAsync,
  handleInstanceShowModal,
  selectInstance,
  selectInstanceLoading,
  selectInstanceSnapshops,
  getInstanceEnableBackupAsync,
  selectInstanceBackup,
  deleteInstanceBackupSnapshotAsync,
  selectInstanceSnapshopLoading,
  selectInstanceBackupActionLoading,
  selectInstanceBackupLoading,
} from "../../../../store/instances/instanceSlice";
import { ReactComponent as PlusIcon } from "./../../../../assets/icons/plus.svg";
import { ReactComponent as ArchiveIcon } from "./../../../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../../../assets/icons/more.svg";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { InstancesCreateUrl } from "../../../../utils/urls";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import InstancesBackupModal from "./InstancesBackupModal";
import { HighlighterText } from "../../../general/Text";
import QuestionModal, { DeleteModal } from "../../../modals/QuestionModal";
import { useSearch } from "../../../../providers/SearchProvider";
import { getInstancesAsync } from "../../../../store/instances/instancesSlice";
import { InstanceSnapshot } from "../../../../types/instance";
import {
  Alert,
  Button,
  Dropdown,
  EmptyState,
  Flex,
  SimpleTable,
  Tag,
  Typography,
} from "djuno-design";

const InstanceBackupsTab = () => {
  const [showEnableBackupsModal, setShowEnableBackupsModal] =
    useState<boolean>(false);

  const [deleteBackup, setDeleteBackup] = useState<InstanceSnapshot | null>(
    null
  );
  const [imageId, setImageId] = useState<string>("");

  const instance = useAppSelector(selectInstance);
  const loading = useAppSelector(selectInstanceLoading);

  const snapshots = useAppSelector(selectInstanceSnapshops);
  const snapshotsLoading = useAppSelector(selectInstanceSnapshopLoading);

  const backup = useAppSelector(selectInstanceBackup);
  const backupLoading = useAppSelector(selectInstanceBackupLoading);
  const backupActionLoading = useAppSelector(selectInstanceBackupActionLoading);

  const [enableBackups, setEnableBackups] = useState<boolean>(
    !backup ? false : true
  );

  const [filteredSnapshots, setFilteredSnapshots] = useState<
    InstanceSnapshot[]
  >([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (!instance) {
      dispatch(getInstancesAsync({ withoutLoading: false }));
    }
  }, [dispatch, instance]);

  useEffect(() => {
    if (instance) {
      dispatch(
        getInstanceEnableBackupAsync({
          instanceId: instance.id,
          regionName: instance.region,
        })
      ).then((action: any) => {
        if (
          action.type === "instances/enable/backup/fulfilled" &&
          action.payload &&
          action.payload.enableBackup.id
        ) {
          setImageId(action.payload.enableBackup.id);
          setEnableBackups(true);

          if (instance) {
            dispatch(
              getInstanceSnapshotAsync({ instanceId: instance.id })
            ).then((action: any) => {
              if (
                action.type === "instances/snapshot/fulfilled" &&
                action.payload
              ) {
                setFilteredSnapshots(action.payload.snapshots);
              }
            });
          }
        } else {
          setEnableBackups(false);
        }
      });
    }
  }, [dispatch, instance, imageId]);

  const handleDisableBackups = useCallback(() => {
    if (backup && imageId && instance) {
      dispatch(
        deleteInstanceBackupWorkflowProcessAsync({
          regionName: instance.region,
          backupWorkflowId: imageId,
          instanceId: instance.id,
        })
      ).then((action) => {
        if (action.type === "instance/backup/delete/fulfilled") {
          dispatch(
            getInstanceEnableBackupAsync({
              instanceId: instance.id,
              regionName: instance.region,
            })
          );
          setEnableBackups(false);
          setImageId("");
        }
      });
    }
  }, [backup, imageId, instance, dispatch]);

  useEffect(() => {
    if (instance) {
      dispatch(getInstanceSnapshotAsync({ instanceId: instance.id }));
    }
  }, [dispatch, instance]);

  useEffect(() => {
    if (snapshots) {
      const filtered = snapshots.filter((snapshot) =>
        snapshot.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredSnapshots(filtered);
    }
  }, [searchValue, snapshots]);

  return (
    <div>
      {instance && (
        <div className="mt-2 flex flex-col gap-4 w-full">
          <Typography.Text className="!text-xs md:!text-sm">
            Backups are automatic copies of youy servers disk.For every server
            there are seven slots fo backups. If all slots are full and an
            additional one is created, then the oldest backup will be deleted.
            We recommend that you power off your server before creating a backup
            to ensure data consistency on the disks. Enabling Backups for your
            server will cost 20% of your server plan per month
          </Typography.Text>
          <div className="flex items-center gap-3">
            <div>
              <Button
                // uiSize="small"
                onClick={() => {
                  dispatch(handleInstanceShowModal(instance));
                }}
              >
                Run Manual Backups
              </Button>
            </div>
            <>
              {!enableBackups ? (
                <div>
                  <Button
                    // uiSize="small"
                    onClick={() => {
                      setShowEnableBackupsModal(true);
                    }}
                  >
                    Enable Automated Backups
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    // uiSize="small"
                    loading={backupActionLoading}
                    onClick={handleDisableBackups}
                  >
                    Disable Automated Backups
                  </Button>
                </div>
              )}
            </>
          </div>

          {filteredSnapshots.length > 0 && (
            <div className="mt-5 w-full">
              <SimpleTable loading={snapshotsLoading || backupLoading}>
                <SimpleTable.Head>
                  <SimpleTable.Row>
                    <SimpleTable.TH lable="Name" />
                    <SimpleTable.TH lable="ID" />
                    <SimpleTable.TH lable="Region" />
                    <SimpleTable.TH lable="Size" />
                    <SimpleTable.TH lable="Creation date" />
                    <SimpleTable.TH lable="Status" />
                    <SimpleTable.TH lable="" />
                  </SimpleTable.Row>
                </SimpleTable.Head>
                <SimpleTable.Body>
                  {filteredSnapshots.map((snapshot, i) => (
                    <SimpleTable.Row key={i}>
                      <SimpleTable.TD className="cursor-pointer">
                        <div className="flex flex-col gap-1">
                          <HighlighterText
                            searchValue={searchValue}
                            textToHighlight={snapshot.name}
                          />
                        </div>
                      </SimpleTable.TD>
                      <SimpleTable.TD className="cursor-pointer">
                        <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                          {snapshot.id}
                        </Typography.Text>
                      </SimpleTable.TD>

                      <SimpleTable.TD className="cursor-pointer">
                        <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                          {snapshot.region}
                        </Typography.Text>
                      </SimpleTable.TD>

                      <SimpleTable.TD className="cursor-pointer">
                        <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                          {snapshot.size.toFixed(2)} GiB
                        </Typography.Text>
                      </SimpleTable.TD>

                      <SimpleTable.TD className="cursor-pointer">
                        <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                          {snapshot.creationDate}
                        </Typography.Text>
                      </SimpleTable.TD>

                      <SimpleTable.TD className="cursor-pointer">
                        <Flex>
                          <Tag
                            className="text-xs"
                            color={
                              snapshot.status === "active"
                                ? "success"
                                : "warning"
                            }
                          >
                            {snapshot.status}
                          </Tag>
                        </Flex>
                      </SimpleTable.TD>

                      <SimpleTable.TD className="w-32 gap-1">
                        <div className="h-full w-full inline-flex items-center justify-center gap-1">
                          <div className="w-8 flex justify-center items-center">
                            <Dropdown
                              itemsClassName="!p-0"
                              anchor="bottom end"
                              menu={[
                                {
                                  key: "1",
                                  label: (
                                    <div className="flex items-center gap-1">
                                      {/* <DetailsIcon className="w-4" /> */}
                                      <PlusIcon className="w-4" />
                                      Create instance
                                    </div>
                                  ),
                                  onClick: () => navigate(InstancesCreateUrl),
                                  disabled: loading,
                                },

                                {
                                  type: "divider",
                                },
                                {
                                  key: "end",
                                  label: (
                                    <div className="flex items-center gap-1">
                                      <ArchiveIcon className="w-4" />
                                      Delete
                                    </div>
                                  ),
                                  danger: true,
                                  onClick: (_, close) => {
                                    close();
                                    setDeleteBackup(snapshot);
                                  },
                                  disabled:
                                    loading || snapshot.status !== "active",
                                },
                              ]}
                            >
                              <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                                <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                              </div>
                            </Dropdown>
                          </div>
                          {/* <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" /> */}
                        </div>
                      </SimpleTable.TD>
                    </SimpleTable.Row>
                  ))}

                  {snapshots.length === 0 && (
                    <SimpleTable.Row withoutHoverStyle={true}>
                      <SimpleTable.TD colSpan={9} className="!border-0">
                        <EmptyState
                          icon={<EmptyState.PRESENTED_IMAGE_SIMPLE />}
                          text="You have not created any backup yet"
                        />
                      </SimpleTable.TD>
                    </SimpleTable.Row>
                  )}
                </SimpleTable.Body>
              </SimpleTable>
            </div>
          )}
        </div>
      )}

      <InstancesBackupModal />

      <QuestionModal
        title="Enable Backups"
        description={
          <div className="flex flex-col">
            <Typography.Text className="!text-xs md:!text-sm mb-1 mt-5">
              Enabling backups will be recurringly billed,for 20 % per month of
              your current plan.Our terms and conditions apply.
            </Typography.Text>
            <Alert uiType="warning" className="text-xs mb-3" showIcon>
              Volumes are not included in backups
            </Alert>
          </div>
        }
        isOpen={showEnableBackupsModal}
        onClose={() => {
          setShowEnableBackupsModal(false);
          setShowEnableBackupsModal(false);
        }}
        onConfirm={() => {
          setEnableBackups(true); //aslo show table
          setShowEnableBackupsModal(false);
          setShowEnableBackupsModal(false);
          if (instance) {
            dispatch(
              createInstanceAutomatedBackupAsync({
                regionName: instance?.region,
                data: {
                  instanceId: instance.id,
                  name: instance.name,
                  cron: "24 1 * * *",
                },
              })
            );
            dispatch(
              getInstanceEnableBackupAsync({
                instanceId: instance.id,
                regionName: instance.region,
              })
            ).then((action: any) => {
              if (
                action.type === "instances/enable/backup/fulfilled" &&
                action.payload
              ) {
                setImageId(action.payload.enableBackup.id);
              }
            });
            dispatch(
              getInstanceSnapshotAsync({ instanceId: instance.id })
            ).then((action: any) => {
              if (
                action.type === "instances/snapshot/fulfilled" &&
                action.payload
              ) {
                setFilteredSnapshots(action.payload.snapshots);
              }
            });
          }
        }}
        confirmButtonType="primary"
        confirmButtonText="Enable & Buy now"
        confirmButtonClassName="confirm-button-fit-content"
      />

      <DeleteModal
        title="Delete a Manual Backup"
        description={
          <div className="flex flex-col">
            <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap mb-5">
              Permanently delete
              {deleteBackup?.name} {deleteBackup?.creationDate}
            </Typography.Text>
            <Alert
              uiType="warning"
              className="text-xs mb-3"
              // icon={<ErrorIcon className="w-5 mr-1 aspect-square" />}
            >
              All of the data on this instance backup will be lost.
            </Alert>
          </div>
        }
        isOpen={deleteBackup !== null}
        onClose={() => setDeleteBackup(null)}
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        onConfirm={() => {
          deleteBackup &&
            instance &&
            dispatch(
              deleteInstanceBackupSnapshotAsync({
                instanceId: instance.id,
                imageId: deleteBackup.id,
              })
            ).then((action) => {
              if (action.type === "instance/snapshot/delete/fulfilled") {
                getInstanceSnapshotAsync({ instanceId: instance.id });
                dispatch(getInstancesAsync({ withoutLoading: false }));
                setDeleteBackup(null);
              }
              setDeleteBackup(null);
            });
        }}
        loading={snapshotsLoading}
        confirmString={deleteBackup?.name}
      />
    </div>
  );
};

export default InstanceBackupsTab;
