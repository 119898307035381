import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useState } from "react";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArrowIcon } from "./../../../assets/icons/arrow-top-right-on-square.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { useNavigate } from "react-router-dom";
import { DatabaseServiceGeneralInformationUrl } from "../../../utils/urls";
import { DeleteModal } from "../../modals/QuestionModal";
import {
  deleteDBSServiceIntegrationAsync,
  getDBSServiceIntegrationsAsync,
  handleDBSIntegrationEditor,
  selectDBSIntegrations,
  selectDBSIntegrationsActionLoading,
  selectDBSIntegrationsLoading,
  selectDatabaseService,
  selectDatabaseServiceLoading,
} from "../../../store/database/serviceSlice";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import DBSIntegrationCreateModal from "./DBSIntegrationCreateModal";
import {
  getDatabaseServicesAsync,
  selectDatabaseServices,
  selectDatabaseServicesLoading,
} from "../../../store/database/servicesSlice";
import {
  Button,
  Dropdown,
  EmptyState,
  Flex,
  SimpleTable,
  Skeleton,
  Tag,
  Typography,
} from "djuno-design";
import CustomLink from "../../CustomLink";
import { DBSIntegration } from "../../../types/database";

const DBSServiceIntegrationTab = () => {
  const service = useAppSelector(selectDatabaseService);
  const serviceLoading = useAppSelector(selectDatabaseServiceLoading);
  const integrations = useAppSelector(selectDBSIntegrations);
  const integrationsLoading = useAppSelector(selectDBSIntegrationsLoading);
  const integrationsActionLoading = useAppSelector(
    selectDBSIntegrationsActionLoading
  );
  const [deleteIntegration, setDeleteIntegrations] =
    useState<DBSIntegration | null>(null);

  const services = useAppSelector(selectDatabaseServices);
  const servicesLoading = useAppSelector(selectDatabaseServicesLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().integrations;
      if (!permissions.includes(service.engine)) {
        navigate(DatabaseServiceGeneralInformationUrl(service.id));
      } else {
        dispatch(
          getDBSServiceIntegrationsAsync({
            clusterId: service.id,
            engine: service.engine,
          })
        );
      }
    }
  }, [dispatch, navigate, service]);

  useEffect(() => {
    dispatch(getDatabaseServicesAsync());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | Database - service integrations
        </title>
        <meta name="description" content="" />
      </Helmet>

      <>
        <Flex direction="col" className="gap-3 w-full">
          <Flex items="center" justify="between">
            <div className="flex flex-col gap-1">
              <Typography.Title level={5} className="!mb-0">
                Service integration
              </Typography.Title>
            </div>
            <div className="flex items-center gap-2">
              <Button
                uiType="light"
                onClick={() => {
                  if (service) {
                    dispatch(
                      getDBSServiceIntegrationsAsync({
                        clusterId: service.id,
                        engine: service.engine,
                      })
                    );
                  }
                }}
                disabled={serviceLoading}
                className="group"
                tooltip={{ content: "Refresh" }}
              >
                <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
              <Button
                uiType="primary"
                onClick={() => dispatch(handleDBSIntegrationEditor(true))}
                disabled={serviceLoading}
                className="group"
              >
                Add an integrations
                <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
              </Button>
            </div>
          </Flex>
          <Typography.Text className="!font-light !text-sm">
            With service integration, you ca connect different solutions
          </Typography.Text>
        </Flex>

        <div className="mt-5 w-full">
          <SimpleTable
            loading={integrationsLoading}
            containerClassName="min-h-[200px]"
          >
            <SimpleTable.Head>
              <SimpleTable.Row>
                <SimpleTable.TH lable="Source" />
                <SimpleTable.TH lable="Target" />
                <SimpleTable.TH lable="Type" />
                <SimpleTable.TH lable="Status" />
                <SimpleTable.TH lable="" />
              </SimpleTable.Row>
            </SimpleTable.Head>

            <SimpleTable.Body>
              {integrations.map((integration, i) => {
                const sourceService = services.find(
                  (s) => s.id === integration.sourceServiceId
                );
                const destinationService = services.find(
                  (s) => s.id === integration.destinationServiceId
                );
                return (
                  <SimpleTable.Row key={i}>
                    <SimpleTable.TD>
                      {servicesLoading && (
                        <Skeleton style={{ width: 130, height: 30 }} />
                      )}
                      {!servicesLoading && service && (
                        <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                          {service.id === integration.sourceServiceId ? (
                            sourceService?.description
                          ) : (
                            <CustomLink
                              to={DatabaseServiceGeneralInformationUrl(
                                integration.sourceServiceId
                              )}
                              className="group/link flex items-center gap-1"
                            >
                              {sourceService?.description}
                              <ArrowIcon className="group-hover/link:text-primary-500 w-4 h-4" />
                            </CustomLink>
                          )}
                        </Typography.Text>
                      )}
                    </SimpleTable.TD>

                    <SimpleTable.TD>
                      {servicesLoading && (
                        <Skeleton style={{ width: 130, height: 30 }} />
                      )}
                      {!servicesLoading && service && (
                        <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                          {service.id === integration.destinationServiceId ? (
                            destinationService?.description
                          ) : (
                            <CustomLink
                              to={DatabaseServiceGeneralInformationUrl(
                                integration.destinationServiceId
                              )}
                              className="group/link flex items-center gap-1"
                            >
                              {destinationService?.description}
                              <ArrowIcon className="group-hover/link:text-primary-500 w-4 h-4" />
                            </CustomLink>
                          )}
                        </Typography.Text>
                      )}
                    </SimpleTable.TD>

                    <SimpleTable.TD>
                      <Typography.Text className="!text-xs md:!text-sm">
                        {integration.type}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Tag
                        className="text-xs md:text-sm"
                        color={
                          integration.status === "READY" ? "success" : "warning"
                        }
                      >
                        {integration.status}
                      </Tag>
                    </SimpleTable.TD>

                    <SimpleTable.TD className="w-32 gap-1">
                      <div className="h-full w-full inline-flex items-center justify-end gap-1">
                        <div className="w-8 flex justify-center items-center ">
                          <Dropdown
                            anchor="bottom end"
                            itemsClassName="!w-48"
                            menu={[
                              {
                                key: "end",
                                label: (
                                  <div className="flex items-center gap-1">
                                    <ArchiveIcon className="w-4" />
                                    Delete
                                  </div>
                                ),
                                danger: true,
                                onClick: (_, close) => {
                                  close();
                                  setDeleteIntegrations(integration);
                                },
                              },
                            ]}
                          >
                            <Button
                              uiSize="small"
                              uiType="icon"
                              className="!px-2"
                            >
                              <MoreIcon className="w-4 h-4" />
                            </Button>
                          </Dropdown>
                        </div>
                      </div>
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                );
              })}

              {integrations.length === 0 && (
                <SimpleTable.Row withoutHoverStyle>
                  <SimpleTable.TD colSpan={10} className="!border-0">
                    <EmptyState text="No result" />
                  </SimpleTable.TD>
                </SimpleTable.Row>
              )}
            </SimpleTable.Body>
          </SimpleTable>
        </div>
        <DBSIntegrationCreateModal />
        <DeleteModal
          title="Delete a Service Integration"
          isOpen={deleteIntegration !== null}
          onClose={() => setDeleteIntegrations(null)}
          confirmButtonText="Confirm"
          confirmButtonType="danger"
          confirmButtonClassName="w-full"
          onConfirm={() => {
            deleteIntegration &&
              service &&
              dispatch(
                deleteDBSServiceIntegrationAsync({
                  clusterId: service.id,
                  engine: service.engine,
                  integrationId: deleteIntegration.id,
                })
              ).then((action) => {
                if (
                  action.type ===
                  "service/service-integrations/delete/fulfilled"
                ) {
                  dispatch(
                    getDBSServiceIntegrationsAsync({
                      engine: service.engine,
                      clusterId: service.id,
                    })
                  );
                } else {
                }
                setDeleteIntegrations(null);
              });
          }}
          loading={integrationsActionLoading}
          confirmString={deleteIntegration?.type}
        />
      </>
    </>
  );
};

export default DBSServiceIntegrationTab;
