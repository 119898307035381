import { useEffect, useState } from "react";
// import Card from "../../general/Card";
// import Table, {
//   TableBody,
//   TableHead,
//   SimpleTable.Row,
//   SimpleTable.TD,
//   SimpleTable.TH,
// } from "../../general/Table";
// import { Select2 } from "../../inputs/Select";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  createWeb3AuthEndpointUsersDataAsync,
  deleteWeb3AuthEndpointUsersDataAsync,
  getWeb3AuthEndpointUsersDataAsync,
  selectWeb3AuthEndpoint,
  selectWeb3AuthEndpointActionLoading,
  selectWeb3AuthEndpointLoading,
  selectWeb3AuthEndpointUsersData,
  selectWeb3AuthEndpointUsersDataLoading,
} from "../../../store/web3-auth/web3AuthEndpointSlice";
import {
  Web3AuthEndpointUserData,
  Web3FieldType,
} from "../../../types/web3-auth";
import {
  getWeb3FieldTypesAsync,
  selectWeb3FieldTypesLoading,
  selectWeb3FiledTypes,
} from "../../../store/web3-auth/web3PublicSlice";
import { useSearch } from "../../../providers/SearchProvider";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as CheckIcon } from "./../../../assets/icons/check.svg";
import Highlighter from "react-highlight-words";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Web3EndpointUserDataSchema } from "../../../utils/validations";
import { DeleteModal } from "../../modals/QuestionModal";
import {
  Dropdown,
  Button,
  Typography,
  Card,
  Input,
  Checkbox,
  SimpleTable,
  Select,
} from "djuno-design";
import NotData from "../../layouts/NotData";

const UserDataCard = () => {
  const endpoint = useAppSelector(selectWeb3AuthEndpoint);
  const endpointLoading = useAppSelector(selectWeb3AuthEndpointLoading);
  const usersData = useAppSelector(selectWeb3AuthEndpointUsersData);
  const usersDataLoading = useAppSelector(
    selectWeb3AuthEndpointUsersDataLoading
  );
  const actionLoading = useAppSelector(selectWeb3AuthEndpointActionLoading);
  const [filteredUsersData, setFilteredUsersData] = useState<
    Web3AuthEndpointUserData[]
  >([]);
  const [deleteUserData, setDeleteUserData] =
    useState<Web3AuthEndpointUserData | null>(null);
  const dispatch = useAppDispatch();

  const filedTypes = useAppSelector(selectWeb3FiledTypes);
  const filedTypesLoading = useAppSelector(selectWeb3FieldTypesLoading);

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    reset,
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(
      Web3EndpointUserDataSchema(usersData.map((ud) => ud.FieldName))
    ),
  });

  useEffect(() => {
    if (endpoint)
      dispatch(getWeb3AuthEndpointUsersDataAsync(endpoint.Id.toString()));
  }, [dispatch, endpoint]);

  useEffect(() => {
    dispatch(getWeb3FieldTypesAsync()).then((action) => {
      if (action.type === "web3-auth-field-types/fulfilled") {
        const firstItem = (action.payload as { fieldTypes: Web3FieldType[] })
          .fieldTypes[0];
        setValue("FieldType", firstItem.Value.toString());
      }
    });
  }, [dispatch, setValue]);

  const { value: searchValue } = useSearch();

  useEffect(() => {
    const lookedUpFiles = usersData?.filter((userData) =>
      userData.FieldName?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredUsersData(lookedUpFiles);
  }, [dispatch, searchValue, usersData]);

  const addUserData = (data: any) => {
    if (endpoint)
      dispatch(
        createWeb3AuthEndpointUsersDataAsync({
          endpointId: endpoint.Id.toString(),
          data: { ...data, FieldType: Number(data.FieldType) },
        })
      ).then((action) => {
        if (action.type === "web3-auth/endpoint/users-data/create/fulfilled") {
          reset({ FieldType: data.FieldType, FieldName: "" });
          dispatch(getWeb3AuthEndpointUsersDataAsync(endpoint.Id.toString()));
        }
      });
  };

  return (
    <Card title="User data" description="enhance your web 3.0 user with">
      <SimpleTable
        loading={usersDataLoading || endpointLoading}
        className="table-auto"
      >
        <SimpleTable.Head>
          <SimpleTable.Row>
            <SimpleTable.TH lable="Field Name" />
            <SimpleTable.TH lable="Field Type" />
            <SimpleTable.TH lable="Is Required" />
            <SimpleTable.TH lable="Is Unique" />
            <SimpleTable.TH lable="" />
          </SimpleTable.Row>
        </SimpleTable.Head>
        <SimpleTable.Body>
          {filteredUsersData.map((userData, index) => (
            <SimpleTable.Row key={index}>
              <SimpleTable.TD>
                <Highlighter
                  highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                  className="whitespace-nowrap max-w-[110px] md:max-w-none text-ellipsis overflow-hidden text-sm font-medium"
                  searchWords={[searchValue]}
                  autoEscape={true}
                  textToHighlight={userData.FieldName}
                />
              </SimpleTable.TD>
              <SimpleTable.TD>
                <Typography.Text
                  className="!text-xs md:!text-sm"
                  uiType="warning"
                >
                  {
                    filedTypes.find((ft) => ft.Value === userData.FieldType)
                      ?.Text
                  }
                </Typography.Text>
              </SimpleTable.TD>
              <SimpleTable.TD>
                {userData.IsRequired && (
                  <CheckIcon className="w-4 aspect-square text-primary-500" />
                )}
              </SimpleTable.TD>
              <SimpleTable.TD>
                {userData.IsUnique && (
                  <CheckIcon className="w-4 aspect-square text-primary-500" />
                )}
              </SimpleTable.TD>
              <SimpleTable.TD className="w-10">
                <div className="w-8 flex justify-center items-center">
                  <Dropdown
                    anchor="bottom end"
                    itemsClassName="!w-40"
                    menu={[
                      {
                        key: "end",
                        label: (
                          <div className="flex items-center gap-1">
                            <ArchiveIcon className="w-4" />
                            Delete
                          </div>
                        ),
                        danger: true,
                        onClick: (_, close) => {
                          close();
                          setDeleteUserData(userData);
                        },
                      },
                    ]}
                  >
                    <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                      <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                    </div>
                  </Dropdown>
                </div>
              </SimpleTable.TD>
            </SimpleTable.Row>
          ))}
          {filteredUsersData.length === 0 && (
            <SimpleTable.Row withoutHoverStyle={true}>
              <SimpleTable.TD colSpan={4} className="!border-none">
                <NotData />
              </SimpleTable.TD>
            </SimpleTable.Row>
          )}
        </SimpleTable.Body>
      </SimpleTable>

      <form onSubmit={handleSubmit(addUserData)}>
        <div className="flex flex-col md:flex-row md:items-center gap-3 mt-5">
          <Controller
            control={control}
            name="FieldType"
            render={({ field: { onChange, value } }) => (
              <Select
                loading={filedTypesLoading}
                value={value}
                onChange={onChange}
                options={filedTypes.map((ft) => ({
                  label: ft.Text,
                  value: ft.Value.toString(),
                }))}
                className="!w-full md:!w-[150px]"
              />
            )}
          />
          <Input
            {...register("FieldName")}
            placeholder="field name"
            className="w-full md:w-52 lg:w-64"
            // error={errors.FieldName?.message}
          />
          <div className="flex items-center gap-3">
            <Checkbox
              inputProps={{ ...register("IsRequired") }}
              id="isRequired"
              label="is required?"
              // error={errors.IsRequired?.message}
            />
            <Checkbox
              inputProps={{
                ...register("IsUnique"),
              }}
              id="isUnique"
              label="is unique?"
              // error={errors.IsUnique?.message}
            />
          </div>
          <Button
            uiType="primary"
            type="submit"
            disabled={!isValid}
            loading={actionLoading}
            tooltip={{ content: errors.FieldType?.message }}
          >
            Add
          </Button>
        </div>
      </form>

      <DeleteModal
        title="Delete User Data"
        isOpen={deleteUserData !== null}
        onClose={() => setDeleteUserData(null)}
        description=""
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        onConfirm={() => {
          deleteUserData &&
            endpoint &&
            dispatch(
              deleteWeb3AuthEndpointUsersDataAsync({
                endpointId: endpoint.Id,
                userDataId: deleteUserData.Id,
              })
            ).then((action) => {
              if (
                action.type === "web3-auth/endpoint/users-data/delete/fulfilled"
              ) {
                dispatch(
                  getWeb3AuthEndpointUsersDataAsync(endpoint.Id.toString())
                );
              } else {
              }
              setDeleteUserData(null);
            });
        }}
        loading={actionLoading}
        // confirmString={deleteUserData?.FieldName}
      />
    </Card>
  );
};

export default UserDataCard;
