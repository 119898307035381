import { Outlet } from "react-router-dom";
import { TabOptions } from "../../types/tabs";
import {
  MarketApiApiKeysUrl,
  MarketApiOverviewUrl,
  MarketApiPlansUrl,
} from "../../utils/urls";
import { Helmet } from "react-helmet";
import MarketApiApiKeysTab from "../../components/market-api/api_keys/MarketApiApiKeysTab";
import MarketApiOverviewTab from "../../components/market-api/oweview/MarketApiOverviewTab";
import MarketApiPlansTab from "../../components/market-api/plans/MarketApiPlansTab";
import { ReactComponent as ComputerIcon } from "./../../assets/icons/computer-desktop.svg";
import { ReactComponent as KeyIcon } from "./../../assets/icons/key.svg";
import { ReactComponent as ListBulletIcon } from "./../../assets/icons/list-bullet.svg";
import { Typography, Tabs } from "djuno-design";

export const MarketApiPageMenus: TabOptions = [
  {
    label: (
      <div className="flex items-center gap-1">
        <ComputerIcon className="w-3" />
        Overview
      </div>
    ),
    url: MarketApiOverviewUrl,
    element: <MarketApiOverviewTab />,
    testId: "1th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <KeyIcon className="w-3" />
        Access Keys
      </div>
    ),
    url: MarketApiApiKeysUrl,
    element: <MarketApiApiKeysTab />,
    testId: "2th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <ListBulletIcon className="w-3" />
        Plans
      </div>
    ),
    url: MarketApiPlansUrl,
    element: <MarketApiPlansTab />,
    testId: "3th-tab",
  },
];

const MarketApiPage = () => {
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Market API</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex items-center justify-between h-20">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <Typography.Text className="font-medium text-lg">
            Market API
          </Typography.Text>
        </div>
        {/* <div className="mr-6 w-96"></div> */}
      </div>
      <div className="mx-6">
        <Tabs
          options={MarketApiPageMenus.map((o) => ({
            label: o.label,
            url: o.url,
            testId: o.testId,
          }))}
        />
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default MarketApiPage;
