import { useState, useEffect } from "react";
import { Button, Input, Modal } from "djuno-design";

const SaveWorkflowDialog = ({ show, dialogProps, onCancel, onConfirm }) => {
  //   const portalElement = document.getElementById("portal");

  const [workflowName, setWorkflowName] = useState("");
  const [isReadyToSave, setIsReadyToSave] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (workflowName.length < 3) {
      setError("Name must be at least 3 characters long.");
      setIsReadyToSave(false);
    } else {
      setError("");
      setIsReadyToSave(true);
    }
  }, [workflowName]);

  return (
    <Modal
      isOpen={show}
      onClose={onCancel}
      contentClassName="max-w-lg"
      title={dialogProps.title}
    >
      <div className="flex flex-col gap-2 mt-7">
        <Input
          inputProps={{
            id: "workflow-name",
            onChange: (e) => setWorkflowName(e.target.value),
            value: workflowName,
            type: "text",
          }}
          placeholder="My New Workflow"
          error={error}
        />

        <div className="flex items-center gap-2 justify-end mt-7">
          <Button uiType="light" onClick={onCancel}>
            {dialogProps.cancelButtonName}
          </Button>
          <Button
            uiType="primary"
            onClick={() => onConfirm(workflowName)}
            disabled={!isReadyToSave}
          >
            {dialogProps.confirmButtonName}
          </Button>
        </div>
      </div>
    </Modal>
  );

  //   return createPortal(component, portalElement);
};

export default SaveWorkflowDialog;
