import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useEffect } from "react";
import {
  selectShowEditor,
  selectSelectedS3Event,
  handleHideEditor,
  // updateS3AccessKeyAsync,
  // createS3AccessKeyAsync,
  // getS3AccessKeysAsync,
} from "../../../../store/s3/eventsSlice";
import WebhookForm from "./WebhoohForm";
import Tabs from "../../../general/Tabs";
import MySqlForm from "./MySqlForm";
import { Modal } from "djuno-design";

const S3AccessKeyEditorModal = () => {
  const isOpen = useAppSelector(selectShowEditor);
  const event = useAppSelector(selectSelectedS3Event);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen) {
      if (event) {
      } else {
      }
    }
  }, [isOpen, event]);

  const handleClearComponent = () => {};

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideEditor());
        handleClearComponent();
      }}
      contentClassName="max-w-lg"
      containerClassName="!items-start"
      title={event ? "Update event" : "Create new event"}
    >
      <Tabs
        listClassName="!px-0 mt-5"
        options={[
          {
            label: "Webhook",
            element: <WebhookForm />,
          },
          {
            label: "MySql",
            element: <MySqlForm />,
          },
        ]}
      />
    </Modal>
  );
};

export default S3AccessKeyEditorModal;
