import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  handleShowTopupModal,
  invoicesAsync,
  makeCreditOrderAsync,
  selectCloudCredit,
  selectCloudCreditLoading,
  selectShowTopupModal,
  selectTopupLoading,
  selectZeroOrderLoading,
} from "../../../store/billing/billingSlice";
import Button from "../../buttons/Button";
import Modal from "../../modals/Modal";
import { ReactComponent as TopupIcon } from "./../../../assets/icons/topup.svg";
import Text from "../../general/Text";
import Input from "../../inputs/Input";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { CustomToast } from "../../general/Toast";
import { currency } from "../../../utils/currency";
import { yupResolver } from "@hookform/resolvers/yup";
import { TopupSchema } from "../../../utils/validations";

const TopupModal = () => {
  const isOpen = useAppSelector(selectShowTopupModal);
  const topupLoading = useAppSelector(selectTopupLoading);
  const orderLoading = useAppSelector(selectZeroOrderLoading);

  const cloudCredit = useAppSelector(selectCloudCredit);
  const cloudCreditLoading = useAppSelector(selectCloudCreditLoading);

  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    register,
  } = useForm({
    resolver: yupResolver(TopupSchema),
    mode: "all",
    defaultValues: {
      Value: 10,
    },
  });

  useEffect(() => {
    if (isOpen) {
      setValue("Value", 10);
    }
  }, [isOpen, setValue]);

  const handleClose = () => {
    dispatch(handleShowTopupModal(false));
  };

  const handleSubmitForm = (data: any) => {
    dispatch(makeCreditOrderAsync({ amount: data.Value })).then((action) => {
      if (action.type === "billing/make-credit-order/fulfilled") {
        toast.success(() =>
          CustomToast("Your account has been charged successfully")
        );
        dispatch(invoicesAsync());
        handleClose();
      }
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      title={"Top up "}
      contentClassName="max-w-lg"
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="flex justify-center py-4 text-primary-400">
          <TopupIcon width={100} height={100} />
        </div>
        <div>
          <Text className="text-base">
            Your Credit shows{" "}
            <span className="font-medium">
              {currency(cloudCredit?.Balance)}
            </span>{" "}
          </Text>
          <Text className="text-sm mt-2">
            Top up to get 10% discount on every service you use
          </Text>
        </div>
        <div className="gap-5 mt-5">
          <Input
            label="Amount ($)"
            inputProps={{
              type: "number",
              ...register("Value"),
              step: "0.01",
              // value: topupValue,
              // onChange: (e: any) => {
              //   if (e.target.value === "-") {
              //     // setTopupValue("");
              //     setValue("Value", 1);
              //   } else if (Number(e.target.value) < 0) {
              //     // setTopupValue("");
              //     setValue("Value", 1);
              //   } else {
              //     // setTopupValue(e.target.value);
              //     setValue("Value", e.target.value);
              //   }
              // },
            }}
            error={errors.Value?.message}
          />
        </div>
        <div className="flex justify-end mt-5">
          <Button
            type="primary"
            buttonProps={{ type: "submit", disabled: !isValid }}
            loading={topupLoading || orderLoading}
          >
            Top up
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default TopupModal;
