import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { S3TagSchema } from "../../../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import {
  selectBucketDetails,
  getBucketDetailsAsync,
  selectShowTagEditor,
  selectBucketTagsActionLoading,
  updateBucketTagsAsync,
  handleHideTagEditor,
} from "../../../../../store/s3/buckets/bucketSlice";
import { Button, Input, Modal } from "djuno-design";

const S3TagEditorModal = () => {
  const isOpen = useAppSelector(selectShowTagEditor);
  const loading = useAppSelector(selectBucketTagsActionLoading);
  const bucketDetails = useAppSelector(selectBucketDetails);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(S3TagSchema),
  });

  const handleClearComponent = () => {
    reset();
  };

  const onSubmit = (data: any) => {
    // data:
    //key -> string
    //value -> string
    console.log(data);
    if (bucketDetails) {
      //tags
      const tags =
        "tags" in bucketDetails.details ? bucketDetails.details.tags : {};

      dispatch(
        updateBucketTagsAsync({
          name: bucketDetails.name,
          tags: { ...tags, [data.key]: data.value },
        })
      ).then((action) => {
        if (action.type === "bucket/tags/update/fulfilled") {
          handleClearComponent();
          dispatch(handleHideTagEditor());
          dispatch(getBucketDetailsAsync({ name: bucketDetails.name }));
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideTagEditor());
        handleClearComponent();
      }}
      contentClassName="max-w-lg"
      containerClassName="!items-start"
      title={"Change Access Policy"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          <Input
            label="New Tag Key"
            {...register("key")}
            error={errors.key?.message}
          />
          <Input
            label="New Tag Label"
            {...register("value")}
            error={errors.value?.message}
          />
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            onClick={(e) => {
              e.preventDefault();
              dispatch(handleHideTagEditor());
            }}
            uiType="light"
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            uiType="primary"
            disabled={loading}
            type="submit"
          >
            Set
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3TagEditorModal;
