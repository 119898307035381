import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getWebAppJobsAsync,
  selectWebApp,
  selectWebAppJobs,
  selectWebAppJobsLoading,
  selectWebAppLoading,
} from "../../../store/web-app/webAppSlice";
import NotData from "../../layouts/NotData";
import { timeAgo } from "../../../utils/date";
import { Card, Flex, Loading, Typography } from "djuno-design";
import { A } from "../../CustomLink";

const WebAppJobsTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const jobs = useAppSelector(selectWebAppJobs);
  const jobsLoading = useAppSelector(selectWebAppJobsLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (webApp) {
      dispatch(getWebAppJobsAsync({ webAppId: webApp.Id.toString() }));
    }
  }, [dispatch, webApp]);

  return (
    <>
      {jobsLoading && (
        <Card>
          <Flex items="center" justify="center" className="min-h-[300px]">
            <Loading borderSize={2} className="" />
          </Flex>
        </Card>
      )}
      {!jobsLoading && jobs.length === 0 && (
        <Card>
          <NotData
            text={
              <div className="text-sm text-center mt-5">
                <Typography.Text size="sm">
                  There are no jobs for this web service.
                </Typography.Text>
                <Typography.Text size="sm" uiType="secondary" className="mt-2">
                  See our <A>docs</A> for information on how to trigger jobs.
                </Typography.Text>
              </div>
            }
          />
        </Card>
      )}

      {!jobsLoading && jobs.length > 0 && (
        <ul className="mt-2 border dark:border-dark-2 rounded-lg bg-white dark:bg-dark-3">
          {jobs.map((job, index) => (
            <li
              key={index}
              className="p-4 border-b last:border-b-0 dark:border-dark-2"
            >
              <Flex items="center" justify="between">
                <Flex items="center" className="flex-1 gap-4">
                  <div className="flex flex-col gap-2 text-sm ">
                    <Flex items="center" className="gap-3">
                      <Typography.Text
                        size="sm"
                        className="font-medium flex items-center gap-1"
                      >
                        {job.Name}
                      </Typography.Text>
                    </Flex>
                    <Flex direction="col">
                      <Typography.Text size="sm">
                        Start Command: {job.StartCommand}
                      </Typography.Text>
                      <Typography.Text size="sm">
                        Plan: {job.Plan}
                      </Typography.Text>
                      <Typography.Text size="sm">
                        Created {timeAgo(job.CreatedAt)}
                      </Typography.Text>
                    </Flex>
                  </div>
                </Flex>
                <div className="flex items-center text-sm">
                  <Typography.Text size="sm">{job.Status}</Typography.Text>
                </div>
              </Flex>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default WebAppJobsTab;
