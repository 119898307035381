import { ReactComponent as EditIcon } from "./../../../../assets/icons/pencil-square.svg";
import { ReactComponent as CheckIcon } from "./../../../../assets/icons/check.svg";
import { ReactComponent as CloseIcon } from "./../../../../assets/icons/close.svg";
import { ReactComponent as PlusIcon } from "./../../../../assets/icons/plus.svg";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getBucketDetailsAsync,
  getBucketEncryptionAsync,
  getBucketObjectLockingAsync,
  getBucketQuotaAsync,
  getBucketRetentionAsync,
  getBucketVersioningAsync,
  handleShowAccessPolictEditor,
  handleShowEncryptionModal,
  handleShowQuotaEditor,
  handleShowRetentionEditor,
  handleShowTagEditor,
  handleShowVersioningEditor,
  selectBucketDetails,
  selectBucketDetailsLoading,
  selectBucketEncryption,
  selectBucketObjectLocking,
  selectBucketObjectLockingLoading,
  selectBucketQuota,
  selectBucketQuotaLoading,
  selectBucketRetention,
  selectBucketRetentionLoading,
  selectBucketTagsActionLoading,
  selectBucketVersioning,
  selectBucketVersioningLoading,
  selectEncryptionLoading,
  updateBucketTagsAsync,
} from "../../../../store/s3/buckets/bucketSlice";
import S3QuotaEditorModal from "../modals/buckets/S3QuotaEditorModal";
import S3AccessPolicyEditorModal from "../modals/buckets/S3AccessPolicyEditorModal";
import { humanizeSize } from "../../../../utils/file";
import { useCallback, useEffect, useState } from "react";
import QuestionModal from "../../../modals/QuestionModal";
import S3TagEditorModal from "../modals/buckets/S3TagEditorModal";
import S3RetentionEditorModal from "../modals/buckets/S3RetentionEditorModal";
import { capitalizeFirstLetter } from "../../../../utils";
import S3VersioningEditorModal from "../modals/buckets/S3VersioningEditorModal";
import S3EncryptionEditorModal from "../modals/buckets/S3EncryptionEditorModal";
import { S3NotActiveTab } from "../../../layouts/NotData";
import { selectS3Status } from "../../../../store/s3/s3PublicSlice";
import { Typography } from "djuno-design";
import { ActionText } from "../../../CustomLink";

const S3BucketOverviewTab = () => {
  const s3Status = useAppSelector(selectS3Status);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketDetailsLoading = useAppSelector(selectBucketDetailsLoading);

  const bucketEncryption = useAppSelector(selectBucketEncryption); //TODO
  const bucketEncryptionLoading = useAppSelector(selectEncryptionLoading);

  const bucketQuota = useAppSelector(selectBucketQuota);
  const bucketQuotaLoading = useAppSelector(selectBucketQuotaLoading);

  const bucketObjectLocking = useAppSelector(selectBucketObjectLocking);
  const bucketObjectLockingLoading = useAppSelector(
    selectBucketObjectLockingLoading
  );

  const [deleteTag, setDeleteTag] = useState<{
    [key: string]: string;
  } | null>(null);
  const tagsActionLoading = useAppSelector(selectBucketTagsActionLoading);

  const bucketRetention = useAppSelector(selectBucketRetention);
  const bucketRetentionLoading = useAppSelector(selectBucketRetentionLoading);

  const bucketVersioning = useAppSelector(selectBucketVersioning);
  const bucketVersioningLoading = useAppSelector(selectBucketVersioningLoading);

  const dispatch = useAppDispatch();

  const getBucketSettings = useCallback(() => {
    if (bucketDetails?.name) {
      dispatch(getBucketQuotaAsync({ name: bucketDetails.name }));
      dispatch(getBucketObjectLockingAsync({ name: bucketDetails.name }));
      dispatch(getBucketRetentionAsync({ name: bucketDetails.name }));
      dispatch(getBucketVersioningAsync({ name: bucketDetails.name }));
      dispatch(getBucketEncryptionAsync({ name: bucketDetails.name }));
    }
  }, [bucketDetails?.name, dispatch]);

  useEffect(() => {
    getBucketSettings();
  }, [getBucketSettings]);

  const handleDelete = () => {
    if (deleteTag && bucketDetails) {
      let allTags: { [key: string]: string } = {};
      if ("tags" in bucketDetails.details && bucketDetails.details.tags) {
        allTags = bucketDetails.details.tags;
      }
      const updatedTags = Object.fromEntries(
        Object.entries(allTags).filter(
          ([key]) => key !== Object.keys(deleteTag)[0]
        )
      );
      dispatch(
        updateBucketTagsAsync({ name: bucketDetails.name, tags: updatedTags })
      ).then((action) => {
        if (action.type === "bucket/tags/update/fulfilled") {
          dispatch(getBucketDetailsAsync({ name: bucketDetails.name }));
        } else {
        }
        setDeleteTag(null);
      });
    }
  };

  if (!s3Status) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <div className="flex flex-col w-full divide-y dark:divide-dark-1 space-y-6">
        <div className="grid grid-cols-12  gap-y-4 md:gap-y-8">
          <div className="col-span-12 md:col-span-4 flex items-center gap-2">
            <Typography.Text className="whitespace-nowrap !text-xs md:!text-sm">
              Access Policy:
            </Typography.Text>
            <ActionText
              loading={bucketDetailsLoading}
              text={
                typeof bucketDetails?.definition === "undefined"
                  ? "n/a"
                  : bucketDetails?.access
              }
              icon={<EditIcon className="w-3" />}
              onClick={() => dispatch(handleShowAccessPolictEditor())}
            />
          </div>
          <div className="col-span-12 md:col-span-4 flex items-center gap-2">
            <Typography.Text className="whitespace-nowrap !text-xs md:!text-sm">
              Encryption:
            </Typography.Text>
            <ActionText
              text="Disabled"
              icon={<EditIcon className="w-3" />}
              onClick={() => dispatch(handleShowEncryptionModal())}
              loading={bucketEncryptionLoading}
            />
          </div>
          <div className="col-span-12 md:col-span-4 flex items-center gap-2">
            <Typography.Text className="whitespace-nowrap !text-xs md:!text-sm">
              Reported Usage:
            </Typography.Text>
            <ActionText
              text={
                bucketDetails && bucketDetails.size
                  ? humanizeSize(bucketDetails?.size).join("B")
                  : "0B"
              }
              type="simple"
              loading={bucketDetailsLoading}
            />
          </div>
          <div className="col-span-12 md:col-span-4 flex items-center gap-2">
            <Typography.Text className="whitespace-nowrap !text-xs md:!text-sm">
              Object Locking:
            </Typography.Text>
            <ActionText
              text={
                bucketObjectLocking &&
                "object_locking_enabled" in bucketObjectLocking &&
                bucketObjectLocking.object_locking_enabled
                  ? "Enabled"
                  : "Disabled"
              }
              type="simple"
              icon={
                bucketObjectLocking &&
                "object_locking_enabled" in bucketObjectLocking &&
                bucketObjectLocking.object_locking_enabled ? (
                  <CheckIcon className="w-3" />
                ) : (
                  <CloseIcon className="w-3" />
                )
              }
              loading={bucketObjectLockingLoading}
            />
          </div>
          <div className="col-span-12 md:col-span-4 flex items-center gap-2">
            <Typography.Text className="whitespace-nowrap !text-xs md:!text-sm">
              Quota:
            </Typography.Text>
            <ActionText
              text={
                bucketQuota !== null &&
                typeof bucketQuota === "object" &&
                "quota" in bucketQuota
                  ? "Enabled"
                  : "Disabled"
              }
              loading={bucketQuotaLoading}
              icon={<EditIcon className="w-3" />}
              onClick={() => dispatch(handleShowQuotaEditor())}
            />
          </div>
          <div className="col-span-12 md:col-span-4 flex items-center gap-2">
            <Typography.Text className="whitespace-nowrap !text-xs md:!text-sm">
              Hard Quota:
            </Typography.Text>
            <ActionText
              text={
                bucketQuota !== null &&
                typeof bucketQuota === "object" &&
                "quota" in bucketQuota
                  ? humanizeSize(bucketQuota.quota).join("B")
                  : "Disabled"
              }
              type="simple"
              loading={bucketQuotaLoading}
            />
          </div>
          <div className="col-span-12 md:col-span-4 flex items-center gap-2">
            <div className="flex items-center flex-wrap gap-2">
              <Typography.Text className="whitespace-nowrap !text-xs md:!text-sm">
                Tags:
              </Typography.Text>
              {bucketDetails &&
                bucketDetails.details &&
                "tags" in bucketDetails.details &&
                bucketDetails.details.tags &&
                Object.entries(bucketDetails.details.tags).length > 0 &&
                Object.entries(bucketDetails.details.tags).map(
                  ([key, value], i) => (
                    <ActionText
                      key={i}
                      type="simple"
                      text={`${key} : ${value}`}
                      icon={
                        <CloseIcon
                          onClick={() => setDeleteTag({ [key]: value })}
                          className="w-3 hover:scale-110 hover:text-slate-900 dark:hover:text-slate-50 hover:cursor-pointer"
                        />
                      }
                      loading={bucketDetailsLoading}
                    />
                  )
                )}

              <ActionText
                text="Add Tag"
                onClick={() => dispatch(handleShowTagEditor())}
                icon={<PlusIcon className="w-3" />}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <Typography.Title level={6} className="my-4">
            Versioning
          </Typography.Title>
          <div className="grid grid-cols-12 gap-y-4 md:gap-y-8">
            <div className="col-span-12 md:col-span-4 flex items-center gap-2">
              <Typography.Text className="whitespace-nowrap !text-xs md:!text-sm">
                Current Status:
              </Typography.Text>
              <ActionText
                text={
                  bucketVersioning?.status === "Enabled"
                    ? "Versioned"
                    : "Unversioned"
                }
                icon={<EditIcon className="w-3" />}
                onClick={() =>
                  bucketObjectLocking &&
                  (("object_locking_enabled" in bucketObjectLocking &&
                    !bucketObjectLocking.object_locking_enabled) ||
                    !("object_locking_enabled" in bucketObjectLocking)) &&
                  dispatch(handleShowVersioningEditor())
                }
                loading={bucketVersioningLoading}
              />
            </div>
          </div>
        </div>
        {bucketObjectLocking !== null &&
          "object_locking_enabled" in bucketObjectLocking &&
          bucketObjectLocking.object_locking_enabled && (
            <div className="flex flex-col">
              <Typography.Title level={6} className="my-4">
                Retention
              </Typography.Title>
              <div className="grid grid-cols-12 gap-y-4 md:gap-y-8">
                <div className="col-span-12 md:col-span-4 flex items-center gap-2">
                  <Typography.Text className="whitespace-nowrap !text-xs md:!text-sm">
                    Retention:
                  </Typography.Text>
                  <ActionText
                    text={bucketRetention === null ? "Disabled" : "Enabled"}
                    icon={<EditIcon className="w-3" />}
                    onClick={() => dispatch(handleShowRetentionEditor())}
                    loading={bucketRetentionLoading}
                  />
                </div>
                <div className="col-span-12 md:col-span-4 flex items-center gap-2">
                  <Typography.Text className="whitespace-nowrap !text-xs md:!text-sm">
                    Mode:
                  </Typography.Text>
                  {bucketRetention && (
                    <ActionText
                      text={capitalizeFirstLetter(bucketRetention.mode)}
                      type="simple"
                      loading={bucketRetentionLoading}
                    />
                  )}
                </div>
                <div className="col-span-12 md:col-span-4 flex items-center gap-2">
                  <Typography.Text className="whitespace-nowrap !text-xs md:!text-sm">
                    Validity:
                  </Typography.Text>
                  {bucketRetention && (
                    <ActionText
                      text={`${
                        bucketRetention.validity
                      } ${capitalizeFirstLetter(bucketRetention?.unit)}`}
                      type="simple"
                      loading={bucketRetentionLoading}
                    />
                  )}
                </div>
              </div>
            </div>
          )}

        <S3QuotaEditorModal />
        <S3AccessPolicyEditorModal />
        <S3EncryptionEditorModal />
        <QuestionModal
          title="Delete Tag"
          description={
            <>
              Are you sure you want to delete the tag{" "}
              <span className="font-semibold">
                {deleteTag
                  ? `${Object.keys(deleteTag)[0]}:${
                      Object.values(deleteTag)[0]
                    }`
                  : ""}
              </span>
              ?
            </>
          }
          isOpen={deleteTag !== null}
          onClose={() => setDeleteTag(null)}
          onConfirm={handleDelete}
          loading={tagsActionLoading}
          confirmButtonType="danger"
        />
        <S3TagEditorModal />
        <S3RetentionEditorModal />
        <S3VersioningEditorModal />
      </div>
    </>
  );
};

export default S3BucketOverviewTab;
