import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { InstancesCreateUrl } from "../../../utils/urls";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { HighlighterText } from "../../general/Text";
import { DeleteModal } from "../../modals/QuestionModal";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { useSearch } from "../../../providers/SearchProvider";
import {
  deleteInstancesBackupSnapshotAsync,
  getInstancesAsync,
  getInstancesSnapshotsAsync,
  selectInstances,
  selectInstancesLoading,
  selectInstancesSnapshotsAll,
  selectInstancesSnapshotsAllLoading,
} from "../../../store/instances/instancesSlice";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { InstanceSnapshot } from "../../../types/instance";
import {
  Alert,
  Button,
  Dropdown,
  EmptyState,
  Flex,
  SimpleTable,
  Tag,
  Typography,
} from "djuno-design";

const InstancesBackupsTab = () => {
  const [deleteSnapshot, setDeleteSnapshot] = useState<InstanceSnapshot | null>(
    null
  );

  const instances = useAppSelector(selectInstances);
  const loading = useAppSelector(selectInstancesLoading);

  const snapshotsAll = useAppSelector(selectInstancesSnapshotsAll);
  const snapshotsAllLoading = useAppSelector(
    selectInstancesSnapshotsAllLoading
  );

  const [filteredSnapshots, setFilteredSnapshots] = useState<
    InstanceSnapshot[]
  >([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (!instances) {
      dispatch(getInstancesAsync({ withoutLoading: false }));
    }
  }, [dispatch, instances]);

  useEffect(() => {
    dispatch(getInstancesSnapshotsAsync({}));
  }, [dispatch]);

  useEffect(() => {
    if (snapshotsAll) {
      const filtered = snapshotsAll.filter((snapshot) =>
        snapshot.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredSnapshots(filtered);
    }
  }, [searchValue, snapshotsAll]);

  return (
    <div>
      {instances && (
        <div className="mt-2 flex flex-col gap-4 w-full">
          <div className="mt-5 w-full">
            <SimpleTable loading={snapshotsAllLoading}>
              <SimpleTable.Head>
                <SimpleTable.Row>
                  <SimpleTable.TH lable="Name" />
                  <SimpleTable.TH lable="ID" />
                  <SimpleTable.TH lable="Region" />
                  <SimpleTable.TH lable="Size" />
                  <SimpleTable.TH lable="Creation date" />
                  <SimpleTable.TH lable="Status" />
                  <SimpleTable.TH lable="" />
                </SimpleTable.Row>
              </SimpleTable.Head>
              <SimpleTable.Body>
                {filteredSnapshots.map((snapshot, i) => (
                  <SimpleTable.Row key={i}>
                    <SimpleTable.TD className="cursor-pointer">
                      <div className="flex flex-col gap-1">
                        <HighlighterText
                          searchValue={searchValue}
                          textToHighlight={snapshot.name}
                        />
                      </div>
                    </SimpleTable.TD>
                    <SimpleTable.TD className="cursor-pointer">
                      <Typography.Text className="!text-xs md:!text-sm !whitespace-nowrap">
                        {snapshot.id}
                      </Typography.Text>
                    </SimpleTable.TD>

                    <SimpleTable.TD className="cursor-pointer">
                      <Typography.Text className="!text-xs md:!text-sm !whitespace-nowrap">
                        {snapshot.region}
                      </Typography.Text>
                    </SimpleTable.TD>

                    <SimpleTable.TD className="cursor-pointer">
                      <Typography.Text className="!text-xs md:!text-sm !whitespace-nowrap">
                        {snapshot.size.toFixed(2)} GiB
                      </Typography.Text>
                    </SimpleTable.TD>

                    <SimpleTable.TD className="cursor-pointer">
                      <Typography.Text className="!text-xs md:!text-sm !whitespace-nowrap">
                        {snapshot.creationDate}
                      </Typography.Text>
                    </SimpleTable.TD>

                    <SimpleTable.TD className="cursor-pointer">
                      <Flex>
                        <Tag
                          // className="text-xs whitespace-nowrap"
                          color={
                            snapshot.status === "active" ? "success" : "warning"
                          }
                        >
                          {snapshot.status}
                        </Tag>
                      </Flex>
                    </SimpleTable.TD>

                    <SimpleTable.TD className="w-32 gap-1">
                      <div className="h-full w-full inline-flex items-center justify-center gap-1">
                        <div className="w-8 flex justify-center items-center">
                          <Dropdown
                            itemsClassName="!p-0"
                            anchor="bottom end"
                            menu={[
                              {
                                key: "1",
                                label: (
                                  <div className="flex items-center gap-1">
                                    {/* <DetailsIcon className="w-4" /> */}
                                    <PlusIcon className="w-4" />
                                    Create instance
                                  </div>
                                ),
                                onClick: () => navigate(InstancesCreateUrl),
                                disabled: loading,
                              },

                              {
                                type: "divider",
                              },
                              {
                                key: "end",
                                label: (
                                  <div className="flex items-center gap-1">
                                    <ArchiveIcon className="w-4" />
                                    Delete
                                  </div>
                                ),
                                danger: true,
                                onClick: (_, close) => {
                                  close();
                                  setDeleteSnapshot(snapshot);
                                },
                                disabled:
                                  loading || snapshot.status !== "active",
                              },
                            ]}
                          >
                            <Button
                              uiType="icon"
                              uiSize="small"
                              className="!px-2"
                            >
                              <MoreIcon className="w-4 h-4" />
                            </Button>
                          </Dropdown>
                        </div>
                        {/* <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" /> */}
                      </div>
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                ))}

                {filteredSnapshots.length === 0 && (
                  <SimpleTable.Row withoutHoverStyle={true}>
                    <SimpleTable.TD colSpan={9} className="!border-0">
                      <EmptyState text="You have not created any backup yet" />
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                )}
              </SimpleTable.Body>
            </SimpleTable>
          </div>
        </div>
      )}

      <DeleteModal
        title="Delete a Backup"
        description={
          <div className="flex flex-col">
            <Typography.Text className="mb-5">
              Permanently delete
              {deleteSnapshot?.name} {deleteSnapshot?.creationDate}
            </Typography.Text>
            <Alert
              uiType="warning"
              className="text-xs mb-3"
              // icon={<ErrorIcon className="w-5 mr-1 aspect-square" />}
            >
              All of the data on this instance backup will be lost.
            </Alert>
          </div>
        }
        isOpen={deleteSnapshot !== null}
        onClose={() => setDeleteSnapshot(null)}
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        onConfirm={() => {
          deleteSnapshot &&
            dispatch(
              deleteInstancesBackupSnapshotAsync({
                imageId: deleteSnapshot.id,
              })
            ).then((action) => {
              if (action.type === "instances/snapshot/delete/fulfilled") {
                dispatch(getInstancesSnapshotsAsync({}));
                dispatch(getInstancesAsync({ withoutLoading: false }));
                setDeleteSnapshot(null);
              }
              setDeleteSnapshot(null);
            });
        }}
        loading={snapshotsAllLoading}
        confirmString={deleteSnapshot?.name}
      />
    </div>
  );
};

export default InstancesBackupsTab;
