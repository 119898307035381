import NotData from "../../components/layouts/NotData";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect, useState } from "react";
import { ReactComponent as PlusIcon } from "./../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../assets/icons/archive-box.svg";
import { ReactComponent as DetailsIcon } from "./../../assets/icons/bars-3-bottom-left.svg";
import { useNavigate } from "react-router-dom";
import { DeleteModal } from "../../components/modals/QuestionModal";
import { HighlighterText } from "../../components/general/Text";
import { useSearch } from "../../providers/SearchProvider";
import CreateWeb3EndpointModal from "../../components/web3-auth/CreateWeb3EndpointModal";
import { Helmet } from "react-helmet";
import { WebApp } from "../../types/web-app";
import {
  deleteWebAppAsync,
  getWebAppsAsync,
  selectWebApps,
  selectWebAppsActionLoading,
  selectWebAppsLoading,
} from "../../store/web-app/webAppsSlice";
import { timeAgo } from "../../utils/date";
import { WebAppCreateUrl, WebAppUrl } from "../../utils/urls";
import { handleSetWebApp } from "../../store/web-app/webAppSlice";
import { Button, Dropdown, Flex, SimpleTable, Typography } from "djuno-design";

const WebAppsPage = () => {
  const webApps = useAppSelector(selectWebApps);
  const [filteredWebApps, setFilteredWebApps] = useState<WebApp[]>([]);
  const loading = useAppSelector(selectWebAppsLoading);
  const actionLoading = useAppSelector(selectWebAppsActionLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [deleteWebApp, setDeleteWebApp] = useState<WebApp | null>(null);
  const { value: searchValue } = useSearch();

  useEffect(() => {
    // dispatch(getWebAppsAsync());
  }, [dispatch]);

  useEffect(() => {
    const lookedUpFiles = webApps.filter((webApp) =>
      webApp.Name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredWebApps(lookedUpFiles);
  }, [dispatch, searchValue, webApps]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Web Apps</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="p-6">
        <Flex items="center" justify="between">
          <Typography.Title level={5}>Web Apps</Typography.Title>
          <div className="">
            <Button
              uiType="primary"
              onClick={() => navigate(WebAppCreateUrl)}
              className="group"
            >
              Create Web App
              <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
          </div>
        </Flex>

        <div className="mt-5 w-full">
          <SimpleTable loading={loading}>
            <SimpleTable.Head>
              <SimpleTable.Row>
                <SimpleTable.TH>Service Name</SimpleTable.TH>
                <SimpleTable.TH>Status</SimpleTable.TH>
                <SimpleTable.TH>Type</SimpleTable.TH>
                <SimpleTable.TH>Runtime</SimpleTable.TH>
                <SimpleTable.TH>Region</SimpleTable.TH>
                <SimpleTable.TH>Last Deployed</SimpleTable.TH>
                <SimpleTable.TH />
              </SimpleTable.Row>
            </SimpleTable.Head>
            <SimpleTable.Body>
              {filteredWebApps.map((webApp, i) => (
                <SimpleTable.Row
                  key={i}
                  onClick={() => {
                    dispatch(handleSetWebApp(webApp));
                    navigate(WebAppUrl(webApp.Id.toString()));
                  }}
                >
                  <SimpleTable.TD>
                    <Flex
                      items="center"
                      justify="between"
                      className="flex items-center flex-row justify-between space-x-3"
                    >
                      <HighlighterText
                        searchValue={searchValue}
                        textToHighlight={webApp.Name}
                      />
                    </Flex>
                  </SimpleTable.TD>
                  <SimpleTable.TD>
                    <Typography.Text className="!text-xs md:!text-sm">
                      {webApp.Status}
                    </Typography.Text>
                  </SimpleTable.TD>

                  <SimpleTable.TD>
                    <Typography.Text className="!text-xs md:!text-sm">
                      {webApp.Type}{" "}
                    </Typography.Text>
                  </SimpleTable.TD>

                  <SimpleTable.TD>
                    <Typography.Text className="!text-xs md:!text-sm">
                      {webApp.RunTime}{" "}
                    </Typography.Text>
                  </SimpleTable.TD>

                  <SimpleTable.TD>
                    <Typography.Text className="!text-xs md:!text-sm">
                      {webApp.Region.title}
                    </Typography.Text>
                  </SimpleTable.TD>

                  <SimpleTable.TD>
                    <Typography.Text className="!text-xs md:!text-sm">
                      {timeAgo(webApp.LastDeployed)}
                    </Typography.Text>
                  </SimpleTable.TD>

                  <SimpleTable.TD className="w-32 gap-1">
                    <div className="h-full w-full inline-flex items-center justify-end gap-1 px-4">
                      <div className="w-8 flex justify-center items-center">
                        <Dropdown
                          anchor="bottom end"
                          menu={[
                            {
                              key: "1",
                              label: (
                                <div className="flex items-center gap-1">
                                  <DetailsIcon className="w-4" />
                                  Get details
                                </div>
                              ),
                              onClick: () => {
                                dispatch(handleSetWebApp(webApp));
                                navigate(WebAppUrl(webApp.Id.toString()));
                              },
                              disabled: loading,
                            },
                            {
                              type: "divider",
                            },
                            {
                              key: "end",
                              label: (
                                <div className="flex items-center gap-1">
                                  <ArchiveIcon className="w-4" />
                                  Delete
                                </div>
                              ),
                              danger: true,
                              onClick: (_, close) => {
                                close();
                                setDeleteWebApp(webApp);
                              },
                              disabled: loading,
                            },
                          ]}
                        >
                          <Button
                            uiType="icon"
                            uiSize="small"
                            className="!px-2"
                          >
                            <MoreIcon className="w-4 h-4" />
                          </Button>
                        </Dropdown>
                      </div>
                      <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                    </div>
                  </SimpleTable.TD>
                </SimpleTable.Row>
              ))}

              {webApps.length === 0 && (
                <SimpleTable.Row withoutHoverStyle>
                  <SimpleTable.TD colSpan={7} className="!border-0">
                    <NotData text="You have not created any web app yet" />
                  </SimpleTable.TD>
                </SimpleTable.Row>
              )}
            </SimpleTable.Body>
          </SimpleTable>
        </div>
        <CreateWeb3EndpointModal />

        <DeleteModal
          title="Delete Web App"
          isOpen={deleteWebApp !== null}
          onClose={() => setDeleteWebApp(null)}
          description=""
          confirmButtonText="Confirm"
          confirmButtonType="danger"
          confirmButtonClassName="w-full"
          onConfirm={() => {
            deleteWebApp &&
              dispatch(deleteWebAppAsync({ id: deleteWebApp.Id })).then(
                (action) => {
                  if (action.type === "web-apps/delete/fulfilled") {
                    dispatch(getWebAppsAsync());
                  } else {
                  }
                  setDeleteWebApp(null);
                }
              );
          }}
          loading={actionLoading}
          confirmString={deleteWebApp?.Name}
        />
      </div>
    </>
  );
};
export default WebAppsPage;
