import { Helmet } from "react-helmet";
import { TabOptions } from "../../types/tabs";
import { ReactComponent as ComputerIcon } from "./../../assets/icons/computer-desktop.svg";
import { ReactComponent as KeyIcon } from "./../../assets/icons/key.svg";
import { ReactComponent as CircleStackIcon } from "./../../assets/icons/circle-stack.svg";
// import { ReactComponent as ListBulletIcon } from "./../../assets/icons/list-bullet.svg";
import {
  InstancesBackupsUrl,
  // InstancesPlansUrl,
  InstancesSshKeysUrl,
  InstancesUrl,
  InstancesVolumesUrl,
} from "../../utils/urls";
import InstancesTab from "../../components/instances/InstancesTab";
import { Outlet } from "react-router-dom";
import InstancesSshKeysTab from "../../components/instances/ssh-keys/InstancesSshKeysTab";
// import InstancesPlansTab from "../../components/instances/plans/InstancesPlansTab";
import InstancesVolumesTab from "../../components/instances/volumes/InstancesVolumesTab";
import InstancesBackupsTab from "../../components/instances/backups/InstancesBackupsTab";
import { ReactComponent as BackupIcon } from "../../assets/icons/arrow-up-on-square.svg";
import { Tabs, Typography } from "djuno-design";

export const InstancesPageMenus: TabOptions = [
  {
    label: (
      <div className="flex items-center gap-1">
        <ComputerIcon className="w-3" />
        Instances
      </div>
    ),
    url: InstancesUrl,
    element: <InstancesTab />,
    testId: "1th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <KeyIcon className="w-3" />
        SSH keys
      </div>
    ),
    url: InstancesSshKeysUrl,
    element: <InstancesSshKeysTab />,
    testId: "2th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <CircleStackIcon className="w-3" />
        Volumes
      </div>
    ),
    url: InstancesVolumesUrl,
    element: <InstancesVolumesTab />,
    testId: "3th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <BackupIcon className="w-3" />
        Backups
      </div>
    ),
    url: InstancesBackupsUrl,
    element: <InstancesBackupsTab />,
    testId: "3th-tab",
  },
  // {
  //   label: (
  //     <div className="flex items-center gap-1">
  //       <ListBulletIcon className="w-3" />
  //       Plans
  //     </div>
  //   ),
  //   url: InstancesPlansUrl,
  //   element: <InstancesPlansTab />,
  // },
];

const InstancesPage = () => {
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Instances</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex items-center justify-between h-20">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <Typography.Title level={5} className="!mb-0">
            Instances
          </Typography.Title>
        </div>
        <div className="mr-6 w-96"></div>
      </div>
      <div className="mx-6">
        <Tabs
          options={InstancesPageMenus.map((o) => ({
            label: o.label,
            url: o.url,
            testId: o.testId,
          }))}
        />
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default InstancesPage;
