import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useSearch } from "../../../providers/SearchProvider";
import Highlighter from "react-highlight-words";
import {
  getWeb3AuthEndpointUsersAsync,
  handleUserDataInfoModal,
  selectWeb3AuthEndpoint,
  selectWeb3AuthEndpointLoading,
  selectWeb3AuthEndpointUsers,
  selectWeb3AuthEndpointUsersLoading,
} from "../../../store/web3-auth/web3AuthEndpointSlice";
import { Web3AuthEndpointUser } from "../../../types/web3-auth";
import { timeAgo } from "../../../utils/date";
import { HighlighterText } from "../../general/Text";
import { ReactComponent as EyeIcon } from "./../../../assets/icons/eye.svg";
import Web3UserDataInfoModal from "./Web3UserDataInfoModal";
import BlankAvatarImg from "./../../../assets/images/blank-avatar.png";
import {
  Button,
  SimpleTable,
  Typography,
  Input,
  EmptyState,
} from "djuno-design";

const Web3AuthEndpointUsersTab = () => {
  const endpoint = useAppSelector(selectWeb3AuthEndpoint);
  const endpointLoading = useAppSelector(selectWeb3AuthEndpointLoading);
  const users = useAppSelector(selectWeb3AuthEndpointUsers);
  const usersLoading = useAppSelector(selectWeb3AuthEndpointUsersLoading);
  const [filteredUsers, setFilteredUsers] = useState<Web3AuthEndpointUser[]>(
    []
  );

  const dispatch = useAppDispatch();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (endpoint) {
      dispatch(getWeb3AuthEndpointUsersAsync(endpoint.Id.toString()));
    }
  }, [dispatch, endpoint]);

  useEffect(() => {
    const lookedUpFiles = users?.filter((user) =>
      user.Id?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredUsers(lookedUpFiles);
  }, [dispatch, searchValue, users]);

  return (
    <>
      <SimpleTable
        loading={usersLoading || endpointLoading}
        className="table-auto"
      >
        <SimpleTable.Head>
          <SimpleTable.Row>
            <SimpleTable.TH lable="Profile ID" />
            <SimpleTable.TH lable="Address" />
            <SimpleTable.TH lable="Chain" />
            <SimpleTable.TH lable="Last Loging" />
            <SimpleTable.TH lable="User data" />
          </SimpleTable.Row>
        </SimpleTable.Head>
        <SimpleTable.Body>
          {filteredUsers.map((user, index) => (
            <SimpleTable.Row key={index}>
              <SimpleTable.TD>
                <div className="flex items-center gap-2">
                  <img
                    className="object-cover w-10 h-10 rounded-full"
                    src={user.Avatar || BlankAvatarImg}
                    alt="Avatar"
                  />
                  <HighlighterText
                    searchValue={searchValue}
                    textToHighlight={user.Id}
                  />
                </div>
              </SimpleTable.TD>
              <SimpleTable.TD>
                <Input value={user.WalletAddress} copyable />
              </SimpleTable.TD>
              <SimpleTable.TD>
                <Typography.Text className="!text-xs md:!text-sm">
                  {user.Chain}
                </Typography.Text>
              </SimpleTable.TD>
              <SimpleTable.TD>
                <Typography.Text className="text-xs md:text-sm">
                  {timeAgo(user.LastLogin)}
                </Typography.Text>
              </SimpleTable.TD>
              <SimpleTable.TD>
                <Button
                  uiType="icon"
                  onClick={() =>
                    dispatch(handleUserDataInfoModal({ status: true, user }))
                  }
                >
                  <EyeIcon className="w-5 h-5" />
                </Button>
              </SimpleTable.TD>
            </SimpleTable.Row>
          ))}
          {filteredUsers.length === 0 && (
            <SimpleTable.Row withoutHoverStyle={true}>
              <SimpleTable.TD colSpan={5} className="!border-0">
                <EmptyState text="No users" />
              </SimpleTable.TD>
            </SimpleTable.Row>
          )}
        </SimpleTable.Body>
      </SimpleTable>
      <Web3UserDataInfoModal />
    </>
  );
};

export default Web3AuthEndpointUsersTab;
