import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatabaseServiceNameSchema } from "./../../../utils/validations";
import {
  getDatabaseServicesAsync,
  handleHideDatabaseRenameModal,
  updateDBSAsync,
  selectDatabaseServiceRenameIsOpen,
  selectDatabaseServicesActionLoading,
  selectSelectedDatabaseService,
} from "./../../../store/database/servicesSlice";
import { getDatabaseServiceAsync } from "./../../../store/database/serviceSlice";
import { Button, Input, Modal } from "djuno-design";

const DatabaseRenameModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectDatabaseServiceRenameIsOpen);
  const loading = useAppSelector(selectDatabaseServicesActionLoading);
  const selectedDatabase = useAppSelector(selectSelectedDatabaseService);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(DatabaseServiceNameSchema),
    mode: "all",
  });

  useEffect(() => {
    if (isOpen && selectedDatabase) {
      setValue("description", selectedDatabase.description);
    }
  }, [isOpen, selectedDatabase, setValue]);

  const onSubmit = (data: any) => {
    if (selectedDatabase && !loading) {
      dispatch(
        updateDBSAsync({
          id: selectedDatabase.id,
          engine: selectedDatabase.engine,
          data,
        })
      ).then((action) => {
        if (action.type === "db-services/update/fulfilled") {
          reset();
          dispatch(handleHideDatabaseRenameModal());
          dispatch(getDatabaseServicesAsync());
          dispatch(getDatabaseServiceAsync({ id: selectedDatabase.id }));
        }
      });
    }
  };

  const handleClose = () => {
    reset();
    dispatch(handleHideDatabaseRenameModal());
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
      title="Change the name"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          <Input
            {...register("description")}
            error={errors.description?.message}
            placeholder=""
            label="Name"
            required
          />
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <Button uiType="light" onClick={handleClose}>
            Cancel
          </Button>
          <Button uiType="primary" type="submit" loading={loading}>
            Edit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default DatabaseRenameModal;
