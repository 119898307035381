import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { useNavigate, useParams } from "react-router-dom";
import { DatabaseServiceGeneralInformationUrl } from "../../../utils/urls";
import { useSearch } from "../../../providers/SearchProvider";
import {
  selectDatabaseServicesActionLoading,
  selectDatabaseServicesLoading,
} from "../../../store/database/servicesSlice";
import {
  getDBSQuerrStatisticsAsync,
  resetDBSQuerrStatisticsAsync,
  selectDBSQuerrStatistics,
  selectDBSQuerrStatisticsLoading,
  selectDatabaseService,
} from "../../../store/database/serviceSlice";
import { IDBSQueryStatisticts } from "../../../types/database";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import {
  Button,
  EmptyState,
  Flex,
  SimpleTable,
  Typography,
} from "djuno-design";
import { HighlighterText } from "../../general/Text";

const QuerryStatisticsTab = () => {
  const [filteredQuerrStatistics, setFilteredQuerrStatisticss] = useState<
    IDBSQueryStatisticts[]
  >([]);
  const { id } = useParams();

  const service = useAppSelector(selectDatabaseService);
  const querryStatistics = useAppSelector(selectDBSQuerrStatistics);
  const querryStatisticsLoading = useAppSelector(
    selectDBSQuerrStatisticsLoading
  );
  const loading = useAppSelector(selectDatabaseServicesLoading);
  const actionLoading = useAppSelector(selectDatabaseServicesActionLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().queryStatistics;
      if (!permissions.includes(service.engine)) {
        navigate(DatabaseServiceGeneralInformationUrl(service.id));
      } else {
        dispatch(
          getDBSQuerrStatisticsAsync({ id: service.id, engine: service.engine })
        );
      }
    }
  }, [dispatch, navigate, service]);

  useEffect(() => {
    const lookedUpFiles = querryStatistics.filter((query) =>
      query.query?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredQuerrStatisticss(lookedUpFiles);
  }, [dispatch, searchValue, querryStatistics]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database</title>
        <meta name="description" content="" />
      </Helmet>

      {service && (
        <>
          <Flex direction="col" className="gap-3 w-full">
            <div className="flex items-center justify-between">
              <div className="items-center justify-between flex flex-1 transition duration-150">
                <Typography.Title level={5} className="!mb-0">
                  Query Statistics
                </Typography.Title>
              </div>
              <div className="flex items-center gap-2">
                <Button
                  uiType="light"
                  onClick={() => {
                    if (service) {
                      dispatch(
                        resetDBSQuerrStatisticsAsync({
                          engine: service.engine,
                          id: service.id,
                          // data,
                        })
                      );
                    }
                  }}
                  disabled={!service}
                  className="group"
                  tooltip={{ content: "Refresh" }}
                >
                  <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
                </Button>
              </div>
            </div>

            <Typography.Text className="!text-sm !mt-4">
              Query statistics gather the statistics collected in performance
              schemas. They enable you to monitor server events (e.g.syncing,
              temporary tables, indexes, join, etc.)
            </Typography.Text>
          </Flex>
          <div className="mt-5 w-full">
            <SimpleTable
              loading={querryStatisticsLoading}
              containerClassName="min-h-[200px]"
            >
              <SimpleTable.Head>
                <SimpleTable.Row>
                  <SimpleTable.TH lable="Query" />
                  <SimpleTable.TH lable="No.of rows" />
                  <SimpleTable.TH lable="No.of calls" />
                  <SimpleTable.TH lable="Min(ms)" />
                  <SimpleTable.TH lable="Max(ms)" />
                  <SimpleTable.TH lable="Average(ms)" />
                  <SimpleTable.TH lable="No queries(ms)" />
                  <SimpleTable.TH lable="Total(ms)" />
                </SimpleTable.Row>
              </SimpleTable.Head>

              <SimpleTable.Body>
                {filteredQuerrStatistics.map((query, i) => (
                  <SimpleTable.Row key={i}>
                    <SimpleTable.TD className="cursor-pointer">
                      <div className="flex items-center flex-row justify-between space-x-3">
                        <HighlighterText
                          searchValue={searchValue}
                          textToHighlight={query.query}
                        />
                      </div>
                    </SimpleTable.TD>

                    <SimpleTable.TD className="cursor-pointer">
                      <Typography.Text className="text-xs md:text-sm">
                        {query.rows}
                      </Typography.Text>
                    </SimpleTable.TD>

                    <SimpleTable.TD className="cursor-pointer">
                      <Typography.Text className="text-xs md:text-sm">
                        {query.calls}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD className="cursor-pointer">
                      <Typography.Text>{query.minTime}</Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD className="cursor-pointer">
                      <Typography.Text>{query.maxTime}</Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD className="cursor-pointer">
                      <Typography.Text>{query.meanPlanTime}</Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD className="cursor-pointer">
                      <Typography.Text>{query.stddevTime}</Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD className="cursor-pointer">
                      <Typography.Text>{query.totalTime}</Typography.Text>
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                ))}

                {querryStatistics.length === 0 && (
                  <SimpleTable.Row withoutHoverStyle={true}>
                    <SimpleTable.TD colSpan={10} className="!border-0">
                      <EmptyState text="No queris have been run" />
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                )}
              </SimpleTable.Body>
            </SimpleTable>
          </div>
        </>
      )}
    </>
  );
};

export default QuerryStatisticsTab;
