import { PropsWithChildren, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";

import {
  getWebAppAsync,
  selectWebApp,
  selectWebAppLoading,
  selectWebAppUpdatePlanLoading,
  updateWebAppPlanAsync,
} from "../../../store/web-app/webAppSlice";
import { Control, Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { WebAppInstanceTypeSchema } from "../../../utils/validations";
import { RadioGroup } from "@headlessui/react";
import { webAppInstances } from "../../../store/web-app/webAppCreateSlice";
import { WebAppPlan } from "../../../types/web-app";
import { AnimatePresence, motion } from "framer-motion";
import { Button, Card, cn, Flex, Loading, Typography } from "djuno-design";

const WebAppPlanTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const updateLoading = useAppSelector(selectWebAppUpdatePlanLoading);

  const dispatch = useAppDispatch();

  const {
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(WebAppInstanceTypeSchema),
  });

  useEffect(() => {
    if (webApp) {
      setValue("InstanceType", webApp.Plan.id);
    }
  }, [dispatch, setValue, webApp]);

  const handleSubmitForm = (data: any) => {
    if (webApp)
      dispatch(
        updateWebAppPlanAsync({
          webAppId: webApp.Id.toString(),
          InstanceId: data.InstanceId,
        })
      ).then((action) => {
        if (action.type === "web-app/update/plan/fulfilled") {
          dispatch(getWebAppAsync(webApp.Id.toString()));
        }
      });
  };

  return (
    <div className="flex flex-col gap-6">
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Card title="Pick an Instance Type">
          {webAppLoading && (
            <Flex items="center" justify="center" className="min-h-[200px]">
              <Loading borderSize={2} />
            </Flex>
          )}
          <WebAppInstancesGroup
            webAppInstances={webAppInstances}
            control={control}
          >
            <AnimatePresence>
              {errors.InstanceType &&
                typeof errors.InstanceType.message === "string" && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                  >
                    <p className="mt-2 text-xs text-red-600 dark:text-red-500">
                      {errors.InstanceType.message}
                    </p>
                  </motion.div>
                )}
            </AnimatePresence>
          </WebAppInstancesGroup>
        </Card>
        <div className="flex items-center justify-end gap-2 mt-4">
          <Button
            uiType="primary"
            disabled={!isValid}
            type="submit"
            loading={updateLoading}
          >
            Save Changes
          </Button>
        </div>
      </form>
    </div>
  );
};

export const WebAppInstancesGroup: React.FC<
  PropsWithChildren<{
    webAppInstances: WebAppPlan[];
    control: Control<any>;
  }>
> = ({ webAppInstances, control, children }) => {
  return (
    <Controller
      name="InstanceType"
      control={control}
      render={({ field: { value, onChange } }) => (
        <RadioGroup value={value || null} onChange={onChange}>
          <div className="mt-6">
            <div className="block md:grid grid-cols-3 gap-10 mb-8 md:mb-6">
              <div className="mb-2 pr-0 md:pr-6">
                <Typography.Text size="sm" className="font-medium">
                  For hobby projects
                </Typography.Text>
              </div>
              <div className="grid gap-4 grid-cols-1 md:grid-cols-2 col-span-2 pr-8">
                <div className="col-span-1 lg:col-span-1">
                  <RadioGroup.Option value={webAppInstances[0].id}>
                    {({ checked }) => (
                      <WebAppInstanceCard
                        title={webAppInstances[0].title}
                        ram={webAppInstances[0].ram}
                        price={webAppInstances[0].price}
                        cpu={webAppInstances[0].cpu}
                        selected={checked}
                      />
                    )}
                  </RadioGroup.Option>
                </div>
                <div className="flex space-x-1.5"></div>
              </div>
            </div>
            <div className="block md:grid grid-cols-3 gap-10">
              <Flex direction="col" className="mb-6 pr-8 md:pr-6">
                <Typography.Text size="sm" className="font-medium mb-1">
                  For professional use
                </Typography.Text>
                <Typography.Text size="sm" className=" mb-4" uiType="secondary">
                  For more power and to get the most out of Render, we recommend
                  using one of our paid instance types. All paid instances
                  support:
                </Typography.Text>
              </Flex>
              <div className="col-span-2 pr-8">
                <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-5">
                  {webAppInstances.slice(1).map((instance, i) => (
                    <RadioGroup.Option key={i} value={instance.id}>
                      {({ checked }) => (
                        <WebAppInstanceCard
                          title={instance.title}
                          ram={instance.ram}
                          price={instance.price}
                          cpu={instance.cpu}
                          selected={checked}
                        />
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
                {children}
              </div>
            </div>
          </div>
        </RadioGroup>
      )}
    />
  );
};

export const WebAppInstanceCard: React.FC<{
  title: string;
  price: string;
  ram: string;
  cpu: string;
  selected?: boolean;
}> = ({ title, price, ram, cpu, selected }) => {
  return (
    <div
      className={cn(
        "col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300 cursor-pointer",
        {
          "border-primary-400 dark:border-primary-400": selected,
        }
      )}
    >
      <div className="flex flex-col w-full gap-2">
        <div className="flex items-center justify-between">
          <Typography.Text size="sm" className="text-base font-medium">
            {title}
          </Typography.Text>
          <Typography.Text size="sm" uiType="secondary">
            {ram} (RAM)
          </Typography.Text>
        </div>
        <div className="flex items-center justify-between">
          <Typography.Text
            size="sm"
            className="font-semibold flex items-center gap-1"
          >
            ${price}{" "}
            <Typography.Text
              size="sm"
              className="font-normal"
              uiType="secondary"
            >
              / month
            </Typography.Text>
          </Typography.Text>
          <Typography.Text size="sm" uiType="secondary">
            {cpu} CPU
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};
export default WebAppPlanTab;
