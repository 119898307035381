import { useNavigate } from "react-router-dom";
import { ReactComponent as CubeTransparentIcon } from "./../../assets/icons/cube-transparent.svg";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right.svg";
import { RpcEndpointsUrl } from "../../utils/urls";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  selectServices,
  selectLoading,
  getServicesAsync,
} from "../../store/rpc/servicesSlice";
import { useEffect, useState } from "react";
import {
  getNetworkTypeName,
  groupServicesByBlockchain,
} from "../../utils/service";
import classNames from "classnames";
import { ServiceGroup } from "../../types/service";
import {
  selectActionLoading,
  createRpcAsync,
  getAllRpcAsync,
} from "../../store/rpc/rpcsSlice";
import Text from "../../components/general/Text";
import { AnimatePresence, motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { Button, cn, Flex, Loading, Typography } from "djuno-design";

const RpcEndpointCreatePage = () => {
  const services = useAppSelector(selectServices);
  const servicesLoading = useAppSelector(selectLoading);
  const groupedServices = groupServicesByBlockchain(services);
  const rpcActionLoading = useAppSelector(selectActionLoading);
  const [selectedChain, setSelectedChain] = useState<
    ServiceGroup | undefined
  >();
  const [selectedNetwork, setSelectedNetwork] = useState<number | undefined>();
  const [step, setStep] = useState<"chain" | "network">("chain");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getServicesAsync());
  }, [dispatch]);

  const handleSelectChainByName = (chainName: string) => {
    setSelectedChain(groupedServices.find((gs) => gs.Blockchain === chainName));
  };

  const handleCreateRpc = () => {
    if (selectedNetwork && !servicesLoading && !rpcActionLoading) {
      dispatch(
        createRpcAsync({
          RpcServiceId: selectedNetwork,
        })
      ).then((action) => {
        if (action.type === "rpc/create/fulfilled") {
          dispatch(getAllRpcAsync());
          navigate(RpcEndpointsUrl);
        }
      });
    }
  };
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Create Endpoints</title>
        <meta
          name="description"
          content="RPC-endpoints, or Remote Procedure Call endpoints, are a fundamental aspect of distributed computing systems, enabling communication between different components or services across a network"
        />
      </Helmet>
      <div className="flex items-center justify-between h-16 px-6 sticky top-0 bg-white dark:bg-dark-1">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <Text className="font-medium flex items-center gap-1">
            <CubeTransparentIcon className="w-5 h-5" />
            Create Endpoint
          </Text>
        </div>
        <div className="">
          <Button
            uiType="light"
            uiSize="small"
            onClick={() => navigate(RpcEndpointsUrl)}
            className="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      <div className="mt-5 w-full overflow-x-auto px-6 min-h-[calc(100%-6rem)]">
        <div className="mx-auto flex-1 flex justify-center pb-20">
          <AnimatePresence>
            {step === "chain" && (
              <>
                {!servicesLoading && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="max-w-4xl px-1 pb-12 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2 md:gap-4"
                  >
                    {groupedServices.map((service, index) => (
                      <BlockChainCard
                        key={index}
                        name={service.Blockchain}
                        iconUrl={service.Icon}
                        selected={
                          selectedChain?.Blockchain === service.Blockchain
                        }
                        onClick={handleSelectChainByName}
                      />
                    ))}
                  </motion.div>
                )}
                {servicesLoading && (
                  <motion.div
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="min-h-[300px] w-full flex justify-center items-center"
                  >
                    <Loading borderSize={2} />
                  </motion.div>
                )}
              </>
            )}
            {step === "network" && (
              <motion.div
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="px-1 items-start grid grid-cols-1 md:grid-cols-4 md:gap-8 max-w-4xl"
              >
                <BlockChainCard
                  name={selectedChain?.Blockchain || ""}
                  iconUrl={selectedChain?.Icon || ""}
                  selected={true}
                />
                <div className="space-y-4 flex-grow pb-8 col-span-3 mt-4 md:mt-0">
                  {selectedChain?.Networks.map((network, index) => (
                    <div
                      key={index}
                      className={classNames(
                        "border-2 cursor-pointer text-md dark:border-dark-2 bg-white dark:bg-dark-3 rounded-xl p-6",
                        {
                          "border-primary-400 dark:border-primary-400":
                            selectedNetwork === network.Id,
                        }
                      )}
                      onClick={() => setSelectedNetwork(network.Id)}
                    >
                      <div className="text-slate-800 dark:text-slate-100 text-md font-medium mb-1">
                        {getNetworkTypeName(network.NetworkType)}
                      </div>
                      <div className="text-slate-600 dark:text-slate-200 text-sm">
                        {network.NetworkTypeDescription}
                      </div>
                    </div>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div className="fixed bottom-0 right-0 left-0 flex items-center justify-between w-full h-16 border-t bg-white dark:bg-dark-1 dark:border-dark-2 px-6 lg:pl-80">
        <div>
          {step === "network" && (
            <Button
              uiType="light"
              onClick={() => {
                setSelectedNetwork(undefined);
                setStep("chain");
              }}
            >
              back
            </Button>
          )}
        </div>
        <div>
          {step === "chain" && (
            <Button
              uiType="primary"
              disabled={!!!selectedChain}
              onClick={() => setStep("network")}
              className="group"
            >
              Continue
              <ArrowRightIcon className="w-[16px] h-[16px] group-hover:scale-110 group-hover:translate-x-1 transition-all duration-300" />
            </Button>
          )}

          {step === "network" && (
            <Button
              uiType="primary"
              disabled={!!!selectedNetwork}
              onClick={handleCreateRpc}
              className="group"
              loading={rpcActionLoading}
            >
              Continue
              <ArrowRightIcon className="w-[16px] h-[16px]  group-hover:scale-110 group-hover:translate-x-1 transition-all duration-300" />
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export const BlockChainCard = ({
  name,
  iconUrl,
  selected,
  onClick,
}: {
  name: string;
  iconUrl: string;
  selected?: boolean;
  onClick?: (chainName: string) => void;
}) => {
  return (
    <div
      className={cn(
        "border-2 text-md rounded-xl dark:bg-dark-3 dark:border-gray-400/10 bg-white p-4 shadow hover:shadow-lg transition-all duration-300",
        {
          "border-primary-400 dark:border-primary-400": selected,
          "cursor-pointer": onClick,
        }
      )}
      onClick={() => (onClick ? onClick(name) : {})}
    >
      <Flex
        direction="col"
        items="center"
        className="space-y-4 p-3 text-center"
      >
        <Flex items="center" justify="center" className="w-[74px] h-[74px] p-2">
          <img className="h-full" alt={name} src={iconUrl} />
        </Flex>
        <Typography.Text size="sm" className="font-semibold">
          {name}
        </Typography.Text>
      </Flex>
    </div>
  );
};

export default RpcEndpointCreatePage;
