import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getInstanceAsync,
  handleInstanceReactiveHideModal,
  reactiveInstanceAsync,
  selectInstance,
  selectInstanceLoading,
  selectShowReactiveModal,
} from "../../../../store/instances/instanceSlice";
import { getInstancesAsync } from "../../../../store/instances/instancesSlice";
import { Button, Modal, Typography } from "djuno-design";

const InstanceReactiveModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowReactiveModal);
  const loading = useAppSelector(selectInstanceLoading);
  const instance = useAppSelector(selectInstance);

  const {
    handleSubmit,
    formState: { isValid },
  } = useForm({
    shouldUnregister: true,
    reValidateMode: "onChange",
  });

  const onSubmit = (data: any) => {
    if (instance) {
      dispatch(reactiveInstanceAsync({ instanceId: instance?.id, data })).then(
        (action) => {
          if (action.type === "instance/unshelve/fulfilled") {
            dispatch(getInstancesAsync({ withoutLoading: false }));
            dispatch(getInstanceAsync({ instanceId: instance.id }));
            dispatch(handleInstanceReactiveHideModal());
          }
        }
      );
    }
  };

  const handleClose = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
    }
    dispatch(handleInstanceReactiveHideModal());
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
      // containerClassName="!items-start"
      title="Reactivate your instance"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          <Typography.Text className="!text-sm mb-5">
            You are about to reactivate your b3-8-bhs5 instance. The resources
            dedicated to your Public Cloud instance will be restored (IP address
            included). The data on your local disk will be restored. The
            duration of the operation depends on the size of your local disk.
          </Typography.Text>
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <Button uiType="light" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            uiType="primary"
            disabled={!isValid}
            type="submit"
            loading={loading}
          >
            Confirm
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default InstanceReactiveModal;
