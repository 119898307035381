import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as LinkIcon } from "./../../../assets/icons/link.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { DeleteModal } from "../../modals/QuestionModal";
import { useSearch } from "../../../providers/SearchProvider";
import { HighlighterText } from "../../general/Text";
import {
  deleteInstancesVolumesAsync,
  getInstancesVolumesAsync,
  handleInstanceAttachShowModal,
  handleInstanceDetachShowModal,
  handleSetSelectedInstanceVolume,
  selectInstancesVolumes,
  selectInstancesVolumesActionLoading,
  selectInstancesVolumesLoading,
} from "../../../store/instances/instancesVolumesSlice";
import { InstanceVolume } from "../../../types/instance";
import { InstancesSskKeyEditorModal } from "../ssh-keys/InstancesSskKeyEditorModal";
import { Link, useNavigate } from "react-router-dom";
import {
  InstanceOverviewUrl,
  InstancesCreateVolumeUrl,
  InstancesEditVolumeUrl,
  InstancesVolumeOverviewUrl,
} from "../../../utils/urls";
import {
  selectInstances,
  selectInstancesLoading,
} from "../../../store/instances/instancesSlice";
import InstanceAttachModal from "./InstanceAttachModal";
import InstanceDetachModal from "./InstanceDetachModal";
import {
  Button,
  Dropdown,
  DropdownElement,
  EmptyState,
  SimpleTable,
  Tag,
  Typography,
} from "djuno-design";
import { A } from "../../CustomLink";

const InstancesVolumesTab = () => {
  const navigate = useNavigate();

  const [deleteVolume, setDeleteVolume] = useState<string | null>(null);

  const volumes = useAppSelector(selectInstancesVolumes);
  const [filteredVolumes, setFilteredVolumes] = useState<InstanceVolume[]>([]);
  const loading = useAppSelector(selectInstancesVolumesLoading);
  const actionLoading = useAppSelector(selectInstancesVolumesActionLoading);

  const instances = useAppSelector(selectInstances);
  const instancesLoading = useAppSelector(selectInstancesLoading);

  const dispatch = useAppDispatch();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (volumes.length === 0) {
      dispatch(getInstancesVolumesAsync({}));
    }
  }, [dispatch, volumes.length]);

  useEffect(() => {
    const lookedUpApiKeys = volumes.filter((key) =>
      key.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredVolumes(lookedUpApiKeys);
  }, [dispatch, searchValue, volumes]);

  const getInstanceFromId = useCallback(
    (id: string) => {
      return instances.find((i) => i.id === id);
    },
    [instances]
  );

  return (
    <>
      <div className="flex transition-all duration-500">
        {/* <Text>Volumes</Text> */}
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2">
          <Button
            onClick={() => dispatch(getInstancesVolumesAsync({}))}
            className="group"
            tooltip={{ content: "Refresh" }}
          >
            <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
          <Button
            uiType="primary"
            onClick={() => navigate(InstancesCreateVolumeUrl)}
            className="group"
          >
            Create New
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-6">
        <SimpleTable loading={loading}>
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Name/ID" />
              <SimpleTable.TH lable="Region" />
              <SimpleTable.TH lable="Type" />
              <SimpleTable.TH lable="Capacity" />
              <SimpleTable.TH lable="Instance attached" />
              <SimpleTable.TH lable="Status" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {filteredVolumes.map((volume, index) => (
              <SimpleTable.Row
                key={index}
                onClick={() => navigate(InstancesVolumeOverviewUrl(volume.id))}
              >
                <SimpleTable.TD className="w-36">
                  <div className="flex flex-col gap-1">
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={volume.name}
                    />
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={volume.id}
                    />
                  </div>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {volume.region}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {volume.type}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">{`${volume.size.toString()} GB`}</Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {volume.attachedTo.map((attached, idx) => {
                      return (
                        <A>
                          <Link key={idx} to={InstanceOverviewUrl(attached)}>
                            {getInstanceFromId(attached)?.name}
                          </Link>
                        </A>
                      );
                    })}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Tag
                    className="text-xs !inline-block"
                    color={
                      volume.status === "available" ||
                      volume.status === "in-use"
                        ? "success"
                        : volume.status === "error" ||
                          volume.status === "error_backing-up" ||
                          volume.status === "error_deleting" ||
                          volume.status === "error_extending" ||
                          volume.status === "error_restoring"
                        ? "error"
                        : "processing"
                    }
                  >
                    {volume.status}
                  </Tag>
                </SimpleTable.TD>
                <SimpleTable.TD className="w-20 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1.5">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        anchor="bottom end"
                        itemsClassName="!p-0"
                        menu={[
                          {
                            key: "edit",
                            label: (
                              <div className="flex items-center gap-1">
                                <EditIcon className="w-4" />
                                Edit
                              </div>
                            ),
                            onClick: (_, close) => {
                              close();
                              dispatch(handleSetSelectedInstanceVolume(volume));
                              navigate(InstancesEditVolumeUrl(volume.id));
                            },
                          },
                          ...(volume.status === "available"
                            ? [
                                {
                                  key: "attach",
                                  label: (
                                    <div className="flex items-center gap-1">
                                      <LinkIcon className="w-4" />
                                      Attach to instance
                                    </div>
                                  ),
                                  onClick: (_, close) => {
                                    close();
                                    dispatch(
                                      handleInstanceAttachShowModal({
                                        selectedVolume: volume,
                                      })
                                    );
                                  },
                                } as DropdownElement,
                              ]
                            : []),

                          ...(volume.status === "in-use" ||
                          volume.attachedTo.length > 0
                            ? [
                                {
                                  key: "detach",
                                  label: (
                                    <div className="flex items-center gap-1 whitespace-nowrap">
                                      <ArchiveIcon className="w-4" />
                                      Detach from instance
                                    </div>
                                  ),
                                  onClick: (_, close) => {
                                    close();
                                    dispatch(
                                      handleInstanceDetachShowModal({
                                        selectedVolume: volume,
                                      })
                                    );
                                  },
                                } as DropdownElement,
                              ]
                            : []),
                          // {
                          //   key: "backup",
                          //   label: (
                          //     <div className="flex items-center gap-1">
                          //       <BackupIcon className="w-3" />
                          //       Create a backup
                          //     </div>
                          //   ),
                          //   onClick: (_, close) => {
                          //     close();
                          //     dispatch(handleSetSelectedInstanceVolume(volume));
                          //     navigate(InstancesVolumeBackupUrl(volume.id));
                          //   },
                          // },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              setDeleteVolume(volume.id);
                            },
                          },
                        ]}
                      >
                        <Button uiType="icon" uiSize="small" className="!px-2">
                          <MoreIcon className="w-4 h-4" />
                        </Button>
                      </Dropdown>
                    </div>
                    <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}
            {volumes.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                <SimpleTable.TD colSpan={7} className="!border-0">
                  <EmptyState text="You have not created any Volume yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>
      <InstancesSskKeyEditorModal />
      <InstanceAttachModal />
      <InstanceDetachModal />
      <DeleteModal
        title="Delete a Volume"
        isOpen={deleteVolume !== null}
        onClose={() => setDeleteVolume(null)}
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        loading={actionLoading}
        onConfirm={() => {
          deleteVolume &&
            dispatch(deleteInstancesVolumesAsync({ id: deleteVolume })).then(
              (action) => {
                if (action.type === "instances/volumes/delete/fulfilled") {
                  dispatch(getInstancesVolumesAsync({}));
                }
                setDeleteVolume(null);
              }
            );
        }}
        confirmString={volumes.find((v) => v.id === deleteVolume)?.name}
      />
    </>
  );
};

export default InstancesVolumesTab;
