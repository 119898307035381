import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import AccountProvider from "../../providers/AccountProvider";
import { useCallback, useEffect, useMemo } from "react";
import Header from "./Header";
import SearchProvider from "../../providers/SearchProvider";
import ConfettiExplosion from "react-confetti-explosion";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getMeAsync,
  selectConfettiStatus,
  selectOnStageEnv,
  selectUserLoading,
} from "../../store/auth/authSlice";
import { LoadingSpin } from "../general/Loading";
import {
  getServiceTypesAsync,
  selectServiceTypes,
  selectServiceTypesLoading,
} from "../../store/publicSlice";
import { ReactComponent as LogoTextIcon } from "./../../assets/icons/logo-text.svg";
import { ReactComponent as Logo } from "./../../assets/icons/logo.svg";
import { ReactComponent as SettingIcon } from "./../../assets/icons/setting.svg";
import { ReactComponent as CreditCardIcon } from "./../../assets/icons/credit-card.svg";
import { ReactComponent as BookIcon } from "./../../assets/icons/book-open.svg";
import { ReactComponent as LifebouyIcon } from "./../../assets/icons/lifebouy.svg";
import Text from "../general/Text";
import {
  PanelLayout as DjunoPanelLayout,
  PanelSidebar,
  Sidebar as DjunoSidebar,
  SidebarItem,
  PanelHeader as DjunoHeader,
} from "djuno-design";
import {
  BlockApiUrl,
  BlockEventNotificationsUrl,
  DatabaseServicesUrl,
  HomeUrl,
  InstancesUrl,
  IpfsFilesUrl,
  MarketApiUrl,
  NftApiUrl,
  RpcEndpointsUrl,
  S3BucketsUrl,
  SettingsBillingUrl,
  SettingsProfileUrl,
  WalletApiUrl,
  Web3AuthEndpointsUrl,
  WebAppsUrl,
  WorkflowsUrl,
} from "../../utils/urls";

const mainMenu: (SidebarItem & { data?: any })[] = [
  {
    id: "endpoints",
    activeConditions: [
      {
        index: 0,
        value: "rpc-endpoints",
      },
    ],
    link: RpcEndpointsUrl,
    testId: "2th-sidebar",
    data: { serviceTypeId: 1 },
  },
  {
    id: "web-apps",
    activeConditions: [
      {
        index: 0,
        value: "web-apps",
      },
    ],
    link: WebAppsUrl,
    testId: "3th-sidebar",
    data: { serviceTypeId: 2 },
  },
  {
    id: "workflow-studio",
    activeConditions: [
      {
        index: 0,
        value: "workflow-studio",
      },
    ],
    link: WorkflowsUrl,
    testId: "4th-sidebar",
    data: { serviceTypeId: 3 },
  },
  {
    id: "dapp-studio",
    activeConditions: [
      {
        index: 0,
        value: "app-studio",
      },
    ],
    link: "",
    testId: "5th-sidebar",
    data: { serviceTypeId: 4 },
  },
  {
    id: "blockchain-studio",
    activeConditions: [
      {
        index: 0,
        value: "blockchain-studio",
      },
    ],
    link: "",
    testId: "6th-sidebar",
    data: { serviceTypeId: 5 },
  },
  {
    id: "web3-auth",
    activeConditions: [
      {
        index: 0,
        value: "web3-auth",
      },
    ],
    link: Web3AuthEndpointsUrl,
    testId: "7th-sidebar",
    data: { serviceTypeId: 6 },
  },
  {
    id: "wallet-api",
    activeConditions: [
      {
        index: 0,
        value: "wallet-api",
      },
    ],
    link: WalletApiUrl,
    testId: "8th-sidebar",
    data: { serviceTypeId: 7 },
  },
  {
    id: "nft-api",
    activeConditions: [
      {
        index: 0,
        value: "nft-api",
      },
    ],
    link: NftApiUrl,
    testId: "9th-sidebar",
    data: { serviceTypeId: 8 },
  },
  {
    id: "market-api",
    activeConditions: [
      {
        index: 0,
        value: "market-api",
      },
    ],
    link: MarketApiUrl,
    testId: "10th-sidebar",
    data: { serviceTypeId: 9 },
  },
  {
    id: "block-api",
    activeConditions: [
      {
        index: 0,
        value: "block-api",
      },
    ],
    link: BlockApiUrl,
    testId: "11th-sidebar",
    data: { serviceTypeId: 10 },
  },
  {
    id: "block-events",
    activeConditions: [
      {
        index: 0,
        value: "block-events",
      },
    ],
    link: BlockEventNotificationsUrl,
    testId: "12th-sidebar",
    data: { serviceTypeId: 11 },
  },
  {
    id: "ipfs",
    activeConditions: [
      {
        index: 0,
        value: "ipfs",
      },
    ],
    link: IpfsFilesUrl,
    testId: "13th-sidebar",
    data: { serviceTypeId: 12 },
  },
  {
    id: "s3",
    activeConditions: [
      {
        index: 0,
        value: "s3",
      },
    ],
    link: S3BucketsUrl,
    testId: "14th-sidebar",
    data: { serviceTypeId: 13 },
  },
  {
    id: "database",
    activeConditions: [
      {
        index: 0,
        value: "database",
      },
    ],
    link: DatabaseServicesUrl,
    testId: "15th-sidebar",
    data: { serviceTypeId: 14 },
  },
  {
    id: "instance",
    activeConditions: [
      {
        index: 0,
        value: "instances",
      },
    ],
    link: InstancesUrl,
    testId: "16th-sidebar",
    data: { serviceTypeId: 15 },
  },
];

const PanelLayout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  //get user data
  useEffect(() => {
    dispatch(getMeAsync({}));
  }, [dispatch]);

  //get active service types
  useEffect(() => {
    dispatch(getServiceTypesAsync());
  }, [dispatch]);

  const confettiStatus = useAppSelector(selectConfettiStatus);
  const userLoading = useAppSelector(selectUserLoading);
  const serviceTypes = useAppSelector(selectServiceTypes);
  const serviceTypesLoading = useAppSelector(selectServiceTypesLoading);
  const onStageEnv = useAppSelector(selectOnStageEnv);

  const generalLoading = useMemo(() => {
    // return true;
    return userLoading || serviceTypesLoading;
  }, [serviceTypesLoading, userLoading]);

  const handleSidebarNavigation = useCallback(
    (item: SidebarItem | undefined) => {
      const route = `${item?.link}`;
      navigate(route);
    },
    [navigate]
  );

  const sidebarItems = useMemo(() => {
    const _serviceTypes = [...serviceTypes];
    const serviceTypeItems = _serviceTypes
      .filter((st) => st.IsMenuItem)
      .filter((st) => st.IsActive)
      .sort((a, b) => a.OrdrIndx - b.OrdrIndx);

    let menuItems: (SidebarItem & { data?: any })[] = [];
    serviceTypeItems.forEach((serviceTypeItem) => {
      const data = mainMenu.find(
        (i) => i.data.serviceTypeId === serviceTypeItem.Id
      );
      if (!data) return;
      menuItems.push({
        ...data,
        label: () => (
          <div className="flex items-center justify-between w-full gap-1">
            {serviceTypeItem.ServiceName}
            {serviceTypeItem.Tag && (
              <Text className="text-xs" type={"success-alert"}>
                {serviceTypeItem.Tag}
              </Text>
            )}
          </div>
        ),
        disabled: !serviceTypeItem.Enabled,
        onClick: handleSidebarNavigation,
      });
    });
    return [...menuItems]; //homeItem,
  }, [serviceTypes, handleSidebarNavigation]);

  const sidebarSubItems: SidebarItem[] = useMemo(() => {
    return [
      {
        id: "settings",
        label: "Settings",
        activeConditions: [
          {
            index: 0,
            value: "settings",
          },
          {
            index: 1,
            value: "profile",
          },
        ],
        link: SettingsProfileUrl,
        icon: SettingIcon,
        onClick: handleSidebarNavigation,
        testId: "17th-sidebar",
      },
      {
        id: "billing",
        label: "Usage & Billing",
        activeConditions: [
          {
            index: 0,
            value: "settings",
          },
          {
            index: 1,
            value: "billing",
          },
        ],
        link: SettingsBillingUrl,
        icon: CreditCardIcon,
        onClick: handleSidebarNavigation,
        testId: "18th-sidebar",
        active: onStageEnv?.IsAccountOwner,
      },
      {
        id: "documentation",
        label: "Documentation",
        activeConditions: [
          {
            index: 0,
            value: "documentation",
          },
        ],
        onClick: () => {
          window.open("https://docs.djuno.cloud");
        },
        icon: BookIcon,
        testId: "19th-sidebar",
      },
      {
        id: "support",
        label: "Support",
        activeConditions: [
          {
            index: 0,
            value: "support",
          },
        ],
        link: "/support",
        icon: LifebouyIcon,
        onClick: handleSidebarNavigation,
        testId: "20th-sidebar",
      },
    ];
  }, [handleSidebarNavigation, onStageEnv?.IsAccountOwner]);

  const GlobalLoadingContent = () => {
    return (
      <div className="flex flex-col gap-4 items-center">
        <Logo className="w-20 h-20" />
        <div className="flex items-center gap-1">
          <LoadingSpin borderSize={2} size={15} />
          <Text className="text-sm font-medium">Loading data...</Text>
        </div>
      </div>
    );
  };

  //TODO
  const SidebarContent = (props: any) => {
    return (
      <PanelSidebar
        {...props}
        sidebarHeader={
          <Link to={HomeUrl} className="px-4">
            <LogoTextIcon className="text-black/60 dark:text-gray-300 h-9" />
          </Link>
        }
      >
        <DjunoSidebar
          type={props.type}
          items={sidebarItems}
          segments={props.segments}
          subItems={sidebarSubItems}
          navItemHeight={38}
        />
      </PanelSidebar>
    );
  };

  //TODO
  const HeaderContent = (props: any) => {
    return (
      <DjunoHeader
        {...props}
        mobileIcon={<Logo className="w-[25px] h-[25px] text-[#0074E4]" />}
      >
        <Header />
      </DjunoHeader>
    );
  };

  return (
    <SearchProvider>
      <div className="h-screen w-screen bg-white dark:bg-dark-1">
        <DjunoPanelLayout
          type="normal"
          pathname={location.pathname}
          renderSidebar={(sidebarProps) => <SidebarContent {...sidebarProps} />}
          renderHeader={(headerProps) => <HeaderContent {...headerProps} />}
          globalLoading={generalLoading}
          globalLoadingContent={<GlobalLoadingContent />}
        >
          {confettiStatus && (
            <div className="absolute top-0 left-0 bottom-0 right-0 w-full h-full flex justify-center items-center -translate-y-44">
              <ConfettiExplosion
                force={0.4}
                duration={2500}
                particleCount={100}
                width={850}
              />
            </div>
          )}
          <div className="max-w-7xl mx-auto">
            <AccountProvider>
              <Outlet />
            </AccountProvider>
          </div>
        </DjunoPanelLayout>
      </div>
    </SearchProvider>
  );
};

export default PanelLayout;
