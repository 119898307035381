import { ReactComponent as CheckIcon } from "./../../assets/icons/check.svg";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getPlansAsync,
  getSubscriptionsAsync,
  selectPlanGroups,
  selectPlanGroupsLoading,
  selectSubscriptionActionLoading,
  selectSubscriptions,
  selectSubscriptionsLoading,
  updateSubscriptionsAsync,
} from "../../store/billing/billingSlice";
import { useEffect, useMemo } from "react";
import { IPlan } from "../../types/billing";
import { selectOnStageEnv } from "../../store/auth/authSlice";
import { Loading, Button, Typography, Tag } from "djuno-design";

export const UniversalPlansBox = () => {
  const env = useAppSelector(selectOnStageEnv);

  const subscriptions = useAppSelector(selectSubscriptions);
  const subscriptionsLoading = useAppSelector(selectSubscriptionsLoading);
  const subscriptionActionLoading = useAppSelector(
    selectSubscriptionActionLoading
  );

  const planGroups = useAppSelector(selectPlanGroups);
  const planGroupsLoading = useAppSelector(selectPlanGroupsLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (subscriptions.length === 0) {
      dispatch(getSubscriptionsAsync());
    }
  }, [dispatch, subscriptions.length]);

  useEffect(() => {
    // if (planGroups.length === 0) {
    dispatch(getPlansAsync());
    // }
  }, [dispatch, planGroups.length]);

  const plans = useMemo(() => {
    const planGroup = planGroups.find((pg) => pg.PlanGroupId === 1);
    if (planGroup) {
      return planGroup.PlanDetail;
    }
    return [];
  }, [planGroups]);

  const handleBuyPlan = (planId: number) => {
    dispatch(updateSubscriptionsAsync({ data: { PlanId: planId } })).then(
      (action) => {
        if (action.type === "billing/update-subscriptions/fulfilled") {
          dispatch(getSubscriptionsAsync());
        }
      }
    );
  };

  return (
    <>
      {(subscriptionsLoading || planGroupsLoading) && (
        <div className="h-full w-full flex items-center justify-center min-h-[300px]">
          <Loading borderSize={2} />
        </div>
      )}
      {!subscriptionsLoading && !planGroupsLoading && (
        <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-5">
          {plans.map((plan, i) => (
            <PlanItem
              key={i}
              {...plan}
              handleBuy={env?.IsAccountOwner ? handleBuyPlan : undefined}
              selected={subscriptions.map((s) => s.PlanId).includes(plan.Id)}
            />
          ))}
          <PlanItem
            Id={0}
            Default={false}
            Name="Enterprise"
            PlanDescription={`{\n"Included Credit Monthly":"floating",\n"rate limit":"floating",\n"Additional Calls":"floating"\n}`}
            selected={false}
          />
        </div>
      )}
    </>
  );
};

export const PlanItem: React.FC<
  IPlan & {
    selected?: boolean;
    handleBuy?: (planId: number) => void;
  }
> = ({ Id, Name, Price, PlanDescription, selected, handleBuy }) => {
  const ActionButton = () => {
    if (Price === undefined) {
      const salesEmail = process.env.REACT_APP_SALES_EMAIL;
      return (
        <a
          href={`mailto:${salesEmail}?subject=Hello%20there`}
          // target="_blank"
          // rel="noreferrer"
        >
          <Button className="!w-full !justify-center">Talk to an Expert</Button>
        </a>
      );
    }

    return (
      <Button
        onClick={() => (handleBuy ? handleBuy(Id) : null)}
        disabled={selected || handleBuy === undefined}
        className="!w-full !justify-center"
      >
        Buy plan
      </Button>
    );
  };
  return (
    <div
      className={classNames(
        "col-span-1 rounded-2xl border-2 dark:bg-dark-3 mx-auto flex flex-col gap-5 p-4 w-full hover:shadow-lg transition-shadow duration-200",
        {
          "border-slate-200 dark:border-gray-800": !selected,
          "border-primary-400": selected,
        }
      )}
    >
      <div className="flex flex-col gap-5">
        <div className="flex items-center gap-2">
          <Typography.Text className="text-base font-medium">
            {Name}{" "}
          </Typography.Text>
          {selected && <Tag color="processing"> Active plan</Tag>}
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex gap-1 items-center h-8">
            {Price !== undefined ? (
              <>
                <Typography.Text className="!text-2xl !font-semibold">
                  {Price === 0 ? "Pay as go" : `$${Price}`}
                </Typography.Text>
                {Price > 0 && (
                  <Typography.Text className="!text-xs !font-medium">
                    / month
                  </Typography.Text>
                )}
              </>
            ) : (
              <>
                <Typography.Text className="!text-sm !font-medium">
                  Custom Pricing
                </Typography.Text>
              </>
            )}
          </div>
          {/* <Text className="text-sm font-light">
            $12 per month if paid annually
          </Text> */}
        </div>
        <ActionButton />
      </div>

      {PlanDescription && (
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-1">
            {Object.keys(JSON.parse(PlanDescription)).map((key) => {
              const value = JSON.parse(PlanDescription)[key];
              return <OptionRow text={`${key}: ${value}`} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const OptionRow: React.FC<{ text: string | React.ReactNode }> = ({ text }) => {
  return (
    <div className="flex items-center gap-2">
      <div className="w-[14px] h-[14px] rounded-full bg-primary-400 flex justify-center items-center">
        <CheckIcon className="w-3 h-3 text-white" />
      </div>
      <Typography.Text className="!text-xs !font-light">{text}</Typography.Text>
    </div>
  );
};
