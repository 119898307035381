import { Skeleton, Typography } from "djuno-design";
import { ReactComponent as CircleStackIcon } from "./../../../../assets/icons/circle-stack.svg";

const ServersCountWidget: React.FunctionComponent<{
  onlineCount: number;
  offlineCount: number;
  loading?: boolean;
}> = ({ onlineCount, offlineCount, loading }) => {
  return (
    <div className="w-full col-span-4 md:col-span-2 lg:col-span-1 rounded-lg border dark:border-dark-2 bg-white dark:bg-dark-3 p-5">
      <div className="flex flex-col w-full gap-3">
        <div className="flex justify-between items-center">
          <Typography.Text className="!text-xs md:!text-sm">
            Servers
          </Typography.Text>
          <CircleStackIcon className="w-5 dark:text-slate-50" />
        </div>
        <div className="h-10 flex justify-between">
          <div className="flex items-end gap-1">
            {loading && <Skeleton style={{ width: 50 }} />}
            {!loading && (
              <Typography.Title level={2} className="!mb-0">
                {onlineCount}
              </Typography.Title>
            )}
            <div className="flex items-center gap-1">
              <span className="w-2 h-2 bg-green-500 rounded-full" />
              <Typography.Text className="!text-xs" uiType="secondary">
                Online
              </Typography.Text>
            </div>
          </div>
          <div className="flex items-end gap-1">
            {loading && <Skeleton style={{ width: 50 }} />}
            {!loading && (
              <Typography.Title level={2} className="!mb-0">
                {offlineCount}
              </Typography.Title>
            )}
            <div className="flex items-center gap-1">
              <span className="w-2 h-2 bg-red-500 rounded-full" />
              <Typography.Text className="!text-xs" uiType="secondary">
                Offline
              </Typography.Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServersCountWidget;
