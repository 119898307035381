import { SettingsBillingUrl } from "../../utils/urls";
import Button from "../buttons/Button";
import { ReactComponent as NoData } from "./../../assets/icons/exclamation-triangle-icon.svg";
import { Link } from "react-router-dom";

const SuspendAccount = () => {
  return (
    <div className="px-4  md:px-6 max-w-[1200px] mx-auto h-[100vh] flex overflow-y-hidden">
      <div className="w-full  flex flex-col justify-center items-center place-self-center ">
        <div className="max-w-xl flex flex-col items-center gap-4">
          <NoData className="w-20 text-slate-500 dark:text-slate-300" />
          <div className="text-base font-bold text-slate-800 dark:text-slate-50 text-center">
            Account Suspended
          </div>
          <p className="text-sm text-slate-600 dark:text-gray-500 text-center">
            Your account has been temporarily suspended due to an unpaid
            invoice. Please settle your outstanding balance to regain full
            access to the platform.
          </p>
          <Link to={SettingsBillingUrl}>
            <Button type="light" contentClassName="!w-full">
              Go to Billing
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SuspendAccount;
