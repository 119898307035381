import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getInstanceAsync,
  handleInstanceNameHideModal,
  selectInstance,
  selectInstanceLoading,
  selectShowNameModal,
  updateInstanceNameAsync,
} from "../../../../store/instances/instanceSlice";
import { getInstancesAsync } from "../../../../store/instances/instancesSlice";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { InstanceNameSchema } from "../../../../utils/validations";
import { Button, Flex, Input, Modal, Typography } from "djuno-design";

const InstanceNameModal = () => {
  const isOpen = useAppSelector(selectShowNameModal);
  const [isInstanceNameChanged, setInstanceNameChanged] = useState(false);
  const instance = useAppSelector(selectInstance);
  const instanceLoading = useAppSelector(selectInstanceLoading);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: errorsName },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(InstanceNameSchema),
    mode: "all",
  });

  const currentName = watch("instanceName");

  //Name
  useEffect(() => {
    if (instance && isOpen) {
      setValue("instanceName", instance.name);
    }
  }, [instance, setValue, isOpen]);

  useEffect(() => {
    if (isOpen && instance) {
      setInstanceNameChanged(currentName !== instance.name);
    }
  }, [currentName, isOpen, instance]);

  const onSubmit = (data: any) => {
    if (instance && !instanceLoading) {
      dispatch(
        updateInstanceNameAsync({
          instanceId: instance.id,
          data,
        })
      ).then((action) => {
        if (action.type === "instance/update/fulfilled") {
          reset();
          dispatch(handleInstanceNameHideModal());
          dispatch(getInstancesAsync({ withoutLoading: false }));
          dispatch(getInstanceAsync({ instanceId: instance.id }));
        }
      });
    }
  };

  const handleClose = () => {
    reset();
    setInstanceNameChanged(false);
    dispatch(handleInstanceNameHideModal());
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
      title="Change instance name"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <Flex items="center" className="mt-6 gap-1">
          <Typography.Text size="sm" strong>
            Location:
          </Typography.Text>
          <Typography.Text className="!text-sm">
            {instance?.region}
          </Typography.Text>
        </Flex>

        <Flex items="center" className="mt-2 gap-1">
          <Typography.Text size="sm" strong>
            SSH key:
          </Typography.Text>
          <Typography.Text className="!text-sm">
            {instance?.sshKey?.name}
          </Typography.Text>
        </Flex>

        <div>
          <Input
            containerClassName="!mt-6"
            label="Instance new name"
            {...register("instanceName")}
            error={errorsName.instanceName?.message}
          />
        </div>

        <div>
          <Typography.Text className="!text-xs">
            May contain numbers, letters, underscores, dashes, and full stops
            only.
          </Typography.Text>
        </div>

        <Flex justify="end" className="!mt-5">
          <Button
            uiType="primary"
            type="submit"
            disabled={!isInstanceNameChanged || instanceLoading}
          >
            Change the name
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

export default InstanceNameModal;
