import { useFieldArray, useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { useEffect, useState } from "react";
import { ReactComponent as ArrowRightIcon } from "./../../../../../assets/icons/arrow-right.svg";
import { S3MultipleLifecycleSchema } from "../../../../../utils/validations";
import { S3Bucket } from "../../../../../types/s3-bucket";
import { selectCheckedBuckets } from "../../../../../store/s3/buckets/bucketsSlice";
import {
  createMultiBucketLifecycleAsync,
  handleHideLifecycleEditor,
  selectBucketLifecycleActionLoading,
  selectShowBucketLifecycleEditor,
} from "../../../../../store/s3/buckets/setting/lifecycleSlice";
import {
  LifecycleCreateLevels,
  S3LifecycleTag,
} from "../../../../../types/s3-lifecycle";
import {
  getS3TiersAsync,
  selectS3Tiers,
  selectS3TiersLoading,
} from "../../../../../store/s3/tiersSlice";
import RadioGrouper, { RadioGroupeItem } from "../../../../inputs/RadioGrouper";
import { ReactComponent as PlusIcon } from "./../../../../../assets/icons/plus.svg";
import { ReactComponent as ArchiveIcon } from "./../../../../../assets/icons/archive-box.svg";
import { ReactComponent as CheckmarkIcon } from "./../../../../../assets/icons/check.svg";
import { ErrorTooltip } from "../../../../general/Tooltip";
import {
  Button,
  Card,
  cn,
  Input,
  Modal,
  Select,
  Steps,
  Switcher,
  Typography,
} from "djuno-design";

export interface LifecycleError {
  bucketName: string;
  error?: string;
}

const typeOptions: RadioGroupeItem<"expiry" | "transition">[] = [
  { label: "Expiry", value: "expiry" },
  { label: "Transition", value: "transition" },
];

// const versionOptions: SelectOption<string>[] = [
//   {
//     label: "Current Version",
//     value: "current",
//   },
//   {
//     label: "Non-Current Version",
//     value: "non-current",
//   },
// ];

const S3MultipleBucketEditorModal = () => {
  const isOpen = useAppSelector(selectShowBucketLifecycleEditor);
  const loading = useAppSelector(selectBucketLifecycleActionLoading);
  const dispatch = useAppDispatch();

  const tiers = useAppSelector(selectS3Tiers);
  const tiersLoading = useAppSelector(selectS3TiersLoading);
  const checkedBuckets = useAppSelector(selectCheckedBuckets);

  const [selectedType, setSelectedType] = useState<RadioGroupeItem<string>>(
    typeOptions[0]
  );

  const [enabledDeleteMarker, setEnabledDeleteMarker] = useState(false);
  const [filterCheckedBuckets, setFilterCheckedBuckets] = useState<S3Bucket[]>(
    []
  );
  const [results, setResults] = useState<LifecycleError[]>([]);
  const [step, setStep] = useState<LifecycleCreateLevels>(
    "LifecycleConfiguration"
  );
  // const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    setFilterCheckedBuckets(checkedBuckets);
  }, [checkedBuckets]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(S3MultipleLifecycleSchema(selectedType.value)),
    shouldUnregister: true,
    defaultValues: {
      expiry_days: "0",
      noncurrentversion_expiration_days: "0",
      transition_days: "0",
      noncurrentversion_transition_days: "0",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tags",
    shouldUnregister: true,
  });

  const handleClearComponent = () => {
    reset();
    setStep("LifecycleConfiguration");
    setSelectedType(typeOptions[0]);
    // setValue("tags", [{ key: "", value: "" }]);
  };

  // useEffect(() => {
  //   setValue("expiry_days", "0");
  //   setValue("noncurrentversion_transition_days", "0");
  //   setSelectedType(typeOptions[0]);
  // }, [setValue]);

  useEffect(() => {
    if (isOpen && tiers.length === 0) {
      dispatch(getS3TiersAsync());
    }
  }, [dispatch, isOpen, tiers.length]);

  const onSubmit = (data: any) => {
    // setFormSubmitted(true);
    const tags = (data.tags as S3LifecycleTag[])
      .filter((tag) => tag.key !== "")
      .map((tag) => `${tag.key}=${tag.value}`)
      .join("&");

    let firstData = {
      buckets: filterCheckedBuckets.map((check) => check.name),
      type: selectedType.value,
      prefix: data.prefix || "",
      tags,
      expired_object_delete_marker: enabledDeleteMarker,
    };
    let formData;
    if (selectedType.value === "expiry") {
      formData = {
        ...firstData,
        expiry_days: +data.expiry_days,
        noncurrentversion_expiration_days:
          +data.noncurrentversion_expiration_days,
      };
    } else {
      formData = {
        ...firstData,
        transition_days: +data.transition_days,
        noncurrentversion_transition_days:
          +data.noncurrentversion_transition_days,
        noncurrentversion_transition_storage_class:
          data.noncurrentversion_transition_storage_class,
        storage_class: data.storage_class,
      };
    }

    dispatch(
      createMultiBucketLifecycleAsync({
        data: formData,
      })
    ).then((action: any) => {
      if (action.type === "bucket/multilifecycles/create/fulfilled") {
        setStep("Results");
        const createResult = action.payload.buckets;
        setResults(createResult);
      }
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideLifecycleEditor());
        handleClearComponent();
      }}
      contentClassName="max-w-xl"
      containerClassName="!items-start"
      title={
        <div className="justify-center w-full flex flex-col">
          <div>Set Lifecycle to multiple buckets</div>
        </div>
      }
    >
      <hr className="border-t-b border-gray-100 mt-5" />
      <Steps
        step={step}
        steps={[
          { label: "Lifecycle Configuration", value: "LifecycleConfiguration" },
          { label: "Results", value: "Results" },
        ]}
      />

      <div className="w-full overflow-x-auto">
        {/* LifecycleConfiguration */}
        <form className={cn({ hidden: step !== "LifecycleConfiguration" })}>
          <div className="flex flex-col w-full gap-5">
            <div className="flex items-center justify-between w-full">
              <div className="flex gap-2 items-center">
                <Typography.Text className="!text-sm font-semibold flex items-center gap-2">
                  Local Buckets to replicate
                </Typography.Text>
              </div>
              <div className="text-sm font-medium text-slate-800  dark:text-slate-200 mb-1 border p-2 rounded-lg">
                {filterCheckedBuckets.map((check, index) => (
                  <span key={check.name}>
                    {check.name}
                    {index !== filterCheckedBuckets.length - 1 ? ", " : ""}
                  </span>
                ))}
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <Typography.Text className="!text-sm font-semibold flex items-center gap-2">
                Remote Endpoint Configuration
              </Typography.Text>
            </div>
            <div className="flex gap-5 justify-between items-center">
              <Typography.Text className="!text-sm font-semibold">
                ILM Rule
              </Typography.Text>
              <div className="">
                <RadioGrouper
                  items={typeOptions}
                  selected={selectedType}
                  setSelected={setSelectedType}
                />
              </div>
            </div>
            {/* expiry */}
            <div
              className={cn("flex flex-col gap-5", {
                hidden: selectedType.value !== "expiry",
              })}
            >
              <Input
                label="Expiry Days"
                {...register("expiry_days")}
                type="number"
                error={errors.expiry_days?.message}
              />

              <Input
                label="Non-current Expiration Days"
                {...register("noncurrentversion_expiration_days")}
                type="number"
                error={errors.noncurrentversion_expiration_days?.message}
              />
            </div>

            {/* transition */}
            <div
              className={cn("flex flex-col gap-5", {
                hidden: selectedType.value !== "transition",
              })}
            >
              <Input
                label="Transition Days"
                {...register("transition_days")}
                type="number"
                error={errors.transition_days?.message}
              />

              <Input
                label="Non-current Transition Days"
                {...register("noncurrentversion_transition_days")}
                type="number"
                error={errors.expiry_days?.message}
              />
              <Input
                label="Non-current Version Transition Storage Class"
                {...register("noncurrentversion_transition_storage_class")}
                type="string"
                error={errors.expiry_days?.message}
              />
              <Controller
                control={control}
                name="storage_class"
                render={({ field: { value, onChange } }) => (
                  <Select
                    label="Storage Class"
                    options={tiers.map((tier) => ({
                      label: tier[tier.type].name,
                      value: tier[tier.type].name,
                    }))}
                    value={value ?? undefined}
                    onChange={onChange}
                    loading={tiersLoading}
                    required
                    error={errors.storage_class?.message}
                  />
                )}
              />
            </div>

            {/* File Configuration */}
            <Card className="">
              <Input
                label="Prefix"
                {...register("prefix")}
                error={errors.prefix?.message}
              />
              <div className="mt-2">
                <label className="flex items-center gap-1 text-sm font-medium text-slate-800 dark:text-slate-50 whitespace-nowrap">
                  Tags
                </label>
                <div className="flex flex-col w-full">
                  {fields.map((_, index) => (
                    <div key={index} className="grid gap-3 grid-cols-12 pb-2">
                      <div className="col-span-4">
                        <Input
                          label=""
                          {...register(`tags.${index}.key` as const)}
                          error={
                            errors.tags &&
                            errors.tags[index] &&
                            errors.tags[index]?.key &&
                            errors.tags[index]?.key?.message
                          }
                          placeholder="Tag Key"
                        />
                      </div>
                      <div className="col-span-7">
                        <Input
                          label=""
                          {...register(`tags.${index}.value` as const)}
                          error={
                            errors.tags &&
                            errors.tags[index] &&
                            errors.tags[index]?.value &&
                            errors.tags[index]?.value?.message
                          }
                          placeholder="Tag Value"
                        />
                      </div>
                      <div className="col-span-1 flex items-start justify-center">
                        <Button
                          uiType="icon"
                          onClick={(e) => {
                            e.preventDefault();
                            if (index !== 0) {
                              remove(index);
                            }
                          }}
                          disabled={false}
                          className="mt-1.5"
                        >
                          <ArchiveIcon className="w-5 text-slate-700 dark:text-slate-300 hover:text-red-500 hover:dark:text-red-400" />
                        </Button>
                      </div>
                    </div>
                  ))}
                  <div className="flex justify-end m-2">
                    <Button
                      uiType="light"
                      onClick={(event) => {
                        event.preventDefault();
                        append({
                          key: "",
                          value: "",
                        });
                      }}
                    >
                      <PlusIcon className="w-4" />
                    </Button>
                  </div>
                </div>
              </div>
            </Card>

            <div className="flex gap-5 justify-between items-center">
              <Typography.Text className="!text-sm font-semibold flex items-center gap-2">
                Expire Object Delete Marker
              </Typography.Text>
              <div className="">
                <Switcher
                  value={enabledDeleteMarker}
                  onChange={setEnabledDeleteMarker}
                />
              </div>
            </div>
          </div>
        </form>

        {/* Results */}
        <div
          className={cn("mt-5 flex flex-col w-full gap-5", {
            hidden: step !== "Results",
          })}
        >
          <div className="flex items-center justify-between w-full">
            <div className="flex gap-2 items-center">
              <div className="text-sm font-medium text-slate-800 dark:text-slate-200 mb-1">
                Multi Bucket lifecycle Assignments Results
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="flex gap-2 items-center">
              <div className="text-sm font-medium text-slate-500 dark:text-slate-200 mb-1">
                Buckets Results
              </div>
            </div>
          </div>

          <div className="flex gap-1">
            <div className="w-52">
              {results.map((r) => (
                <div
                  key={r.bucketName}
                  className="flex items-center group relative min-w-fit text-sm"
                >
                  <div className="border-2 mb-3 min-h-[40px] text-sm text-left rounded-lg w-full p-2.5 bg-secondary-100 disabled:bg-secondary-300 disabled:text-secondary-400 dark:bg-dark-2 disabled:dark:bg-secondary-500 outline-none disabled:cursor-not-allowed dark:border-dark-2 dark:focus:border-slate-600 dark:text-slate-50 border-secondary-100 focus:bg-secondary-50 focus:border-secondary-200 duration-300">
                    {r.bucketName}
                  </div>
                  {r.error ? (
                    <ErrorTooltip content={r.error} />
                  ) : (
                    <CheckmarkIcon className="w-8 h-8 text-success mb-4 ml-1" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center w-full h-14">
        {step === "LifecycleConfiguration" && (
          <div className="flex justify-end w-full">
            <div className="mt-4 flex justify-end">
              <Button
                loading={loading}
                uiType="primary"
                onClick={handleSubmit(onSubmit)}
                disabled={loading}
                // type="submit"
              >
                Create Rules
                <ArrowRightIcon className="w-4 h-4 group-hover:scale-110 group-hover:translate-x-1 transition-all duration-300" />
              </Button>
            </div>
          </div>
        )}

        {step === "Results" && (
          <div className="flex items-center justify-between w-full">
            <Button
              uiType="light"
              onClick={() => {
                setStep("LifecycleConfiguration");
              }}
            >
              back
            </Button>

            <Button
              loading={loading}
              uiType="primary"
              disabled={loading}
              type="submit"
              onClick={() => {
                dispatch(handleHideLifecycleEditor());
              }}
            >
              Done
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default S3MultipleBucketEditorModal;
