import { Outlet } from "react-router-dom";
import IpfsFilesTab from "../../components/ipfs/files/IpfsFilesTab";
import IpfsOverviewTab from "../../components/ipfs/overview/IpfsOverviewTab";
import { TabOptions } from "../../types/tabs";
import {
  IpfsFilesUrl,
  IpfsOverviewUrl,
  IpfsPlansUrl,
  IpfsSettingsUrl,
} from "../../utils/urls";
// import Text from "../../components/general/Text";
import { Helmet } from "react-helmet";
import { ReactComponent as ComputerIcon } from "./../../assets/icons/computer-desktop.svg";
import { ReactComponent as DocumentIcon } from "./../../assets/icons/document-text.svg";
import { ReactComponent as SettingIcon } from "./../../assets/icons/setting.svg";
import { ReactComponent as ListBulletIcon } from "./../../assets/icons/list-bullet.svg";
import IpfsSettingsTab from "../../components/ipfs/settings/IpfsSettingsTab";
import IpfsPlanesTab from "../../components/ipfs/plans/IpfsPlansTab";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectIpfsStatus } from "../../store/ipfs/ipfsPublicSlice";
import { useEffect } from "react";
import { fetchFilesAysnc } from "../../store/ipfs/filesSlice";
import { Modal, Loading, Typography, Tabs } from "djuno-design";

export const IpfsPageMenus: TabOptions = [
  {
    label: (
      <div className="flex items-center gap-1">
        <ComputerIcon className="w-3" />
        Overview
      </div>
    ),
    url: IpfsOverviewUrl,
    element: <IpfsOverviewTab />,
    testId: "1th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <DocumentIcon className="w-3" />
        Files
      </div>
    ),
    url: IpfsFilesUrl,
    element: <IpfsFilesTab />,
    testId: "2th-tab",
  },

  {
    label: (
      <div className="flex items-center gap-1">
        <SettingIcon className="w-3" />
        Settings
      </div>
    ),
    url: IpfsSettingsUrl,
    element: <IpfsSettingsTab />,
    testId: "3th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <ListBulletIcon className="w-3" />
        Plans
      </div>
    ),
    url: IpfsPlansUrl,
    element: <IpfsPlanesTab />,
    testId: "4th-tab",
  },
];

const IpfsPage = () => {
  const ipfsStatus = useAppSelector(selectIpfsStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (ipfsStatus === false) {
      interval = setInterval(() => {
        dispatch(fetchFilesAysnc());
      }, 20000);
    } else {
      if (interval) clearInterval(interval);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, ipfsStatus]);
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | IPFS</title>
        <meta
          name="description"
          content="IPFS, or InterPlanetary File System, is a decentralized protocol designed to revolutionize the way we store and access content on the internet. Unlike traditional web hosting systems that rely on centralized servers, IPFS utilizes a peer-to-peer network where files are stored across multiple nodes. Each file is assigned a unique cryptographic hash, enabling efficient retrieval and verification"
        />
      </Helmet>
      <div className="flex items-center justify-between h-20">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <Typography.Text className="!font-medium !text-lg">
            IPFS
          </Typography.Text>
        </div>
        <div className="mr-6 w-96"></div>
      </div>
      <div className="mx-6">
        <Tabs
          options={IpfsPageMenus.map((o) => ({
            label: o.label,
            url: o.url,
            testId: o.testId,
          }))}
        />
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default IpfsPage;
