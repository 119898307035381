import { useNavigate, Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { useAppDispatch } from "./hooks";
import { syncState } from "./store/auth/authSlice";
import { ToastClasses } from "./components/modals/alerts";
import { DDProvider } from "djuno-design";

function App() {
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  useEffect(() => {
    dispatch(syncState());
  }, [dispatch]);

  return (
    <DDProvider navigator={(url) => url && navigator(url)}>
      <Outlet />
      <Toaster
        toastOptions={{
          className: ToastClasses,
        }}
      />
    </DDProvider>
  );
}

export default App;
