import Button from "../../buttons/Button";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  selectSelectedInvoiceForPay,
  handleConfigPayNowModal,
  selectPayByCloudCreditLoading,
  selectPayByDefaultCardLoading,
  selectPayByIntentLoading,
  payNowByCloudCreditAsync,
  payNowByDefaultCardAsync,
  payNowByIntentsync,
  invoicesAsync,
} from "../../../store/billing/billingSlice";

import Modal from "../../modals/Modal";
import CardDetailsBox from "./CardDetailsBox";
import Text from "../../general/Text";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { CustomToast } from "../../general/Toast";
import { BillingIntentResponse } from "../../../types/billing";
import { selectUser } from "../../../store/auth/authSlice";
import Checkbox from "../../inputs/Checkbox";
import { Select2, SelectOption } from "../../inputs/Select";

const InvoicePayNowModal = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const invoice = useAppSelector(selectSelectedInvoiceForPay);
  const payByCloudCreditLoading = useAppSelector(selectPayByCloudCreditLoading);
  const payByDefaultCardLoading = useAppSelector(selectPayByDefaultCardLoading);
  const payByIntentLoading = useAppSelector(selectPayByIntentLoading);

  const [showPayByIntentModal, setShowPayByIntentModal] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const [defaultCard, setDefaultCard] = useState(false);

  const payOptions = useMemo(() => {
    if (!invoice) return [];
    return invoice.Actions.map((actionName) => {
      return {
        label: actionName,
        value: actionName,
      };
    });
  }, [invoice]);

  const [selectedPayOption, setSelectedPayOption] = useState<
    string | undefined
  >(payOptions[0]?.value);

  const handlePayByCloudCredit = () => {
    if (invoice) {
      dispatch(payNowByCloudCreditAsync({ invoiceId: invoice.Id })).then(
        (action) => {
          if (action.type === "billing/invoice/pay-by-cloud-credit/fulfilled") {
            payCompilited();
          }
        }
      );
    }
  };

  const handlePayByDefaultCard = () => {
    if (invoice) {
      dispatch(payNowByDefaultCardAsync({ invoiceId: invoice.Id })).then(
        (action) => {
          if (action.type === "billing/invoice/pay-by-default-card/fulfilled") {
            payCompilited();
          }
        }
      );
    }
  };

  const handleBeforInitPayByIntent = async () => {
    if (invoice) {
      return dispatch(
        payNowByIntentsync({
          invoiceId: invoice.Id,
          saveCard,
          defaultCard: saveCard ? defaultCard : false,
        })
      ).then((action) => {
        if (action.type === "billing/invoice/intent/fulfilled") {
          const orderToken = (action.payload as BillingIntentResponse).Token;
          return orderToken;
        } else {
          return undefined;
        }
      });
    } else {
      return undefined;
    }
  };

  const handleAfterInitPayByIntent = async () => {
    setShowPayByIntentModal(false);
    payCompilited();
  };

  const handleStartPayInvoice = () => {
    if (selectedPayOption) {
      if (selectedPayOption === "Cloud Credit") handlePayByCloudCredit();
      if (selectedPayOption === "Card") handlePayByDefaultCard();
      if (selectedPayOption === "Intent") setShowPayByIntentModal(true);
    }
  };

  const handleClose = () => {
    setSaveCard(false);
    setDefaultCard(false);
    setShowPayByIntentModal(false);
    dispatch(handleConfigPayNowModal(null));
  };

  const payCompilited = () => {
    handleClose();
    toast.success(
      () =>
        CustomToast(
          "Your invoice has been paid successfully. A confirmation email has been sent to your registered address. Thank you for your payment!"
        ),
      { duration: 4000 }
    );
    dispatch(invoicesAsync());
  };

  return (
    <>
      <Modal
        isOpen={invoice !== null}
        onClose={handleClose}
        contentClassName="max-w-lg"
        title="Choose Payment Method"
      >
        <Text type="text" className="text-sm mt-5">
          Select how you'd like to pay your invoice. You can use your default
          saved card, add a new card, or apply cloud credit if available. Please
          choose one of the options below to proceed with the payment
        </Text>
        <div className="flex items-center justify-start mt-5 w-full gap-2">
          <Text type="text" className="text-sm whitespace-nowrap">
            Select payment method
          </Text>
          <Select2<string>
            options={payOptions}
            value={selectedPayOption}
            onChange={setSelectedPayOption}
            className="w-full"
            emptyString="Select an option"
          />
          <Button
            buttonProps={{
              disabled: !selectedPayOption,
              onClick: handleStartPayInvoice,
            }}
            loading={
              payByCloudCreditLoading ||
              payByDefaultCardLoading ||
              payByIntentLoading
            }
          >
            Pay
          </Button>
          {/* {invoice && invoice.Actions && (
            <>
              {invoice.Actions.includes("Cloud credit") && (
                <Button
                  type="primary"
                  buttonClassName="!w-32"
                  loading={payByCloudCreditLoading}
                  buttonProps={{
                    onClick: () => {
                      !payByCloudCreditLoading && handlePayByCloudCredit();
                    },
                    disabled: payByDefaultCardLoading || payByIntentLoading,
                  }}
                >
                  Cloud credit
                </Button>
              )}
              {invoice.Actions.includes("Card") && (
                <Button
                  type="primary"
                  buttonClassName="!w-32"
                  loading={payByDefaultCardLoading}
                  buttonProps={{
                    onClick: () => {
                      !payByDefaultCardLoading && handlePayByDefaultCard();
                    },
                    disabled: payByCloudCreditLoading || payByIntentLoading,
                  }}
                >
                  Default Card
                </Button>
              )}
              {invoice.Actions.includes("Intent") && (
                <Button
                  type="primary"
                  buttonClassName="!w-32"
                  loading={payByIntentLoading}
                  buttonProps={{
                    onClick: () => {
                      !payByIntentLoading && setShowPayByIntentModal(true);
                    },
                    disabled:
                      payByCloudCreditLoading || payByDefaultCardLoading,
                  }}
                >
                  New Card
                </Button>
              )}
            </>
          )} */}
        </div>
        {selectedPayOption === "Intent" && (
          <div className="flex flex-col gap-2 mt-5">
            <Checkbox
              label="Save card for next payments"
              inputProps={{
                checked: saveCard,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setSaveCard(e.target.checked);
                },
              }}
            />
            {saveCard && (
              <Checkbox
                label="Set as default"
                inputProps={{
                  checked: defaultCard,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setDefaultCard(e.target.checked);
                  },
                }}
              />
            )}
          </div>
        )}

        <Modal
          isOpen={showPayByIntentModal}
          onClose={() => {
            setShowPayByIntentModal(false);
          }}
          title="Pay invoice by card"
          contentClassName="max-w-lg"
        >
          <CardDetailsBox
            beforInitFn={handleBeforInitPayByIntent}
            afterInitFn={handleAfterInitPayByIntent}
            initLoading={payByIntentLoading}
            savingLoading={false}
            username={user?.FullName}
          >
            {/* <div className="flex flex-col gap-2">
              <Checkbox
                label="Save card for next payments"
                inputProps={{
                  checked: saveCard,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setSaveCard(e.target.checked);
                  },
                }}
              />
              {saveCard && (
                <Checkbox
                  label="Set as default"
                  inputProps={{
                    checked: defaultCard,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      setDefaultCard(e.target.checked);
                    },
                  }}
                />
              )}
            </div> */}
          </CardDetailsBox>
        </Modal>
      </Modal>
    </>
  );
};

export default InvoicePayNowModal;
