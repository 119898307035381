import { useEffect, useState } from "react";
import { DeleteModal } from "./../../../../modals/QuestionModal";
import NotData from "./../../../../layouts/NotData";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./../../../../../hooks";
import { InstancesVolumeSnapshot } from "./../../../../../types/instance";
import { useSearch } from "./../../../../../providers/SearchProvider";
import { InstancesCreateVolumeUrl } from "./../../../../../utils/urls";
import { HighlighterText } from "./../../../../general/Text";
import { Helmet } from "react-helmet";
import {
  getInstancesVolumeAsync,
  getInstancesVolumesAsync,
  selectInstancesSelectedVolume,
  selectInstancesSelectedVolumeLoading,
} from "./../../../../../store/instances/instancesVolumesSlice";
import {
  deleteInstancesVolumeSnapshotAsync,
  getInstanceVolumeSnapshotListAsync,
  handleShowVolumeSnapshotModal,
  selectInstancesVolumeSnapshotsList,
  selectInstancesVolumeSnapshotsListLoading,
} from "./../../../../../store/instances/instancesVolumeSlice";
import { formatTimestamp } from "./../../../../../utils/date";
import InstanceVolumeSnapshostsModal from "./InstanceVolumeSnapshostsModal";
import { ReactComponent as PlusIcon } from "./../../../../../assets/icons/plus.svg";
import { ReactComponent as ArchiveIcon } from "./../../../../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../../../../assets/icons/more.svg";
import { Button, Dropdown, SimpleTable, Tag, Typography } from "djuno-design";

const InstanceVolumeSnapshotTab = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [deleteVolumeSnapshot, setDeleteVolumeSnapshot] = useState<
    string | null
  >(null);
  const volume = useAppSelector(selectInstancesSelectedVolume);
  const volumeLoading = useAppSelector(selectInstancesSelectedVolumeLoading);
  const volumeSnapshotsList = useAppSelector(
    selectInstancesVolumeSnapshotsList
  );
  const volumeSnapshotsListLoading = useAppSelector(
    selectInstancesVolumeSnapshotsListLoading
  );

  const [filteredVolumeSnapshots, setFilteredVolumeSnapshots] = useState<
    InstancesVolumeSnapshot[]
  >([]);

  const dispatch = useAppDispatch();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (volume === null && id !== undefined) {
      dispatch(getInstancesVolumeAsync({ id }));
    }
  }, [dispatch, id, volume]);

  useEffect(() => {
    if (volume && volume.id) {
      dispatch(
        getInstanceVolumeSnapshotListAsync({
          volumeId: volume?.id,
        })
      );
    }
  }, [dispatch, volume]);

  useEffect(() => {
    const lookedUpApiKeys = volumeSnapshotsList.filter((key) =>
      key.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredVolumeSnapshots(lookedUpApiKeys);
  }, [dispatch, searchValue, volumeSnapshotsList]);

  return (
    <div className="">
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Volume Snapshots</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="flex flex-col gap-3">
        <div className="flex transition-all duration-500">
          <div className="ml-auto flex items-center justify-end w-full">
            <Button
              uiType="primary"
              onClick={() =>
                volume && dispatch(handleShowVolumeSnapshotModal({ volume }))
              }
              className="group"
            >
              Create a volume snapshot
              <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
          </div>
        </div>

        <div className="mt-6">
          <SimpleTable loading={volumeSnapshotsListLoading || volumeLoading}>
            <SimpleTable.Head>
              <SimpleTable.Row>
                <SimpleTable.TH lable="Name" />
                <SimpleTable.TH lable="Region" />
                <SimpleTable.TH lable="Volume" />
                <SimpleTable.TH lable="Creation date" />
                <SimpleTable.TH lable="Size" />
                <SimpleTable.TH lable="Status" />
                <SimpleTable.TH lable="" />
              </SimpleTable.Row>
            </SimpleTable.Head>
            <SimpleTable.Body>
              {filteredVolumeSnapshots.map(
                (volumeSnapshot: any, index: any) => (
                  <SimpleTable.Row key={index} withoutHoverStyle>
                    <SimpleTable.TD className="w-36">
                      <div className="flex flex-col gap-1">
                        <HighlighterText
                          searchValue={searchValue}
                          textToHighlight={volumeSnapshot.name}
                        />
                      </div>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-sm whitespace-nowrap">
                        {volumeSnapshot.region}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-sm whitespace-nowrap">
                        {volumeSnapshot.name}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-sm whitespace-nowrap">
                        {
                          formatTimestamp(
                            volumeSnapshot.creationDate,
                            "MMM DD hh:mm:ss A"
                          ).datetime
                        }
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-sm !whitespace-nowrap">
                        {volumeSnapshot.size} GiB
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Tag
                        className="text-sm whitespace-nowrap"
                        color={
                          volumeSnapshot.status === "available"
                            ? "success"
                            : "processing"
                        }
                      >
                        {volumeSnapshot.status}
                      </Tag>
                    </SimpleTable.TD>
                    <SimpleTable.TD className="w-10">
                      <div className="w-8 flex justify-center items-center">
                        <Dropdown
                          anchor="bottom end"
                          itemsClassName="!w-49"
                          menu={[
                            {
                              key: "create",
                              label: (
                                <div className="flex items-center gap-1">
                                  <ArchiveIcon className="w-4" />
                                  Create a volume
                                </div>
                              ),
                              onClick: (_, close) => {
                                close();
                                navigate(InstancesCreateVolumeUrl);
                              },
                            },
                            // {
                            //   type: "divider",
                            // },
                            {
                              key: "end",
                              label: (
                                <div className="flex items-center gap-1">
                                  <ArchiveIcon className="w-4" />
                                  Delete
                                </div>
                              ),
                              danger: true,
                              onClick: (_, close) => {
                                close();
                                setDeleteVolumeSnapshot(volumeSnapshot.id);
                              },
                            },
                          ]}
                        >
                          <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                            <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                          </div>
                        </Dropdown>
                      </div>
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                )
              )}
              {volumeSnapshotsList.length === 0 && (
                <SimpleTable.Row withoutHoverStyle={true}>
                  <SimpleTable.TD colSpan={7} className="!border-0">
                    <NotData text="You have not created any Volume Snapshot yet" />
                  </SimpleTable.TD>
                </SimpleTable.Row>
              )}
            </SimpleTable.Body>
          </SimpleTable>
        </div>
      </div>
      <InstanceVolumeSnapshostsModal />
      <DeleteModal
        title="Delete a Volume Snapshot"
        isOpen={deleteVolumeSnapshot !== null}
        onClose={() => setDeleteVolumeSnapshot(null)}
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        loading={volumeSnapshotsListLoading}
        onConfirm={() => {
          deleteVolumeSnapshot &&
            volume &&
            dispatch(
              deleteInstancesVolumeSnapshotAsync({
                volumeId: volume.id,
                snapshotId: deleteVolumeSnapshot,
              })
            ).then((action) => {
              if (
                action.type === "instances/volume/snapshot/delete/fulfilled"
              ) {
                dispatch(
                  getInstancesVolumeAsync({
                    id: volume?.id,
                  })
                );
                dispatch(
                  getInstanceVolumeSnapshotListAsync({
                    volumeId: volume?.id,
                  })
                );
                dispatch(getInstancesVolumesAsync({}));
              }
              setDeleteVolumeSnapshot(null);
            });
        }}
        confirmString={
          volumeSnapshotsList.find((v) => v.id === deleteVolumeSnapshot)?.name
        }
      />
    </div>
  );
};

export default InstanceVolumeSnapshotTab;
