import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  deleteMultipleObjectAsync,
  deleteObjectAsync,
  getObjectDownloadAsync,
  getObjectVersionsAsync,
  handleChangeSelectedVersion,
  handleShowObjectPreview,
  handleShowObjectShareModal,
  restoreObjectAsync,
  selectObjectDeleteLoading,
  selectObjectDownloadLoading,
  selectObjectRestoreLoading,
  selectObjectVersions,
  selectObjectVersionsLoading,
  selectSelectedObject,
  selectSelectedVersionOfObject,
} from "../../../store/s3/buckets/objectSlice";
import {
  getFileNameAndExt,
  getMainVersionOfObject,
  getObjectTotalVersionsSize,
  objectIsFolder,
} from "../../../utils/bucket";
import { humanizeSize } from "../../../utils/file";
import { ReactComponent as BarsIcon } from "./../../../assets/icons/bars-3-bottom-left.svg";
import { ReactComponent as DownloadIcon } from "./../../../assets/icons/arrow-down-tray.svg";
import { ReactComponent as ShareIcon } from "./../../../assets/icons/share.svg";
import { ReactComponent as EyeIcon } from "./../../../assets/icons/eye.svg";
import { ReactComponent as ArrowPathIcon } from "./../../../assets/icons/arrow-path.svg";
import { ReactComponent as DeleteIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as DeleteIcon2 } from "./../../../assets/icons/archive-box2.svg";
import { ReactComponent as SquaresIcon } from "./../../../assets/icons/squares-2x2.svg";
import { S3BucketObjectInfo } from "../../../types/s3-bucket";
import FileIcon, { extToType } from "../../general/FileIcon";
import { FileTypes } from "../../../types";
import { dateConvertor } from "../../../utils/date";
import { selectBucketDetails } from "../../../store/s3/buckets/bucketSlice";
import { useEffect, useState } from "react";
import QuestionModal from "../../modals/QuestionModal";
import {
  Button,
  Checkbox,
  cn,
  Loading,
  Switcher,
  Tag,
  Typography,
} from "djuno-design";

const S3ObjectVersions = () => {
  const bucketDetails = useAppSelector(selectBucketDetails);
  const selectedObject = useAppSelector(selectSelectedObject);

  const versions = useAppSelector(selectObjectVersions);
  const versionsLoading = useAppSelector(selectObjectVersionsLoading);
  const mainVersion = getMainVersionOfObject(versions);
  const selectedVersion = useAppSelector(selectSelectedVersionOfObject);
  const dispatch = useAppDispatch();

  const deleteLoading = useAppSelector(selectObjectDeleteLoading);
  const [enableBypass, setEnableBypass] = useState(false);
  const [deleteNonCurrent, setDeleteNonCurrent] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState(false);

  const [enableMultiSelect, setEnableMultiSelect] = useState(false);
  const [checkedVersions, setCheckedVersions] = useState<S3BucketObjectInfo[]>(
    []
  );

  const restoreLoading = useAppSelector(selectObjectRestoreLoading);
  const [restoreVersion, setRestoreVersion] = useState<S3BucketObjectInfo>();
  const handleRestoreVersion = () => {
    if (
      bucketDetails &&
      restoreVersion &&
      restoreVersion.version_id &&
      mainVersion
    ) {
      dispatch(
        restoreObjectAsync({
          bucketName: bucketDetails.name,
          prefix: restoreVersion.name,
          versionId: restoreVersion.version_id,
        })
      ).then((action) => {
        if (action.type === "object/restore/fulfilled") {
          setRestoreVersion(undefined);
          dispatch(
            getObjectVersionsAsync({
              bucketName: bucketDetails.name,
              prefix: mainVersion.name,
            })
          );
        }
      });
    }
  };

  useEffect(() => {
    if (!enableMultiSelect) {
      setCheckedVersions([]);
    }
  }, [enableMultiSelect]);

  const handleCheckVersion = (version: S3BucketObjectInfo) => {
    const isSelected =
      checkedVersions.find((o) => o.version_id === version.version_id) || false;
    const filteredObjects = checkedVersions.filter(
      (o) => o.version_id !== version.version_id
    );
    if (isSelected) {
      setCheckedVersions([...filteredObjects]);
    } else {
      setCheckedVersions([...filteredObjects, version]);
    }
  };

  const handleDeleteNonCurrentVersions = () => {
    if (bucketDetails && mainVersion) {
      dispatch(
        deleteObjectAsync({
          bucketName: bucketDetails.name,
          prefix: mainVersion.name,
          non_current_versions: true,
          bypass: enableBypass,
        })
      ).then((action) => {
        if (action.type === "object/delete/fulfilled") {
          dispatch(
            getObjectVersionsAsync({
              bucketName: bucketDetails.name,
              prefix: mainVersion.name,
            })
          );
        }
      });
    }
  };

  const handleDeleteSelectedVersions = () => {
    if (bucketDetails && mainVersion) {
      dispatch(
        deleteMultipleObjectAsync({
          bucketName: bucketDetails.name,
          objects: checkedVersions,
          bypass: enableBypass,
          all_versions: false,
        })
      ).then((action) => {
        if (action.type === "object/delete-multiple/fulfilled") {
          dispatch(
            getObjectVersionsAsync({
              bucketName: bucketDetails.name,
              prefix: mainVersion.name,
            })
          );
        }
      });
    }
  };

  return (
    <div className="w-full flex flex-col">
      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-start">
          <BarsIcon className="w-5 mt-1 text-slate-700 dark:text-slate-200" />
          <div className="flex flex-col">
            <div className="flex gap-2 items-center">
              <Typography.Title level={6} className="!mb-0">
                {getFileNameAndExt(selectedObject?.name)?.fullName} Versions
              </Typography.Title>
            </div>
            <div className="flex items-center gap-4">
              <Typography.Text uiType="secondary" className="!text-sm">{`${
                versions?.length
              } version${
                versions && versions?.length > 1 ? "s" : ""
              }`}</Typography.Text>
              <Typography.Text uiType="secondary" className="!text-sm">
                {versions &&
                  humanizeSize(getObjectTotalVersionsSize(versions), {
                    fractionDigits: 2,
                  }).join("B")}
              </Typography.Text>
            </div>
          </div>
        </div>
        <div className="px-6 flex items-center gap-3">
          <Button
            uiType={enableMultiSelect ? "primary" : undefined}
            tooltip={{ content: "Select Multiple Versions" }}
            className="!px-2"
            onClick={() => setEnableMultiSelect((prev) => !prev)}
          >
            <SquaresIcon className="w-5" />
          </Button>

          {enableMultiSelect && (
            <Button
              uiType="danger"
              tooltip={{ content: "Delete Selected Versions" }}
              className="!px-2"
              disabled={checkedVersions.length === 0}
              onClick={() => setDeleteSelected(true)}
            >
              <DeleteIcon2 className="w-5" />
            </Button>
          )}

          <Button
            uiType="danger"
            tooltip={{ content: "Delete Non Current Versions" }}
            className="!px-2"
            onClick={() => setDeleteNonCurrent(true)}
          >
            <DeleteIcon className="w-5" />
          </Button>
        </div>
      </div>
      <div className="mt-5">
        {!versionsLoading &&
          versions?.map((version, i) => (
            <VersionRow
              key={i}
              version={version}
              versionNumber={`V${versions.length - i}`}
              selected={
                selectedVersion !== null
                  ? "version_id" in selectedVersion
                    ? version.version_id === selectedVersion.version_id
                    : false
                  : false
              }
              showCheckbox={enableMultiSelect}
              checked={
                !!checkedVersions.find(
                  (cv) => cv.version_id === version.version_id
                )
              }
              onCheck={handleCheckVersion}
              onRestore={setRestoreVersion}
            />
          ))}
        {versionsLoading && (
          <div className="mt-10">
            <Loading borderSize={2} />
          </div>
        )}
      </div>
      <QuestionModal
        isOpen={!!restoreVersion}
        onClose={() => setRestoreVersion(undefined)}
        title="Restore File Version"
        onConfirm={handleRestoreVersion}
        loading={restoreLoading}
      >
        <div className="flex flex-col gap-2">
          <div>
            <Typography.Text className="!text-sm">
              Are you sure you want to restore
            </Typography.Text>
            <Typography.Title level={6}>
              {restoreVersion
                ? getFileNameAndExt(restoreVersion.name)?.fullName
                : ""}
            </Typography.Title>
          </div>
          <div>
            <Typography.Text className="!text-sm">
              with Version ID:
            </Typography.Text>
            <Typography.Title level={6}>
              {restoreVersion?.version_id}
            </Typography.Title>
          </div>
        </div>
      </QuestionModal>
      <QuestionModal
        isOpen={deleteNonCurrent}
        onClose={() => {
          setDeleteNonCurrent(false);
          setEnableBypass(false);
        }}
        title="Delete Non-Current versions"
        confirmButtonType="danger"
        onConfirm={handleDeleteNonCurrentVersions}
        loading={deleteLoading}
      >
        <div className="flex flex-col gap-2 mt-5">
          <div>
            <Typography.Text className="!text-sm">
              Are you sure you want to delete all the non-current versions for:{" "}
              {getFileNameAndExt(mainVersion?.name)?.fullName}?
            </Typography.Text>
          </div>
          <div className="flex items-center justify-between">
            <Typography.Text className="!text-sm">
              Bypass Governance Mode
            </Typography.Text>
            <Switcher value={enableBypass} onChange={setEnableBypass} />
          </div>
        </div>
      </QuestionModal>
      <QuestionModal
        isOpen={deleteSelected}
        onClose={() => {
          setDeleteSelected(false);
          setEnableBypass(false);
        }}
        title="Delete Selected Versions"
        confirmButtonType="danger"
        onConfirm={handleDeleteSelectedVersions}
        loading={deleteLoading}
      >
        <div className="flex flex-col gap-2 mt-5">
          <div>
            <Typography.Text className="!text-sm">
              Are you sure you want to delete the selected{" "}
              {checkedVersions.length} versions for{" "}
              {getFileNameAndExt(mainVersion?.name)?.fullName}?
            </Typography.Text>
          </div>
          <div className="flex items-center justify-between">
            <Typography.Text className="!text-sm">
              Bypass Governance Mode
            </Typography.Text>
            <Switcher value={enableBypass} onChange={setEnableBypass} />
          </div>
        </div>
      </QuestionModal>
    </div>
  );
};

const VersionRow = ({
  version,
  versionNumber,
  selected,
  showCheckbox,
  checked,
  onCheck,
  onRestore,
}: {
  version: S3BucketObjectInfo;
  versionNumber: string;
  selected?: boolean;
  checked?: boolean;
  showCheckbox?: boolean;
  onCheck?: (version: S3BucketObjectInfo) => void;
  onRestore?: (version: S3BucketObjectInfo) => void;
}) => {
  const bucketDetails = useAppSelector(selectBucketDetails);
  const dispatch = useAppDispatch();

  const downloadLoading = useAppSelector(selectObjectDownloadLoading);
  const [downloadVersion, setDownloadVersion] = useState<S3BucketObjectInfo>();
  const handleDownload = () => {
    if (bucketDetails && version.version_id) {
      setDownloadVersion(version);
      dispatch(
        getObjectDownloadAsync({
          bucketName: bucketDetails.name,
          prefix: version.name,
          versionId: version.version_id,
        })
      ).then(() => setDownloadVersion(undefined));
    }
  };

  const handleChangeChecked = (e: any) => {
    e.stopPropagation();
    onCheck && onCheck(version);
  };

  return (
    <div
      className={cn("grid grid-cols-4 cursor-pointer col-span-full", {
        "hover:bg-slate-100 dark:hover:bg-dark-2 ": !selected,
        "bg-primary-50 dark:bg-dark-1": selected,
      })}
      onClick={(e) => dispatch(handleChangeSelectedVersion(version))}
    >
      <div className="border-b col-span-3 border-slate-300/60 dark:border-dark-2 py-3 px-2 w-full text-ellipsis whitespace-nowrap overflow-hidden col-name">
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-2">
            {showCheckbox && (
              // <input

              //   type="checkbox"
              //   checked={checked || false}
              //   onChange={() => {}}
              //   className="block w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 rounded ring-0 dark:bg-gray-700 dark:border-gray-600"
              //   onClick={handleChangeChecked}
              // />
              <div onClick={(event) => event.stopPropagation()}>
                <Checkbox
                  id="default-checkbox"
                  value={checked || false}
                  onChange={handleChangeChecked}
                />
              </div>
            )}

            <FileIcon
              disabled={version.is_delete_marker}
              type={
                objectIsFolder(version.name)
                  ? "folder"
                  : (extToType(
                      getFileNameAndExt(version.name)?.fileExt || ""
                    ) as FileTypes)
              }
            />
            <Typography.Title
              level={6}
              uiType={version.is_delete_marker ? "disabled" : undefined}
              className="!mb-0"
            >
              {versionNumber}
              {/* {getFileNameAndExt(version.name)?.fullName} */}
            </Typography.Title>
            {version.is_latest && (
              <Tag color="processing" className="ml-1">
                CURRENT VERSION
              </Tag>
            )}
            {version.is_delete_marker && (
              <Tag color="warning" className="!text-xsml-1">
                DELETED
              </Tag>
            )}
          </div>
          <Typography.Text uiType="secondary" className="!text-sm">
            {version.version_id}
          </Typography.Text>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-1">
              <Typography.Text
                className="!text-xs font-semibold"
                uiType={version.is_delete_marker ? "secondary" : undefined}
              >
                Last modified:
              </Typography.Text>
              <Typography.Text
                className="!text-xs"
                uiType={version.is_delete_marker ? "secondary" : undefined}
              >
                {version.last_modified && dateConvertor(version.last_modified)}
              </Typography.Text>
            </div>
            <div className="flex items-center gap-1">
              <Typography.Text
                className="!text-xs font-semibold"
                uiType={version.is_delete_marker ? "disabled" : undefined}
              >
                Size:
              </Typography.Text>
              <Typography.Text
                className="!text-xs"
                uiType={version.is_delete_marker ? "disabled" : undefined}
              >
                {version.size
                  ? humanizeSize(version.size, { fractionDigits: 2 }).join("B")
                  : "0.0B"}
              </Typography.Text>
            </div>
          </div>
        </div>
      </div>
      <div className="border-b col-span-1  border-slate-300/60 dark:border-dark-2 py-3 px-2 w-full text-ellipsis whitespace-nowrap overflow-hidden col-lastModified">
        <div className="flex items-center gap-2">
          <Button
            uiType="icon"
            tooltip={{ content: !version.is_delete_marker ? "Preview" : "" }}
            disabled={version.is_delete_marker}
            onClick={() => dispatch(handleShowObjectPreview({ version }))}
          >
            <EyeIcon className="w-4 text-slate-700 dark:text-slate-300" />
          </Button>
          <Button
            uiType="icon"
            tooltip={{
              content: !version.is_delete_marker ? "Download this version" : "",
            }}
            disabled={version.is_delete_marker}
            onClick={(e) => {
              e.stopPropagation();
              handleDownload();
            }}
            loading={
              downloadLoading &&
              downloadVersion?.version_id === version.version_id
            }
          >
            <DownloadIcon className="w-4 text-slate-700 dark:text-slate-300" />
          </Button>
          <Button
            uiType="icon"
            tooltip={{
              content: !version.is_delete_marker ? "Share this version" : "",
            }}
            disabled={version.is_delete_marker}
            onClick={() => dispatch(handleShowObjectShareModal({ version }))}
          >
            <ShareIcon className="w-4 text-slate-700 dark:text-slate-300" />
          </Button>
          <Button
            uiType="icon"
            tooltip={{
              content: !version.is_delete_marker ? "Restore this version" : "",
            }}
            disabled={version.is_delete_marker}
            onClick={() => (onRestore ? onRestore(version) : {})}
          >
            <ArrowPathIcon className="w-4 text-slate-700 dark:text-slate-300" />
          </Button>
        </div>
      </div>
    </div>
  );
};
export default S3ObjectVersions;
