import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { S3AccessPolicySchema } from "../../../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { useEffect, useState } from "react";
import {
  selectBucketDetails,
  handleHideAccessPolictEditor,
  selectShowAccessPolicyEditor,
  selectBucketAccessPolicyLoading,
  setBucketAccessPolicyAsync,
  getBucketDetailsAsync,
} from "../../../../../store/s3/buckets/bucketSlice";
import Select, { SelectOption } from "../../../../inputs/Select";
import { AccessPolicyTypes } from "../../../../../types/s3-bucket";
import { Button, Modal, Textarea } from "djuno-design";

export const accessPolicyOptions: SelectOption<AccessPolicyTypes>[] = [
  { label: "Public", value: "PUBLIC" },
  { label: "Private", value: "PRIVATE" },
  { label: "Custom", value: "CUSTOM" },
];

const S3AccessPolicyEditorModal = () => {
  const isOpen = useAppSelector(selectShowAccessPolicyEditor);
  const loading = useAppSelector(selectBucketAccessPolicyLoading);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const [selectedAccessPolicy, setSelectedAccessPolicy] = useState<
    SelectOption<AccessPolicyTypes> | undefined
  >();
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(S3AccessPolicySchema(selectedAccessPolicy?.value)),
  });

  //set form data with bucket details
  useEffect(() => {
    if (isOpen && bucketDetails) {
      if (typeof bucketDetails.definition !== "undefined") {
        setValue("access", bucketDetails.access);
        const sapo = accessPolicyOptions.find(
          (apo) => apo.value === bucketDetails.access
        );
        sapo && setSelectedAccessPolicy(sapo);
      }
      setValue("definition", bucketDetails.definition);
    } else {
      reset();
    }
  }, [isOpen, bucketDetails, setValue, reset]);

  const handleClearComponent = () => {
    setSelectedAccessPolicy(undefined);
    reset();
  };

  const onSubmit = (data: any) => {
    // data:
    //definition -> string or ''
    //--- others:
    // selectedAccessPolicy
    console.log(data);
    if (bucketDetails && selectedAccessPolicy) {
      dispatch(
        setBucketAccessPolicyAsync({
          name: bucketDetails.name,
          formData: {
            access: selectedAccessPolicy.value,
            definition: data.definition || bucketDetails.definition,
          },
        })
      ).then((action) => {
        if (action.type === "bucket/access-policy/set/fulfilled") {
          handleClearComponent();
          dispatch(handleHideAccessPolictEditor());
          dispatch(getBucketDetailsAsync({ name: bucketDetails.name }));
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideAccessPolictEditor());
        handleClearComponent();
      }}
      contentClassName="max-w-lg"
      title={"Change Access Policy"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          <Select<AccessPolicyTypes>
            label="Access Policy"
            options={accessPolicyOptions}
            selected={selectedAccessPolicy}
            setSelected={(o) => {
              setValue("access", o ? o.value : "");
              setSelectedAccessPolicy(o);
            }}
            error={errors.access?.message}
          />
          {selectedAccessPolicy?.value === "CUSTOM" && (
            <Textarea
              {...register("definition")}
              label="Write Policy"
              error={errors.definition?.message}
              rows={4}
            />
          )}
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            onClick={(e) => {
              e.preventDefault();
              dispatch(handleHideAccessPolictEditor());
            }}
            uiType="light"
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            uiType="primary"
            disabled={loading}
            type="submit"
          >
            Set
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3AccessPolicyEditorModal;
