import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  deleteS3AccessKeyAsync,
  getS3AccessKeysAsync,
  handleShowEditor,
  selectActionLoading,
  selectS3AccessKeys,
  selectLoading,
  deleteMultipleS3AccessKeyAsync,
} from "../../../store/s3/accessKeysSlice";
import S3AccessKeyEditorModal from "./S3AccessKeyEditorModal";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { S3NotActiveTab } from "../../layouts/NotData";
import { DeleteModal } from "../../modals/QuestionModal";
import { selectOnStageEnv } from "../../../store/auth/authSlice";
import S3AccessKeyShowModal from "./S3AccessKeyShowModal";
import { S3AccessKey } from "../../../types/s3-access-key";
import { useSearch } from "../../../providers/SearchProvider";
import { formatTimestamp } from "../../../utils/date";
import { selectS3Status } from "../../../store/s3/s3PublicSlice";
import {
  Button,
  Checkbox,
  Dropdown,
  EmptyState,
  SecureCopy,
  SimpleTable,
  Typography,
} from "djuno-design";
import { HighlighterText } from "../../general/Text";

const S3AccessKeysTab = () => {
  const s3Status = useAppSelector(selectS3Status);
  const env = useAppSelector(selectOnStageEnv);
  const accessKeys = useAppSelector(selectS3AccessKeys);
  const [filteredAccessKeys, setFilteredAccessKeys] = useState<S3AccessKey[]>(
    []
  );
  const [checkedAccessKeys, setCheckedAccessKeys] = useState<S3AccessKey[]>([]);

  const loading = useAppSelector(selectLoading);
  const actionLoading = useAppSelector(selectActionLoading);

  const [deleteAccessKey, setDeleteAccessKey] = useState<string | null>(null);
  const [deleteChecked, setDeleteChecked] = useState(false);

  const dispatch = useAppDispatch();
  const { value: searchValue } = useSearch();

  useEffect(() => {
    // if (accessKeys.length === 0) {
    dispatch(getS3AccessKeysAsync());
    // }
  }, [dispatch]);

  useEffect(() => {
    const lookedUpBuckets = accessKeys.filter((ak) =>
      ak.accessKey?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredAccessKeys(lookedUpBuckets);
  }, [dispatch, searchValue, accessKeys]);

  const handleDelete = () => {
    deleteAccessKey &&
      dispatch(deleteS3AccessKeyAsync({ accessKey: deleteAccessKey })).then(
        (action) => {
          if (action.type === "s3-access-keys/delete/fulfilled") {
            dispatch(getS3AccessKeysAsync());
          } else {
          }
          setDeleteAccessKey(null);
        }
      );
  };

  const handleChangeChecked = (accessKey: S3AccessKey) => {
    const isSelected =
      checkedAccessKeys.find((ak) => ak.accessKey === accessKey.accessKey) ||
      false;
    const filteredAccessKeys = checkedAccessKeys.filter(
      (ak) => ak.accessKey !== accessKey.accessKey
    );
    if (isSelected) {
      setCheckedAccessKeys([...filteredAccessKeys]);
    } else {
      setCheckedAccessKeys([...filteredAccessKeys, accessKey]);
    }
  };

  const handleDeleteSelectedItems = () => {
    dispatch(
      deleteMultipleS3AccessKeyAsync({ accessKeys: checkedAccessKeys })
    ).then((action) => {
      if (action.type === "s3-access-keys/delete-multiple/fulfilled") {
        dispatch(getS3AccessKeysAsync());
        setCheckedAccessKeys([]);
        setDeleteChecked(false);
      } else {
      }
    });
  };

  if (!s3Status) {
    return <S3NotActiveTab />;
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <Typography.Title level={5} className="!mb-0">
            Access Keys
          </Typography.Title>
        </div>
        <div className="flex items-center gap-2">
          <Button
            uiType="dangerLight"
            onClick={() => setDeleteChecked(true)}
            disabled={checkedAccessKeys.length === 0}
            className="!px-2"
            tooltip={{
              content: "Delete Selected",
              theme: "black",
              className: "!text-xs",
            }}
          >
            <ArchiveIcon className="w-5" />
          </Button>
          <Button
            uiType="primary"
            onClick={() => dispatch(handleShowEditor({}))}
            className="group"
          >
            Create New
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <div className="flex items-center text-sm gap-2 flex-1 mb-3">
          <Typography.Text size="sm">API:</Typography.Text>
          <SecureCopy
            type="copy"
            text={env?.StorageApiRoute || ""}
            className="min-w-[300px]"
            textClassName=" !text-xs !truncate !overflow-x-hidden"
          />
        </div>
        <SimpleTable loading={loading}>
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Access Key" />
              <SimpleTable.TH lable="Expiry" />
              <SimpleTable.TH lable="Status" />
              <SimpleTable.TH lable="Name" />
              <SimpleTable.TH lable="Description" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {filteredAccessKeys.map((accessKey, i) => (
              <SimpleTable.Row key={i}>
                <SimpleTable.TD>
                  <div className="flex items-center space-x-1 ml-1">
                    <div onClick={(event) => event.stopPropagation()}>
                      <Checkbox
                        value={checkedAccessKeys.some(
                          (o) => o.accessKey === accessKey.accessKey
                        )}
                        onChange={() => handleChangeChecked(accessKey)}
                      />
                    </div>
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={accessKey.accessKey}
                    />
                  </div>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {accessKey.expiration &&
                      formatTimestamp(
                        accessKey.expiration,
                        "YYYY/M/D HH:mm:ss [UTC]"
                      ).datetime}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {accessKey.accountStatus === "on" ? "Enabled" : "Disabled"}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {accessKey.name}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {accessKey.description}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD className="w-32 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        itemsClassName="!w-40"
                        anchor="bottom end"
                        menu={[
                          {
                            key: "1",
                            label: (
                              <div className="flex items-center gap-1">
                                <EditIcon className="w-4" />
                                Edit
                              </div>
                            ),
                            onClick: (_, close) => {
                              close();
                              dispatch(
                                handleShowEditor({
                                  accessKey: accessKey.accessKey,
                                })
                              );
                            },
                          },
                          {
                            type: "divider",
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              setDeleteAccessKey(accessKey.accessKey);
                            },
                          },
                        ]}
                      >
                        <div className=" p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200">
                          <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}

            {accessKeys.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true}>
                <SimpleTable.TD colSpan={6} className="!border-0">
                  <EmptyState text="You have not created any Access Key yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>
      <S3AccessKeyEditorModal />
      <S3AccessKeyShowModal />
      <DeleteModal
        title="Delete Access Key"
        isOpen={deleteAccessKey !== null}
        onClose={() => setDeleteAccessKey(null)}
        onConfirm={handleDelete}
        loading={actionLoading}
        confirmString={
          accessKeys.find(
            (accessKey) => accessKey.accessKey === deleteAccessKey
          )?.name
        }
        confirmButtonType="danger"
      />
      {/* <QuestionModal
        isOpen={deleteChecked}
        onClose={() => setDeleteChecked(false)}
        title="Delete Access Keys"
        description={`Are you sure you want to delete the selected ${checkedAccessKeys.length} Access Keys?`}
        onConfirm={handleDeleteSelectedItems}
        confirmButtonType="danger"
        loading={actionLoading}
      /> */}
    </>
  );
};

export default S3AccessKeysTab;
