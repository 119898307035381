import { Outlet } from "react-router-dom";
import { ReactComponent as LogoTextIcon } from "./../../assets/icons/logo-text.svg";
import { ThemeChanger } from "djuno-design";

const AuthLayout = () => {
  return (
    <div className="w-screen bg-white dark:bg-zinc-900 min-h-screen flex justify-center items-center">
      <div className="fixed top-5 right-10 text-slate-400 dark:text-slate-300">
        <ThemeChanger anchor="bottom end" />
      </div>
      <div className="max-w-lg min-w-[22rem] w-3/5 mx-5 flex flex-col justify-start">
        <div className="p-8 flex flex-col bg-white shadow-[0_4px_30px_0_rgba(0,0,0,0.05)] dark:bg-dark-3  dark:shadow-none rounded-xl  ">
          <div className="flex justify-center mb-2">
            <LogoTextIcon className="text-black/60 dark:text-gray-300 h-10" />
          </div>
          <Outlet />
        </div>
      </div>
      {/* <PChatWidget /> */}
    </div>
  );
};

export default AuthLayout;
