import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useState } from "react";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
// import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as DeleteIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { useNavigate } from "react-router-dom";
import {
  DatabaseServiceGeneralInformationUrl,
  DatabaseServiceNamespacesCreateUrl,
} from "../../../utils/urls";
import { DeleteModal } from "../../modals/QuestionModal";
import { HighlighterText } from "../../general/Text";
import { useSearch } from "../../../providers/SearchProvider";
import {
  getDatabaseServicesAsync,
  selectDatabaseServicesLoading,
} from "../../../store/database/servicesSlice";
import {
  deleteDBSNmaespaceAsync,
  getDBSNamespacesAsync,
  getDatabaseServiceAsync,
  handleShowNamespaceEditorModal,
  selectDatabaseService,
  selectDatabaseServiceLoading,
  selectNamespaces,
  selectNamespacesLoading,
} from "../../../store/database/serviceSlice";
import { IDBSNamespaces } from "../../../types/database";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import DBSNamespaceEditorModal from "./DBSNamespaceEditorModal";
import { convertFromISO8601Duration } from "./DBSNamespaceCreateTab";
import {
  Button,
  Dropdown,
  EmptyState,
  Flex,
  SimpleTable,
  Typography,
} from "djuno-design";

const DBSNamespacesTab = () => {
  const [deleteNamespace, setDeleteNamespace] = useState<IDBSNamespaces | null>(
    null
  );
  const [filteredNamespaces, setFilteredNamespaces] = useState<
    IDBSNamespaces[]
  >([]);

  const service = useAppSelector(selectDatabaseService);
  const serviceLoading = useAppSelector(selectDatabaseServiceLoading);

  const namespaces = useAppSelector(selectNamespaces);
  const namespacesLoading = useAppSelector(selectNamespacesLoading);
  const loading = useAppSelector(selectDatabaseServicesLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().users;
      if (!permissions.includes(service.engine)) {
        navigate(DatabaseServiceGeneralInformationUrl(service.id));
      } else {
        dispatch(
          getDBSNamespacesAsync({ id: service.id, engine: service.engine })
        );
      }
    }
  }, [dispatch, navigate, service]);

  useEffect(() => {
    const lookedUpFiles = namespaces.filter((namespace) =>
      namespace.name?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredNamespaces(lookedUpFiles);
  }, [dispatch, searchValue, namespaces]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database</title>
        <meta name="description" content="" />
      </Helmet>

      <Flex direction="col" className="gap-3 w-full">
        <Flex items="center" justify="between">
          <div className="flex flex-col gap-1">
            <Typography.Title level={5} className="!mb-0">
              Namespaces
            </Typography.Title>
          </div>
          <div className="flex items-center gap-2">
            <Button
              uiType="light"
              onClick={() => {
                if (service) {
                  dispatch(
                    getDBSNamespacesAsync({
                      id: service.id,
                      engine: service.engine,
                    })
                  );
                }
              }}
              disabled={serviceLoading}
              className="group"
              tooltip={{ content: "Refresh" }}
            >
              <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
            <Button
              uiType="primary"
              onClick={() =>
                service &&
                navigate(DatabaseServiceNamespacesCreateUrl(service.id))
              }
              disabled={serviceLoading}
              className="group"
            >
              Add a namespaces
              <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
          </div>
        </Flex>
        <Typography.Text className="!font-light !text-sm">
          Point collections. The namespaces are either non-aggregated (includes
          all point clouds as they arrive) or aggregated (only one point is
          stored for each interval configured for the namespace).
        </Typography.Text>
      </Flex>
      <div className="mt-3 w-full">
        <SimpleTable
          loading={namespacesLoading}
          containerClassName="min-h-[250px]"
        >
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Name" />
              <SimpleTable.TH lable="Type" />
              <SimpleTable.TH lable="Retention period" />
              <SimpleTable.TH lable="Resolution" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>

          <SimpleTable.Body>
            {filteredNamespaces.map((namespace, i) => (
              <SimpleTable.Row key={i}>
                <SimpleTable.TD>
                  <div className="flex items-center flex-row justify-between space-x-3">
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={namespace.name}
                    />
                  </div>
                </SimpleTable.TD>

                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {namespace.type}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {convertFromISO8601Duration(
                      namespace.retention.periodDuration
                    )}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm">
                    {convertFromISO8601Duration(namespace.resolution)}
                  </Typography.Text>
                </SimpleTable.TD>

                <SimpleTable.TD className="w-20 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1">
                    <div className="w-8 flex justify-center items-center ">
                      <Dropdown
                        anchor="bottom end"
                        itemsClassName="!w-48"
                        menu={[
                          {
                            key: "1",
                            label: (
                              <div className="flex items-center gap-1">
                                <EditIcon className="w-4"></EditIcon>
                                Edit
                              </div>
                            ),
                            onClick: (_, close) => {
                              close();
                              dispatch(
                                handleShowNamespaceEditorModal({
                                  namespace,
                                })
                              );
                            },
                            disabled: loading,
                          },
                          {
                            type: "divider",
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <DeleteIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              setDeleteNamespace(namespace);
                            },
                          },
                        ]}
                      >
                        <Button uiSize="small" uiType="icon" className="!px-2">
                          <MoreIcon className="w-4 h-4" />
                        </Button>
                      </Dropdown>
                    </div>
                    {/* <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" /> */}
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}

            {namespaces.length === 0 && !namespacesLoading && (
              <SimpleTable.Row withoutHoverStyle>
                <SimpleTable.TD colSpan={5} className="!border-0">
                  <EmptyState text="You have not created any namespace yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>

      <DBSNamespaceEditorModal />

      <DeleteModal
        title="Delete a Namespace"
        isOpen={deleteNamespace !== null}
        onClose={() => setDeleteNamespace(null)}
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        onConfirm={() => {
          deleteNamespace &&
            service &&
            dispatch(
              deleteDBSNmaespaceAsync({
                id: service.id,
                engine: service.engine,
                namespaceId: deleteNamespace.id,
              })
            ).then((action) => {
              if (action.type === "service/namespace/delete/fulfilled") {
                dispatch(getDatabaseServicesAsync());
                dispatch(getDatabaseServiceAsync({ id: service.id }));
                dispatch(
                  getDBSNamespacesAsync({
                    id: service.id,
                    engine: service.engine,
                  })
                );
              } else {
              }
              setDeleteNamespace(null);
            });
        }}
        loading={namespacesLoading}
        confirmString={deleteNamespace?.name}
      />
    </>
  );
};

export default DBSNamespacesTab;
