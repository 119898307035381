import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import {
  selectBucketDetails,
  setBucketEncryptionAsync,
  selectEncryptionLoading,
  selectBucketDetailsLoading,
  selectShowEncryptionEditor,
  handleHideEncryptionModal,
  selectBucketEncryption,
  getBucketEncryptionAsync,
} from "../../../../../store/s3/buckets/bucketSlice";
import { useEffect, useState } from "react";
import {
  getBucketsKmsKeysAsync,
  handleShowKmsEditor,
  selectKmsKeys,
  selectKmsLoading,
} from "../../../../../store/s3/buckets/bucketsSlice";
import { ReactComponent as AddIcon } from "./../../../../../assets/icons/plus.svg";
import S3KmsEditorModal from "./S3KmsEditorModal";
import { Button, Modal, Select, SelectOption } from "djuno-design";

export const encryptionOptions: SelectOption[] = [
  { label: "Disabled", value: "Disabled" },
  { label: "SSE-S3", value: "SSE-S3" },
  { label: "SSE-KMS", value: "SSE-KMS" },
];

const S3EncryptionEditorModal = () => {
  const isOpen = useAppSelector(selectShowEncryptionEditor);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketDetailsLoading = useAppSelector(selectBucketDetailsLoading);
  const bucketEncryption = useAppSelector(selectBucketEncryption);
  const encryptionLoading = useAppSelector(selectEncryptionLoading);

  const kmsKeys = useAppSelector(selectKmsKeys);
  const kmsKeysLoading = useAppSelector(selectKmsLoading);

  const [selectedEnc, setSelectedEnc] = useState<string>();
  const [selectedKmsKey, setSelectedKmsKey] = useState<string>();

  const dispatch = useAppDispatch();

  const handleClearComponent = () => {
    // reset();
  };

  //specify selections value
  useEffect(() => {
    if (bucketEncryption) {
      //TODO
      // setSelectedEnc()
      // setSelectedKmsKey()
    } else {
      setSelectedEnc(encryptionOptions[0].value);
    }
  }, [bucketEncryption]);

  useEffect(() => {
    if (selectedEnc === "SSE-KMS") {
      dispatch(getBucketsKmsKeysAsync());
    }
  }, [dispatch, selectedEnc]);

  const onSubmit = (data: any) => {
    if (bucketDetails && selectedEnc) {
      dispatch(
        setBucketEncryptionAsync({
          name: bucketDetails.name,
          data: {
            isDisabled: selectedEnc === "Disabled",
            encType: selectedEnc,
            kmsKeyID: selectedKmsKey ? +selectedKmsKey : null, //TODO
          },
        })
      ).then((action) => {
        if (action.type === "bucket/encryption/set/fulfilled") {
          handleClearComponent();
          dispatch(handleHideEncryptionModal());
          dispatch(getBucketEncryptionAsync({ name: bucketDetails.name }));
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideEncryptionModal());
        handleClearComponent();
      }}
      contentClassName="max-w-lg"
      containerClassName="!items-start"
      title={"Enable Bucket Encryption"}
    >
      <div className="mt-5 flex flex-col w-full gap-5">
        <Select
          label="Encryption Type"
          options={encryptionOptions}
          value={selectedEnc}
          onChange={(o) => {
            setSelectedEnc(o);
          }}
        />
        {selectedEnc === "SSE-KMS" && (
          <div className="flex flex-col gap-1">
            <Select
              label="KMS Key ID"
              options={[
                ...kmsKeys.map((k) => ({
                  label: k.name,
                  value: k.id.toString(),
                })),
              ]}
              value={selectedKmsKey}
              onChange={setSelectedKmsKey}
              loading={kmsKeysLoading}
            />
            <div className="flex justify-end">
              <Button
                tooltip={{ content: "Create New KMS key" }}
                uiType="icon"
                onClick={() => dispatch(handleShowKmsEditor())}
              >
                <AddIcon className="w-5" />
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="mt-4 flex justify-end gap-2">
        <Button
          onClick={(e) => {
            e.preventDefault();
            dispatch(handleHideEncryptionModal());
          }}
          uiType="light"
        >
          Cancel
        </Button>
        <Button
          loading={encryptionLoading || bucketDetailsLoading}
          uiType="primary"
          disabled={encryptionLoading || bucketDetailsLoading}
          onClick={onSubmit}
        >
          Set
        </Button>
      </div>
      <S3KmsEditorModal />
    </Modal>
  );
};

export default S3EncryptionEditorModal;
