import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import {
  Instance,
  InstanceFlavor,
  InstanceImage,
  InstanceSnapshot,
  InstancesState,
  OvhRegion,
  ProductAvalibility,
} from "../../types/instance";
import {
  deleteInstanceApi,
  getInstancesApi,
  getInstancesProductAvalibilityApi,
  getInstancesFlavorsApi,
  getRegionsApi,
  getInstancesImagesApi,
  createInstanceApi,
  createMultipleInstanceApi,
  getInstancesSnapshotsApi,
  deleteInstancesBackupSnapshotApi,
} from "../../apis/instancesAPI";

const dummyProductAvailibility: ProductAvalibility = {
  plans: [
    {
      code: "volume.classic.consumption",
      regions: [
        {
          name: "BHS5",
          datacenter: "BHS",
          continentCode: "NA",
          enabled: true,
          type: "region",
        },
        {
          name: "DE1",
          datacenter: "DE",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "EU-CENTRAL-LZ-PRG-A",
          datacenter: "PRG",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-SOUTH-LZ-MAD-A",
          datacenter: "MAD",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-SOUTH-LZ-MIL-A",
          datacenter: "MIL",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-AMS-A",
          datacenter: "AMS",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-BRU-A",
          datacenter: "BRU",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-MRS-A",
          datacenter: "MRS",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-ZRH-A",
          datacenter: "ZRH",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "GRA11",
          datacenter: "GRA",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "GRA7",
          datacenter: "GRA",
          continentCode: "EU",
          enabled: false,
          type: "region",
        },
        {
          name: "GRA9",
          datacenter: "GRA",
          continentCode: "EU",
          enabled: false,
          type: "region",
        },
        {
          name: "RBX-A",
          datacenter: "RBX",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "SBG5",
          datacenter: "SBG",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "SBG7",
          datacenter: "SBG",
          continentCode: "EU",
          enabled: false,
          type: "region",
        },
        {
          name: "SGP1",
          datacenter: "SGP",
          continentCode: "ASIA",
          enabled: false,
          type: "region",
        },
        {
          name: "SYD1",
          datacenter: "SYD",
          continentCode: "ASIA",
          enabled: false,
          type: "region",
        },
        {
          name: "UK1",
          datacenter: "UK",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "WAW1",
          datacenter: "WAW",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
      ],
    },
    {
      code: "volume.classic.monthly.postpaid",
      regions: [
        {
          name: "BHS5",
          datacenter: "BHS",
          continentCode: "NA",
          enabled: true,
          type: "region",
        },
        {
          name: "DE1",
          datacenter: "DE",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "EU-CENTRAL-LZ-PRG-A",
          datacenter: "PRG",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-SOUTH-LZ-MAD-A",
          datacenter: "MAD",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-SOUTH-LZ-MIL-A",
          datacenter: "MIL",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-AMS-A",
          datacenter: "AMS",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-BRU-A",
          datacenter: "BRU",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-MRS-A",
          datacenter: "MRS",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-ZRH-A",
          datacenter: "ZRH",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "GRA11",
          datacenter: "GRA",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "GRA7",
          datacenter: "GRA",
          continentCode: "EU",
          enabled: false,
          type: "region",
        },
        {
          name: "GRA9",
          datacenter: "GRA",
          continentCode: "EU",
          enabled: false,
          type: "region",
        },
        {
          name: "RBX-A",
          datacenter: "RBX",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "SBG5",
          datacenter: "SBG",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "SBG7",
          datacenter: "SBG",
          continentCode: "EU",
          enabled: false,
          type: "region",
        },
        {
          name: "SGP1",
          datacenter: "SGP",
          continentCode: "ASIA",
          enabled: false,
          type: "region",
        },
        {
          name: "SYD1",
          datacenter: "SYD",
          continentCode: "ASIA",
          enabled: false,
          type: "region",
        },
        {
          name: "UK1",
          datacenter: "UK",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "WAW1",
          datacenter: "WAW",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
      ],
    },
    {
      code: "volume.high-speed-gen2.consumption",
      regions: [
        {
          name: "BHS5",
          datacenter: "BHS",
          continentCode: "NA",
          enabled: true,
          type: "region",
        },
        {
          name: "DE1",
          datacenter: "DE",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "EU-CENTRAL-LZ-PRG-A",
          datacenter: "PRG",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-SOUTH-LZ-MAD-A",
          datacenter: "MAD",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-SOUTH-LZ-MIL-A",
          datacenter: "MIL",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-AMS-A",
          datacenter: "AMS",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-BRU-A",
          datacenter: "BRU",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-MRS-A",
          datacenter: "MRS",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-ZRH-A",
          datacenter: "ZRH",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "GRA11",
          datacenter: "GRA",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "GRA7",
          datacenter: "GRA",
          continentCode: "EU",
          enabled: false,
          type: "region",
        },
        {
          name: "GRA9",
          datacenter: "GRA",
          continentCode: "EU",
          enabled: false,
          type: "region",
        },
        {
          name: "RBX-A",
          datacenter: "RBX",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "SBG5",
          datacenter: "SBG",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "SBG7",
          datacenter: "SBG",
          continentCode: "EU",
          enabled: false,
          type: "region",
        },
        {
          name: "UK1",
          datacenter: "UK",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "WAW1",
          datacenter: "WAW",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
      ],
    },
    {
      code: "volume.high-speed-gen2.monthly.postpaid",
      regions: [
        {
          name: "BHS5",
          datacenter: "BHS",
          continentCode: "NA",
          enabled: true,
          type: "region",
        },
        {
          name: "DE1",
          datacenter: "DE",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "EU-CENTRAL-LZ-PRG-A",
          datacenter: "PRG",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-SOUTH-LZ-MAD-A",
          datacenter: "MAD",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-SOUTH-LZ-MIL-A",
          datacenter: "MIL",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-AMS-A",
          datacenter: "AMS",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-BRU-A",
          datacenter: "BRU",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-MRS-A",
          datacenter: "MRS",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-ZRH-A",
          datacenter: "ZRH",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "GRA11",
          datacenter: "GRA",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "GRA7",
          datacenter: "GRA",
          continentCode: "EU",
          enabled: false,
          type: "region",
        },
        {
          name: "GRA9",
          datacenter: "GRA",
          continentCode: "EU",
          enabled: false,
          type: "region",
        },
        {
          name: "RBX-A",
          datacenter: "RBX",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "SBG5",
          datacenter: "SBG",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "SBG7",
          datacenter: "SBG",
          continentCode: "EU",
          enabled: false,
          type: "region",
        },
        {
          name: "UK1",
          datacenter: "UK",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "WAW1",
          datacenter: "WAW",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
      ],
    },
    {
      code: "volume.high-speed.consumption",
      regions: [
        {
          name: "BHS5",
          datacenter: "BHS",
          continentCode: "NA",
          enabled: true,
          type: "region",
        },
        {
          name: "DE1",
          datacenter: "DE",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "EU-CENTRAL-LZ-PRG-A",
          datacenter: "PRG",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-SOUTH-LZ-MAD-A",
          datacenter: "MAD",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-SOUTH-LZ-MIL-A",
          datacenter: "MIL",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-AMS-A",
          datacenter: "AMS",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-BRU-A",
          datacenter: "BRU",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-MRS-A",
          datacenter: "MRS",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-ZRH-A",
          datacenter: "ZRH",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "GRA11",
          datacenter: "GRA",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "GRA7",
          datacenter: "GRA",
          continentCode: "EU",
          enabled: false,
          type: "region",
        },
        {
          name: "GRA9",
          datacenter: "GRA",
          continentCode: "EU",
          enabled: false,
          type: "region",
        },
        {
          name: "RBX-A",
          datacenter: "RBX",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "SBG5",
          datacenter: "SBG",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "SBG7",
          datacenter: "SBG",
          continentCode: "EU",
          enabled: false,
          type: "region",
        },
        {
          name: "SGP1",
          datacenter: "SGP",
          continentCode: "ASIA",
          enabled: false,
          type: "region",
        },
        {
          name: "SYD1",
          datacenter: "SYD",
          continentCode: "ASIA",
          enabled: false,
          type: "region",
        },
        {
          name: "UK1",
          datacenter: "UK",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "WAW1",
          datacenter: "WAW",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
      ],
    },
    {
      code: "volume.high-speed.monthly.postpaid",
      regions: [
        {
          name: "BHS5",
          datacenter: "BHS",
          continentCode: "NA",
          enabled: true,
          type: "region",
        },
        {
          name: "DE1",
          datacenter: "DE",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "EU-CENTRAL-LZ-PRG-A",
          datacenter: "PRG",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-SOUTH-LZ-MAD-A",
          datacenter: "MAD",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-SOUTH-LZ-MIL-A",
          datacenter: "MIL",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-AMS-A",
          datacenter: "AMS",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-BRU-A",
          datacenter: "BRU",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-MRS-A",
          datacenter: "MRS",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "EU-WEST-LZ-ZRH-A",
          datacenter: "ZRH",
          continentCode: "EU",
          enabled: false,
          type: "localzone",
        },
        {
          name: "GRA11",
          datacenter: "GRA",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "GRA7",
          datacenter: "GRA",
          continentCode: "EU",
          enabled: false,
          type: "region",
        },
        {
          name: "GRA9",
          datacenter: "GRA",
          continentCode: "EU",
          enabled: false,
          type: "region",
        },
        {
          name: "RBX-A",
          datacenter: "RBX",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "SBG5",
          datacenter: "SBG",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "SBG7",
          datacenter: "SBG",
          continentCode: "EU",
          enabled: false,
          type: "region",
        },
        {
          name: "SGP1",
          datacenter: "SGP",
          continentCode: "ASIA",
          enabled: false,
          type: "region",
        },
        {
          name: "SYD1",
          datacenter: "SYD",
          continentCode: "ASIA",
          enabled: false,
          type: "region",
        },
        {
          name: "UK1",
          datacenter: "UK",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
        {
          name: "WAW1",
          datacenter: "WAW",
          continentCode: "EU",
          enabled: true,
          type: "region",
        },
      ],
    },
  ],
  products: [],
};

const dummyRegions: OvhRegion[] = [
  {
    name: "BHS",
    type: "region",
    status: "UP",
    services: [
      {
        name: "key-manager",
        status: "UP",
        endpoint: "https://key-manager.bhs.cloud.ovh.net",
      },
      {
        name: "storage-s3-high-perf",
        status: "UP",
        endpoint: "https://s3.bhs.perf.cloud.ovh.net/",
      },
      {
        name: "storage-s3-standard",
        status: "UP",
        endpoint: "https://s3.bhs.io.cloud.ovh.net/",
      },
      {
        name: "storage",
        status: "UP",
        endpoint:
          "https://storage.bhs.cloud.ovh.net/v1/AUTH_73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
    ],
    ipCountries: [],
    continentCode: "NA",
    datacenterLocation: "BHS",
  },
  {
    name: "BHS5",
    type: "region",
    status: "UP",
    services: [
      {
        name: "octavialoadbalancer",
        status: "UP",
        endpoint: "https://load-balancer.bhs5.cloud.ovh.net",
      },
      {
        name: "workflow",
        status: "UP",
        endpoint: "https://workflow.bhs5.cloud.ovh.net/v2",
      },
      {
        name: "instance",
        status: "UP",
        endpoint:
          "https://compute.bhs5.cloud.ovh.net/v2.1/73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
      {
        name: "network",
        status: "UP",
        endpoint: "https://network.compute.bhs5.cloud.ovh.net/",
      },
      {
        name: "image",
        status: "UP",
        endpoint: "https://image.compute.bhs5.cloud.ovh.net/",
      },
      {
        name: "volume",
        status: "UP",
        endpoint:
          "https://volume.compute.bhs5.cloud.ovh.net/v2/73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
      {
        name: "key-manager",
        status: "UP",
        endpoint: "https://key-manager.bhs.cloud.ovh.net",
      },
    ],
    ipCountries: ["ca"],
    continentCode: "NA",
    datacenterLocation: "BHS",
  },
  {
    name: "CA-EAST-TOR",
    type: "region",
    status: "DOWN",
    services: [
      {
        name: "storage-s3-high-perf",
        status: "UP",
        endpoint: "https://s3.ca-east-tor.perf.cloud.ovh.net/",
      },
      {
        name: "storage-s3-standard",
        status: "UP",
        endpoint: "https://s3.ca-east-tor.io.cloud.ovh.net/",
      },
    ],
    ipCountries: [],
    continentCode: "NA",
    datacenterLocation: "TOR",
  },
  {
    name: "DE",
    type: "region",
    status: "UP",
    services: [
      {
        name: "key-manager",
        status: "UP",
        endpoint: "https://key-manager.de.cloud.ovh.net",
      },
      {
        name: "storage-s3-high-perf",
        status: "UP",
        endpoint: "https://s3.de.perf.cloud.ovh.net/",
      },
      {
        name: "storage-s3-standard",
        status: "UP",
        endpoint: "https://s3.de.io.cloud.ovh.net/",
      },
      {
        name: "storage",
        status: "UP",
        endpoint:
          "https://storage.de.cloud.ovh.net/v1/AUTH_73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
    ],
    ipCountries: [],
    continentCode: "EU",
    datacenterLocation: "DE",
  },
  {
    name: "DE1",
    type: "region",
    status: "UP",
    services: [
      {
        name: "octavialoadbalancer",
        status: "UP",
        endpoint: "https://load-balancer.de1.cloud.ovh.net",
      },
      {
        name: "volume",
        status: "UP",
        endpoint:
          "https://volume.compute.de1.cloud.ovh.net/v2/73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
      {
        name: "workflow",
        status: "UP",
        endpoint: "https://workflow.de1.cloud.ovh.net/v2",
      },
      {
        name: "instance",
        status: "UP",
        endpoint:
          "https://compute.de1.cloud.ovh.net/v2.1/73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
      {
        name: "image",
        status: "UP",
        endpoint: "https://image.compute.de1.cloud.ovh.net/",
      },
      {
        name: "network",
        status: "UP",
        endpoint: "https://network.compute.de1.cloud.ovh.net/",
      },
      {
        name: "key-manager",
        status: "UP",
        endpoint: "https://key-manager.de.cloud.ovh.net",
      },
    ],
    ipCountries: [
      "be",
      "cz",
      "de",
      "es",
      "fi",
      "fr",
      "ie",
      "it",
      "lt",
      "nl",
      "pl",
      "pt",
      "uk",
    ],
    continentCode: "EU",
    datacenterLocation: "DE",
  },
  {
    name: "GRA",
    type: "region",
    status: "UP",
    services: [
      {
        name: "storage-s3-standard",
        status: "UP",
        endpoint: "https://s3.gra.io.cloud.ovh.net/",
      },
      {
        name: "storage-s3-high-perf",
        status: "UP",
        endpoint: "https://s3.gra.perf.cloud.ovh.net/",
      },
      {
        name: "key-manager",
        status: "UP",
        endpoint: "https://key-manager.gra.cloud.ovh.net",
      },
      {
        name: "storage",
        status: "UP",
        endpoint:
          "https://storage.gra.cloud.ovh.net/v1/AUTH_73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
    ],
    ipCountries: [],
    continentCode: "EU",
    datacenterLocation: "GRA",
  },
  {
    name: "GRA11",
    type: "region",
    status: "UP",
    services: [
      {
        name: "key-manager",
        status: "UP",
        endpoint: "https://key-manager.gra.cloud.ovh.net",
      },
      {
        name: "octavialoadbalancer",
        status: "UP",
        endpoint: "https://load-balancer.gra11.cloud.ovh.net",
      },
      {
        name: "image",
        status: "UP",
        endpoint: "https://image.compute.gra11.cloud.ovh.net/",
      },
      {
        name: "volume",
        status: "UP",
        endpoint:
          "https://volume.compute.gra11.cloud.ovh.net/v2/73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
      {
        name: "network",
        status: "UP",
        endpoint: "https://network.compute.gra11.cloud.ovh.net/",
      },
      {
        name: "workflow",
        status: "UP",
        endpoint: "https://workflow.gra11.cloud.ovh.net/v2",
      },
      {
        name: "instance",
        status: "UP",
        endpoint:
          "https://compute.gra11.cloud.ovh.net/v2.1/73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
    ],
    ipCountries: [
      "be",
      "cz",
      "de",
      "es",
      "fi",
      "fr",
      "ie",
      "it",
      "lt",
      "nl",
      "pl",
      "pt",
      "uk",
    ],
    continentCode: "EU",
    datacenterLocation: "GRA",
  },
  {
    name: "RBX",
    type: "region",
    status: "UP",
    services: [
      {
        name: "storage-s3-standard",
        status: "UP",
        endpoint: "https://s3.rbx.io.cloud.ovh.net/",
      },
      {
        name: "storage-s3-high-perf",
        status: "UP",
        endpoint: "https://s3.rbx.perf.cloud.ovh.net/",
      },
    ],
    ipCountries: [],
    continentCode: "EU",
    datacenterLocation: "RBX",
  },
  {
    name: "RBX-A",
    type: "region",
    status: "UP",
    services: [
      {
        name: "key-manager",
        status: "UP",
        endpoint: "https://key-manager.rbx.cloud.ovh.net",
      },
      {
        name: "octavialoadbalancer",
        status: "UP",
        endpoint: "https://load-balancer.rbx-a.cloud.ovh.net",
      },
      {
        name: "workflow",
        status: "UP",
        endpoint: "https://workflow.rbx-a.cloud.ovh.net/v2",
      },
      {
        name: "instance",
        status: "UP",
        endpoint:
          "https://compute.rbx-a.cloud.ovh.net/v2.1/73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
      {
        name: "volume",
        status: "UP",
        endpoint:
          "https://volume.compute.rbx-a.cloud.ovh.net/v2/73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
      {
        name: "image",
        status: "UP",
        endpoint: "https://image.compute.rbx-a.cloud.ovh.net/",
      },
      {
        name: "network",
        status: "UP",
        endpoint: "https://network.compute.rbx-a.cloud.ovh.net/",
      },
    ],
    ipCountries: [
      "be",
      "cz",
      "de",
      "es",
      "fi",
      "fr",
      "ie",
      "it",
      "lt",
      "nl",
      "pl",
      "pt",
      "uk",
    ],
    continentCode: "EU",
    datacenterLocation: "RBX",
  },
  {
    name: "RBX-ARCHIVE",
    type: "region",
    status: "UP",
    services: [
      {
        name: "storage-s3-coldarchive",
        status: "UP",
        endpoint: "https://s3.rbx-archive.io.cloud.ovh.net/",
      },
    ],
    ipCountries: [],
    continentCode: "EU",
    datacenterLocation: "RBX",
  },
  {
    name: "SBG",
    type: "region",
    status: "UP",
    services: [
      {
        name: "key-manager",
        status: "UP",
        endpoint: "https://key-manager.sbg.cloud.ovh.net",
      },
      {
        name: "storage-s3-standard",
        status: "UP",
        endpoint: "https://s3.sbg.io.cloud.ovh.net/",
      },
      {
        name: "storage-s3-high-perf",
        status: "UP",
        endpoint: "https://s3.sbg.perf.cloud.ovh.net/",
      },
      {
        name: "storage",
        status: "UP",
        endpoint:
          "https://storage.sbg.cloud.ovh.net/v1/AUTH_73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
    ],
    ipCountries: [],
    continentCode: "EU",
    datacenterLocation: "SBG",
  },
  {
    name: "SBG5",
    type: "region",
    status: "UP",
    services: [
      {
        name: "key-manager",
        status: "UP",
        endpoint: "https://key-manager.sbg.cloud.ovh.net",
      },
      {
        name: "octavialoadbalancer",
        status: "UP",
        endpoint: "https://load-balancer.sbg5.cloud.ovh.net",
      },
      {
        name: "instance",
        status: "UP",
        endpoint:
          "https://compute.sbg5.cloud.ovh.net/v2.1/73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
      {
        name: "volume",
        status: "UP",
        endpoint:
          "https://volume.compute.sbg5.cloud.ovh.net/v2/73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
      {
        name: "image",
        status: "UP",
        endpoint: "https://image.compute.sbg5.cloud.ovh.net/",
      },
      {
        name: "network",
        status: "UP",
        endpoint: "https://network.compute.sbg5.cloud.ovh.net/",
      },
      {
        name: "workflow",
        status: "UP",
        endpoint: "https://workflow.sbg5.cloud.ovh.net/v2",
      },
    ],
    ipCountries: [
      "be",
      "cz",
      "de",
      "es",
      "fi",
      "fr",
      "ie",
      "it",
      "lt",
      "nl",
      "pl",
      "pt",
      "uk",
    ],
    continentCode: "EU",
    datacenterLocation: "SBG",
  },
  {
    name: "UK",
    type: "region",
    status: "UP",
    services: [
      {
        name: "key-manager",
        status: "UP",
        endpoint: "https://key-manager.uk.cloud.ovh.net",
      },
      {
        name: "storage-s3-standard",
        status: "UP",
        endpoint: "https://s3.uk.io.cloud.ovh.net/",
      },
      {
        name: "storage-s3-high-perf",
        status: "UP",
        endpoint: "https://s3.uk.perf.cloud.ovh.net/",
      },
      {
        name: "storage",
        status: "UP",
        endpoint:
          "https://storage.uk.cloud.ovh.net/v1/AUTH_73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
    ],
    ipCountries: [],
    continentCode: "EU",
    datacenterLocation: "UK",
  },
  {
    name: "UK1",
    type: "region",
    status: "UP",
    services: [
      {
        name: "key-manager",
        status: "UP",
        endpoint: "https://key-manager.uk.cloud.ovh.net",
      },
      {
        name: "octavialoadbalancer",
        status: "UP",
        endpoint: "https://load-balancer.uk1.cloud.ovh.net",
      },
      {
        name: "workflow",
        status: "UP",
        endpoint: "https://workflow.uk1.cloud.ovh.net/v2",
      },
      {
        name: "volume",
        status: "UP",
        endpoint:
          "https://volume.compute.uk1.cloud.ovh.net/v2/73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
      {
        name: "instance",
        status: "UP",
        endpoint:
          "https://compute.uk1.cloud.ovh.net/v2.1/73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
      {
        name: "network",
        status: "UP",
        endpoint: "https://network.compute.uk1.cloud.ovh.net/",
      },
      {
        name: "image",
        status: "UP",
        endpoint: "https://image.compute.uk1.cloud.ovh.net/",
      },
    ],
    ipCountries: ["uk"],
    continentCode: "EU",
    datacenterLocation: "UK",
  },
  {
    name: "WAW",
    type: "region",
    status: "UP",
    services: [
      {
        name: "key-manager",
        status: "UP",
        endpoint: "https://key-manager.waw.cloud.ovh.net",
      },
      {
        name: "storage-s3-standard",
        status: "UP",
        endpoint: "https://s3.waw.io.cloud.ovh.net/",
      },
      {
        name: "storage-s3-high-perf",
        status: "UP",
        endpoint: "https://s3.waw.perf.cloud.ovh.net/",
      },
      {
        name: "storage",
        status: "UP",
        endpoint:
          "https://storage.waw.cloud.ovh.net/v1/AUTH_73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
    ],
    ipCountries: [],
    continentCode: "EU",
    datacenterLocation: "WAW",
  },
  {
    name: "WAW1",
    type: "region",
    status: "UP",
    services: [
      {
        name: "key-manager",
        status: "UP",
        endpoint: "https://key-manager.waw.cloud.ovh.net",
      },
      {
        name: "octavialoadbalancer",
        status: "UP",
        endpoint: "https://load-balancer.waw1.cloud.ovh.net",
      },
      {
        name: "instance",
        status: "UP",
        endpoint:
          "https://compute.waw1.cloud.ovh.net/v2.1/73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
      {
        name: "image",
        status: "UP",
        endpoint: "https://image.compute.waw1.cloud.ovh.net/",
      },
      {
        name: "network",
        status: "UP",
        endpoint: "https://network.compute.waw1.cloud.ovh.net/",
      },
      {
        name: "volume",
        status: "UP",
        endpoint:
          "https://volume.compute.waw1.cloud.ovh.net/v2/73ebd8c9b04c4f43bbfa4aa133351ef1",
      },
      {
        name: "workflow",
        status: "UP",
        endpoint: "https://workflow.waw1.cloud.ovh.net/v2",
      },
    ],
    ipCountries: ["pl"],
    continentCode: "EU",
    datacenterLocation: "WAW",
  },
];

const initialState: InstancesState = {
  instances: [],
  loading: false,
  actionLoading: false, //for create
  selectedInstance: null, //for rename //TODO
  showRenameModal: false,

  //public data

  flavors: [],
  flavorsLoading: false,

  productAvailibility: undefined, //dummyProductAvailibility
  productAvailibilityLoading: false,

  regions: [], //...dummyRegions
  regionsLoading: false,

  images: [],
  imagesLoading: false,

  snapshots: [],
  snapshotsLoading: false,
};

export const getInstancesAsync = createAsyncThunk<
  { instances: Array<Instance> },
  { withoutLoading?: boolean },
  IThunkRejectValue
>(
  "instances",
  async (
    { withoutLoading },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      dispatch(
        getInstancesAsync.pending("", {
          withoutLoading,
        })
      );

      const response = await getInstancesApi();
      const instances = response.data.Result;
      return fulfillWithValue({ instances });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createInstanceAsync = createAsyncThunk<
  true,
  { data: any },
  IThunkRejectValue
>("instances/create", async ({ data }, { rejectWithValue }) => {
  try {
    await createInstanceApi(data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createMultipleInstanceAsync = createAsyncThunk<
  true,
  { data: any },
  IThunkRejectValue
>("instances/create-multiple", async ({ data }, { rejectWithValue }) => {
  try {
    await createMultipleInstanceApi(data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteInstanceAsync = createAsyncThunk<
  true,
  { id: string },
  IThunkRejectValue
>("instances/delete", async ({ id }, { rejectWithValue }) => {
  try {
    await deleteInstanceApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getInstancesFlavorsAsync = createAsyncThunk<
  { flavors: Array<InstanceFlavor> },
  undefined,
  IThunkRejectValue
>("instances/flavors", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getInstancesFlavorsApi();
    const flavors = response.data.Result;
    return fulfillWithValue({ flavors });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getInstancesProductAvailabilityAsync = createAsyncThunk<
  { avalibility: ProductAvalibility },
  { addonFamily?: string } | undefined,
  IThunkRejectValue
>(
  "instances/productAvailability",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getInstancesProductAvalibilityApi({
        addonFamily: data?.addonFamily,
      });
      const avalibility = response.data.Result;
      return fulfillWithValue({ avalibility });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getRegionsAsync = createAsyncThunk<
  { regions: OvhRegion[] },
  undefined,
  IThunkRejectValue
>("instances/regions", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getRegionsApi();
    const regions = response.data.Result;
    return fulfillWithValue({ regions });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getInstancesImagesAsync = createAsyncThunk<
  { images: Array<InstanceImage> },
  { region: string },
  IThunkRejectValue
>(
  "instances/images",
  async ({ region }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getInstancesImagesApi(region);
      const images = response.data.Result;
      return fulfillWithValue({ images });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);
export const getInstancesSnapshotsAsync = createAsyncThunk<
  { snapshots: Array<InstanceSnapshot> },
  { withoutLoading?: boolean },
  IThunkRejectValue
>(
  "instances/snapshots",
  async ({ withoutLoading }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getInstancesSnapshotsApi();
      const snapshots = response.data.Result;
      return fulfillWithValue({ snapshots });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);
export const deleteInstancesBackupSnapshotAsync = createAsyncThunk<
  true,
  { imageId: string },
  IThunkRejectValue
>("instances/snapshot/delete", async ({ imageId }, { rejectWithValue }) => {
  try {
    await deleteInstancesBackupSnapshotApi(imageId);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const instancesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    handleShowInstanceRenameModal: (state, action: PayloadAction<Instance>) => {
      state.showRenameModal = true;
      state.selectedInstance = action.payload;
    },
    handleHideInstanceRenameModal: (state) => {
      state.showRenameModal = false;
      state.selectedInstance = null;
    },
    handleClearInstanceImages: (state) => {
      state.images = [];
      state.imagesLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInstancesAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.loading = true;
      })
      .addCase(getInstancesAsync.fulfilled, (state, action) => {
        const { instances } = action.payload;
        state.instances = instances;
        state.loading = false;
      })
      .addCase(getInstancesAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createInstanceAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createInstanceAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createInstanceAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createMultipleInstanceAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createMultipleInstanceAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createMultipleInstanceAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteInstanceAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteInstanceAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteInstanceAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getInstancesFlavorsAsync.pending, (state) => {
        state.flavorsLoading = true;
      })
      .addCase(getInstancesFlavorsAsync.fulfilled, (state, action) => {
        state.flavorsLoading = false;
        state.flavors = action.payload.flavors;
      })
      .addCase(getInstancesFlavorsAsync.rejected, (state, { payload }) => {
        state.flavorsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getInstancesProductAvailabilityAsync.pending, (state) => {
        state.productAvailibilityLoading = true;
      })
      .addCase(
        getInstancesProductAvailabilityAsync.fulfilled,
        (state, action) => {
          state.productAvailibilityLoading = false;
          state.productAvailibility = action.payload.avalibility;
        }
      )
      .addCase(
        getInstancesProductAvailabilityAsync.rejected,
        (state, { payload }) => {
          state.productAvailibilityLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(getRegionsAsync.pending, (state) => {
        state.regionsLoading = true;
      })
      .addCase(getRegionsAsync.fulfilled, (state, action) => {
        state.regionsLoading = false;
        state.regions = action.payload.regions;
      })
      .addCase(getRegionsAsync.rejected, (state, { payload }) => {
        state.regionsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getInstancesImagesAsync.pending, (state) => {
        state.imagesLoading = true;
      })
      .addCase(getInstancesImagesAsync.fulfilled, (state, action) => {
        state.imagesLoading = false;
        state.images = action.payload.images;
      })
      .addCase(getInstancesImagesAsync.rejected, (state, { payload }) => {
        state.imagesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(getInstancesSnapshotsAsync.pending, (state) => {
        state.snapshotsLoading = true;
      })
      .addCase(getInstancesSnapshotsAsync.fulfilled, (state, action) => {
        const { snapshots } = action.payload;
        state.snapshots = snapshots;
        state.snapshotsLoading = false;
      })
      .addCase(getInstancesSnapshotsAsync.rejected, (state, { payload }) => {
        state.snapshotsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(deleteInstancesBackupSnapshotAsync.pending, (state) => {
        state.snapshotsLoading = true;
      })
      .addCase(deleteInstancesBackupSnapshotAsync.fulfilled, (state) => {
        state.snapshotsLoading = false;
      })
      .addCase(
        deleteInstancesBackupSnapshotAsync.rejected,
        (state, { payload }) => {
          state.snapshotsLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );
  },
});

export const selectInstances = (state: RootState) => state.instances.instances;
export const selectInstancesLoading = (state: RootState) =>
  state.instances.loading;

export const selectInstancesActionLoading = (state: RootState) =>
  state.instances.actionLoading;

export const selectDatabaseServiceRenameIsOpen = (state: RootState) =>
  state.instances.showRenameModal;

export const selectSelectedInstance = (state: RootState) =>
  state.instances.selectedInstance;

export const selectInstancesFlavors = (state: RootState) =>
  state.instances.flavors;
export const selectInstancesFlavorsLoading = (state: RootState) =>
  state.instances.flavorsLoading;

export const selectInstancesProductAvailibility = (state: RootState) =>
  state.instances.productAvailibility;
export const selectInstancesProductAvailibilityLoading = (state: RootState) =>
  state.instances.productAvailibilityLoading;

export const selectInstancesRegions = (state: RootState) =>
  state.instances.regions;
export const selectInstancesRegionsLoading = (state: RootState) =>
  state.instances.regionsLoading;

export const selectInstancesImages = (state: RootState) =>
  state.instances.images;
export const selectInstancesImagesLoading = (state: RootState) =>
  state.instances.imagesLoading;

export const selectInstancesSnapshotsAll = (state: RootState) =>
  state.instances.snapshots;
export const selectInstancesSnapshotsAllLoading = (state: RootState) =>
  state.instances.snapshotsLoading;

export const {
  handleHideInstanceRenameModal,
  handleShowInstanceRenameModal,
  handleClearInstanceImages,
} = instancesSlice.actions;
export default instancesSlice.reducer;
