import { ReactComponent as SeparatorIcon } from "./../../../assets/icons/separator.svg";
import {
  fetchFilesAysnc,
  breadCrumbRedirect,
  selectBreadCrumbItems,
} from "../../../store/ipfs/filesSlice";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useMemo } from "react";
import classNames from "classnames";
import { Typography } from "djuno-design";

export default function FilesBreadCrumb() {
  const dispatch = useAppDispatch();
  const breadCrumbItems = useAppSelector(selectBreadCrumbItems);

  const clickOnBreadcrumbItem = (breadCrumbIndex: number) => {
    dispatch(breadCrumbRedirect(breadCrumbIndex));
    dispatch(fetchFilesAysnc());
  };

  const path = useMemo(() => {
    if (breadCrumbItems.length >= 4) {
      return [
        { name: breadCrumbItems[0].title, index: 0 },
        { name: "...", index: "..." },
        {
          name: breadCrumbItems[breadCrumbItems.length - 2].title,
          index: breadCrumbItems.length - 2,
        },
        {
          name: breadCrumbItems[breadCrumbItems.length - 1].title,
          index: breadCrumbItems.length - 1,
        },
      ];
    }
    return breadCrumbItems.map((item, i) => ({
      name: item.title,
      index: i,
    }));
  }, [breadCrumbItems]);

  return (
    <div className="breadCrumb">
      <ul className="flex gap-1 m-0 p-0 items-center">
        {breadCrumbItems.length === 1 ? (
          <li>
            <Typography.Text className="!text-md !font-medium">
              My Drive
            </Typography.Text>
          </li>
        ) : (
          path.map((segment, index) => {
            const clickable =
              typeof segment.index === "number" &&
              segment.index + 1 !== breadCrumbItems.length;
            return (
              <div key={index} className="flex gap-1 m-0 p-0 items-center">
                <li
                  onClick={() =>
                    clickable && clickOnBreadcrumbItem(segment.index as number)
                  }
                  className={classNames(
                    "text-xs md:text-base text-slate-400 dark:text-slate-500 max-w-[100px] whitespace-nowrap overflow-hidden text-ellipsis hover:text-primary-500 hover:dark:text-primary-500 last:text-slate-800 last:dark:text-slate-200 last:hover:text-slate-800 last:hover:dark:text-slate-200 last:font-normal last:hover:cursor-default last:text-base duration-300 transition-all",
                    { "cursor-pointer": clickable }
                  )}
                >
                  {segment.name}
                </li>
                {index < path.length - 1 && (
                  <span>
                    <SeparatorIcon className="text-slate-700 dark:text-slate-200 w-4 h-4" />
                  </span>
                )}
              </div>
            );
          })
        )}
      </ul>
    </div>
  );
}
