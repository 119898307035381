import { useAppSelector } from "../../../hooks";
import {
  selectMarketApiRequestLogs,
  selectMarketApiRequestLogsLoading,
} from "../../../store/market-api/overviewSlice";
import MarketApiBanner from "./MarketApiBanner";
import PieChart, { PieChartData } from "../../general/charts/PieChart";
import { Typography, SimpleTable, Card, EmptyState } from "djuno-design";

const MarketApiOverviewTab = () => {
  const logs = useAppSelector(selectMarketApiRequestLogs);
  const logsLoading = useAppSelector(selectMarketApiRequestLogsLoading);

  const creditsTodayData: PieChartData[] = [
    {
      name: "Free",
      value: 100,
      className: "fill-[#eee] dark:fill-white/10",
    },
  ];

  const creditsYesterdayData: PieChartData[] = [
    {
      name: "Free",
      value: 100,
      className: "fill-[#eee] dark:fill-white/10",
    },
  ];

  const creditsThisMonthData: PieChartData[] = [
    {
      name: "Free",
      value: 100,
      className: "fill-[#eee] dark:fill-white/10",
    },
  ];

  const creditsLastMonthData: PieChartData[] = [
    {
      name: "Free",
      value: 100,
      className: "fill-[#eee] dark:fill-white/10",
    },
  ];

  return (
    <div className="flex flex-col gap-8">
      <MarketApiBanner />
      <Card title="API Key Usage">
        <div className="flex flex-col md:flex-row gap-5 md:divide-x dark:divide-white/10">
          <div className="flex items-center gap-5">
            <div className="flex flex-col items-center">
              <PieChart data={creditsTodayData} centerLabel="0" />
              <Typography.Text className="!text-xs">
                Credits Today
              </Typography.Text>
            </div>
            <div className="flex flex-col items-center">
              <PieChart data={creditsYesterdayData} centerLabel="0" />
              <Typography.Text className="!text-xs">
                Credits Yesterday
              </Typography.Text>
            </div>
          </div>

          <div className="flex items-center gap-5 md:pl-5">
            <div className="flex flex-col items-center">
              <PieChart data={creditsThisMonthData} centerLabel="0/10,000" />
              <Typography.Text className="!text-xs">
                Credits This Month
              </Typography.Text>
            </div>
            <div className="flex flex-col items-center">
              <PieChart data={creditsLastMonthData} centerLabel="0/10,000" />
              <Typography.Text className="!text-xs">
                Credits Last Month
              </Typography.Text>
            </div>
          </div>
        </div>
      </Card>
      <Card title="API Request Log">
        <SimpleTable loading={logsLoading} className="table-auto">
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Last 100 API Requests" />
              <SimpleTable.TH lable="Timestamp" />
              <SimpleTable.TH lable="Credit Count" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {logs.map((log, index) => (
              <SimpleTable.Row key={index}>
                <SimpleTable.TD>
                  <Typography.Text
                    className="!text-xs md:!text-sm"
                    uiType="warning"
                  >
                    --
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text
                    className="!text-xs md:!text-sm"
                    uiType="warning"
                  >
                    --
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text
                    className="!text-xs md:!text-sm"
                    uiType="warning"
                  >
                    --
                  </Typography.Text>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}
            {logs.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true}>
                <SimpleTable.TD colSpan={3} className="!border-none">
                  <EmptyState />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </Card>
    </div>
  );
};

export default MarketApiOverviewTab;
