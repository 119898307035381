import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getS3MetricsAsync,
  handleSetTimes,
  selectS3EndTime,
  selectS3Metrics,
  selectS3MetricsLoading,
  selectS3StartTime,
} from "../../../store/s3/overviewSlice";
import BucketsCountWidget from "./widgets/BucketsCountWidget";
import ObjectsCountWidget from "./widgets/ObjectsCountWidget";
import ServersCountWidget from "./widgets/ServersCountWidget";
import { S3MetricsServer } from "../../../types/s3";
import { getServersCountCalculator } from "../../../utils/bucket";
import DrivesCountWidget from "./widgets/DrivesCountWidget";
import CapacityWidget from "./widgets/CapacityWidget";
import NetworkWidget from "./widgets/NetworkWidget";
import DataReceivedWidget from "./widgets/DataReceivedWidget";
import DataSentWidget from "./widgets/DataSentWidget";
import NodeIoWidget from "./widgets/NodeIoWidget";
import ApiRequestWidget from "./widgets/ApiRequestWidget";
import ApiRequestErrorWidget from "./widgets/ApiRequestErrorWidget";
import InternodeDataWidget from "./widgets/InternodeDataWidget";
import NodeMemoryWidget from "./widgets/NodeMemoryWidget";
import NodeCpuWidget from "./widgets/NodeCpuWidget";
import DrivesFreeWidget from "./widgets/DrivesFreeWidget";
import DriveUsedWidget from "./widgets/DriveUsedWidget";
import NodeSyscallsWidget from "./widgets/NodeSyscallsWidget";
import NodeFileWidget from "./widgets/NodeFileWidget";
import DataUsageWidget from "./widgets/DataUsageWidget";
import ObjectSizeWidget from "./widgets/ObjectSizeWidget";
import LastHealActivityWidget from "./widgets/LastHealActivityWidget";
import LastScanActivityWidget from "./widgets/LastScanActivityWidget";
import UptimeWidget from "./widgets/UptimeWidget";
import StatWidget from "./widgets/freams/StatWidget";
import ServersWidget from "./widgets/ServersWidget";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { useForm } from "react-hook-form";
import { S3NotActiveTab } from "../../layouts/NotData";
import { selectS3Status } from "../../../store/s3/s3PublicSlice";
import { humanizeSize } from "../../../utils/file";
import ReportedUsageWidget from "./widgets/ReportedUsageWidget";
import { Button, Input, Typography } from "djuno-design";

const S3OverviewTab = () => {
  const s3Status = useAppSelector(selectS3Status);
  const info = useAppSelector(selectS3Metrics);
  const infoLoading = useAppSelector(selectS3MetricsLoading);

  const dispatch = useAppDispatch();

  const startTime = useAppSelector(selectS3StartTime);
  const endTime = useAppSelector(selectS3EndTime);

  const [onlineServersCount, setOnlineServersCount] = useState(0);
  const [offlineServersCount, setOfflineServersCount] = useState(0);
  const [onlineDrivesCount, setOnlineDriversCount] = useState(0);
  const [offlineDrivesCount, setOfflineDriversCount] = useState(0);

  useEffect(() => {
    dispatch(getS3MetricsAsync());
  }, [dispatch]);

  const getServersCounts = useMemo(() => {
    return (servers: S3MetricsServer[]) => getServersCountCalculator(servers);
  }, []);

  useEffect(() => {
    if (info !== null) {
      const {
        onlineServersCount,
        offlineServersCount,
        onlineDrivesCount,
        offlineDrivesCount,
      } = getServersCounts(info.servers);
      console.log(humanizeSize(info.usage, { fractionDigits: 2 }).join("B"));
      setOnlineServersCount(onlineServersCount);
      setOfflineServersCount(offlineServersCount);
      setOnlineDriversCount(onlineDrivesCount);
      setOfflineDriversCount(offlineDrivesCount);
    }
  }, [getServersCounts, info]);

  const { register, handleSubmit } = useForm();
  const handleSubmitTimes = (data: any) => {
    dispatch(handleSetTimes({ start: data.start, end: data.end }));
  };

  if (!s3Status) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <div className="flex transition-all duration-500">
        <div className="text-slate-800 dark:text-slate-100">
          {/* S3 Overview Tab */}
        </div>
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2"></div>
      </div>
      <form onSubmit={handleSubmit(handleSubmitTimes)}>
        <div className="flex flex-col md:flex-row justify-between md:items-center gap-3 p-3 border rounded-md dark:border-dark-2">
          <div className="flex md:items-center gap-4 flex-col md:flex-row">
            <div className="flex flex-col md:flex-row md:items-center gap-1">
              <Typography.Text className="!text-sm whitespace-nowrap">
                Start Time
              </Typography.Text>
              <Input
                {...register("start")}
                type="datetime-local"
                className="!w-full"
              />
            </div>
            <div className="flex flex-col md:flex-row md:items-center gap-1">
              <Typography.Text className="!text-sm whitespace-nowrap">
                End Time
              </Typography.Text>
              <Input {...register("end")} type="datetime-local" />
            </div>
          </div>
          <Button type="submit">
            <RefreshIcon className="w-4" />
            Sync
          </Button>
        </div>
      </form>
      <div className="mt-10 w-full flex flex-col gap-4 md:gap-7">
        <div className="grid grid-cols-4 gap-4 md:gap-7">
          <BucketsCountWidget
            bucketCount={info?.buckets}
            loading={infoLoading}
          />
          <ObjectsCountWidget
            objectCount={info?.objects}
            loading={infoLoading}
          />
          <ServersCountWidget
            onlineCount={onlineServersCount}
            offlineCount={offlineServersCount}
            loading={infoLoading}
          />
          <DrivesCountWidget
            onlineCount={onlineDrivesCount}
            offlineCount={offlineDrivesCount}
            loading={infoLoading}
          />
        </div>
        <div className="grid grid-cols-3 gap-4 md:gap-7">
          <ReportedUsageWidget />
          <CapacityWidget />
          <NetworkWidget />
        </div>
        <div className="grid grid-cols-3 gap-4 md:gap-7">
          <LastHealActivityWidget />
          <LastScanActivityWidget />
          <UptimeWidget />
        </div>
        <div className="grid grid-cols-3 gap-4 md:gap-7">
          <StatWidget
            title="Backend type"
            value={info?.backend.backendType}
            dataLoading={infoLoading}
          />
          <StatWidget
            title="Standard storage class parity"
            value={info?.backend.standardSCParity}
            dataLoading={infoLoading}
          />
          <StatWidget
            title="Reduced redundancy storage class parity"
            value={info?.backend.rrSCParity}
            dataLoading={infoLoading}
          />
        </div>
        <div className="grid grid-cols-2 gap-4 md:gap-7">
          <DataUsageWidget />
          <ObjectSizeWidget />
        </div>
        <div className="grid grid-cols-2 gap-4 md:gap-7">
          <DataReceivedWidget />
          <DataSentWidget />
        </div>
        <div className="grid grid-cols-1 gap-4 md:gap-7">
          <ApiRequestWidget />
        </div>
        <div className="grid grid-cols-2 gap-4 md:gap-7">
          <ApiRequestErrorWidget />
          <InternodeDataWidget />
        </div>
        <div className="grid grid-cols-1 gap-4 md:gap-7">
          <NodeIoWidget />
        </div>
        <div className="grid grid-cols-2 gap-4 md:gap-7">
          <NodeMemoryWidget />
          <NodeCpuWidget />
        </div>
        <div className="grid grid-cols-2 gap-4 md:gap-7">
          <DrivesFreeWidget />
          <DriveUsedWidget />
        </div>
        <div className="grid grid-cols-2 gap-4 md:gap-7">
          <NodeSyscallsWidget />
          <NodeFileWidget />
        </div>
        <div className="grid grid-cols-1 gap-4 md:gap-7">
          <ServersWidget />
        </div>
      </div>
    </>
  );
};

export default S3OverviewTab;
