import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteS3AnonymousAccessApi,
  getBucketAnonymousApi,
  setS3AnonymousAccessApi,
} from "../../../../apis/s3/anonymousAPI";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import { ToastClasses } from "../../../../components/modals/alerts";
import { IThunkRejectValue, RootState } from "../../../../types";
import {
  S3Anonymous,
  S3AnonymousDelete,
  S3AnonymousState,
} from "../../../../types/s3-anonymous";
import { changeS3ActiveStatus } from "../../s3PublicSlice";
import { getExtractErrors } from "../../../../apis";
import { CustomErrorToast } from "../../../../components/general/Toast";

const initialState: S3AnonymousState = {
  anonymouses: [],
  showAnonymousModal: false,
  anonymousLoading: false,
  anonymousActionLoading: false,
  selectedAnonymouse: null,
};

export const getBucketAnonymousesAsync = createAsyncThunk<
  { anonymouses: S3Anonymous[] },
  { name: string },
  IThunkRejectValue
>(
  "bucket/anonymouses",
  async ({ name }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getBucketAnonymousApi(name);

      const { Result, NodeStatus } = response.data;
      const anonymouses = Result || [];

      dispatch(changeS3ActiveStatus(NodeStatus === 2));

      return fulfillWithValue({ anonymouses });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const setBucketAnonymousAccessAsync = createAsyncThunk<
  { anonymouse: any },
  {
    bucketName: string;
    data: {
      prefix: string;
      access: string;
    };
  },
  IThunkRejectValue
>(
  "bucket/anonymous-access/set",
  async ({ bucketName, data }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const responce = await setS3AnonymousAccessApi(bucketName, data);
      return fulfillWithValue({ anonymouse: responce.data });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteBucketAnonymousAccessAsync = createAsyncThunk<
  any,
  {
    bucketName: string;
    data: S3AnonymousDelete;
  },
  IThunkRejectValue
>(
  "bucket/anonymous-access/delete",
  async ({ bucketName, data }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const responce = await deleteS3AnonymousAccessApi(bucketName, data);
      return fulfillWithValue(responce.data);
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const anonymousSlice = createSlice({
  name: "bucket-anonymous",
  initialState,
  reducers: {
    handleShowAnonymousModal: (
      state,
      action: { payload: { anonymouse?: S3Anonymous } }
    ) => {
      state.showAnonymousModal = true;
      if (typeof action.payload.anonymouse !== "undefined") {
        state.selectedAnonymouse = action.payload.anonymouse;
      }
    },
    handleHideAnonymousModal: (state) => {
      state.showAnonymousModal = false;
      state.selectedAnonymouse = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBucketAnonymousesAsync.pending, (state) => {
        state.anonymousLoading = true;
      })
      .addCase(getBucketAnonymousesAsync.fulfilled, (state, action) => {
        state.anonymouses = action.payload.anonymouses;
        state.anonymousLoading = false;
      })
      .addCase(getBucketAnonymousesAsync.rejected, (state, { payload }) => {
        state.anonymousLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(setBucketAnonymousAccessAsync.pending, (state) => {
        state.anonymousActionLoading = true;
      })
      .addCase(setBucketAnonymousAccessAsync.fulfilled, (state) => {
        state.anonymousActionLoading = false;
      })
      .addCase(setBucketAnonymousAccessAsync.rejected, (state, { payload }) => {
        state.anonymousActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteBucketAnonymousAccessAsync.pending, (state) => {
        state.anonymousActionLoading = true;
      })
      .addCase(deleteBucketAnonymousAccessAsync.fulfilled, (state) => {
        state.anonymousActionLoading = false;
      })
      .addCase(
        deleteBucketAnonymousAccessAsync.rejected,
        (state, { payload }) => {
          state.anonymousActionLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );
  },
});

//bucket-events
export const selectBucketAnonymouses = (state: RootState) =>
  state.s3Anonymous.anonymouses;
export const selectBucketAnonymousesLoading = (state: RootState) =>
  state.s3Anonymous.anonymousLoading;
export const selectBucketAnonymousesActionLoading = (state: RootState) =>
  state.s3Anonymous.anonymousActionLoading;
export const selectShowBucketAnonymousModal = (state: RootState) =>
  state.s3Anonymous.showAnonymousModal;
export const selectSelectedAnonymouse = (state: RootState) =>
  state.s3Anonymous.selectedAnonymouse;

export const { handleShowAnonymousModal, handleHideAnonymousModal } =
  anonymousSlice.actions;
export default anonymousSlice.reducer;
