import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DBSDatabaseSchema } from "../../../utils/validations";
import { useEffect } from "react";
import {
  createDBSDatabasesAsync,
  getDBSDatabasesAsync,
  handleDBSDatabaseEditor,
  selectDBSDatabaseShowEditor,
  selectDBSDatabasesActionLoading,
  selectDatabaseService,
} from "../../../store/database/serviceSlice";
import { Button, Input, Modal } from "djuno-design";

const DBSDatabaseCreateModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectDBSDatabaseShowEditor);
  const actionLoading = useAppSelector(selectDBSDatabasesActionLoading);
  const service = useAppSelector(selectDatabaseService);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(DBSDatabaseSchema),
    mode: "all",
  });

  useEffect(() => {
    if (isOpen) {
      setValue("name", "");
    }
  }, [isOpen, setValue]);

  const onSubmit = (data: any) => {
    if (service) {
      dispatch(
        createDBSDatabasesAsync({
          clusterId: service.id,
          engine: service.engine,
          data: data,
        })
      ).then((action) => {
        if (action.type === "service/databases/create/fulfilled") {
          dispatch(handleDBSDatabaseEditor(false));
          dispatch(
            getDBSDatabasesAsync({
              engine: service.engine,
              clusterId: service.id,
            })
          );
        }
      });
    }
  };

  const handleClose = () => {
    reset();
    dispatch(handleDBSDatabaseEditor(false));
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
      title={`Create a database`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          <Input
            {...register("name")}
            error={errors.name?.message}
            placeholder=""
            label="Database name"
            required
          />
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <Button uiType="light" onClick={handleClose}>
            Cancel
          </Button>

          <Button uiType="primary" type="submit" loading={actionLoading}>
            Create database
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default DBSDatabaseCreateModal;
