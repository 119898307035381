import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getS3WidgetAsync,
  selectCapacity,
  selectCapacityLoading,
  selectS3EndTime,
  selectS3StartTime,
} from "../../../../store/s3/overviewSlice";
import { humanizeSize } from "../../../../utils/file";
import { getLastValueOfWidgetTarget } from "../../../../utils/bucket";
import PieChart, { PieChartData } from "../../../general/charts/PieChart";
import { Skeleton, Typography } from "djuno-design";

const CapacityWidget = () => {
  const capacity = useAppSelector(selectCapacity);
  const capacityLoading = useAppSelector(selectCapacityLoading);
  const startTime = useAppSelector(selectS3StartTime);
  const endTime = useAppSelector(selectS3EndTime);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getS3WidgetAsync({ key: "capacity", n: 50, startTime, endTime }));
  }, [dispatch, endTime, startTime]);

  const getUsedSpace = useCallback(() => {
    return getLastValueOfWidgetTarget(capacity?.targets, "Used Space")[1];
  }, [capacity?.targets]);

  const getTotalSpace = useCallback(() => {
    return getLastValueOfWidgetTarget(capacity?.targets, "Total Usable")[1];
  }, [capacity?.targets]);

  const usageSpace = +getUsedSpace();
  const totalSpace = +getTotalSpace();
  const usagePer = +((usageSpace / totalSpace) * 100).toFixed(0);

  const data01: PieChartData[] = [
    {
      name: "Free",
      value: 100 - usagePer,
      className: "fill-[#eee] dark:fill-white/10",
    },
    { name: "Usage", value: usagePer, className: "fill-primary-500" },
  ];
  return (
    <div className="w-full col-span-3 lg:col-span-1 rounded-lg border dark:border-dark-2 bg-white dark:bg-dark-3 p-5">
      <div className="flex flex-col w-full gap-3 justify-center h-full">
        <div className=" flex justify-between">
          <div className="flex items-center gap-3">
            <Typography.Text className="!text-sm">Capacity</Typography.Text>
          </div>
          <div className="flex items-end gap-1 lg:hidden xl:block">
            <PieChart data={data01} centerLabel={`${100 - usagePer}% free`} />
          </div>
          <div className="flex flex-col gap-1 justify-center">
            <Typography.Text uiType="secondary" className="!text-sm">
              Usage
            </Typography.Text>
            <div className="flex">
              {capacityLoading && (
                <Skeleton style={{ width: 44, height: 26 }} />
              )}
              {!capacityLoading && (
                <Typography.Title level={3} className="!mb-0">
                  {humanizeSize(usageSpace).join("B")}
                </Typography.Title>
              )}
            </div>

            <div className="flex items-center gap-1">
              {capacityLoading && (
                <Skeleton style={{ width: 30, height: 20 }} />
              )}
              {!capacityLoading && (
                <Typography.Text className="!text-xs" uiType="secondary">
                  Of:{" "}
                  {humanizeSize(totalSpace, { fractionDigits: 1 }).join("B")}
                </Typography.Text>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CapacityWidget;
