import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getWebAppAsync,
  handleShowWebAppUpdateImageModal,
  selectWebApp,
  selectWebAppLoading,
  selectWebAppUpdateDockerCommandLoading,
  selectWebAppUpdateHealthCheckPathLoading,
  selectWebAppUpdateNameLoading,
  selectWebAppUpdatePreDeployCommandLoading,
  updateWebAppDockerCommandAsync,
  updateWebAppHealthCheckPathAsync,
  updateWebAppNameAsync,
  updateWebAppPreDeployCommandAsync,
} from "../../../../store/web-app/webAppSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  WebAppDockerCommandSchema,
  WebAppNameHealthChecksSchema,
  WebAppNameSchema,
  WebAppPreDeployCommandSchema,
} from "../../../../utils/validations";
import WebAppImageEditorModal from "./WebAppImageEditorModal";
import {
  handleSetWebAppCredentialId,
  handleSetWebAppImageURL,
  validateWebAppImageAsync,
} from "../../../../store/web-app/webAppCreateSlice";
import { deleteWebAppAsync } from "../../../../store/web-app/webAppsSlice";
import { useNavigate } from "react-router-dom";
import { WebAppsUrl } from "../../../../utils/urls";
import { Button, Card, Flex, Input, Modal, Typography } from "djuno-design";
import { A } from "../../../CustomLink";

const WebAppSettingsTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);
  const dispatch = useAppDispatch();
  return (
    <div className="flex flex-col gap-10">
      <General />
      <Deploy />
      <CustomDomains />
      <HealthChecks />
      <DangerZone />
    </div>
  );
};

const General = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);
  const updateNameLoading = useAppSelector(selectWebAppUpdateNameLoading);

  const [canEditName, setCanEditName] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    register,
    formState: { isValid },
    setValue,
    setFocus,
  } = useForm({
    resolver: yupResolver(WebAppNameSchema),
  });

  useEffect(() => {
    if (webApp) {
      setValue("Name", webApp.Name);
    }
  }, [setValue, webApp]);

  const handleChangeName = (data: any) => {
    if (webApp) {
      dispatch(
        updateWebAppNameAsync({
          webAppId: webApp.Id.toString(),
          Name: data.Name,
        })
      ).then((action) => {
        if (action.type === "web-app/update/name/fulfilled") {
          setCanEditName(false);
          dispatch(getWebAppAsync(webApp.Id.toString()));
        }
      });
    }
  };

  return (
    <Card title="General">
      <form onSubmit={handleSubmit(handleChangeName)}>
        <div className="grid grid-cols-3 gap-x-10 my-10">
          <Flex direction="col">
            <Typography.Text size="sm" className="font-medium">
              Name
            </Typography.Text>
            <Typography.Text size="sm" className="mt-1" uiType="secondary">
              A unique name for your Web Service.
            </Typography.Text>
          </Flex>
          <div className="col-span-2">
            <Input disabled={!canEditName} {...register("Name")} />
            <div className="mt-2 w-full flex justify-end gap-3">
              {!canEditName && (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setCanEditName(true);
                    setTimeout(() => setFocus("Name"), 100);
                  }}
                >
                  Edit
                </Button>
              )}
              {canEditName && (
                <>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setCanEditName(false);
                      setValue("Name", webApp?.Name || "");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!isValid}
                    type="submit"
                    uiType="primary"
                    loading={updateNameLoading}
                  >
                    Save Changes
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </form>

      <div className="grid grid-cols-3 gap-x-10 my-10">
        <Flex direction="col">
          <Typography.Text size="sm" className="font-medium">
            Region
          </Typography.Text>
          <Typography.Text size="sm" className="mt-1" uiType="secondary">
            The region where your web service runs.
          </Typography.Text>
        </Flex>
        <div className="col-span-2">
          <Input value={webApp?.Region.title} disabled />
        </div>
      </div>

      <div className="grid grid-cols-3 gap-x-10 my-10">
        <div className="col-span-1 text-sm">
          <Typography.Text size="sm" className="font-medium">
            Instance Type
          </Typography.Text>
        </div>
        <div className="col-span-2">
          <div className="antialiasedleading-6 py-3 px-4 bg-slate-100 dark:bg-gray-700 rounded border border-solid border-slate-200 dark:border-gray-600">
            <div className="flex justify-between flex-col sm:flex-row items-start sm:items-center space-x-0 space-y-4 sm:space-y-0 sm:space-x-6">
              <div className="flex flex-1 flex-col lg:flex-row items-start lg:items-center">
                <Typography.Text size="sm">
                  {webApp?.Plan?.title}
                </Typography.Text>
                <div className="w-[1px] h-[24px] bg-slate-200 dark:bg-gray-600 mx-4 hidden lg:block" />
                <div className="flex space-x-5 text-sm">
                  <div className="flex gap-1">
                    <Typography.Text size="sm" className="font-medium">
                      {webApp?.Plan?.cpu}
                    </Typography.Text>
                    <Typography.Text size="sm">CPU</Typography.Text>
                  </div>
                  <div className="flex gap-1">
                    <Typography.Text size="sm" className="font-medium">
                      {webApp?.Plan?.ram}
                    </Typography.Text>
                    <Typography.Text size="sm">MB</Typography.Text>
                  </div>
                </div>
              </div>
              <Button>Update</Button>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

const Deploy = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const dispatch = useAppDispatch();

  const Image = () => {
    return (
      <div className="grid grid-cols-3 gap-x-10 my-10">
        <Flex direction="col">
          <Typography.Text size="sm" className="font-medium">
            Image
          </Typography.Text>
          <Typography.Text size="sm" uiType="secondary" className="mt-1">
            The image URL and credential used for your Web Service.
          </Typography.Text>
        </Flex>
        <div className="col-span-2">
          <div className="mb-4 flex justify-end">
            <Button
              onClick={() => {
                if (webApp) {
                  dispatch(
                    handleSetWebAppImageURL(webApp.ExternalImage.ImageURL)
                  );
                  dispatch(
                    handleSetWebAppCredentialId(
                      webApp.ExternalImage.RegistryCredentialId
                    )
                  );
                  dispatch(handleShowWebAppUpdateImageModal());
                  dispatch(validateWebAppImageAsync());
                }
              }}
              disabled={webAppLoading || webApp === null}
            >
              Edit
            </Button>
            <WebAppImageEditorModal />
          </div>
          <div className="flex flex-col space-y-6 bg-slate-50/50 dark:bg-gray-700 border border-solid border-slate-200 dark:border-gray-600 rounded-lg p-4">
            <div>
              <Input
                label="Image URL"
                readOnly
                value={webApp?.ExternalImage.ImageURL}
                disabled
              />
            </div>
            <div>
              <Input
                label="Credential (Optional)"
                readOnly
                value={
                  webApp?.ExternalImage.RegistryCredentialId || "No Credential"
                }
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DockerCommandForm = () => {
    const updateNameLoading = useAppSelector(
      selectWebAppUpdateDockerCommandLoading
    );
    const [canEditDockerCommand, setCanEditDockerCommand] =
      useState<boolean>(false);

    const {
      handleSubmit,
      register,
      formState: { isValid },
      setValue,
      setFocus,
    } = useForm({
      resolver: yupResolver(WebAppDockerCommandSchema),
    });

    useEffect(() => {
      if (webApp) {
        setValue("DockerCommand", webApp.DockerCommand);
      }
    }, [setValue]);

    const handleUpdate = (data: any) => {
      if (webApp) {
        dispatch(
          updateWebAppDockerCommandAsync({
            webAppId: webApp.Id.toString(),
            DockerCommand: data.DockerCommand,
          })
        ).then((action) => {
          if (action.type === "web-app/update/docker-command/fulfilled") {
            setCanEditDockerCommand(false);
            dispatch(getWebAppAsync(webApp.Id.toString()));
          }
        });
      }
    };

    return (
      <form onSubmit={handleSubmit(handleUpdate)}>
        <div className="grid grid-cols-3 gap-x-10 my-10">
          <Flex direction="col">
            <Typography.Text size="sm" className="font-medium">
              Docker Command
            </Typography.Text>
            <Typography.Text size="sm" uiType="secondary" className="mt-1">
              Add an optional command to override the Docker{" "}
              <span className="text-primary-300 ml-1">CMD</span> for this
              service. This will also override the{" "}
              <span className="text-primary-300 ml-1">ENTRYPOINT</span> if
              defined in your Dockerfile. Examples:{" "}
              <span className="text-primary-300 ml-1">
                ./start.sh --type=worker
              </span>{" "}
              or{" "}
              <span className="text-primary-300 ml-1">
                ./bin/bash -c cd /some/dir && ./start.sh
              </span>
            </Typography.Text>
          </Flex>
          <div className="col-span-2">
            <Input
              {...register("DockerCommand")}
              disabled={!canEditDockerCommand}
            />
            <div className="mt-2 w-full flex justify-end gap-3">
              {!canEditDockerCommand && (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setCanEditDockerCommand(true);
                    setTimeout(() => setFocus("DockerCommand"), 100);
                  }}
                >
                  Edit
                </Button>
              )}
              {canEditDockerCommand && (
                <>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setCanEditDockerCommand(false);
                      setValue("DockerCommand", webApp?.DockerCommand || "");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={!isValid}
                    uiType="primary"
                    loading={updateNameLoading}
                  >
                    Save Changes
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    );
  };

  const PreDeployCommandForm = () => {
    const updateLoading = useAppSelector(
      selectWebAppUpdatePreDeployCommandLoading
    );
    const [canEditInput, setCanEditInput] = useState<boolean>(false);

    const {
      handleSubmit,
      register,
      formState: { isValid },
      setValue,
      setFocus,
    } = useForm({
      resolver: yupResolver(WebAppPreDeployCommandSchema),
    });

    useEffect(() => {
      if (webApp) {
        setValue("PreDeployCommand", webApp.PreDeployCommand);
      }
    }, [setValue]);

    const handleUpdate = (data: any) => {
      if (webApp) {
        dispatch(
          updateWebAppPreDeployCommandAsync({
            webAppId: webApp.Id.toString(),
            PreDeployCommand: data.PreDeployCommand,
          })
        ).then((action) => {
          if (action.type === "web-app/update/pre-deploy-command/fulfilled") {
            setCanEditInput(false);
            dispatch(getWebAppAsync(webApp.Id.toString()));
          }
        });
      }
    };

    const available = useMemo(() => webApp?.Plan.id !== 1, []);

    return (
      <form onSubmit={handleSubmit(handleUpdate)}>
        <div className="grid grid-cols-3 gap-x-10 my-10">
          <Flex direction="col">
            <Typography.Text
              size="sm"
              className="font-medium flex items-center gap-2"
            >
              Pre-Deploy Command{" "}
              <Typography.Text size="sm" className="!text-sm !text-slate-400">
                Optional
              </Typography.Text>
            </Typography.Text>
            <Typography.Text size="sm" uiType="secondary" className="mt-1">
              This command runs before starting your service. It is typically
              used for tasks like running a database migration or uploading
              assets to a CDN.{" "}
            </Typography.Text>
          </Flex>
          <div className="col-span-2">
            <Input {...register("PreDeployCommand")} disabled={!canEditInput} />
            <div className="mt-2 w-full flex justify-end gap-3">
              {!canEditInput && (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setCanEditInput(true);
                    setTimeout(() => setFocus("PreDeployCommand"), 100);
                  }}
                  disabled={!available}
                  tooltip={{
                    place: "top-end",
                    content: !available
                      ? "Pre-Deploy Command is available for paid instances only."
                      : "",
                    className: "!text-xs",
                  }}
                >
                  Edit
                </Button>
              )}
              {canEditInput && (
                <>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setCanEditInput(false);
                      setValue(
                        "PreDeployCommand",
                        webApp?.PreDeployCommand || ""
                      );
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    uiType="primary"
                    type="submit"
                    disabled={!isValid}
                    loading={updateLoading}
                  >
                    Save Changes
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    );
  };
  return (
    <Card title="Deploy">
      <Image />
      <DockerCommandForm />
      <PreDeployCommandForm />
    </Card>
  );
};

const CustomDomains = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);
  const dispatch = useAppDispatch();
  return (
    <Card title="Custom Domains">
      <div className="flex flex-col gap-2 mt-4">
        <Typography.Text size="sm" className="text-sm">
          Your service is always available at{" "}
          <A href={webApp?.URL || undefined} target="_blank">
            {webApp?.URL}
          </A>
          .
        </Typography.Text>
        <Typography.Text size="sm">
          You can also point <A>custom domains</A> you own to this service. See{" "}
          <A>DNS configuration instructions</A>.
        </Typography.Text>
      </div>
      <div className="mt-4">
        <Button>Add Custom Domain</Button>
      </div>
    </Card>
  );
};

const HealthChecks = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);
  const updateLoading = useAppSelector(
    selectWebAppUpdateHealthCheckPathLoading
  );

  const [canEditInput, setCanEditInput] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    register,
    formState: { isValid },
    setValue,
    setFocus,
  } = useForm({
    resolver: yupResolver(WebAppNameHealthChecksSchema),
  });

  useEffect(() => {
    if (webApp) {
      setValue("HealthCheckPath", webApp.HealthCheckPath || "");
    }
  }, [setValue, webApp]);

  const handleUpdate = (data: any) => {
    if (webApp) {
      dispatch(
        updateWebAppHealthCheckPathAsync({
          webAppId: webApp.Id.toString(),
          Path: data.HealthCheckPath,
        })
      ).then((action) => {
        if (action.type === "web-app/update/health-check-path/fulfilled") {
          setCanEditInput(false);
          dispatch(getWebAppAsync(webApp.Id.toString()));
        }
      });
    }
  };

  return (
    <Card title="Health Checks">
      <form onSubmit={handleSubmit(handleUpdate)}>
        <div className="grid grid-cols-3 gap-x-10 my-10">
          <Flex direction="col">
            <Typography.Text size="sm" className="font-medium">
              Health Check Path
            </Typography.Text>
            <Typography.Text size="sm" uiType="secondary" className="mt-1">
              {" "}
              If you're running a server, enter the path where your server will
              always return a{" "}
              <span className="text-primary-300 ml-1">200 OK</span> response. We
              use it to monitor your app and for <A>zero downtime deploys</A>.
            </Typography.Text>
          </Flex>
          <div className="col-span-2">
            <Input {...register("HealthCheckPath")} disabled={!canEditInput} />
            <div className="mt-2 w-full flex justify-end gap-3">
              {!canEditInput && (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setCanEditInput(true);
                    setTimeout(() => setFocus("HealthCheckPath"), 100);
                  }}
                >
                  Edit
                </Button>
              )}
              {canEditInput && (
                <>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setCanEditInput(false);
                      setValue(
                        "HealthCheckPath",
                        webApp?.HealthCheckPath || ""
                      );
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    uiType="primary"
                    type="submit"
                    disabled={!isValid}
                    loading={updateLoading}
                  >
                    Save Changes
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </Card>
  );
};

const DangerZone = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [webAppName, setWebAppName] = useState<string>("");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const availableForDelete = useMemo(() => {
    return "sudo delete web service " + webApp?.Name === webAppName;
  }, [webApp?.Name, webAppName]);

  const handleCloseModal = () => {
    setShowDeleteModal(false);
    setWebAppName("");
  };

  const handleDelete = () => {
    if (webApp)
      dispatch(deleteWebAppAsync({ id: webApp.Id })).then((action) => {
        if (action.type === "web-apps/delete/fulfilled") {
          navigate(WebAppsUrl);
        }
      });
  };

  return (
    <div className="flex gap-3">
      <Button uiType="danger" onClick={() => setShowDeleteModal(true)}>
        Delete Web App
      </Button>
      <Button
        uiType="danger" //TODO danger-light
        tooltip={{
          content: "You will not be billed for suspended services",
          theme: "error",
          className: "!text-xs",
        }}
        disabled
      >
        Suspend Web App
      </Button>
      <Modal
        title="Delete Web App"
        isOpen={showDeleteModal}
        onClose={handleCloseModal}
        contentClassName="!max-w-xl"
      >
        <Flex direction="col" className="!mt-6">
          <Typography.Text size="sm">
            All resources for {webApp?.Name} will stop working immediately. This
            action cannot be undone.
          </Typography.Text>
          <Typography.Text size="sm" className="mt-2 text-sm">
            Are you sure you want to delete this web service?
          </Typography.Text>
          <Typography.Text size="sm" className="my-2 text-sm">
            Type "
            <span className="text-red-500">
              sudo delete web service {webApp?.Name}
            </span>
            " below to confirm.
          </Typography.Text>
        </Flex>
        <Input
          value={webAppName}
          onChange={(e) => setWebAppName(e.target.value)}
        />
        <div className="mt-4 flex justify-end gap-2">
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleCloseModal();
            }}
            uiType="light"
            className="w-[70px]"
          >
            Cancel
          </Button>
          <Button
            // loading={retentionLoading}
            uiType="danger"
            disabled={!availableForDelete}
            onClick={handleDelete}
            className="w-[70px]"
          >
            Delete Web App
          </Button>
        </div>
      </Modal>
    </div>
  );
};
export default WebAppSettingsTab;
