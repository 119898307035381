import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getInstanceAsync,
  handleInstanceStopHideModal,
  selectInstance,
  selectInstanceLoading,
  selectShowStopModal,
  stopInstanceAsync,
} from "../../../../store/instances/instanceSlice";
import { getInstancesAsync } from "../../../../store/instances/instancesSlice";
import { Button, Flex, Modal, Typography } from "djuno-design";

const InstanceStopModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowStopModal);
  const loading = useAppSelector(selectInstanceLoading);
  const instance = useAppSelector(selectInstance);

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    shouldUnregister: true,
    reValidateMode: "onChange",
  });

  const onSubmit = (data: any) => {
    if (instance) {
      dispatch(stopInstanceAsync({ instanceId: instance?.id, data })).then(
        (action) => {
          if (action.type === "instance/stop/fulfilled") {
            dispatch(getInstancesAsync({ withoutLoading: false }));
            dispatch(getInstanceAsync({ instanceId: instance.id }));
            dispatch(handleInstanceStopHideModal());
          }
        }
      );
    }
  };

  const handleClose = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault(); // Prevent form submission when clicking cancel
    }
    reset();
    dispatch(handleInstanceStopHideModal());
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
      //containerClassName="!items-start"
      title="Stop your instance"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          <Typography.Text className="!text-sm mb-5">
            You are about to stop your captainProd-1 instance. The resources
            dedicated to your Public Cloud instance are still reserved
            (including the IP address). You can reboot your instance at any
            time. In the meantime, you will still be charged the same price for
            your instance.
          </Typography.Text>
        </div>

        <Flex justify="end" className="mt-4 gap-2">
          <Button uiType="light" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            uiType="primary"
            type="submit"
            disabled={!isValid}
            loading={loading}
          >
            Confirm
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

export default InstanceStopModal;
