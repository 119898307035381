import { Helmet } from "react-helmet";
import { selectDatabaseService } from "../../../store/database/serviceSlice";
import { ReactComponent as RightArrow } from "./../../../assets/icons/arrow-up.svg";
import { useNavigate } from "react-router-dom";
import { DatabaseServiceGeneralInformationUrl } from "../../../utils/urls";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { binarySize, humanizeSize } from "../../../utils/file";
import { useCallback, useEffect, useMemo, useState } from "react";
import { capitalizeFirstLetter } from "../../../utils";
import {
  dbsPeriodItems,
  filterAvailabilitiesByRegion,
  getDBSPriceAmout,
  getNodeTypesFromAvailability,
} from "../../../pages/databases/ServiceCreatePage";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatabaseServiceUpdateNodeSchema } from "../../../utils/validations";
import {
  DBSAddon,
  DBSAvailability,
  DBSNodeType,
} from "../../../types/database";
import {
  getDBSAvailabilityAsync,
  getDBSCapabilitiesAsync,
  getDBSCatalogAsync,
  selectDBSAvailability,
  selectDBSAvailabilityLoading,
  selectDBSCapabilities,
  selectDBSCapabilitiesLoading,
  selectDBSCatalog,
  selectDBSCatalogLoading,
  selectDatabaseServicesActionLoading,
  updateDBSAsync,
} from "../../../store/database/servicesSlice";
import RadioGrouper from "../../inputs/RadioGrouper";
import { Button, SimpleTable, Tag, Typography } from "djuno-design";

const DBSUpgradeNodeTab = () => {
  const service = useAppSelector(selectDatabaseService);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const capabilities = useAppSelector(selectDBSCapabilities);
  const capabilitiesLoading = useAppSelector(selectDBSCapabilitiesLoading);

  const availability = useAppSelector(selectDBSAvailability);
  const availabilityLoading = useAppSelector(selectDBSAvailabilityLoading);

  const catalog = useAppSelector(selectDBSCatalog);
  const catalogLoading = useAppSelector(selectDBSCatalogLoading);

  const actionLoading = useAppSelector(selectDatabaseServicesActionLoading);
  const { setValue, handleSubmit, watch } = useForm({
    resolver: yupResolver(DatabaseServiceUpdateNodeSchema()),
    reValidateMode: "onChange",
  });

  const [planAvailability, setPlanAvailability] = useState<
    Array<DBSAvailability>
  >([]);
  const [nodeTypes, setNodeTypes] = useState<Array<DBSNodeType>>([]);

  const [addons, setAddons] = useState<Array<DBSAddon>>([]);
  const [period, setPeriod] = useState<"month" | "hour">("hour");

  const selectedNodeType = watch("SelectedNodeType");

  useEffect(() => {
    if (capabilities === undefined) dispatch(getDBSCapabilitiesAsync());
  }, [dispatch, capabilities]);

  useEffect(() => {
    if (availability === undefined) dispatch(getDBSAvailabilityAsync());
  }, [dispatch, availability]);

  useEffect(() => {
    if (catalog === undefined) dispatch(getDBSCatalogAsync());
  }, [dispatch, catalog]);

  // select node type
  const handleSelectNodeType = useCallback(
    (nodeType: DBSNodeType) => {
      const prevNodeType = selectedNodeType;
      if (prevNodeType !== nodeType.flavor.name) {
        setValue("SelectedNodeType", nodeType.flavor.name);
      }
    },
    [selectedNodeType, setValue]
  );

  // process plansAvailability after changing -> selected engine, selected version
  useEffect(() => {
    if (service) {
      const plansAvailability = availability?.filter(
        (a) =>
          a.lifecycle.status === "STABLE" &&
          a.engine === service.engine &&
          a.network === "public" &&
          a.version === service.version &&
          a.plan === service.plan
      );
      setPlanAvailability(plansAvailability || []);
    }
  }, [availability, service]);

  // make nodes by selected planGroup and selected region
  useEffect(() => {
    if (capabilities && service) {
      const availability = filterAvailabilitiesByRegion(
        planAvailability,
        service.nodes[0].region
      );
      const nodeTypes = getNodeTypesFromAvailability(
        availability,
        capabilities,
        service.version
      );
      if (nodeTypes && nodeTypes.length > 0) {
        setNodeTypes(nodeTypes);
        const nodeType = nodeTypes.find(
          (nt) => nt.flavor.name === service.flavor
        );
        if (nodeType) {
          setValue("SelectedNodeType", service.flavor);
        } else {
          setValue("SelectedNodeType", nodeTypes[0].flavor.name);
        }
      }
    }
  }, [capabilities, planAvailability, service, setValue]);

  //filter addons by selected engine and period
  useEffect(() => {
    if (catalog && service) {
      const planCode = `databases.${service.engine}`;
      const filteredAddons = catalog.addons
        .filter((addon) => addon.planCode.includes(planCode))
        .filter((addon) => addon.planCode.includes(period));
      setAddons(filteredAddons);
    }
  }, [catalog, period, service]);

  const disabledIndex = useMemo(() => {
    if (service) {
      const typeNames = nodeTypes.map((nt) => nt.flavor.name);
      return typeNames.indexOf(service.flavor);
    }
    return -1;
  }, [nodeTypes, service]);

  const handleSubmitForm = () => {
    if (service) {
      const updateData = {
        description: service.description,
        plan: service.plan,
        version: service.version,
        flavor: selectedNodeType,
      };
      dispatch(
        updateDBSAsync({
          id: service.id,
          engine: service.engine,
          data: updateData,
        })
      ).then((action) => {
        if (action.type === "db-services/update/fulfilled") {
          navigate(DatabaseServiceGeneralInformationUrl(service.id));
        }
      });
    }
  };
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Upgrade node </title>
        <meta name="description" content="" />
      </Helmet>
      <div className="p-4">
        {service && (
          <div className="flex items-center justify-between">
            <div
              className="group px-1 items-center flex transition-all duration-150 cursor-pointer gap-x-0.5"
              onClick={() =>
                navigate(DatabaseServiceGeneralInformationUrl(service.id))
              }
            >
              <RightArrow className="-rotate-90 w-4 h-4 transition-all duration-500 text-primary-500 group-hover:translate-x-[-4px]" />

              <Typography.Title
                level={6}
                className="!text-sm !text-primary-500 "
              >
                Back to “Information”
              </Typography.Title>
            </div>
            <RadioGrouper
              items={dbsPeriodItems}
              selected={dbsPeriodItems.find((i) => i.value === period)}
              setSelected={(item) => setPeriod(item.value)}
              dir="row"
            />
          </div>
        )}

        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="mt-10 w-full">
            <SimpleTable containerClassName="!min-h-min">
              <SimpleTable.Head>
                <SimpleTable.Row>
                  <SimpleTable.TH lable="Type" />
                  <SimpleTable.TH lable="vCores" />
                  <SimpleTable.TH lable="Memory" />
                  <SimpleTable.TH lable="Usable Storage" />
                  <SimpleTable.TH lable={`Cost/${period}/node (estimated)`} />
                </SimpleTable.Row>
              </SimpleTable.Head>
              <SimpleTable.Body>
                {nodeTypes.map((nodeType, index) => {
                  const {
                    flavor: {
                      specifications: { memory },
                      name: flavorName,
                    },
                    availability: {
                      specifications: { storage },
                    },
                  } = nodeType;

                  let Memory;
                  let minStorage;
                  let maxStorage;

                  if (memory) {
                    const memoryBinary = binarySize(
                      memory.value,
                      memory.unit.slice()[0],
                      1000
                    );
                    Memory = humanizeSize(memoryBinary, {
                      binaryBaseValue: 1000,
                    });
                  }

                  if (storage) {
                    const { minimum, maximum } = storage;
                    const minStorageBinary = binarySize(
                      minimum.value,
                      minimum.unit.slice()[0],
                      1000
                    );
                    minStorage = humanizeSize(minStorageBinary, {
                      binaryBaseValue: 1000,
                    });

                    const maxStorageBinary = binarySize(
                      maximum.value,
                      maximum.unit.slice()[0],
                      1000
                    );
                    maxStorage = humanizeSize(maxStorageBinary, {
                      binaryBaseValue: 1000,
                    });
                  }

                  const filteredAddons = addons.filter((addon) =>
                    addon.planCode.includes(service?.plan + "-" + flavorName)
                  );

                  return (
                    <SimpleTable.Row
                      key={index}
                      selected={nodeType.flavor.name === selectedNodeType}
                      onClick={() => handleSelectNodeType(nodeType)}
                      disabled={index < disabledIndex}
                    >
                      <SimpleTable.TD className="w-36">
                        <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                          {capitalizeFirstLetter(nodeType.flavor.name)}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <Typography.Text className="!text-xs md:!text-sm">
                          {nodeType.flavor.specifications.core}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <Typography.Text className="!text-xs md:!text-sm">
                          {Memory?.number && Memory?.join("B")}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                          {minStorage && maxStorage && (
                            <>
                              {minStorage.number !== maxStorage.number ? (
                                <>{`From ${minStorage.join(
                                  "B"
                                )} to ${maxStorage.join("B")}`}</>
                              ) : (
                                <>{minStorage.join("B")}</>
                              )}
                            </>
                          )}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <div className="flex items-center">
                          <Typography.Text className="!text-xs !font-medium">
                            €
                            {filteredAddons.length > 0
                              ? getDBSPriceAmout(
                                  filteredAddons[0].pricings[0].price
                                )
                              : "0"}{" "}
                            ex. VAT
                          </Typography.Text>
                          <Typography.Text className="!text-[0.6rem]">
                            (€
                            {filteredAddons.length > 0
                              ? getDBSPriceAmout(
                                  filteredAddons[0].pricings[0].price +
                                    filteredAddons[0].pricings[0].tax
                                )
                              : "0"}{" "}
                            incl. VAT)
                          </Typography.Text>
                          {service?.flavor === nodeType.flavor.name && (
                            <Tag className="!text-xs ml-2" color="success">
                              Current solution
                            </Tag>
                          )}
                        </div>
                      </SimpleTable.TD>
                    </SimpleTable.Row>
                  );
                })}
              </SimpleTable.Body>
            </SimpleTable>
          </div>
          <div className="flex items-center gap-3 mt-5">
            <Button
              onClick={() =>
                service &&
                navigate(DatabaseServiceGeneralInformationUrl(service.id))
              }
            >
              Cancel
            </Button>
            <Button
              loading={
                actionLoading ||
                capabilitiesLoading ||
                availabilityLoading ||
                catalogLoading
              }
              uiType="primary"
              type="submit"
            >
              Upgrade my node template
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default DBSUpgradeNodeTab;
