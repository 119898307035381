import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getRpcTokensAsync,
  selectLoading,
  selectRpc,
  selectRpcSecurityTokens,
  selectRpcTokenActionLoading,
} from "../../../store/rpc/rpcSlice";
import { useEffect } from "react";
import { CopyableInput } from "../../general/CopyableKey";
import { Flex, Input, Typography } from "djuno-design";

const RpcEndpointGetStartedTab = () => {
  const rpc = useAppSelector(selectRpc);
  const loading = useAppSelector(selectLoading);

  const rpcSecurityTokens = useAppSelector(selectRpcSecurityTokens);
  const tokenActionLoading = useAppSelector(selectRpcTokenActionLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (rpc !== null) {
      dispatch(getRpcTokensAsync({ id: rpc.Id }));
    }
  }, [dispatch, rpc]);

  return (
    <>
      <div className="flex flex-col transition-all duration-500">
        <Typography.Text>How to go live on Djuno Cloud</Typography.Text>
        <Typography.Text className="text-sm">
          We've made it easy for you to go live with Djuno Cloud today - choose
          your starting point below
        </Typography.Text>
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2"></div>
      </div>

      <div className="border bg-white dark:border-dark-2 dark:bg-dark-3 rounded-2xl py-8 mt-5">
        <div className="flex lg:divide-x dark:divide-dark-2 flex-wrap space-y-6 lg:space-y-0 px-8">
          <Flex items="stretch" className="gap-3 w-full flex">
            <Input
              containerClassName="w-full"
              copyable
              label="HTTP Provider"
              className="lg:pr-4 p-0"
              value={
                loading || tokenActionLoading
                  ? ""
                  : `${rpc?.Http || ""}/x/${rpcSecurityTokens[0]?.Token || ""}`
              }
              loading={loading || tokenActionLoading}
              labelClassName="font-semibold"
            />
            <Input
              containerClassName="w-full"
              copyable
              label="Wss Provider"
              className="lg:pl-4 p-0"
              value={
                loading || tokenActionLoading
                  ? ""
                  : `${rpc?.Wss || ""}/x/${rpcSecurityTokens[0]?.Token || ""}`
              }
              loading={loading || tokenActionLoading}
              labelClassName="font-semibold"
            />
          </Flex>
        </div>
      </div>
    </>
  );
};

export default RpcEndpointGetStartedTab;
