import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getIpfsMetricsAsync,
  selectIpfsMetrics,
  selectIpfsMetricsLoading,
} from "../../../store/ipfs/ipfsPublicSlice";
import IpfsBanner from "./IpfsBanner";
import {
  chartName,
  RpcMetricChart,
} from "../../rpc-endpoints/metrics/RpcEndpointMetricsTab";
import IpfsReportedUsageWidget from "./ReportedUsageWidget";
import { Loading, Typography } from "djuno-design";

const IpfsOverviewTab = () => {
  const ipfsMetrics = useAppSelector(selectIpfsMetrics);
  const ipfsMetricsLoading = useAppSelector(selectIpfsMetricsLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getIpfsMetricsAsync({}));
  }, [dispatch]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    interval = setInterval(() => {
      dispatch(getIpfsMetricsAsync({ withoutLoading: true }));
    }, 10000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch]);

  return (
    <div className="flex flex-col gap-8">
      <IpfsBanner />

      <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4">
        <IpfsReportedUsageWidget />
      </div>

      {ipfsMetricsLoading && (
        <div className="flex justify-center items-center w-full h-[300px]">
          <Loading borderSize={2} />
        </div>
      )}

      {!ipfsMetricsLoading &&
        ipfsMetrics &&
        Object.keys(ipfsMetrics).length > 0 && (
          <div className="flex flex-col w-full gap-14">
            {Object.keys(ipfsMetrics).map((key) => {
              return (
                <div className="h-[200px]">
                  <div className="w-full flex justify-center mt-5">
                    <Typography.Text className="!font-semibold !text-xs">
                      {chartName(key)}
                    </Typography.Text>
                  </div>
                  <RpcMetricChart
                    chartName={chartName(key)}
                    data={ipfsMetrics[key as keyof typeof ipfsMetrics]}
                  />
                </div>
              );
            })}
          </div>
        )}
    </div>
  );
};

export default IpfsOverviewTab;
