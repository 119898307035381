import { Skeleton, Typography } from "djuno-design";
import { useAppSelector } from "../../../../hooks";
import {
  selectS3Metrics,
  selectS3MetricsLoading,
} from "../../../../store/s3/overviewSlice";
import { humanizeSize } from "../../../../utils/file";
import PieChart, { PieChartData } from "../../../general/charts/PieChart";

const ReportedUsageWidget = () => {
  const info = useAppSelector(selectS3Metrics);
  const infoLoading = useAppSelector(selectS3MetricsLoading);

  const usageSpace = info?.usage || 0;
  const totalSpace = 0;
  const usagePer = +((usageSpace / totalSpace) * 100).toFixed(0);

  const data01: PieChartData[] = [
    {
      name: "Free",
      value: 100 - usagePer,
      className: "fill-[#eee] dark:fill-white/10",
    },
    { name: "Usage", value: usagePer, className: "fill-primary-500" },
  ];
  return (
    <div className="w-full col-span-3 lg:col-span-1 rounded-lg border dark:border-dark-2 bg-white dark:bg-dark-3 p-5">
      <div className="flex flex-col w-full gap-3 justify-center h-full">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Typography.Text className="!text-sm">
              Reported Usage
            </Typography.Text>
          </div>
          <div className="flex items-end gap-1 lg:hidden xl:block">
            <PieChart data={data01} centerLabel={`${100 - usagePer}% free`} />
          </div>
          <div className="flex flex-col gap-1 justify-center">
            <div className="flex">
              {infoLoading && <Skeleton style={{ width: 70 }} />}
              {!infoLoading && (
                <Typography.Title level={5}>
                  {humanizeSize(usageSpace, { fractionDigits: 1 }).join("B")}
                </Typography.Title>
              )}
            </div>

            {/* <div className="flex items-center gap-1">
              <Text className="text-xs" type="subtext" loading={infoLoading}>
                Of: {humanizeSize(totalSpace, { fractionDigits: 1 }).join("B")}
              </Text>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReportedUsageWidget;
