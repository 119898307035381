import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import { ToastClasses } from "../../components/modals/alerts";
import { S3Tier, S3TiersState, TierTypes } from "../../types/s3-tiers";
import {
  createS3TierApi,
  getS3TiersApi,
  updateS3TierCredentionApi,
} from "../../apis/s3/tiersAPI";
import { changeS3ActiveStatus } from "./s3PublicSlice";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

const initialState: S3TiersState = {
  tiers: [],
  loading: false,
  actionLoading: false,
  showEditor: false,
  selectedTier: null,
};

export const getS3TiersAsync = createAsyncThunk<
  { tiers: S3Tier[] },
  undefined,
  IThunkRejectValue
>("s3-tiers", async (_, { rejectWithValue, fulfillWithValue, dispatch }) => {
  try {
    const response = await getS3TiersApi();

    const { Result, NodeStatus } = response.data;
    const tiers = (Result || []) as S3Tier[];

    dispatch(changeS3ActiveStatus(NodeStatus === 2));

    return fulfillWithValue({ tiers });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createS3TierAsync = createAsyncThunk<
  boolean,
  { type: TierTypes; data: any },
  IThunkRejectValue
>("s3-tiers/create", async ({ type, data }, { rejectWithValue }) => {
  try {
    await createS3TierApi(type, data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateS3TierCredentioalAsync = createAsyncThunk<
  boolean,
  { data: any; tiers: any },
  IThunkRejectValue
>("s3-tiers/update", async ({ data, tiers }, { rejectWithValue }) => {
  try {
    // Rename keys in data
    const updatedData = {
      access_key: data.accessKey,
      secret_key: data.secretKey,
    };

    const formData = {
      type: tiers[0].type,
      name: tiers[0][tiers[0].type]?.name,
      credentials: {
        ...updatedData,
      },
    };
    await updateS3TierCredentionApi(formData);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const tiersSlice = createSlice({
  name: "tiers",
  initialState,
  reducers: {
    handleShowEditor: (state, action: { payload: { tier?: S3Tier } }) => {
      state.showEditor = true;
      if (typeof action.payload.tier !== "undefined") {
        state.selectedTier = action.payload.tier;
      }
    },
    handleHideEditor: (state) => {
      state.showEditor = false;
      state.selectedTier = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getS3TiersAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getS3TiersAsync.fulfilled, (state, action) => {
        const { tiers } = action.payload;

        state.tiers = tiers;
        state.loading = false;
      })
      .addCase(getS3TiersAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createS3TierAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createS3TierAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createS3TierAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateS3TierCredentioalAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateS3TierCredentioalAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateS3TierCredentioalAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectS3Tiers = (state: RootState) => state.s3Tiers.tiers;
export const selectS3TiersLoading = (state: RootState) => state.s3Tiers.loading;
export const selectS3TierActionLoading = (state: RootState) =>
  state.s3Tiers.actionLoading;

export const selectShowS3TierEditor = (state: RootState) =>
  state.s3Tiers.showEditor;

export const selectSelectedS3Tier = (state: RootState) =>
  state.s3Tiers.selectedTier;

export const { handleShowEditor, handleHideEditor } = tiersSlice.actions;
export default tiersSlice.reducer;
