import { useNavigate, useParams } from "react-router-dom";
import { BlockEventNotificationsUrl } from "../../utils/urls";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import classNames from "classnames";
import { ReactComponent as RightArrow } from "./../../assets/icons/arrow-right.svg";
// import Text from "../../components/general/Text";
import { Helmet } from "react-helmet";
import {
  changeBlockEventStatusAsync,
  getBlockEventAsync,
  getBlockEventHistoryAsync,
  handleChangeBlockEventHistoriesOffset,
  handleShowBlockEventEditDetailModal,
  handleShowBlockEventHistoryModal,
  selectBlockEvent,
  selectBlockEventHistories,
  selectBlockEventHistoriesPageInfo,
  selectBlockEventHistoryLoading,
  selectBlockEventLoading,
  selectChangeStatusLoading,
} from "../../store/block-event/blockEventsSlice";
// import Switcher from "../../components/inputs/Switcher";
// import Button from "../../components/buttons/Button";
import { capitalizeFirstLetter } from "../../utils";
import BlockEventChangeNameModal from "../../components/block-events/notifications/BlockEventChangeNameModal";
import BlockEventDestinationsTable from "../../components/block-events/destinations/BlockEventDestinationsTable";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right.svg";
import DestinationEditorModal from "../../components/block-events/destinations/DestinationEditorModal";
// import Card from "../../components/general/Card";
// import Table, {
//   TableBody,
//   TableHead,
//   SimpleTable.Row,
//   SimpleTable.TD,
//   SimpleTable.TH,
// } from "../../components/general/Table";
import NotData, { NotExist } from "../../components/layouts/NotData";
import BlockEventHistoryModal from "../../components/block-events/notifications/BlockEventHistoryModal";
import {
  handleSetupToEditDestinations,
  handleSetupToEditExpression,
} from "../../store/block-event/blockEventCreateSlice";
import BlockEventExprissionModal from "../../components/block-events/notifications/BlockEventExprissionModal";
import BlockEventDestinationsModal from "../../components/block-events/notifications/BlockEventDestinationsModal";
import Pagination from "../../components/general/Pagination";
import { formatTimestamp } from "../../utils/date";
import {
  SimpleTable,
  Button,
  Switcher,
  Card,
  Typography,
  EmptyState,
} from "djuno-design";

const BlockEventPage = () => {
  const { blockEventId } = useParams();
  const blockEvent = useAppSelector(selectBlockEvent);
  const blockEventLoading = useAppSelector(selectBlockEventLoading);

  const changeStatusLoading = useAppSelector(selectChangeStatusLoading);

  const blockEventHistories = useAppSelector(selectBlockEventHistories);
  const blockEventHistoriesLoading = useAppSelector(
    selectBlockEventHistoryLoading
  );
  const blockEventHistoriesPageInfo = useAppSelector(
    selectBlockEventHistoriesPageInfo
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isExist, setIsExist] = useState<boolean>(true);

  useEffect(() => {
    if (blockEvent === null && blockEventId) {
      dispatch(getBlockEventAsync(blockEventId)).then((action) => {
        if (action.type === "block-event/rejected") {
          setIsExist(false);
        }
      });
    }
  }, [blockEvent, dispatch, blockEventId]);

  useEffect(() => {
    if (blockEvent) {
      console.log(
        "blockEventHistoriesPageInfo.offset:",
        blockEventHistoriesPageInfo.offset
      );
      dispatch(getBlockEventHistoryAsync(blockEvent.BlockEventId));
    }
  }, [blockEvent, dispatch, blockEventHistoriesPageInfo.offset]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(handleClearSinglePage());
  //   };
  // }, [dispatch]);

  const handleChangeStatus = useCallback(
    (status: boolean) => {
      if (blockEvent) {
        dispatch(
          changeBlockEventStatusAsync({
            blockEventId: blockEvent.BlockEventId,
            status: status ? "enable" : "disable",
          })
        ).then((action) => {
          if (action.type === "block-events/update/status/fulfilled") {
            dispatch(getBlockEventAsync(blockEvent.BlockEventId));
          }
        });
      }
    },
    [blockEvent, dispatch]
  );

  const networkName = blockEvent ? blockEvent.Network.split("-") : undefined;
  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | Block Event - ({blockEvent?.Name || ""}
          )
        </title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex items-center justify-between h-20">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <div className="flex items-center gap-2">
            <RightArrow
              onClick={() => navigate(BlockEventNotificationsUrl)}
              className="rotate-180 w-5 h-5 hover:scale-110 transition-all duration-500 text-slate-800 dark:text-slate-100 cursor-pointer"
            />
            <div
              className={classNames(
                " dark:text-slate-100 min-w-[200px] h-8 rounded-md px-2 flex items-center",
                {
                  "bg-slate-300 dark:bg-slate-50/10 animate-pulse":
                    blockEventLoading,
                }
              )}
            >
              <Typography.Text className="!font-medium">
                {blockEvent?.Name}
              </Typography.Text>
            </div>
          </div>
        </div>
        <div className="mr-6 w-96"></div>
      </div>

      {isExist ? (
        <div className="mx-6 flex flex-col gap-7 pb-20">
          <div className="w-full flex flex-col gap-5 rounded-xl bg-white dark:bg-dark-3 border dark:border-dark-2 p-6">
            {/* status */}
            <div className="flex w-full items-center justify-between">
              <Typography.Text className="!text-sm !font-medium">{`Your notification is ${
                blockEvent?.Enabled ? "" : "not"
              } active`}</Typography.Text>
              <Switcher
                loading={changeStatusLoading}
                value={!!blockEvent?.Enabled || false}
                onChange={handleChangeStatus}
              />
            </div>

            {/* name */}
            <div className="grid grid-cols-12 w-full items-start">
              <div className="col-span-4 lg:col-span-2">
                <Typography.Text className="text-sm font-medium">
                  Event Name:
                </Typography.Text>
              </div>
              <Typography.Text className="!text-sm !col-span-6 !lg:col-span-9">
                {blockEvent?.Name}
              </Typography.Text>
              <div className="col-span-2 lg:col-span-1 md:flex justify-end">
                <Button
                  onClick={() =>
                    dispatch(handleShowBlockEventEditDetailModal())
                  }
                >
                  Edit
                </Button>
              </div>
            </div>

            {/* network */}
            <div className="grid grid-cols-12 w-full items-start">
              <div className="col-span-4 lg:col-span-2">
                <Typography.Text className="!text-sm !font-medium">
                  Chain and network:
                </Typography.Text>
              </div>
              <Typography.Text className="!text-sm !col-span-8 lg:!col-span-10">
                {networkName
                  ? `${capitalizeFirstLetter(
                      networkName[0]
                    )} ${capitalizeFirstLetter(networkName[1])}`
                  : ""}
              </Typography.Text>
            </div>

            {/* expression */}
            <div className="grid grid-cols-12 w-full items-start">
              <div className="col-span-12 lg:col-span-2 flex items-center justify-between mb-2 lg:mb-0">
                <Typography.Text className="!text-sm !font-medium">
                  Expression:
                </Typography.Text>
                <div className="lg:hidden">
                  <Button
                    // type="default"

                    disabled={blockEvent === null}
                    onClick={() =>
                      blockEvent &&
                      dispatch(
                        handleSetupToEditExpression({
                          expressionValue: blockEvent.Expression,
                        })
                      )
                    }
                  >
                    Edit
                  </Button>
                </div>
              </div>
              <Typography.Text
                className="!text-sm lg:!col-span-9 !col-span-12 !overflow-x-auto !p-2 lg:!mr-5"
                uiType="warning"
              >
                {blockEvent?.Expression}
              </Typography.Text>
              <div className="col-span-1 justify-end hidden lg:flex">
                <Button
                  // type="default"

                  disabled={blockEvent === null}
                  onClick={() =>
                    blockEvent &&
                    dispatch(
                      handleSetupToEditExpression({
                        expressionValue: blockEvent.Expression,
                      })
                    )
                  }
                >
                  Edit
                </Button>
              </div>
            </div>
          </div>
          <div className="mt-5 w-full">
            <Card
              title="Destinations"
              setting={
                <Button
                  disabled={blockEvent === null}
                  onClick={() =>
                    blockEvent &&
                    dispatch(
                      handleSetupToEditDestinations({
                        destinations: blockEvent.Destinations,
                      })
                    )
                  }
                >
                  Edit
                </Button>
              }
            >
              <BlockEventDestinationsTable
                destinations={blockEvent?.Destinations || []}
                searchValue={""}
                loading={blockEventLoading}
              />
            </Card>
          </div>

          <div className="mt-5 w-full">
            <Card title="Events">
              <SimpleTable loading={blockEventHistoriesLoading}>
                <SimpleTable.Head>
                  <SimpleTable.Row>
                    <SimpleTable.TH lable="Time" />
                    <SimpleTable.TH lable="Block number" />
                    <SimpleTable.TH lable="To" />
                    <SimpleTable.TH lable="Status" />
                    <SimpleTable.TH lable="" />
                  </SimpleTable.Row>
                </SimpleTable.Head>
                <SimpleTable.Body>
                  {blockEventHistories?.map((history, index) => (
                    <SimpleTable.Row key={index}>
                      <SimpleTable.TD className="w-36">
                        <Typography.Text className="!text-sm !whitespace-nowrap">
                          {formatTimestamp(history.created_at).datetime}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <Typography.Text className="!text-sm">
                          {history.block_number}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <Typography.Text className="!text-sm">
                          {history.to}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD>
                        <Typography.Text className="!text-sm">
                          {history.delivered}
                        </Typography.Text>
                      </SimpleTable.TD>
                      <SimpleTable.TD className="w-32">
                        <div className="flex justify-end items-center w-full">
                          <div
                            onClick={() =>
                              dispatch(
                                handleShowBlockEventHistoryModal(history)
                              )
                            }
                            className="p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200 cursor-pointer"
                          >
                            <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                          </div>
                        </div>
                      </SimpleTable.TD>
                    </SimpleTable.Row>
                  ))}
                  {blockEventHistories?.length === 0 && (
                    <SimpleTable.Row withoutHoverStyle={true}>
                      <SimpleTable.TD colSpan={4} className="!border-0">
                        <EmptyState text="No Data" />
                      </SimpleTable.TD>
                    </SimpleTable.Row>
                  )}
                </SimpleTable.Body>
              </SimpleTable>
              <div className="flex justify-end mt-3">
                <Pagination
                  {...blockEventHistoriesPageInfo}
                  siblingCount={1}
                  onPageChange={(offset) =>
                    dispatch(handleChangeBlockEventHistoriesOffset(offset))
                  }
                  loading={blockEventHistoriesLoading}
                />
              </div>
            </Card>
          </div>
          {/* <Tabs
          listClassName="max-w-xs mb-6"
          options={[
            {
              label: "Event",
              element: <></>,
            },
            {
              label: "Block number",
              element: <></>,
            },
          ]}
          tabType="creamy"
        /> */}
          <BlockEventChangeNameModal />
          <DestinationEditorModal />
          <BlockEventExprissionModal />
          <BlockEventHistoryModal />
          <BlockEventDestinationsModal />
        </div>
      ) : (
        <NotExist url={() => navigate(BlockEventNotificationsUrl)} />
      )}
    </>
  );
};
export default BlockEventPage;
