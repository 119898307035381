import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
// import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { DeleteModal } from "../../modals/QuestionModal";
import { useSearch } from "../../../providers/SearchProvider";
import {
  deleteDBSDatabasesAsync,
  getDBSDatabasesAsync,
  handleDBSDatabaseEditor,
  selectDBSDatabases,
  selectDBSDatabasesActionLoading,
  selectDBSDatabasesLoading,
  selectDatabaseService,
} from "../../../store/database/serviceSlice";
import { DBSDatabase } from "../../../types/database";
import DBSDatabaseCreateModal from "./DBSDatabaseCreateModal";
import { generateServicePermissions } from "../../../pages/databases/ServicePage";
import { useNavigate } from "react-router-dom";
import { DatabaseServiceGeneralInformationUrl } from "../../../utils/urls";
import {
  Button,
  Dropdown,
  EmptyState,
  SimpleTable,
  Typography,
} from "djuno-design";
import { HighlighterText } from "../../general/Text";

const DatabasesTab = () => {
  const service = useAppSelector(selectDatabaseService);

  const databases = useAppSelector(selectDBSDatabases);
  const databasesLoading = useAppSelector(selectDBSDatabasesLoading);
  const [filteredDatabases, setFilteredDatabases] = useState<DBSDatabase[]>([]);
  const actionLoading = useAppSelector(selectDBSDatabasesActionLoading);

  const dispatch = useAppDispatch();

  const [deleteDatabase, setDeleteDatabase] = useState<DBSDatabase | null>(
    null
  );
  const { value: searchValue } = useSearch();
  const navigate = useNavigate();

  useEffect(() => {
    if (service) {
      const permissions = generateServicePermissions().databases;
      if (!permissions.includes(service.engine)) {
        navigate(DatabaseServiceGeneralInformationUrl(service.id));
      } else {
        dispatch(
          getDBSDatabasesAsync({
            engine: service.engine,
            clusterId: service.id,
          })
        );
      }
    }
  }, [dispatch, navigate, service]);

  useEffect(() => {
    const lookedUpFiles = databases.filter((database) =>
      database.name?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredDatabases(lookedUpFiles);
  }, [dispatch, searchValue, databases]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Database</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="">
        <div className="flex items-center justify-between">
          <div className="items-center justify-between flex flex-1 transition duration-150">
            <Typography.Title level={5}></Typography.Title>
          </div>
          <div className="flex items-center gap-2">
            <Button
              uiType="light"
              onClick={() => {
                if (service)
                  dispatch(
                    getDBSDatabasesAsync({
                      engine: service.engine,
                      clusterId: service.id,
                    })
                  );
              }}
              disabled={!service}
              className="group"
              tooltip={{ content: "Refresh" }}
            >
              <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>

            <Button
              uiType="primary"
              onClick={() => dispatch(handleDBSDatabaseEditor(true))}
              className="group"
            >
              Create a database
              <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
          </div>
        </div>

        <div className="mt-5 w-full">
          <SimpleTable
            loading={databasesLoading}
            containerClassName="min-h-[250px]"
          >
            <SimpleTable.Head>
              <SimpleTable.Row>
                <SimpleTable.TH lable="Name" />
                <SimpleTable.TH lable="" />
              </SimpleTable.Row>
            </SimpleTable.Head>
            <SimpleTable.Body>
              {filteredDatabases.map((database, i) => (
                <SimpleTable.Row key={i}>
                  <SimpleTable.TD className="cursor-pointer">
                    <div className="flex items-center flex-row justify-between space-x-3">
                      <HighlighterText
                        searchValue={searchValue}
                        textToHighlight={database.name}
                      />
                    </div>
                  </SimpleTable.TD>
                  <SimpleTable.TD className="w-20 gap-1">
                    <div className="h-full w-full inline-flex items-center justify-end gap-1">
                      <div className="w-8 flex justify-center items-center">
                        <Dropdown
                          anchor="bottom end"
                          itemsClassName="!w-48"
                          menu={[
                            {
                              key: "end",
                              label: (
                                <div className="flex items-center gap-1">
                                  <ArchiveIcon className="w-4" />
                                  Delete
                                </div>
                              ),
                              danger: true,
                              onClick: (_, close) => {
                                close();
                                setDeleteDatabase(database);
                              },
                              disabled: database.default,
                            },
                          ]}
                        >
                          <Button
                            uiType="icon"
                            uiSize="small"
                            className="!px-2"
                          >
                            <MoreIcon className="w-4 h-4" />
                          </Button>
                        </Dropdown>
                      </div>
                      {/* <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" /> */}
                    </div>
                  </SimpleTable.TD>
                </SimpleTable.Row>
              ))}

              {databases.length === 0 && (
                <SimpleTable.Row withoutHoverStyle={true}>
                  <SimpleTable.TD colSpan={10} className="!border-0">
                    <EmptyState text="You have not created any database yet" />
                  </SimpleTable.TD>
                </SimpleTable.Row>
              )}
            </SimpleTable.Body>
          </SimpleTable>
        </div>
        <DBSDatabaseCreateModal />
        <DeleteModal
          title="Delete a Database"
          isOpen={deleteDatabase !== null}
          onClose={() => setDeleteDatabase(null)}
          description=""
          confirmButtonText="Confirm"
          confirmButtonType="danger"
          confirmButtonClassName="w-full"
          onConfirm={() => {
            deleteDatabase &&
              service &&
              dispatch(
                deleteDBSDatabasesAsync({
                  clusterId: service.id,
                  engine: service.engine,
                  databaseId: deleteDatabase.id,
                })
              ).then((action) => {
                if (action.type === "service/databases/delete/fulfilled") {
                  dispatch(
                    getDBSDatabasesAsync({
                      engine: service.engine,
                      clusterId: service.id,
                    })
                  );
                  setDeleteDatabase(null);
                }
              });
          }}
          loading={actionLoading}
          confirmString={deleteDatabase?.name}
        />
      </div>
    </>
  );
};

export default DatabasesTab;
