import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart as ReBarChart,
  Bar,
} from "recharts";
import {
  GraphChartCustomTooltipProps,
  S3MetricsWidget,
} from "../../../types/s3";
import { useMemo } from "react";
import {
  combineObjectSizeBarData,
  getGraphLineColor,
} from "../../../utils/bucket";
import Text from "../Text";
import { useDjunoDesign } from "djuno-design";

const ObjectSizeBarChart: React.FunctionComponent<{
  data: S3MetricsWidget | null;
}> = ({ data }) => {
  const combineDataMemo = useMemo(() => {
    return () => combineObjectSizeBarData(data);
  }, [data]);
  const {
    theme: { mode },
  } = useDjunoDesign();
  return (
    <ResponsiveContainer>
      <ReBarChart
        data={combineDataMemo()}
        margin={{ top: 5, right: 15, bottom: 5, left: 5 }}
        layout="vertical"
        barGap={20}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <Tooltip content={<BarCustomTooltip />} />
        <YAxis
          dataKey="name"
          type="category"
          style={{ fontSize: 10 }}
          tick={CustomTick}
          tickLine={false}
          axisLine={false}
          allowDataOverflow={true}
          width={140}
        />
        <XAxis
          dataKey="value"
          type="number"
          axisLine={false}
          tickLine={false}
          display={"none"}
        />
        <Bar
          barSize={12}
          dataKey="value"
          background={{ fill: mode === "dark" ? "#ffffff10" : "#eee" }}
          // className="fill-[#eee] dark:fill-white/10"
          stroke="#70B8FF"
          fill="#70B8FF"
          width={60}
          isAnimationActive={false}
          activeBar={false}
          cursor={"pointer"}
        />
      </ReBarChart>
    </ResponsiveContainer>
  );
};

const BarCustomTooltip = ({
  active,
  payload,
}: GraphChartCustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white/70 dark:bg-dark-3/80 text-xs p-2 border dark:border-dark-2 rounded-md max-h-40 overflow-y-auto">
        <div className="flex flex-col">
          <Text className="text-center " type="subtext">
            {payload[0].payload.name}
          </Text>
          {payload.map((item, i) => (
            <div className="flex items-center gap-1" key={i}>
              <span
                className="w-2 h-2 rounded-full"
                style={{ backgroundColor: getGraphLineColor(i) }}
              />
              <Text className="label">{`${item.dataKey}: ${
                item.payload[item.dataKey]
              }`}</Text>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

const CustomTick = ({ payload, x, y }: { x: any; y: any; payload: any }) => {
  return (
    <text
      width={50}
      transform={`translate(5,${y})`}
      textAnchor="start"
      fill="#666"
      fontSize={"0.65rem"}
    >
      {payload.value}
    </text>
  );
};
export default ObjectSizeBarChart;
