import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "./../../../../hooks";
import {
  getInstanceAsync,
  handleInstanceSuspendHideModal,
  selectInstance,
  selectInstanceLoading,
  selectShowSuspendModal,
  suspendInstanceAsync,
} from "./../../../../store/instances/instanceSlice";
import { getInstancesAsync } from "./../../../../store/instances/instancesSlice";
import { Button, Flex, Modal, Typography } from "djuno-design";

const InstanceSuspendModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowSuspendModal);
  const loading = useAppSelector(selectInstanceLoading);
  const instance = useAppSelector(selectInstance);

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    shouldUnregister: true,
    reValidateMode: "onChange",
  });

  const onSubmit = (data: any) => {
    if (instance) {
      dispatch(suspendInstanceAsync({ instanceId: instance?.id, data })).then(
        (action) => {
          if (action.type === "instance/shelve/fulfilled") {
            dispatch(getInstancesAsync({ withoutLoading: false }));
            dispatch(getInstanceAsync({ instanceId: instance.id }));
            dispatch(handleInstanceSuspendHideModal());
          }
        }
      );
    }
  };

  const handleClose = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
    }
    reset();
    dispatch(handleInstanceSuspendHideModal());
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
      //containerClassName="!items-start"
      title="Suspension of your instance"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="col" className="mt-5 gap-2 mb-5">
          <Typography.Text className="!text-sm">
            You are about to suspend your "{instance?.name}" instance. The
            resources dedicated to your Public Cloud instance will be removed,
            but the IP address will remain. The data on your local disk will be
            stored, and only the storage used for this will be billed (1). The
            duration of the operation depends on the size of your local disk.
            You can reactivate your instance at any time.
          </Typography.Text>
          <Typography.Text className="!text-sm">
            (1) Warning: For instances billed monthly, standard billing will
            continue regardless of the service status.
          </Typography.Text>
        </Flex>

        <div className="mt-4 flex justify-end gap-2">
          <Button uiType="light" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            uiType="primary"
            disabled={!isValid}
            type="submit"
            loading={loading}
          >
            Confirm
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default InstanceSuspendModal;
