import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { DBSUserDetailsSchema } from "../../../utils/validations";
import {
  handleDBSHideUserDetailsModal,
  selectDatabaseServiceUser,
  selectDatabaseServiceUserLoading,
  selectDatabaseUserDetails,
} from "../../../store/database/serviceSlice";
import { useEffect } from "react";
import { Input, Modal, Typography } from "djuno-design";

const UserDetailsModal = () => {
  const isOpen = useAppSelector(selectDatabaseUserDetails);
  const userLoading = useAppSelector(selectDatabaseServiceUserLoading);
  const user = useAppSelector(selectDatabaseServiceUser);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: errorsName },
    setValue,
  } = useForm({
    resolver: yupResolver(DBSUserDetailsSchema),
    shouldUnregister: true,
    reValidateMode: "onChange",
  });

  //Name
  useEffect(() => {
    if (user) {
      setValue("username", user.username);
    }
  }, [user, setValue]);

  const handleClose = () => {
    reset();
    dispatch(handleDBSHideUserDetailsModal());
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-xl "
      title="User information"
    >
      <div className="w-full mt-5">
        <div>
          <Typography.Text className="@font-medium">
            Instance name
          </Typography.Text>
          <Input {...register("username")} disabled />
        </div>
      </div>
      <div className="flex flex-col my-2 gap-5">
        <div className="flex justify-between gap-5">
          <div className="w-1/2 p-2 border border-secondary-300 rounded-lg">
            <Typography.Text className="!text-sm font-medium">
              Keys
            </Typography.Text>
            <Typography.Text className="!text-xs">
              {user?.keys?.map((k, index) => (
                <div
                  key={index}
                  className="bg-secondary-100 focus:ring-0 text-sm rounded-lg block w-fit p-2.5 dark:bg-dark-2 outline-none dark:border-dark-2 dark:focus:border-slate-600 dark:text-slate-50 dark:placeholder-gray-500 border-secondary-100"
                >
                  {k}
                </div>
              ))}
            </Typography.Text>
          </div>
          <div className="w-1/2 p-2 border border-secondary-300 rounded-lg">
            <Typography.Text className="!text-sm font-medium">
              Categories
            </Typography.Text>
            <Typography.Text className="!text-xs">
              {user?.categories?.map((c, index) => (
                <div
                  key={index}
                  className="bg-secondary-100 focus:ring-0 text-sm rounded-lg block w-fit p-2.5 dark:bg-dark-2 outline-none dark:border-dark-2 dark:focus:border-slate-600 dark:text-slate-50 dark:placeholder-gray-500 border-secondary-100"
                >
                  {c}
                </div>
              ))}
            </Typography.Text>
          </div>
        </div>

        <div className="flex justify-between gap-5">
          <div className="w-1/2 p-2 border border-secondary-300 rounded-lg">
            <Typography.Text className="!text-sm font-medium">
              Commands
            </Typography.Text>
            <Typography.Text className="!text-xs">
              {user?.commands?.map((c, index) => (
                <div
                  key={index}
                  className="bg-secondary-100 focus:ring-0 text-sm rounded-lg block w-fit p-2.5 dark:bg-dark-2 outline-none dark:border-dark-2 dark:focus:border-slate-600 dark:text-slate-50 dark:placeholder-gray-500 border-secondary-100"
                >
                  {c}
                </div>
              ))}
            </Typography.Text>
          </div>
          <div className="w-1/2 p-2 border border-secondary-300 rounded-lg">
            <Typography.Text className="!text-sm font-medium">
              Channels
            </Typography.Text>
            <Typography.Text className="!text-xs">
              {user?.channels?.map((c, index) => (
                <div
                  key={index}
                  className="bg-secondary-100 focus:ring-0 text-sm rounded-lg block w-fit p-2.5 dark:bg-dark-2 outline-none dark:border-dark-2 dark:focus:border-slate-600 dark:text-slate-50 dark:placeholder-gray-500 border-secondary-100"
                >
                  {c}
                </div>
              ))}
            </Typography.Text>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UserDetailsModal;
