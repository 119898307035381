import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../../types";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import { ToastClasses } from "../../../components/modals/alerts";
import {
  ExternalBucket,
  S3Bucket,
  S3BucketKms,
  S3BucketObject,
  S3BucketsState,
} from "../../../types/s3-bucket";
import {
  createBucketsApi,
  createBucketsKmsKeyApi,
  deleteBucketApi,
  getBucketsApi,
  getBucketsKmsKeysApi,
  getExternalBucketsApi,
  updateBucketApi,
} from "../../../apis/s3API";
import { changeS3ActiveStatus } from "../s3PublicSlice";
import { getExtractErrors } from "../../../apis";
import { CustomErrorToast } from "../../../components/general/Toast";

const initialState: S3BucketsState = {
  buckets: [],
  loading: false,
  actionLoading: false,
  kmsKeys: [],
  kmsLoading: false,
  showKmsEditor: false,
  checkedBuckets: [],
  externalBuckets: [],
};

export const getBucketsAsync = createAsyncThunk<
  { buckets: S3Bucket[] },
  undefined,
  IThunkRejectValue
>("buckets", async (_, { rejectWithValue, fulfillWithValue, dispatch }) => {
  try {
    const response = await getBucketsApi();
    const { Result, NodeStatus } = response.data;
    const buckets = Result || [];
    dispatch(changeS3ActiveStatus(NodeStatus === 2));
    return fulfillWithValue({ buckets });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});
export const getExternalBucketsAsync = createAsyncThunk<
  any,
  { data: any },
  IThunkRejectValue
>(
  "externalbuckets",
  async ({ data }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await getExternalBucketsApi(data);
      return fulfillWithValue({ externalBuckets: result.data.Result });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteBucketAsync = createAsyncThunk<
  boolean,
  { name: string },
  IThunkRejectValue
>("buckets/delete", async ({ name }, { rejectWithValue }) => {
  try {
    await deleteBucketApi(name);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createBucketAsync = createAsyncThunk<
  boolean,
  any,
  IThunkRejectValue
>("buckets/create", async (data, { rejectWithValue }) => {
  try {
    await createBucketsApi(data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateBucketAsync = createAsyncThunk<
  boolean,
  { name: string; data: any },
  IThunkRejectValue
>("buckets/update", async ({ name, data }, { rejectWithValue }) => {
  try {
    await updateBucketApi(name, data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getBucketsKmsKeysAsync = createAsyncThunk<
  { bucketsKmsKeys: S3BucketKms[] },
  undefined,
  IThunkRejectValue
>("buckets/kms-keys", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getBucketsKmsKeysApi();
    const bucketsKmsKeys = response.data.Result.data;
    return fulfillWithValue({ bucketsKmsKeys });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createBucketsKmsKeyAsync = createAsyncThunk<
  boolean,
  any,
  IThunkRejectValue
>("buckets/kms-keys/create", async (data, { rejectWithValue }) => {
  try {
    await createBucketsKmsKeyApi(data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const bucketsSlice = createSlice({
  name: "buckets",
  initialState,
  reducers: {
    handleShowKmsEditor: (state) => {
      state.showKmsEditor = true;
    },
    handleHideKmsEditor: (state) => {
      state.showKmsEditor = false;
    },

    handleChangeCheckedBuckets: (state, action: PayloadAction<S3Bucket>) => {
      const bu = action.payload;
      const isSelected =
        state.checkedBuckets.find((o) => o.name === bu.name) || false;
      const filteredBuckets = state.checkedBuckets.filter(
        (o) => o.name !== bu.name
      );
      if (isSelected) {
        state.checkedBuckets = [...filteredBuckets];
      } else {
        state.checkedBuckets = [...filteredBuckets, bu];
      }
    },
    handleClearCheckedBuckets: (state) => {
      state.checkedBuckets = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBucketsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBucketsAsync.fulfilled, (state, action) => {
        const { buckets } = action.payload;

        state.buckets = buckets;
        state.loading = false;
      })
      .addCase(getBucketsAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(getExternalBucketsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExternalBucketsAsync.fulfilled, (state, action) => {
        state.externalBuckets = action.payload.externalBuckets;
        state.loading = false;
      })
      .addCase(getExternalBucketsAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createBucketAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createBucketAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createBucketAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateBucketAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateBucketAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateBucketAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteBucketAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteBucketAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteBucketAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBucketsKmsKeysAsync.pending, (state) => {
        state.kmsLoading = true;
      })
      .addCase(getBucketsKmsKeysAsync.fulfilled, (state, action) => {
        state.kmsKeys = action.payload.bucketsKmsKeys;
        state.kmsLoading = false;
      })
      .addCase(getBucketsKmsKeysAsync.rejected, (state, { payload }) => {
        state.kmsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createBucketsKmsKeyAsync.pending, (state) => {
        state.kmsLoading = true;
      })
      .addCase(createBucketsKmsKeyAsync.fulfilled, (state) => {
        state.kmsLoading = false;
      })
      .addCase(createBucketsKmsKeyAsync.rejected, (state, { payload }) => {
        state.kmsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectBuckets = (state: RootState) => state.buckets.buckets;
export const selectLoading = (state: RootState) => state.buckets.loading;
export const selectActionLoading = (state: RootState) =>
  state.buckets.actionLoading;
export const selectCheckedBuckets = (state: RootState) =>
  state.buckets.checkedBuckets;
export const selectExternalBuckets = (state: RootState) =>
  state.buckets.externalBuckets;

//kms
export const selectKmsKeys = (state: RootState) => state.buckets.kmsKeys;
export const selectKmsLoading = (state: RootState) => state.buckets.kmsLoading;
export const selectShowKmsEditor = (state: RootState) =>
  state.buckets.showKmsEditor;

export const {
  handleShowKmsEditor,
  handleHideKmsEditor,
  handleChangeCheckedBuckets,
  handleClearCheckedBuckets,
} = bucketsSlice.actions;
export default bucketsSlice.reducer;
