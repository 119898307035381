import { Outlet, useNavigate, useLocation, useParams } from "react-router-dom";
import { ReactComponent as TierIcon } from "./../../assets/icons/square-3stack.svg";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { ReactComponent as RightArrow } from "./../../assets/icons/arrow-right.svg";
import { S3TierAddUrl, S3TiersUrl } from "../../utils/urls";
import { s3TierTypes } from "../../components/s3/tiers/S3SelectTierType";
import { useEffect, useState } from "react";
import {
  TierTypeOption,
  TierTypes,
  tierTypesNames,
} from "../../types/s3-tiers";
import { Helmet } from "react-helmet";
import { Button, Typography } from "djuno-design";

const S3TierAddPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { type } = useParams<{ type: TierTypes }>();

  const [selectedType, setSelectedType] = useState<TierTypeOption>();

  useEffect(() => {
    if (type) {
      const findSelectedType = (
        keyToFind: string
      ): TierTypeOption | undefined => {
        for (const category of s3TierTypes) {
          const foundObject = category.options.find(
            (option) => option.key === keyToFind
          );
          if (foundObject) {
            return foundObject;
          }
        }
        return undefined;
      };

      setSelectedType(findSelectedType(type));
    }
  }, [type]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | S3 (add new tier)</title>
        <meta
          name="description"
          content="Simple-storage technology refers to a straightforward method of storing data or information in a manner that is easy to implement and access"
        />
      </Helmet>
      <div className="flex items-center justify-between h-16 px-6 bg-white dark:bg-dark-1">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <div className="flex items-center gap-1">
            {location.pathname !== S3TierAddUrl ? (
              <>
                <RightArrow
                  onClick={() => navigate(S3TierAddUrl)}
                  className="rotate-180 w-5 h-5 hover:scale-110 transition-all duration-500 text-slate-800 dark:text-slate-100 cursor-pointer"
                />
                {typeof type !== "undefined" &&
                  tierTypesNames.includes(type) && (
                    <div className="flex items-center">
                      <div className="w-[50px] h-[50px] flex justify-start items-center p-2">
                        <img
                          className="h-full"
                          alt={selectedType?.name}
                          src={selectedType?.iconUrl}
                        />
                      </div>
                      <Typography.Text className="!text-sm">
                        {selectedType?.name} - Add Tier Configuration
                      </Typography.Text>
                    </div>
                  )}
              </>
            ) : (
              <Typography.Text className="!text-sm font-medium flex items-center gap-1">
                <TierIcon className="w-5 h-5" />
                Tier Types
              </Typography.Text>
            )}
          </div>
        </div>
        <div className="">
          <Button
            uiType="light"
            uiSize="small"
            onClick={() => navigate(S3TiersUrl)}
            className="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      <div className="mt-10 w-full overflow-x-auto px-6 min-h-[calc(100%-6rem)]">
        <Outlet />
      </div>
    </>
  );
};

export default S3TierAddPage;
