import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./../../hooks";
import { Instance } from "./../../types/instance";
import {
  deleteInstanceAsync,
  getInstancesAsync,
  selectInstances,
  selectInstancesActionLoading,
  selectInstancesLoading,
} from "./../../store/instances/instancesSlice";
import { useNavigate } from "react-router-dom";
import { useSearch } from "./../../providers/SearchProvider";
import { ReactComponent as PlusIcon } from "./../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../assets/icons/archive-box.svg";
import { ReactComponent as DetailsIcon } from "./../../assets/icons/bars-3-bottom-left.svg";
// import { ReactComponent as EditIcon } from "./../../assets/icons/pencil-square.svg";
import { ReactComponent as RefreshIcon } from "./../../assets/icons/arrow-path.svg";

import { HighlighterText } from "./../general/Text";
import {
  InstanceOverviewUrl,
  InstancesCreateUrl,
  InstancesUrl,
} from "./../../utils/urls";
import { DeleteModal } from "./../modals/QuestionModal";
import {
  getInstancesVolumesAsync,
  selectInstancesVolumes,
} from "./../../store/instances/instancesVolumesSlice";
import {
  handleInstanceDeleteHideModal,
  handleInstanceDeleteShowModal,
  selectInstance,
  selectInstanceBackupsLoading,
  selectShowDeleteModal,
} from "./../../store/instances/instanceSlice";
import {
  Button,
  Dropdown,
  EmptyState,
  Flex,
  SimpleTable,
  Tag,
  Typography,
} from "djuno-design";

const InstancesTab = () => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [filteredInstances, setFilteredInstances] = useState<Instance[]>([]);
  const instances = useAppSelector(selectInstances);
  const loading = useAppSelector(selectInstancesLoading);
  const volumes = useAppSelector(selectInstancesVolumes);
  const instance = useAppSelector(selectInstance);
  const isOpen = useAppSelector(selectShowDeleteModal);
  const instanceBackupsLoading = useAppSelector(selectInstanceBackupsLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    dispatch(getInstancesAsync({ withoutLoading: false }));
  }, [dispatch]);

  useEffect(() => {
    const lookedUpFiles = instances.filter((instance) =>
      instance.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredInstances(lookedUpFiles);
  }, [dispatch, searchValue, instances]);

  useEffect(() => {
    if (volumes.length === 0) {
      dispatch(getInstancesVolumesAsync({}));
    }
  }, [dispatch, volumes.length]);

  useEffect(() => {
    let interval: any;

    const statusesToCheck = [
      "SHELVING",
      "UNSHELVING",
      "BUILD",
      "RESCUING",
      "UNRESCUING",
      "REBOOT",
      "HARD_REBOOT",
      "REBUILD",
    ];
    const shouldSetInterval = instances.some((instance) =>
      statusesToCheck.includes(instance?.status)
    );

    if (shouldSetInterval) {
      interval = setInterval(() => {
        dispatch(getInstancesAsync({ withoutLoading: true }));
      }, 20000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, instances]);

  const handleDeleteInstance = async () => {
    if (instance) {
      setDeleteLoading(true);
      const action = await dispatch(deleteInstanceAsync({ id: instance.id }));
      if (action.type === "instances/delete/fulfilled") {
        dispatch(getInstancesAsync({ withoutLoading: false }));
        navigate(InstancesUrl);
        handleClose();
      }
      setDeleteLoading(false);
    }
  };

  const handleClose = () => {
    dispatch(handleInstanceDeleteHideModal());
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          {/* <Text className="font-medium text-lg">Instances</Text> */}
        </div>
        <div className="flex items-center gap-2">
          <Button
            uiType="light"
            onClick={() =>
              dispatch(getInstancesAsync({ withoutLoading: true }))
            }
            className="group"
            tooltip={{ content: "Refresh" }}
          >
            <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
          <Button
            uiType="primary"
            onClick={() => navigate(InstancesCreateUrl)}
            className="group"
          >
            Create an instance
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      <div className="mt-5 w-full">
        <SimpleTable loading={loading}>
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Name/ID" />
              <SimpleTable.TH lable="Location" />
              <SimpleTable.TH lable="Template" />
              <SimpleTable.TH lable="Image" />
              <SimpleTable.TH lable="Public IPs" />
              <SimpleTable.TH lable="Private IPs" />
              <SimpleTable.TH lable="Volumes" />
              <SimpleTable.TH lable="Status" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {filteredInstances.map((instance, i) => (
              <SimpleTable.Row
                key={i}
                onClick={() => navigate(InstanceOverviewUrl(instance.id))}
              >
                <SimpleTable.TD className="cursor-pointer">
                  <div className="flex flex-col gap-1">
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={instance.name}
                    />
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={instance.id}
                      className="!text-xs"
                    />
                  </div>
                </SimpleTable.TD>
                <SimpleTable.TD className="cursor-pointer">
                  <Typography.Text className="!text-xs md:!text-sm">
                    {instance.region}
                  </Typography.Text>
                </SimpleTable.TD>

                <SimpleTable.TD className="cursor-pointer">
                  <Typography.Text className="!text-xs md:!text-sm">
                    {instance.planCode?.split(".")[0]}
                  </Typography.Text>
                </SimpleTable.TD>

                <SimpleTable.TD className="cursor-pointer">
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {instance?.image?.name}
                  </Typography.Text>
                </SimpleTable.TD>

                <SimpleTable.TD className="cursor-pointer">
                  <Typography.Text className="!text-xs md:!text-sm">
                    {
                      instance.ipAddresses.filter(
                        (ip) => ip.version === 4 && ip.type === "public"
                      )[0]?.ip
                    }
                  </Typography.Text>
                </SimpleTable.TD>

                <SimpleTable.TD className="cursor-pointer">
                  <Typography.Text className="!text-xs md:!text-sm">
                    {
                      instance.ipAddresses.filter(
                        (ip) => ip.version === 4 && ip.type === "private"
                      )[0]?.ip
                    }
                  </Typography.Text>
                </SimpleTable.TD>

                <SimpleTable.TD className="cursor-pointer">
                  <Typography.Text className="!text-xs md:!text-sm">
                    {volumes
                      ? volumes
                          .filter(
                            (volume) => volume?.attachedTo[0] === instance.id
                          )
                          ?.map((v, j) => (
                            <div className="flex flex-col" key={j}>
                              {v.name}
                            </div>
                          ))
                      : " "}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD className="cursor-pointer">
                  <Tag
                    className="!text-xs"
                    color={instance.status === "ACTIVE" ? "success" : "warning"}
                  >
                    {instance.status}
                  </Tag>
                </SimpleTable.TD>

                <SimpleTable.TD className="w-32 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-center gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        anchor="bottom end"
                        itemsClassName="!p-0"
                        menu={[
                          {
                            key: "1",
                            label: (
                              <Flex
                                items="center"
                                className="gap-1 whitespace-nowrap"
                              >
                                <DetailsIcon className="w-4" />
                                General Information
                              </Flex>
                            ),
                            onClick: () =>
                              navigate(InstanceOverviewUrl(instance.id)),
                            disabled: loading,
                          },
                          {
                            type: "divider",
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              dispatch(handleInstanceDeleteShowModal(instance));
                            },
                            disabled: loading,
                          },
                        ]}
                      >
                        <Button uiType="icon" uiSize="small" className="!px-2">
                          <MoreIcon className="w-4 h-4" />
                        </Button>
                      </Dropdown>
                    </div>
                    <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}

            {instances.length === 0 && (
              <SimpleTable.Row withoutHoverStyle className="min-h-[300px]">
                <SimpleTable.TD colSpan={9}>
                  <EmptyState text="You have not created any instance yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>
      <DeleteModal
        isOpen={isOpen}
        title="Delete instance"
        onClose={handleClose}
        description=""
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        loading={loading || instanceBackupsLoading}
        onConfirm={handleDeleteInstance}
        confirmString={instance?.name}
      />
    </>
  );
};

export default InstancesTab;
