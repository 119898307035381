import Button from "../buttons/Button";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  resendVerificationEmailAsync,
  selectEmailVerificationLoading,
} from "../../store/auth/authSlice";
import { ReactComponent as EmailIcon } from "./../../assets/icons/verify-email.svg";
import Countdown from "../general/Countdown";
import Text from "../general/Text";

const EmailNotVerify = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectEmailVerificationLoading);
  const handleResendVerificationEmail = () =>
    dispatch(resendVerificationEmailAsync());
  return (
    <div className="px-4  md:px-6 max-w-[1200px] mx-auto h-[100vh] flex overflow-y-hidden">
      <div className="w-full  flex flex-col justify-center items-center place-self-center ">
        <div className="max-w-xl flex flex-col items-center gap-4">
          <EmailIcon className="w-20 text-slate-500 dark:text-slate-300" />
          <div className="text-base font-bold text-slate-800 dark:text-slate-50 text-center">
            Verify your email address
          </div>
          <p className="text-sm text-slate-600 dark:text-gray-500 text-center">
            Please verify your email address by clicking the link we just sent
            to your inbox
          </p>
          <Countdown
            seconds={5}
            className="flex-col"
            timerPosition="right"
            timerRender={({ formatedTime, timeLeft }) => {
              if (timeLeft === 0) return null;
              return (
                <Text className="text-sm">
                  Resend after {formatedTime} seconds
                </Text>
              );
            }}
          >
            {({ disabled }) => (
              <Button
                loading={loading}
                type="light"
                buttonProps={{
                  onClick: handleResendVerificationEmail,
                  disabled: loading || disabled,
                }}
                contentClassName="!w-full"
              >
                Resend verification email
              </Button>
            )}
          </Countdown>
        </div>
      </div>
    </div>
  );
};

export default EmailNotVerify;
