import { cn } from "djuno-design";
import { AnchorHTMLAttributes } from "react";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";

const CustomLink: React.FC<
  React.PropsWithChildren<{ to: string; className?: string }>
> = ({ to, children, className }) => {
  return (
    <Link
      className={cn(
        "cursor-pointer text-sky-500 hover:text-sky-600",
        className
      )}
      to={to}
    >
      {children}
    </Link>
  );
};

const A: React.FC<AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <a
      {...props}
      className={cn(
        "cursor-pointer text-sky-500 hover:text-sky-600",
        className
      )}
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

const ActionText = ({
  text,
  icon,
  onClick,
  type,
  className,
  loading,
}: {
  text?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  type?: "default" | "simple";
  className?: string;
  loading?: boolean;
}) => {
  return (
    <div
      onClick={() => (onClick ? onClick() : {})}
      className={cn(
        className,
        "text-xs font-semibold p-1 px-1.5 rounded-md flex items-center gap-1 justify-between transition-all duration-300",
        {
          "hover:bg-primary-100/50 hover:text-primary-500 dark:hover:bg-primary-400/20 transition-all duration-150 cursor-pointer":
            onClick,
          "bg-primary-100/30 text-primary-400 dark:bg-primary-100/10 ":
            type === undefined || type === "default",
          "bg-gray-100 text-gray-600 dark:bg-gray-400/10 dark:text-gray-300":
            type === "simple",
          "min-w-[90px] animate-pulse": loading,
        }
      )}
    >
      {text}
      {icon}
    </div>
  );
};

const HighlighterText: React.FC<{
  searchValue: string;
  textToHighlight: string;
  className?: string;
}> = ({ searchValue, textToHighlight, className }) => {
  return (
    <Highlighter
      highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
      className={cn(
        "whitespace-nowrap max-w-[110px] md:max-w-none text-ellipsis overflow-hidden text-sm font-medium",
        className
      )}
      searchWords={[searchValue]}
      autoEscape={true}
      textToHighlight={textToHighlight}
    />
  );
};

export { A, ActionText, HighlighterText };
export default CustomLink;
