import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { ReactComponent as ExpandMoreIcon } from "./../../../assets/icons/arrow-down.svg";
import { ReactComponent as CheckIcon } from "./../../../assets/icons/check.svg";
import { ReactComponent as IconArrowUpRightCircle } from "./../../../assets/icons/arrow-top-right-on-square.svg";
import { ReactComponent as IconKey } from "./../../../assets/icons/key.svg";

// third-party
import * as Yup from "yup";
import lodash from "lodash";

// project imports
import InputParameters from "./../workflows/inputs/InputParameters";
import CredentialInput from "./../workflows/inputs/CredentialInput";
import EditVariableDialog from "./../workflows/dialog/EditVariableDialog";

// Const
import { wallet_details, networkExplorers, privateKeyField } from "./constant";

// utils
import {
  handleCredentialParams,
  initializeNodeData,
} from "./../../../utils/wfHelper";
import toast from "react-hot-toast";
import { ToastClasses } from "../../modals/alerts";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  createWorkflowWalletAsync,
  deleteWorkflowWalletAsync,
  getWorkflowWalletAsync,
  getWorkflowWalletCredentialsAsync,
  handleClearWalletsSlice,
  selectWorkflowSelectedWallet,
  selectWorkflowSelectedWalletCredentials,
  selectWorkflowSelectedWalletCredentialsLoading,
  selectWorkflowSelectedWalletLoading,
  selectWorkflowWalletActionLoading,
  updateWorkflowWalletAsync,
} from "../../../store/workflows/walletsSlice";
import Button from "../../buttons/Button";
import Text from "../../general/Text";
import CopyableKey from "../../general/CopyableKey";
import { IconBtnWrapper } from "../../general/Wrappers";
import Tooltip from "../../general/Tooltip";
import Loading from "../../general/Loading";
import { handleClearWorkflow } from "../../../store/workflows/workflowSlice";

const WalletDialog = ({ show, dialogProps, onCancel, onConfirm }) => {
  const portalElement = document.getElementById("portal");

  const dispatch = useAppDispatch();

  const selectedWallet = useAppSelector(selectWorkflowSelectedWallet);
  const selectedWalletLoading = useAppSelector(
    selectWorkflowSelectedWalletLoading
  );

  const actionLoading = useAppSelector(selectWorkflowWalletActionLoading);

  const walletCredential = useAppSelector(
    selectWorkflowSelectedWalletCredentials
  );
  const walletCredentialLoading = useAppSelector(
    selectWorkflowSelectedWalletCredentialsLoading
  );

  const [walletDetails, setWalletDetails] = useState(wallet_details);
  const [walletData, setWalletData] = useState({});
  const [walletParams, setWalletParams] = useState([]);
  const [walletValues, setWalletValues] = useState({});
  const [walletValidation, setWalletValidation] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [isReadyToAdd, setIsReadyToAdd] = useState(false);
  const [isEditVariableDialogOpen, setEditVariableDialog] = useState(false);
  const [editVariableDialogProps, setEditVariableDialogProps] = useState({});
  const walletParamsType = ["networks", "credentials", "walletInfo"];

  const handleAccordionChange = (expanded) => (event, isExpanded) => {
    setExpanded(isExpanded ? expanded : false);
  };

  const reset = () => {
    setWalletData({});
    setWalletParams([]);
    setWalletValues({});
    setWalletValidation({});
    setIsReadyToAdd(false);
    setExpanded(false);
  };

  const checkIsReadyToAdd = (walletData) => {
    for (let i = 0; i < walletParamsType.length; i += 1) {
      const paramType = walletParamsType[i];
      if (!walletData[paramType] || !walletData[paramType].submit) {
        setIsReadyToAdd(false);
        return;
      }
    }
    setIsReadyToAdd(true);
  };

  const onEditVariableDialogOpen = (input, values, arrayItemBody) => {
    const dialogProps = {
      input,
      values,
      arrayItemBody,
      cancelButtonName: "Cancel",
      confirmButtonName: "Save",
      hideVariables: true,
    };

    setEditVariableDialogProps(dialogProps);
    setEditVariableDialog(true);
  };

  const onConfirmModal = () => {
    dispatch(handleClearWorkflow());
    onConfirm();
  };

  const onCancelModal = () => {
    dispatch(handleClearWorkflow());
    onCancel();
  };

  const addNewWallet = async (type) => {
    const createNewWalletBody = {
      network: walletData.networks.network,
      name: walletData.walletInfo.name,
      providerCredential: JSON.stringify(walletData.credentials),
    };
    if (type === "IMPORT")
      createNewWalletBody.privateKey = walletData.walletInfo.privateKey;

    dispatch(createWorkflowWalletAsync(createNewWalletBody)).then((action) => {
      if (action.type === "workflows/wallet/create/fulfilled") {
        toast.success("New wallet added", { className: ToastClasses });
        onConfirmModal();
      } else {
        onCancelModal();
      }
    });
  };

  const saveWallet = async () => {
    const saveWalletBody = {
      network: walletData.networks.network,
      name: walletData.walletInfo.name,
      providerCredential: JSON.stringify(walletData.credentials),
    };

    dispatch(
      updateWorkflowWalletAsync({ id: dialogProps.id, body: saveWalletBody })
    ).then((action) => {
      if (action.type === "workflows/wallet/update/fulfilled") {
        toast.success("Wallet saved", { className: ToastClasses });
        onConfirmModal();
      } else {
        onCancelModal();
      }
    });
  };

  const deleteWallet = async () => {
    dispatch(deleteWorkflowWalletAsync(dialogProps.id)).then((action) => {
      if (action.type === "workflows/wallet/delete/fulfilled") {
        toast.success("Wallet deleted", { className: ToastClasses });
        onConfirmModal();
      } else {
        onCancelModal();
      }
    });
  };

  const valueChanged = (formValues, paramsType) => {
    const updateWalletData = {
      ...walletData,
      [paramsType]: formValues,
    };

    const index = walletParamsType.indexOf(paramsType);
    if (index >= 0 && index !== walletParamsType.length - 1) {
      for (let i = index + 1; i < walletParamsType.length; i += 1) {
        const paramType = walletParamsType[i];
        if (updateWalletData[paramType]) {
          updateWalletData[paramType].submit = null;
        }
      }
    }

    setWalletData(updateWalletData);
  };

  const paramsChanged = (formParams, paramsType) => {
    // Because formParams options can be changed due to show hide options,
    // To avoid that, replace with original details options

    const credentialMethodParam = formParams.find(
      (param) => param.name === "credentialMethod"
    );
    const credentialMethodParamIndex = formParams.findIndex(
      (param) => param.name === "credentialMethod"
    );

    if (credentialMethodParam !== undefined) {
      const originalParam = walletDetails[paramsType].find(
        (param) => param.name === "credentialMethod"
      );
      if (originalParam !== undefined) {
        formParams[credentialMethodParamIndex]["options"] =
          originalParam.options;
      }
    }

    const updateWalletDetails = {
      ...walletDetails,
      [paramsType]: formParams,
    };
    setWalletDetails(updateWalletDetails);
  };

  const onSubmit = async (formValues, paramsType) => {
    const updateWalletData = {
      ...walletData,
      [paramsType]: formValues,
    };
    setWalletData(updateWalletData);

    const index = walletParamsType.indexOf(paramsType);
    if (index >= 0 && index !== walletParamsType.length - 1) {
      setExpanded(walletParamsType[index + 1]);
    } else if (index === walletParamsType.length - 1) {
      setExpanded(false);
    }

    checkIsReadyToAdd(updateWalletData);
  };

  const showHideOptions = (displayType, options) => {
    let returnOptions = options;
    const toBeDeleteOptions = [];

    for (let i = 0; i < returnOptions.length; i += 1) {
      const option = returnOptions[i];
      const displayOptions = option[displayType];

      if (displayOptions) {
        Object.keys(displayOptions).forEach((path) => {
          const comparisonValue = displayOptions[path];
          const groundValue = lodash.get(walletData, path, "");

          if (Array.isArray(comparisonValue)) {
            if (
              displayType === "show" &&
              !comparisonValue.includes(groundValue)
            ) {
              toBeDeleteOptions.push(option);
            }
            if (
              displayType === "hide" &&
              comparisonValue.includes(groundValue)
            ) {
              toBeDeleteOptions.push(option);
            }
          }
        });
      }
    }

    for (let i = 0; i < toBeDeleteOptions.length; i += 1) {
      returnOptions = returnOptions.filter(
        (opt) => JSON.stringify(opt) !== JSON.stringify(toBeDeleteOptions[i])
      );
    }

    return returnOptions;
  };

  const displayOptions = (params) => {
    let clonedParams = params;

    for (let i = 0; i < clonedParams.length; i += 1) {
      const input = clonedParams[i];
      if (input.type === "options") {
        input.options = showHideOptions("show", input.options);
        input.options = showHideOptions("hide", input.options);
      }
    }

    return clonedParams;
  };

  const setYupValidation = (params) => {
    const validationSchema = {};
    for (let i = 0; i < params.length; i += 1) {
      const input = params[i];
      if (input.type === "string" && !input.optional) {
        validationSchema[input.name] = Yup.string().required(
          `${input.label} is required. Type: ${input.type}`
        );
      } else if (input.type === "number" && !input.optional) {
        validationSchema[input.name] = Yup.number().required(
          `${input.label} is required. Type: ${input.type}`
        );
      } else if (
        (input.type === "options" || input.type === "asyncOptions") &&
        !input.optional
      ) {
        validationSchema[input.name] = Yup.string().required(
          `${input.label} is required. Type: ${input.type}`
        );
      }
    }
    return validationSchema;
  };

  const initializeFormValuesAndParams = (paramsType) => {
    const initialValues = {};
    let walletParams = displayOptions(
      lodash.cloneDeep(walletDetails[paramsType] || [])
    );
    walletParams = handleCredentialParams(
      walletParams,
      paramsType,
      walletDetails[paramsType],
      walletData
    );

    if (dialogProps.type === "IMPORT" && paramsType === "walletInfo") {
      walletParams.push(...privateKeyField);
    }

    for (let i = 0; i < walletParams.length; i += 1) {
      const input = walletParams[i];

      // Load from walletData values
      if (paramsType in walletData && input.name in walletData[paramsType]) {
        initialValues[input.name] = walletData[paramsType][input.name];

        // Check if option value is still available from the list of options
        if (input.type === "options") {
          const optionVal = input.options.find(
            (option) => option.name === initialValues[input.name]
          );
          if (!optionVal) delete initialValues[input.name];
        }
      } else {
        // Load from walletParams default values
        initialValues[input.name] = input.default || "";
      }
    }

    initialValues.submit = null;

    setWalletValues(initialValues);
    setWalletValidation(setYupValidation(walletParams));
    setWalletParams(walletParams);
  };

  const transformWalletResponse = (walletResponseData, walletDetails) => {
    const walletData = {
      networks: {},
      credentials: {},
      walletInfo: {},
    };

    if (walletResponseData) {
      walletData.networks = {
        network: walletResponseData.network,
        submit: true,
      };
      walletData.walletInfo = { ...walletResponseData, submit: true };
      if (walletResponseData.providerCredential) {
        try {
          walletData.credentials = JSON.parse(
            walletResponseData.providerCredential
          );
        } catch (e) {
          console.error(e);
        }
      }
    } else {
      walletData.networks = initializeNodeData(walletDetails.networks);
      walletData.credentials = initializeNodeData(walletDetails.credentials);
      walletData.walletInfo = initializeNodeData(walletDetails.walletInfo);
    }
    return walletData;
  };

  // Get Wallet Details from API
  useEffect(() => {
    if (selectedWallet) {
      const walletResponseData = selectedWallet;
      const wallet_data = transformWalletResponse(walletResponseData);
      setWalletData(wallet_data);
      setExpanded("networks");
    }
  }, [selectedWallet]);

  // Initialization
  useEffect(() => {
    if (show && (dialogProps.type === "ADD" || dialogProps.type === "IMPORT")) {
      reset();
      setWalletData(transformWalletResponse(null, walletDetails));
      setExpanded("networks");
    } else if (show && dialogProps.type === "EDIT" && dialogProps.id) {
      reset();
      dispatch(getWorkflowWalletAsync(dialogProps.id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, dialogProps]);

  // Initialize Parameters Initial Values & Validation
  useEffect(() => {
    if (walletDetails && walletData && expanded) {
      initializeFormValuesAndParams(expanded);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletDetails, walletData, expanded]);

  const component = show ? (
    <Dialog
      open={show}
      onClose={onCancelModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { borderRadius: "1rem" } }}
    >
      <DialogTitle sx={{ fontSize: "1rem" }} id="alert-dialog-title">
        {dialogProps.title}
      </DialogTitle>
      <DialogContent
        className="min-w-[340px] md:min-w-[400px] lg:min-w-[500px]"
        sx={{ maxWidth: 500 }}
      >
        {walletData &&
          walletData.walletInfo &&
          walletData.walletInfo.address &&
          dialogProps.type === "EDIT" && (
            <Box>
              <Text className="text-sm">BALANCE</Text>
              <Text className="text-sm mb-3 font-semibold">
                {walletData.walletInfo.balance}
              </Text>
              <Text className="text-sm">ADDRESS</Text>
              <div className="flex items-center gap-1 text-xs">
                <CopyableKey text={walletData.walletInfo.address} />
                <Tooltip content="Open in Block Explorer">
                  <IconBtnWrapper>
                    <IconArrowUpRightCircle
                      onClick={() =>
                        window.open(
                          `${
                            networkExplorers[walletData.networks.network]
                          }/address/${walletData.walletInfo.address}`,
                          "_blank"
                        )
                      }
                      className="w-4 h-4"
                    />
                  </IconBtnWrapper>
                </Tooltip>
              </div>
              {walletCredential && walletCredential.privateKey && (
                <>
                  <Text className="text-sm mt-3">PRIVATE KEY</Text>
                  <CopyableKey text={walletCredential.privateKey} />
                </>
              )}
              {walletCredential && walletCredential.mnemonic && (
                <>
                  <Text className="text-sm  mt-3">mnemonic</Text>
                  <CopyableKey text={walletCredential.mnemonic} />
                </>
              )}
              {walletCredential === null && !walletCredentialLoading && (
                <Button
                  size="small"
                  type="light"
                  buttonClassName="mt-4"
                  buttonProps={{
                    onClick: () =>
                      dispatch(
                        getWorkflowWalletCredentialsAsync(dialogProps.id)
                      ),
                  }}
                >
                  <IconKey className="w-3 h-3" />
                  View PrivateKey and Mnemonic
                </Button>
              )}
              {walletCredentialLoading && (
                <Loading borderSize={2} className="!min-h-[100px]" />
              )}
            </Box>
          )}

        {/* networks */}
        <Box>
          <Accordion
            expanded={expanded === "networks"}
            onChange={handleAccordionChange("networks")}
            sx={{ boxShadow: "none" }}
            className="bg-white dark:bg-dark-3"
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon className="w-[15px] h-[15px] text-slate-700 dark:text-slate-300" />
              }
              aria-controls="networks-content"
              id="networks-header"
            >
              <div className="flex items-center justify-between">
                <Text className="text-sm font-semibold">Networks</Text>
                {walletData &&
                  walletData.networks &&
                  walletData.networks.submit && (
                    <div className="w-[18px] h-[18px] rounded-full bg-success flex justify-center items-center ml-1">
                      <CheckIcon className="p-1 text-white" />
                    </div>
                  )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <InputParameters
                paramsType="networks"
                params={walletParams}
                initialValues={walletValues}
                nodeParamsValidation={walletValidation}
                valueChanged={valueChanged}
                onSubmit={onSubmit}
                setVariableSelectorState={() => null}
                onEditVariableDialogOpen={onEditVariableDialogOpen}
              />
            </AccordionDetails>
          </Accordion>
          <Divider />
        </Box>

        {/* credentials */}
        <Box>
          <Accordion
            expanded={expanded === "credentials"}
            onChange={handleAccordionChange("credentials")}
            sx={{ boxShadow: "none" }}
            className="bg-white dark:bg-dark-3"
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon className="w-[15px] h-[15px] text-slate-700 dark:text-slate-300" />
              }
              aria-controls="credentials-content"
              id="credentials-header"
            >
              <div className="flex items-center justify-between">
                <Text className="text-sm font-semibold">Credentials</Text>
                {walletData &&
                  walletData.credentials &&
                  walletData.credentials.submit && (
                    <div className="w-[18px] h-[18px] rounded-full bg-success flex justify-center items-center ml-1">
                      <CheckIcon className="p-1 text-white" />
                    </div>
                  )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <CredentialInput
                paramsType="credentials"
                initialParams={walletParams}
                initialValues={walletValues}
                initialValidation={walletValidation}
                valueChanged={valueChanged}
                paramsChanged={paramsChanged}
                onSubmit={onSubmit}
              />
            </AccordionDetails>
          </Accordion>
          <Divider />
        </Box>

        {/* walletInfo */}
        <Box>
          <Accordion
            expanded={expanded === "walletInfo"}
            onChange={handleAccordionChange("walletInfo")}
            sx={{ boxShadow: "none" }}
            className="bg-white dark:bg-dark-3"
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon className="w-[15px] h-[15px] text-slate-700 dark:text-slate-300" />
              }
              aria-controls="walletInfo-content"
              id="walletInfo-header"
            >
              <div className="flex items-center justify-between">
                <Text className="text-sm font-semibold">Wallet Details</Text>
                {walletData &&
                  walletData.walletInfo &&
                  walletData.walletInfo.submit && (
                    <div className="w-[18px] h-[18px] rounded-full bg-success flex justify-center items-center ml-1">
                      <CheckIcon className="p-1 text-white" />
                    </div>
                  )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <InputParameters
                paramsType="walletInfo"
                params={walletParams}
                initialValues={walletValues}
                nodeParamsValidation={walletValidation}
                valueChanged={valueChanged}
                onSubmit={onSubmit}
                setVariableSelectorState={() => null}
                onEditVariableDialogOpen={onEditVariableDialogOpen}
              />
            </AccordionDetails>
          </Accordion>
          <Divider />
        </Box>

        <EditVariableDialog
          key={JSON.stringify(editVariableDialogProps)}
          show={isEditVariableDialogOpen}
          dialogProps={editVariableDialogProps}
          onCancel={() => setEditVariableDialog(false)}
          onConfirm={(updateValues) => {
            valueChanged(updateValues, expanded);
            setEditVariableDialog(false);
          }}
        />
      </DialogContent>
      <DialogActions sx={{ px: "20px", pb: "20px" }}>
        <Button buttonProps={{ onClick: onCancelModal }}>
          {dialogProps.cancelButtonName}
        </Button>
        {/* {dialogProps.type === "EDIT" && (
          <Button
            type="danger"
            buttonProps={{
              onClick: deleteWallet,
            }}
            loading={actionLoading}
          >
            Delete
          </Button>
        )} */}
        <Button
          type="primary"
          buttonProps={{
            disabled: !isReadyToAdd || selectedWalletLoading,
            onClick: () => {
              dialogProps.type === "ADD" || dialogProps.type === "IMPORT"
                ? addNewWallet(dialogProps.type)
                : saveWallet();
            },
          }}
          loading={actionLoading || selectedWalletLoading}
        >
          {dialogProps.confirmButtonName}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;

  return createPortal(component, portalElement);
};

WalletDialog.propTypes = {
  show: PropTypes.bool,
  dialogProps: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default WalletDialog;
