import { useAppDispatch, useAppSelector } from "./../../../hooks";
import { useEffect, useState } from "react";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as DetailsIcon } from "./../../../assets/icons/bars-3-bottom-left.svg";
import { useNavigate } from "react-router-dom";
import { DeleteModal } from "./../../../components/modals/QuestionModal";
import { HighlighterText } from "./../../../components/general/Text";
import { useSearch } from "./../../../providers/SearchProvider";
import { Web3AuthEndpoint } from "./../../../types/web3-auth";
import {
  selectWeb3AuthEndpoints,
  selectActionLoading,
  getWeb3AuthEndpointsAsync,
  deleteWeb3AuthAsync,
  selectWeb3EndpointsLoading,
  openWeb3AuthEditorModal,
} from "./../../../store/web3-auth/web3AuthEndpointsSlice";
import CreateWeb3EndpointModal from "./../../../components/web3-auth/CreateWeb3EndpointModal";
import { Web3EndpoindUsersUrl } from "./../../../utils/urls";
import { handleSetEndpoint } from "./../../../store/web3-auth/web3AuthEndpointSlice";
import { Helmet } from "react-helmet";
import Web3Banner from "./../../../components/web3-auth/Web3Banner";
import {
  Dropdown,
  Button,
  SimpleTable,
  Typography,
  EmptyState,
} from "djuno-design";

const Web3AuthEndpointsTab = () => {
  const [deleteWeb3Auth, setDeleteWeb3Auth] = useState<Web3AuthEndpoint | null>(
    null
  );
  const endpoints = useAppSelector(selectWeb3AuthEndpoints);
  const endpointsLoading = useAppSelector(selectWeb3EndpointsLoading);
  const actionLoading = useAppSelector(selectActionLoading);

  const [filteredEndpoints, setFilteredEndpoints] = useState<
    Web3AuthEndpoint[]
  >([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    dispatch(getWeb3AuthEndpointsAsync());
  }, [dispatch]);

  useEffect(() => {
    const lookedUpFiles = endpoints.filter((endpoint) =>
      endpoint.Name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredEndpoints(lookedUpFiles);
  }, [dispatch, searchValue, endpoints]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Web3 Auth</title>
        <meta
          name="description"
          content="web3-auth refers to authentication mechanisms used within Web3 applications, which are decentralized applications built on blockchain technology. Web3-auth facilitates user authentication and authorization processes within these decentralized applications"
        />
      </Helmet>

      <div className="">
        <div className="flex items-center justify-between">
          <Typography.Text className="font-medium text-lg"></Typography.Text>
          <div className="">
            <Button
              uiType="primary"
              onClick={() => dispatch(openWeb3AuthEditorModal({}))}
              className="group"
            >
              Create <span className="hidden md:block">Web3.0 Auth</span>{" "}
              Endpoint
              <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
          </div>
        </div>

        <Web3Banner />
        <div className="mt-5 w-full">
          <SimpleTable loading={endpointsLoading}>
            <SimpleTable.Head>
              <SimpleTable.Row>
                <SimpleTable.TH lable="Name" />
                <SimpleTable.TH lable="Chains" />
                <SimpleTable.TH lable="Users" />
                <SimpleTable.TH lable="" />
              </SimpleTable.Row>
            </SimpleTable.Head>
            <SimpleTable.Body>
              {filteredEndpoints.map((endpoint, i) => (
                <SimpleTable.Row
                  key={i}
                  onClick={() => {
                    dispatch(handleSetEndpoint(endpoint));
                    navigate(Web3EndpoindUsersUrl(endpoint.Id.toString()));
                  }}
                >
                  <SimpleTable.TD className="cursor-pointer">
                    <div className="flex items-center flex-row justify-between space-x-3">
                      <HighlighterText
                        searchValue={searchValue}
                        textToHighlight={endpoint.Name}
                      />
                    </div>
                  </SimpleTable.TD>
                  <SimpleTable.TD className="cursor-pointer">
                    <Typography.Text className="text-xs md:text-sm">
                      {endpoint.NetworkItems.map((c) => c.NetworkName).join(
                        ", "
                      )}
                    </Typography.Text>
                  </SimpleTable.TD>

                  <SimpleTable.TD className="cursor-pointer">
                    <Typography.Text className="text-xs md:text-sm">
                      {endpoint.Users}
                    </Typography.Text>
                  </SimpleTable.TD>

                  <SimpleTable.TD className="w-32 gap-1">
                    <div className="h-full w-full inline-flex items-center justify-end gap-1 px-4">
                      <div className="w-8 flex justify-center items-center">
                        <Dropdown
                          anchor="bottom end"
                          itemsClassName="!w-40"
                          menu={[
                            {
                              key: "1",
                              label: (
                                <div className="flex items-center gap-1">
                                  <DetailsIcon className="w-4" />
                                  Get details
                                </div>
                              ),
                              onClick: () => {
                                dispatch(handleSetEndpoint(endpoint));
                                navigate(
                                  Web3EndpoindUsersUrl(endpoint.Id.toString())
                                );
                              },

                              disabled: endpointsLoading,
                            },
                            {
                              type: "divider",
                            },
                            {
                              key: "end",
                              label: (
                                <div className="flex items-center gap-1">
                                  <ArchiveIcon className="w-4" />
                                  Delete
                                </div>
                              ),
                              danger: true,
                              onClick: (_, close) => {
                                close();
                                setDeleteWeb3Auth(endpoint);
                              },
                              disabled: endpointsLoading,
                            },
                          ]}
                        >
                          <Button
                            uiSize="small"
                            uiType="icon"
                            className="!px-2"
                          >
                            <MoreIcon className="w-4 h-4" />
                          </Button>
                        </Dropdown>
                      </div>
                      <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                    </div>
                  </SimpleTable.TD>
                </SimpleTable.Row>
              ))}

              {filteredEndpoints.length === 0 && (
                <SimpleTable.Row withoutHoverStyle={true}>
                  <SimpleTable.TD colSpan={4} className="!border-0">
                    <EmptyState text="You have not created any web3 auth endpoint yet" />
                  </SimpleTable.TD>
                </SimpleTable.Row>
              )}
            </SimpleTable.Body>
          </SimpleTable>
        </div>
        <CreateWeb3EndpointModal />

        <DeleteModal
          title="Delete Web3 Auth Endpoint"
          isOpen={deleteWeb3Auth !== null}
          onClose={() => setDeleteWeb3Auth(null)}
          description=""
          confirmButtonText="Confirm"
          confirmButtonType="danger"
          confirmButtonClassName="w-full"
          onConfirm={() => {
            deleteWeb3Auth &&
              dispatch(deleteWeb3AuthAsync({ id: deleteWeb3Auth.Id })).then(
                (action) => {
                  if (action.type === "web3-auth/delete/fulfilled") {
                    dispatch(getWeb3AuthEndpointsAsync());
                  } else {
                  }
                  setDeleteWeb3Auth(null);
                }
              );
          }}
          loading={actionLoading}
          confirmString={deleteWeb3Auth?.Name}
        />
      </div>
    </>
  );
};
export default Web3AuthEndpointsTab;
