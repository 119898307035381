import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import { IpfsMetrics, IpfsPublicStateType } from "../../types/ipfs-file";
import { getExtractErrorCode, getExtractErrors } from "../../apis";
import { getIpfsMetricsApi, getIpfsUsageApi } from "../../apis/filesAPI";
import toast from "react-hot-toast";
import { CustomErrorToast } from "../../components/general/Toast";

const initialState: IpfsPublicStateType = {
  activeStatus: true, //is IPFS enabled or not?
  metrics: null,
  usage: null,
  metricsLoading: false,
};

export const getIpfsMetricsAsync = createAsyncThunk<
  { ipfsMetrics: IpfsMetrics; usage: number },
  { withoutLoading?: boolean },
  IThunkRejectValue
>(
  "ipfs/metrics",
  async (data, { rejectWithValue, fulfillWithValue, dispatch, requestId }) => {
    try {
      dispatch(getIpfsMetricsAsync.pending(requestId, data));

      const metrics_response = await getIpfsMetricsApi();
      const ipfsMetrics = metrics_response.data.Result;

      const usage_response = await getIpfsUsageApi();
      const ipfsUsage = usage_response.data.Result.Usage;

      return fulfillWithValue({ ipfsMetrics, usage: ipfsUsage });
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
        code: getExtractErrorCode(e),
      });
    }
  }
);

const ipfsPublicSlice = createSlice({
  name: "ipfs-public",
  initialState: initialState,
  reducers: {
    changeIpfsActiveStatus(state, action: PayloadAction<boolean>) {
      state.activeStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIpfsMetricsAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.metricsLoading = true;
      })
      .addCase(getIpfsMetricsAsync.fulfilled, (state, action) => {
        state.metrics = action.payload.ipfsMetrics;
        state.usage = action.payload.usage;
        state.metricsLoading = false;
      })
      .addCase(getIpfsMetricsAsync.rejected, (state, { payload }) => {
        state.metricsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectIpfsStatus = (state: RootState) =>
  state.ipfsPublic.activeStatus;

//metrics
export const selectIpfsUsage = (state: RootState) => state.ipfsPublic.usage;
export const selectIpfsMetrics = (state: RootState) => state.ipfsPublic.metrics;
export const selectIpfsMetricsLoading = (state: RootState) =>
  state.ipfsPublic.metricsLoading;

export const { changeIpfsActiveStatus } = ipfsPublicSlice.actions;
export default ipfsPublicSlice.reducer;
