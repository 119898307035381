import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createS3EventAsync,
  selectActionLoading,
  selectLoading,
  selectSelectedS3Event,
} from "../../../../store/s3/eventsSlice";
import { S3NsqEventSchema } from "../../../../utils/validations";
import { useEffect, useState } from "react";
import { InfoTooltip } from "../../../general/Tooltip";
import { S3EventsUrl } from "../../../../utils/urls";
import { useNavigate } from "react-router-dom";
import useGetS3Events from "../../../../hooks/useGetS3Events";
import {
  Button,
  Input,
  Switcher,
  Textarea,
  Tooltip,
  Typography,
} from "djuno-design";

const NsqForm = () => {
  const event = useAppSelector(selectSelectedS3Event);
  const loading = useAppSelector(selectActionLoading);
  const eventsLoading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getS3Events = useGetS3Events();

  const [enableTLS, setEnableTLS] = useState<boolean>(false);
  const [enableTlsSkip, setEnableTlsSkip] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(S3NsqEventSchema),
  });

  useEffect(() => {
    if (event) {
    } else {
    }
  }, [event, setValue]);

  const onSubmit = (data: any) => {
    let keyValues = [
      {
        key: "nsqd_address",
        value: data.nsqd_address,
      },
      {
        key: "topic",
        value: data.topic,
      },
      {
        key: "tls",
        value: enableTLS ? "on" : "off",
      },
      {
        key: "tls_skip_verify",
        value: enableTlsSkip ? "on" : "off",
      },
      {
        key: "queue_dir",
        value: data.queue_dir,
      },
      {
        key: "comment",
        value: data.comment,
      },
    ];
    if (data.queue_limit !== null) {
      keyValues = [
        ...keyValues,
        {
          key: "queue_limit",
          value: data.queue_limit.toString(),
        },
      ];
    }
    const formData = {
      key_values: keyValues,
    };
    // if (event) {
    // dispatch(
    //   updateS3EventAsync({
    //     id: event.Id,
    //     data: {
    //       label: data.label,
    //     },
    //   })
    // ).then((action) => {
    //   if (action.type === "s3-events/update/fulfilled") {
    //     // handleClearComponent();
    //     dispatch(handleHideEditor());
    //     dispatch(getS3EventsAsync());
    //     reset();
    //   }
    // });
    // } else {
    dispatch(
      createS3EventAsync({
        event: "nsq",
        identifier: data.identifier,
        data: formData,
      })
    ).then((action) => {
      if (action.type === "s3-events/create/fulfilled") {
        getS3Events(() => {
          reset();
          navigate(S3EventsUrl);
        });
      }
    });
    // }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col w-full gap-5">
        <Input
          label="Identifier"
          tooltip={{
            className: "!text-xs",
            content: "Unique descriptive string for this destination",
          }}
          placeholder="Enter Destination Identifier"
          {...register("identifier")}
          error={errors.identifier?.message}
          required
        />

        <Input
          label="NSQD Address"
          tooltip={{
            className: "!text-xs",
            content: "NSQ server address e.g. '127.0.0.1:4150'",
          }}
          placeholder="Enter NSQD Address"
          {...register("nsqd_address")}
          error={errors.nsqd_address?.message}
        />

        <Input
          label="Topic"
          tooltip={{ className: "!text-xs", content: "NSQ topic" }}
          placeholder="Enter Topic"
          {...register("topic")}
          error={errors.topic?.message}
        />

        <div className="flex items-center justify-between w-full px-1">
          <div className="flex gap-1 items-center">
            <Typography.Text className="!text-sm">TLS</Typography.Text>
            <Tooltip.Info
              className="!text-xs"
              content="Set to 'on' to enable TLS"
            />
          </div>
          <Switcher value={enableTLS} onChange={setEnableTLS} />
        </div>

        <div className="flex items-center justify-between w-full px-1">
          <div className="flex gap-1 items-center">
            <Typography.Text className="!text-sm">
              TLS skip verify
            </Typography.Text>
            <Tooltip.Info
              className="!text-xs"
              content="Trust server TLS without verification, defaults to 'on' (verify)"
            />
          </div>
          <Switcher value={enableTlsSkip} onChange={setEnableTlsSkip} />
        </div>

        <Input
          label="Queue Directory"
          tooltip={{
            className: "!text-xs",
            content:
              "Staging directory for undelivered messages e.g. '/home/events'",
          }}
          placeholder="ِEnter Queue Directory"
          {...register("queue_dir")}
          error={errors.queue_dir?.message}
        />

        <Input
          label="Queue Limit"
          tooltip={{
            className: "!text-xs",
            content:
              "Maximum limit for undelivered messages, defaults to '10000'",
          }}
          placeholder="ِEnter Queue Limit"
          {...register("queue_limit")}
          type="number"
          error={errors.queue_limit?.message}
        />

        <Textarea
          label="Comment"
          placeholder="Enter custom notes if any"
          {...register("comment")}
          error={errors.comment?.message}
        />
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          loading={loading || eventsLoading}
          uiType="primary"
          disabled={loading || eventsLoading}
          type="submit"
        >
          {event ? "Update Evet Target" : "Save Evet Target"}
        </Button>
      </div>
    </form>
  );
};
export default NsqForm;
