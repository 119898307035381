import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { useState } from "react";
import {
  getObjectInspectAsync,
  handleHideObjectInspectModal,
  selectObjectInspectLoading,
  selectObjectVersions,
  selectObjectVersionsLoading,
  selectShowObjectInspectModal,
} from "../../../../../store/s3/buckets/objectSlice";
import { getMainVersionOfObject } from "../../../../../utils/bucket";
import { selectBucketDetails } from "../../../../../store/s3/buckets/bucketSlice";
import { Button, Modal, Switcher, Typography } from "djuno-design";

const S3ObjectInspectModal = () => {
  const isOpen = useAppSelector(selectShowObjectInspectModal);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const versions = useAppSelector(selectObjectVersions);
  const versionsLoading = useAppSelector(selectObjectVersionsLoading);
  const mainVersion = getMainVersionOfObject(versions);
  const inspectLoading = useAppSelector(selectObjectInspectLoading);
  const [enabledEncrypt, setEnabledEncrypt] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { handleSubmit, reset } = useForm();

  const handleClearComponent = () => {
    reset();
    setEnabledEncrypt(false);
  };

  const onSubmit = () => {
    if (mainVersion && mainVersion.version_id && bucketDetails) {
      dispatch(
        getObjectInspectAsync({
          bucketName: bucketDetails.name,
          prefix: mainVersion.name,
          encrypt: enabledEncrypt,
        })
      ).then((action) => {
        if (action.type === "object/inspect/fulfilled") {
          handleClearComponent();
          dispatch(handleHideObjectInspectModal());
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideObjectInspectModal());
        handleClearComponent();
      }}
      contentClassName="max-w-lg"
      // containerClassName="!items-start"
      title={"Inspect Object"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          <div className="flex items-center justify-between w-full">
            <Typography.Text className="!text-sm flex gap-0.5">
              Would you like to encrypt?{" "}
            </Typography.Text>
            <Switcher value={enabledEncrypt} onChange={setEnabledEncrypt} />
          </div>
          <Typography.Text className="!font-normal !text-base">
            File: {mainVersion?.name}
          </Typography.Text>
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            onClick={(e) => {
              e.preventDefault();
              dispatch(handleHideObjectInspectModal());
            }}
            uiType="light"
          >
            Cancel
          </Button>
          <Button
            loading={versionsLoading || inspectLoading}
            uiType="primary"
            disabled={versionsLoading || inspectLoading}
            type="submit"
          >
            Inspect
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3ObjectInspectModal;
