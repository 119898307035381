import classNames from "classnames";
import React, { useState, useEffect, ReactNode } from "react";
import Text from "./Text";

type CountdownRenderProps = {
  formatedTime: string;
  timeLeft: number;
  disabled: boolean;
};

interface CountdownProps {
  seconds: number;
  children?: ReactNode | ((props: CountdownRenderProps) => ReactNode);
  className?: string;
  showTimer?: boolean;
  timerPosition?: "left" | "right";
  timerRender?: (props: CountdownRenderProps) => React.ReactNode;
  onClick?: () => void;
}

const Countdown: React.FC<CountdownProps> = ({
  seconds,
  children,
  className,
  showTimer = true,
  timerPosition = "left",
  timerRender,
  onClick,
}) => {
  const [timeLeft, setTimeLeft] = useState<number>(seconds);

  useEffect(() => {
    if (timeLeft <= 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const formatTime = (secs: number): string => {
    const days = Math.floor(secs / (3600 * 24));
    const hrs = Math.floor((secs % (3600 * 24)) / 3600);
    const mins = Math.floor((secs % 3600) / 60);
    const secsLeft = secs % 60;

    let formattedTime = "";

    if (days > 0) {
      formattedTime += `${days}d `;
    }
    if (hrs > 0 || days > 0) {
      formattedTime += `${hrs.toString().padStart(2, "0")}:`;
    }

    formattedTime += `${mins.toString().padStart(2, "0")}:`;
    formattedTime += `${secsLeft.toString().padStart(2, "0")}`;

    return formattedTime.trim();
  };

  const disabled = React.useMemo(() => timeLeft > 0, [timeLeft]);

  const timer = () => {
    if (!showTimer) return <></>;
    if (timerRender)
      return timerRender({
        formatedTime: formatTime(timeLeft),
        timeLeft,
        disabled,
      });
    return (
      <Text className="text-sm" type="text">
        {formatTime(timeLeft)}
      </Text>
    );
  };

  return (
    <div
      className={classNames(
        "flex items-center w-full h-full gap-1",
        {
          "cursor-not-allowed": disabled,
        },
        className
      )}
      onClick={() => {
        if (!disabled && onClick) onClick();
      }}
    >
      {timerPosition === "left" && timer()}
      {typeof children === "function"
        ? children({
            disabled,
            timeLeft,
            formatedTime: formatTime(timeLeft),
          })
        : children}
      {timerPosition === "right" && timer()}
    </div>
  );
};

export default Countdown;
