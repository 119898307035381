import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getS3WidgetAsync,
  selectNetwork,
  selectNetworkLoading,
  selectS3EndTime,
  selectS3StartTime,
} from "../../../../store/s3/overviewSlice";
import { humanizeSize } from "../../../../utils/file";
import { getLastValueOfWidgetTarget } from "../../../../utils/bucket";
import { Skeleton, Typography } from "djuno-design";

const NetworkWidget = () => {
  const network = useAppSelector(selectNetwork);
  const loading = useAppSelector(selectNetworkLoading);
  const startTime = useAppSelector(selectS3StartTime);
  const endTime = useAppSelector(selectS3EndTime);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getS3WidgetAsync({ key: "network", n: [64, 65], startTime, endTime })
    );
  }, [dispatch, endTime, startTime]);

  const getUsed = useCallback(() => {
    return getLastValueOfWidgetTarget(network?.get?.targets)[1];
  }, [network?.get?.targets]);

  const putUsed = useCallback(() => {
    return getLastValueOfWidgetTarget(network?.put?.targets)[1];
  }, [network?.put?.targets]);

  return (
    <div className="flex w-full col-span-3 lg:col-span-1 rounded-lg border dark:border-dark-2 bg-white dark:bg-dark-3 p-5">
      <div className=" flex justify-between w-full">
        <div className="flex items-center gap-3">
          <Typography.Text className="!text-sm">Nework</Typography.Text>
        </div>
        <div className="flex flex-col justify-center gap-1">
          <Typography.Text className="!text-sm" uiType="secondary">
            GET
          </Typography.Text>
          <div className="flex">
            {loading && <Skeleton style={{ width: 30, height: 26 }} />}
            {!loading && (
              <Typography.Title className="!mb-0" level={3}>
                {humanizeSize(+getUsed(), { fractionDigits: 1 }).join("B")}
              </Typography.Title>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-center gap-1">
          <Typography.Text className="!text-sm" uiType="secondary">
            PUT
          </Typography.Text>
          <div className="flex">
            {loading && <Skeleton style={{ width: 30, height: 26 }} />}
            {!loading && (
              <Typography.Title className="!mb-0" level={3}>
                {humanizeSize(+putUsed(), { fractionDigits: 1 }).join("B")}
              </Typography.Title>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default NetworkWidget;
