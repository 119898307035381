import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { ReactComponent as BellAlertIcon } from "./../../assets/icons/bell-alert.svg";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right.svg";
import { ReactComponent as HashtagIcon } from "./../..//assets/icons/hashtag.svg";
import { ReactComponent as RightArrow } from "./../../assets/icons/arrow-up.svg";
import { DatabaseBackupsUrl, DatabaseServicesUrl } from "../../utils/urls";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatabaseServiceCreateSchema } from "../../utils/validations";
import {
  createDBSAsync,
  getDBSAvailabilityAsync,
  getDBSCapabilitiesAsync,
  getDBSCatalogAsync,
  selectDBSAvailability,
  selectDBSAvailabilityLoading,
  selectDBSCapabilities,
  selectDBSCapabilitiesLoading,
  selectDBSCatalog,
  selectDBSCatalogLoading,
  selectDatabaseServicesActionLoading,
} from "../../store/database/servicesSlice";
import {
  DBSAddon,
  DBSAvailability,
  DBSCapabilities,
  DBSCreateApiData,
  DBSGroupAvailability,
  DBSNodeType,
  IDBSBackup,
} from "../../types/database";
import { binarySize, humanizeSize } from "../../utils/file";
import { capitalizeFirstLetter, generateRandomName } from "../../utils";
import RadioGrouper, {
  RadioGroupeItem,
} from "../../components/inputs/RadioGrouper";
import {
  getDBSBackupsAsync,
  selectDatabaseService,
  selectDBSBackups,
  selectDBSBackupsLoading,
} from "../../store/database/serviceSlice";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { formatTimestamp } from "../../utils/date";
import {
  AdditionalStorageInput,
  DatabaseServicePlansInput,
  DatabaseServiceRegionsInput,
  DatabaseServiceTypesInput,
  dbsPeriodItems,
  extractRegionsFromAvailabilities,
  filterAvailabilitiesByRegion,
  getClusterTotalStorage,
  getDBSPriceAmout,
  getNodeTypesFromAvailability,
  NumberOfNodesInput,
} from "./ServiceCreatePage";
import {
  Button,
  Card,
  Flex,
  Loading,
  Select,
  SimpleTable,
  Tag,
  Tooltip,
  Typography,
} from "djuno-design";

const items: RadioGroupeItem<number>[] = [
  { label: "Newest", value: 1 },
  { label: "Specific date", value: 2 },
  { label: "Backup", value: 3 },
];

const ServiceCreateDuplicatePage = () => {
  const navigate = useNavigate();

  const service = useAppSelector(selectDatabaseService);

  const availability = useAppSelector(selectDBSAvailability);
  const availabilityLoading = useAppSelector(selectDBSAvailabilityLoading);

  const capabilities = useAppSelector(selectDBSCapabilities);
  const capabilitiesLoading = useAppSelector(selectDBSCapabilitiesLoading);

  const actionLoading = useAppSelector(selectDatabaseServicesActionLoading);

  const catalog = useAppSelector(selectDBSCatalog);
  const catalogLoading = useAppSelector(selectDBSCatalogLoading);

  const selectedService = useAppSelector(selectDatabaseService);

  const backups = useAppSelector(selectDBSBackups);
  const backupsLoading = useAppSelector(selectDBSBackupsLoading);

  const [planAvailability, setPlanAvailability] = useState<
    Array<DBSAvailability>
  >([]);
  const [availableEnginVersions, setAvailableEnginVersions] = useState<
    Array<string>
  >([]);

  // selected planGroup
  const [selectedPlanGroup, setSelectedPlanGroup] = useState<
    DBSGroupAvailability | undefined
  >(undefined);

  const [serviceName, setServiceName] = useState("");

  // all regions having in the planGroup availabilities
  const [regions, setRegions] = useState<Array<string>>([]);

  // node types
  const [nodeTypes, setNodeTypes] = useState<Array<DBSNodeType>>([]);
  const [selectedDBSNodeType, setSelectedDBSNodeType] = useState<
    DBSNodeType | undefined
  >();

  // addons
  const [addons, setAddons] = useState<Array<DBSAddon>>([]);
  const [period, setPeriod] = useState<"month" | "hour">("hour");

  //for duplicate fork
  const params = useParams();
  const isDatabaseSelected = Boolean(params.databaseId);

  const dispatch = useAppDispatch();

  const location = useLocation();
  const { state } = location;
  const initialSelectedBackup = state?.selectedBackup;
  const initialSelectedItemValue = state?.selectedValue || 1;
  const initialSelectedBackupTime = state?.selectedBackupTime;

  const [selectedItem, setSelectedItem] = useState<RadioGroupeItem<number>>(
    items.find((item) => item.value === initialSelectedItemValue) || items[0]
  );

  const [selectedBackup, setSelectedBackup] = useState<IDBSBackup | undefined>(
    initialSelectedBackup
  );
  const [selectedDate, setSelectedDate] = useState(dayjs().toISOString());
  const [specificDate, setSpecificDate] = useState(dayjs().toISOString());
  const [backupDate, setBackupDate] = useState(
    dayjs(selectedService?.createdAt).toISOString()
  );

  const handleDateChange = (newValue: any) => {
    setSpecificDate(newValue.toISOString());
  };

  useEffect(() => {
    if (selectedItem.value === 1) {
      setSpecificDate(dayjs().toISOString());
    } else if (selectedItem.value === 3) {
      setBackupDate(dayjs(selectedService?.createdAt).toISOString());
    }
  }, [selectedItem, selectedService]);

  useEffect(() => {
    if (initialSelectedBackup) {
      setSelectedBackup(initialSelectedBackup);
    }
  }, [initialSelectedBackup]);

  // Calculate min and max dates from backups
  const minDate = useMemo(() => {
    return dayjs(
      Math.min(...backups.map((b) => new Date(b.createdAt).getTime()))
    );
  }, [backups]);

  const maxDate = useMemo(() => {
    return dayjs(
      Math.max(...backups.map((b) => new Date(b.createdAt).getTime()))
    );
  }, [backups]);

  const {
    setValue,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(DatabaseServiceCreateSchema()),
    reValidateMode: "onChange",
    mode: "all",
  });

  // watch form values
  const selectedEngineName = watch("SelectedEngine");
  const selectedEngineVersion = watch("SelectedEngineVersion");
  const selectedPlan = watch("SelectedPlan");
  const selectedRegion = watch("SelectedRegion");
  const selectedNodeType = watch("SelectedNodeType");
  const numberOfNodes = watch("NumberOfNodes");
  const additionalStorage = watch("AdditionalStorage");
  const watchBackup = watch("SelectedBackup", selectedBackup?.id || "");

  //get first data
  useEffect(() => {
    dispatch(getDBSAvailabilityAsync());
    dispatch(getDBSCapabilitiesAsync());
    dispatch(getDBSCatalogAsync());
  }, [dispatch, reset]);

  // initial base form values
  useEffect(() => {
    if (selectedService) {
      setValue("SelectedEngine", selectedService.engine);
      setValue("SelectedEngineVersion", selectedService.version);
      setValue("SelectedRegion", selectedService.nodes[0].region);
      setValue("SelectedNodeType", selectedService.flavor);
      setValue("NumberOfNodes", selectedService.nodeNumber);
      // setSelectedItem(items[0]);
    }
  }, [selectedService, setValue]);

  useEffect(() => {
    if (!selectedService && capabilities) {
      setValue("SelectedEngine", capabilities.engines[0].name);
      setValue("SelectedEngineVersion", capabilities.engines[0].defaultVersion);
    }
  }, [capabilities, selectedService, setValue]);

  // all operational engines
  const engines = useMemo(() => {
    if (capabilities && capabilities.engines) {
      return capabilities.engines.filter((e) => e.category === "operational");
    }
    return [];
  }, [capabilities]);

  // full data of selected engine
  const selectedEngine = useMemo(() => {
    if (capabilities && capabilities.engines && selectedEngineName) {
      return capabilities.engines.find((e) => e.name === selectedEngineName);
    }
  }, [capabilities, selectedEngineName]);

  // process plansAvailability after changing -> selected engine
  useEffect(() => {
    if (selectedEngine) {
      const plansAvailability = availability?.filter(
        (a) =>
          a.lifecycle.status === "STABLE" &&
          a.engine === selectedEngine.name &&
          a.network === "public"
      );
      setPlanAvailability(plansAvailability || []);
      const serviceName = generateRandomName(selectedEngine.name);
      setServiceName(serviceName);
    }
  }, [availability, selectedEngine]);

  // make plan groups from planAvailability
  const planGruops: Array<DBSGroupAvailability> = useMemo(() => {
    if (capabilities) {
      const { planGroups, availableVersions } = groupAvailabilityByPlan(
        planAvailability,
        capabilities
      );
      setValue(
        "SelectedPlan",
        !selectedService ? planGroups[0]?.plan : selectedService.plan
      );
      setSelectedPlanGroup(
        !selectedService
          ? planGroups[0]
          : planGroups.find((pg) => pg.plan === selectedService.plan)
      );
      setAvailableEnginVersions(availableVersions);
      return planGroups;
    } else {
      return [];
    }
  }, [capabilities, planAvailability, selectedService, setValue]);

  // create main plan code
  const planCode = useMemo(() => {
    return `databases.${selectedEngineName}-${selectedPlan}-${selectedNodeType}.${period}.consumption`;
  }, [period, selectedEngineName, selectedNodeType, selectedPlan]);

  // find main addon
  const planAddon = useMemo(() => {
    return addons.find((a) => a.planCode === planCode);
  }, [addons, planCode]);

  // create additional storage plan code
  const additionnalStoragePlanCode = useMemo(() => {
    return `databases.${selectedEngineName}-${selectedPlan}-additionnal-storage-gb.${period}.consumption`;
  }, [period, selectedEngineName, selectedPlan]);

  // find additional-storage addon
  const additionalStoragePlanAddon = useMemo(() => {
    return addons.find((a) => a.planCode === additionnalStoragePlanCode);
  }, [addons, additionnalStoragePlanCode]);

  // set selectedPlanGroup state when selected plan is changing
  useEffect(() => {
    if (selectedPlan && planGruops) {
      const planGroup = planGruops.find((pg) => pg.plan === selectedPlan);
      setSelectedPlanGroup(planGroup);
    }
  }, [planGruops, selectedPlan]);

  // make regions and selected region from selectedPlanGroup
  useEffect(() => {
    if (selectedPlanGroup) {
      const regions = extractRegionsFromAvailabilities(
        selectedPlanGroup.availability
      );
      setRegions(regions);
      const prevSelectedRegion = selectedRegion;
      if (prevSelectedRegion && regions.includes(selectedRegion)) {
        setValue("SelectedRegion", prevSelectedRegion);
      } else {
        setValue("SelectedRegion", regions[0]);
      }
      setValue("NumberOfNodes", selectedPlanGroup.minNodeNumber);
    }
  }, [selectedPlanGroup, selectedRegion, setValue]);

  // make nodes by selected planGroup and selected region
  useEffect(() => {
    if (selectedPlanGroup && capabilities) {
      const availability = filterAvailabilitiesByRegion(
        selectedPlanGroup.availability,
        selectedRegion
      );
      const nodeTypes = getNodeTypesFromAvailability(
        availability,
        capabilities,
        selectedEngineVersion
      );
      if (nodeTypes && nodeTypes.length > 0) {
        setNodeTypes(nodeTypes);

        const prevNodeType = selectedNodeType;
        const nodeType = nodeTypes.find(
          (nt) => nt.flavor.name === prevNodeType
        );
        if (nodeType) {
          setValue("SelectedNodeType", prevNodeType);
          setSelectedDBSNodeType(nodeType);
        } else {
          setValue("SelectedNodeType", nodeTypes[0].flavor.name);
          setSelectedDBSNodeType(nodeTypes[0]);
        }
      }
    }
  }, [
    capabilities,
    selectedEngineVersion,
    selectedNodeType,
    selectedPlanGroup,
    selectedRegion,
    selectedService,
    setValue,
  ]);

  //filter addons by selected engine and period
  useEffect(() => {
    if (catalog) {
      const planCode = `databases.${
        !selectedService ? selectedEngineName : selectedService.engine
      }`;
      const filteredAddons = catalog.addons
        .filter((addon) => addon.planCode.includes(planCode))
        .filter((addon) => addon.planCode.includes(period));
      setAddons(filteredAddons);
    }
  }, [catalog, period, selectedEngineName, selectedService]);

  // select node type
  // const handleSelectNodeType = useCallback(
  //   (nodeType: DBSNodeType) => {
  //     const prevNodeType = selectedNodeType;
  //     if (prevNodeType !== nodeType.flavor.name) {
  //       setValue("SelectedNodeType", nodeType.flavor.name);
  //       if (!selectedService) {
  //         setSelectedDBSNodeType(nodeType);
  //       }
  //     }
  //   },
  //   [selectedNodeType, setValue, selectedService]
  // );
  const handleSelectNodeType = useCallback(
    (nodeType: DBSNodeType) => {
      const prevNodeType = selectedNodeType;
      if (prevNodeType !== nodeType.flavor.name) {
        setValue("SelectedNodeType", nodeType.flavor.name);
        setSelectedDBSNodeType(nodeType);
      }
    },
    [selectedNodeType, setValue]
  );

  //for backups
  useEffect(() => {
    if (selectedService) {
      dispatch(
        getDBSBackupsAsync({
          engine: selectedService.engine,
          id: selectedService.id,
        })
      );
    }
  }, [dispatch, selectedService]);

  const handleSubmitForm = (data: any) => {
    const engine = data.SelectedEngine;
    const createData: DBSCreateApiData = {
      description: serviceName,
      nodesPattern: {
        flavor: data.SelectedNodeType,
        number: data.NumberOfNodes,
        region: data.SelectedRegion,
      },
      plan: data.SelectedPlan,
      version: data.SelectedEngineVersion,
    };

    if (data.AdditionalStorage > 0) {
      createData.disk = { size: data.AdditionalStorage };
    }

    let requestData: DBSCreateApiData;

    // Duplicate fork
    if (isDatabaseSelected && selectedService) {
      const formattedTimestamp = formatTimestamp(
        selectedDate,
        "YYYY-MM-DDTHH:mm:ssZ",
        { isUTC: true }
      ).datetime;

      // Construct the forkFrom object based on selectedItem.value
      let forkFrom: any = { serviceId: selectedService.id };
      if (selectedItem.value === 3) {
        forkFrom.backupId = watchBackup; // Use the selected backup ID
      } else if (selectedItem.value === 2) {
        forkFrom.pointInTime = formattedTimestamp; // Use the specific date
      } else if (selectedItem.value === 1) {
        forkFrom.pointInTime = formattedTimestamp; // Use the specific date
      }

      requestData = {
        ...createData,
        forkFrom: forkFrom,
      };
    } else {
      requestData = createData;
    }

    // Create service
    dispatch(createDBSAsync({ engine, data: requestData })).then((action) => {
      if (action.type === "db-services/create/fulfilled") {
        reset();
        navigate(DatabaseServicesUrl);
      }
    });
  };

  // set selectedPlanGroup state
  const disabledPlanGroupIndex = useMemo(() => {
    if (selectedService) {
      const planNames = planGruops.map((pg) => pg.plan);
      return planNames.indexOf(selectedService.plan);
    }
    return -1;
  }, [planGruops, selectedService]);

  // set node template state
  const disabledNodeTemplateIndex = useMemo(() => {
    if (selectedService) {
      const typeNames = nodeTypes.map((nt) => nt.flavor.name);
      return typeNames.indexOf(selectedService.flavor);
    }
    return -1;
  }, [nodeTypes, selectedService]);

  return (
    <>
      <div className="flex items-center justify-between h-16 px-6 sticky top-0 z-20 bg-white dark:bg-dark-1 border-b dark:border-dark-2">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <div className="font-medium mr-2 text-standard text-md dark:text-slate-100 flex items-center gap-1">
            {selectedService && isDatabaseSelected ? (
              <div
                className="group px-1 items-center flex flex-1 transition-all duration-150 cursor-pointer gap-x-0.5"
                onClick={() => navigate(DatabaseBackupsUrl(selectedService.id))}
              >
                <RightArrow className="-rotate-90 w-4 h-4 transition-all duration-500 text-primary-500 group-hover:translate-x-[-4px]" />
                <Typography.Title
                  level={6}
                  className="!text-sm !text-primary-500 "
                >
                  Backups
                </Typography.Title>
              </div>
            ) : (
              "Create a database service"
            )}
          </div>
        </div>

        <div className="">
          <Button
            uiType="light"
            uiSize="small"
            onClick={() => {
              selectedService && isDatabaseSelected
                ? navigate(DatabaseBackupsUrl(selectedService.id))
                : navigate(DatabaseServicesUrl);
            }}
            className="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      {(availabilityLoading || capabilitiesLoading) && (
        <div className="h-full w-full flex items-center justify-center min-h-[calc(100vh-128px)]">
          <Loading borderSize={2} />
        </div>
      )}
      {!availabilityLoading && !capabilitiesLoading && (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="mt-10 w-full px-6 flex flex-col lg:flex-row pb-24 gap-8 relative">
            <div className="w-full lg:w-2/3 flex flex-col gap-10">
              {/* duplicate fork */}
              <div>
                <Flex direction="col">
                  <Typography.Text className="!text-base !font-medium">
                    {selectedService && isDatabaseSelected
                      ? " Duplicate your database service (fork)"
                      : "Select your database type"}
                  </Typography.Text>
                  <Typography.Text uiType="secondary" className="!text-sm mt-1">
                    {selectedService && isDatabaseSelected
                      ? "Your backup fork will be run on a new cluster."
                      : " Select a database type to suit your needs"}
                  </Typography.Text>
                </Flex>
                <div className="mt-3">
                  {selectedService && isDatabaseSelected ? (
                    <>
                      <Typography.Text className="!text-lg font-medium">
                        Restore point
                      </Typography.Text>
                      <Typography.Text className="!text-sm mt-1 mb-1">
                        Select the restore point from which the service will be
                        duplicated
                      </Typography.Text>
                      <RadioGrouper
                        items={items}
                        selected={selectedItem}
                        setSelected={setSelectedItem}
                        dir="row"
                        disabled={
                          !selectedService.capabilities.backupTime.update
                            ? true
                            : false
                        }
                      />

                      {selectedItem.value === 2 && (
                        <div className="mt-2">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              views={[
                                "year",
                                "month",
                                "day",
                                "hours",
                                "minutes",
                                "seconds",
                              ]}
                              value={dayjs(specificDate)}
                              onChange={handleDateChange}
                              minDate={minDate} // Setting minimum date
                              maxDate={maxDate} // Setting maximum date
                            />
                          </LocalizationProvider>
                        </div>
                      )}

                      {selectedItem.value === 3 && (
                        <div className="mt-2">
                          <Controller
                            name="SelectedBackup"
                            control={control}
                            defaultValue={
                              selectedBackup ? selectedBackup.id : ""
                            }
                            render={({ field: { onChange, value } }) => (
                              <>
                                <Select
                                  label=""
                                  className="mt-2"
                                  options={backups.map((backup) => ({
                                    label:
                                      formatTimestamp(
                                        backup.createdAt,
                                        "MMM DD hh:mm:ss "
                                      ).datetime +
                                      " " +
                                      humanizeSize(Number(backup.size.value), {
                                        fractionDigits: 2,
                                      }).join("B"),

                                    value: backup.id,
                                  }))}
                                  value={value ?? undefined}
                                  onChange={(value) => {
                                    const backup = backups.find(
                                      (b) => b.id === value
                                    );
                                    setSelectedBackup(backup);
                                    onChange(value);
                                  }}
                                  emptyString="Select a version"
                                />
                              </>
                            )}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <DatabaseServiceTypesInput
                        engines={engines}
                        control={control}
                        errorMessage={errors.SelectedEngine?.message}
                      />
                      <div className="w-1/2">
                        <Typography.Text className="!text-sm mt-1">
                          Select Version
                        </Typography.Text>
                        <Controller
                          name="SelectedEngineVersion"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <Select
                              label=""
                              className="mt-2"
                              options={
                                selectedEngine
                                  ? [
                                      ...selectedEngine.versions.map((v) => ({
                                        label: v,
                                        value: v,
                                        disabled:
                                          !availableEnginVersions.includes(v),
                                      })),
                                    ]
                                  : []
                              }
                              value={value}
                              onChange={onChange}
                              emptyString="Select a version"
                            />
                          )}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* service plan */}
              <div className="">
                <Typography.Text className="!text-base !font-medium">
                  Select a service plan
                </Typography.Text>
                <div className="mt-5">
                  <DatabaseServicePlansInput
                    control={control}
                    plans={planGruops}
                    addons={addons}
                    period={period}
                    errorMessage={errors.SelectedPlan?.message}
                    disabledIndex={disabledPlanGroupIndex}
                    selectedService={selectedService || null}
                  />
                </div>
              </div>

              <div className="">
                <Typography.Text className="!text-base !font-medium">
                  Select a region
                </Typography.Text>
                <div className="mt-5">
                  <DatabaseServiceRegionsInput
                    control={control}
                    regions={regions}
                    errorMessage={errors.SelectedPlan?.message}
                    selectedService={selectedService || null}
                  />
                </div>
              </div>

              <div className="">
                <Flex direction="col">
                  <Typography.Text className="!text-base !font-medium">
                    Node type
                  </Typography.Text>
                  <Typography.Text uiType="secondary" className="!text-sm">
                    Select the node template
                  </Typography.Text>
                </Flex>
                <div className="mt-5">
                  <SimpleTable containerClassName="!min-h-min">
                    <SimpleTable.Head>
                      <SimpleTable.Row>
                        <SimpleTable.TH lable="Type" />
                        <SimpleTable.TH lable="vCores" />
                        <SimpleTable.TH lable="Memory" />
                        <SimpleTable.TH lable="Usable Storage" />
                        <SimpleTable.TH
                          lable={`Cost/${period}/node (estimated)`}
                        />
                      </SimpleTable.Row>
                    </SimpleTable.Head>
                    <SimpleTable.Body>
                      {nodeTypes.map((nodeType, index) => {
                        const {
                          flavor: {
                            specifications: { memory },
                            name: flavorName,
                          },
                          availability: {
                            specifications: { storage },
                          },
                        } = nodeType;

                        let Memory;
                        let minStorage;
                        let maxStorage;

                        if (memory) {
                          const memoryBinary = binarySize(
                            memory.value,
                            memory.unit.slice()[0],
                            1000
                          );
                          Memory = humanizeSize(memoryBinary, {
                            binaryBaseValue: 1000,
                          });
                        }

                        if (storage) {
                          const { minimum, maximum } = storage;
                          const minStorageBinary = binarySize(
                            minimum.value,
                            minimum.unit.slice()[0],
                            1000
                          );
                          minStorage = humanizeSize(minStorageBinary, {
                            binaryBaseValue: 1000,
                          });

                          const maxStorageBinary = binarySize(
                            maximum.value,
                            maximum.unit.slice()[0],
                            1000
                          );
                          maxStorage = humanizeSize(maxStorageBinary, {
                            binaryBaseValue: 1000,
                          });
                        }

                        const filteredAddons = addons.filter((addon) =>
                          addon.planCode.includes(
                            selectedPlan + "-" + flavorName
                          )
                        );

                        return (
                          <SimpleTable.Row
                            key={index}
                            selected={nodeType.flavor.name === selectedNodeType}
                            className={
                              disabledNodeTemplateIndex
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }
                            onClick={() => handleSelectNodeType(nodeType)}
                            disabled={index < disabledNodeTemplateIndex}
                          >
                            <SimpleTable.TD className="w-36">
                              <Typography.Text className="!text-xs md:text-sm whitespace-nowrap">
                                {capitalizeFirstLetter(nodeType.flavor.name)}
                              </Typography.Text>
                            </SimpleTable.TD>
                            <SimpleTable.TD>
                              <Typography.Text className="!text-xs md:text-sm whitespace-nowrap">
                                {nodeType.flavor.specifications.core}
                              </Typography.Text>
                            </SimpleTable.TD>
                            <SimpleTable.TD>
                              <Typography.Text className="!text-xs md:text-sm whitespace-nowrap">
                                {Memory?.number && Memory?.join("B")}
                              </Typography.Text>
                            </SimpleTable.TD>
                            <SimpleTable.TD>
                              <Typography.Text className="!text-xs md:text-sm whitespace-nowrap">
                                {minStorage && maxStorage && (
                                  <>
                                    {minStorage.number !== maxStorage.number ? (
                                      <>{`From ${minStorage.join(
                                        "B"
                                      )} to ${maxStorage.join("B")}`}</>
                                    ) : (
                                      <>{minStorage.join("B")}</>
                                    )}
                                  </>
                                )}
                              </Typography.Text>
                            </SimpleTable.TD>
                            <SimpleTable.TD>
                              <div className="flex items-center">
                                <Typography.Text className="!text-xs font-medium whitespace-nowrap">
                                  €
                                  {filteredAddons.length > 0
                                    ? getDBSPriceAmout(
                                        filteredAddons[0].pricings[0].price
                                      )
                                    : "0"}{" "}
                                  ex. VAT
                                </Typography.Text>
                                <Typography.Text className="!text-[0.6rem] whitespace-nowrap">
                                  (€
                                  {filteredAddons.length > 0
                                    ? getDBSPriceAmout(
                                        filteredAddons[0].pricings[0].price +
                                          filteredAddons[0].pricings[0].tax
                                      )
                                    : "0"}{" "}
                                  incl. VAT)
                                </Typography.Text>
                                {service?.flavor === nodeType.flavor.name && (
                                  <Tag
                                    className="!text-xs ml-2 whitespace-nowrap"
                                    color="success"
                                  >
                                    Current solution
                                  </Tag>
                                )}
                              </div>
                            </SimpleTable.TD>
                          </SimpleTable.Row>
                        );
                      })}
                    </SimpleTable.Body>
                  </SimpleTable>
                </div>
              </div>

              <div className="">
                <Flex direction="col" className="mb-2">
                  <Typography.Text className="!text-base !font-medium">
                    Cluster sizing
                  </Typography.Text>
                  <Typography.Text uiType="secondary" className="!text-sm">
                    Number of nodes
                  </Typography.Text>
                </Flex>
                {selectedPlanGroup && (
                  <NumberOfNodesInput
                    plan={selectedPlanGroup}
                    control={control}
                    errorMessage={errors.NumberOfNodes?.message}
                  />
                )}

                {selectedDBSNodeType && (
                  <AdditionalStorageInput
                    control={control}
                    nodeType={selectedDBSNodeType}
                    period={period}
                    nodeCount={numberOfNodes}
                    addon={additionalStoragePlanAddon}
                    errorMessage={errors.AdditionalStorage?.message}
                  />
                )}
              </div>
            </div>
            <div className="w-full lg:w-1/3">
              <Card title="Your order" className="sticky top-20">
                <div className="flex flex-col gap-3">
                  <div className="">
                    <Typography.Text className="!text-sm font-semibold inline">
                      Name:
                    </Typography.Text>
                    <Typography.Text className="!text-sm ml-1 break-words inline">
                      {selectedService
                        ? selectedService.description
                        : serviceName}
                    </Typography.Text>
                    <span className="inline-block">
                      <Tooltip.Info content="The name of your cluster is automatically generated. You can change it later." />
                    </span>
                  </div>

                  <div className="">
                    <Typography.Text className="!text-sm font-semibold inline">
                      Service:
                    </Typography.Text>
                    <Typography.Text className="!text-sm ml-1 break-words inline">
                      {selectedService
                        ? capitalizeFirstLetter(selectedService.engine)
                        : capitalizeFirstLetter(selectedEngineName)}
                    </Typography.Text>
                    <div className="flex items-center gap-1">
                      <HashtagIcon className="ml-2 w-5 aspect-square text-slate-600 dark:text-slate-400" />
                      <Typography.Text className="!text-sm">
                        {selectedService
                          ? `Version ${selectedService.version}`
                          : `Version ${selectedEngineVersion}`}
                      </Typography.Text>
                    </div>
                  </div>

                  <div className="">
                    <Typography.Text className="!text-sm font-semibold inline">
                      Solution:
                    </Typography.Text>
                    <Typography.Text className="!text-sm ml-1 break-words inline">
                      {selectedService
                        ? capitalizeFirstLetter(selectedService.plan)
                        : capitalizeFirstLetter(selectedPlan)}
                    </Typography.Text>
                  </div>

                  <div className="">
                    <Typography.Text className="!text-sm font-semibold inline">
                      Datacenter:
                    </Typography.Text>
                    <Typography.Text className="!text-sm ml-1 break-words inline">
                      {selectedRegion}
                    </Typography.Text>
                  </div>

                  <div className="">
                    <Typography.Text className="!text-sm font-semibold inline">
                      Node template:
                    </Typography.Text>
                    <Typography.Text className="!text-sm ml-1 break-words inline">
                      {selectedService
                        ? capitalizeFirstLetter(selectedService.flavor)
                        : capitalizeFirstLetter(selectedNodeType)}
                    </Typography.Text>
                  </div>

                  <div className="">
                    <Typography.Text className="!text-sm font-semibold inline">
                      Cluster:
                    </Typography.Text>
                    <div className="flex flex-col ml-2">
                      <Typography.Text className="!text-sm ml-1 break-words inline">
                        {selectedService
                          ? `${selectedService.nodes.length} nodes`
                          : `${numberOfNodes} nodes`}
                      </Typography.Text>
                      <Typography.Text className="!text-sm ml-1 break-words inline">
                        {selectedDBSNodeType &&
                          getClusterTotalStorage(
                            selectedDBSNodeType,
                            additionalStorage
                          )}
                      </Typography.Text>
                    </div>
                  </div>

                  <div className="my-3">
                    <RadioGrouper
                      items={dbsPeriodItems}
                      selected={dbsPeriodItems.find((i) => i.value === period)}
                      setSelected={(item) => setPeriod(item.value)}
                    />
                  </div>

                  <div className="">
                    <Typography.Text className="!text-sm font-semibold inline">
                      Price:
                    </Typography.Text>
                    <div className="flex flex-col ml-2">
                      <div className="flex items-center flex-wrap">
                        <Typography.Text className="!text-xs font-medium whitespace-nowrap">
                          €
                          {planAddon
                            ? getDBSPriceAmout(
                                (planAddon.pricings[0].price +
                                  +(additionalStoragePlanAddon
                                    ? additionalStoragePlanAddon.pricings[0]
                                        .price * Number(additionalStorage || 0)
                                    : 0)) *
                                  numberOfNodes
                              )
                            : "0"}{" "}
                          ex. VAT
                        </Typography.Text>
                        <Typography.Text className="!text-[0.6rem]  whitespace-nowrap">
                          (€
                          {planAddon
                            ? getDBSPriceAmout(
                                (planAddon.pricings[0].price +
                                  planAddon.pricings[0].tax +
                                  (additionalStoragePlanAddon
                                    ? (additionalStoragePlanAddon.pricings[0]
                                        .price +
                                        additionalStoragePlanAddon.pricings[0]
                                          .tax) *
                                      Number(additionalStorage || 0)
                                    : 0)) *
                                  numberOfNodes
                              )
                            : "0"}{" "}
                          incl. VAT)
                        </Typography.Text>
                        <Typography.Text className="!text-xs font-medium  whitespace-nowrap">
                          /{period}
                        </Typography.Text>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className="fixed bottom-0 right-0 left-0 flex items-center w-full h-16 border-t bg-white dark:bg-dark-1 dark:border-dark-2 px-6 md:pl-72">
            <div className="flex justify-end w-full">
              <Button
                uiType="primary"
                type="submit"
                className="group  w-[110px]"
                loading={actionLoading}
              >
                Order
                <ArrowRightIcon className="w-4 h-4 group-hover:scale-110 group-hover:translate-x-1 transition-all duration-300" />
              </Button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export function groupAvailabilityByPlan(
  availability: Array<DBSAvailability>,
  capabilities: DBSCapabilities
): { planGroups: Array<DBSGroupAvailability>; availableVersions: string[] } {
  const planGroups: { [key: string]: DBSGroupAvailability } = {};
  const availableVersions: string[] = [];

  availability.forEach((item) => {
    availableVersions.push(item.version);
    const {
      plan,
      flavor: flavorName,
      minNodeNumber,
      maxNodeNumber,
      backups,
      network,
    } = item;

    let minCore: number | undefined;
    let maxCore: number | undefined;
    let minDiskSize: number | undefined;
    let maxDiskSize: number | undefined;
    let minDiskSizeUnit: string | undefined;
    let maxDiskSizeUnit: string | undefined;
    let minMemory: number | undefined;
    let maxMemory: number | undefined;
    let minMemoryUnit: string | undefined;
    let maxMemoryUnit: string | undefined;

    // Find the corresponding flavor object
    const flavor = capabilities.flavors.find(
      (flavor) => flavor.name === flavorName
    );

    if (flavor) {
      minCore = flavor.core;
      maxCore = flavor.core;

      // Check if storage information exists in specifications
      if (flavor.specifications.storage) {
        minDiskSize = flavor.specifications.storage.value;
        maxDiskSize = flavor.specifications.storage.value;
        minDiskSizeUnit = flavor.specifications.storage.unit;
        maxDiskSizeUnit = flavor.specifications.storage.unit;
      }

      // Check if memory information exists in specifications
      if (flavor.specifications.memory) {
        minMemory = flavor.specifications.memory.value;
        maxMemory = flavor.specifications.memory.value;
        minMemoryUnit = flavor.specifications.memory.unit;
        maxMemoryUnit = flavor.specifications.memory.unit;
      }
    }

    if (!planGroups[plan]) {
      planGroups[plan] = {
        plan,
        availability: [],
        minNodeNumber,
        maxNodeNumber,
        minCore,
        maxCore,
        minDiskSize,
        maxDiskSize,
        minDiskSizeUnit,
        maxDiskSizeUnit,
        minMemory,
        maxMemory,
        minMemoryUnit,
        maxMemoryUnit,
        backups: [],
        networks: [],
      };
    } else {
      if (minCore !== undefined && maxCore !== undefined) {
        planGroups[plan].minCore = Math.min(planGroups[plan].minCore!, minCore);
        planGroups[plan].maxCore = Math.max(planGroups[plan].maxCore!, maxCore);
      }
      if (minDiskSize !== undefined && maxDiskSize !== undefined) {
        planGroups[plan].minDiskSize = Math.min(
          planGroups[plan].minDiskSize!,
          minDiskSize
        );
        planGroups[plan].maxDiskSize = Math.max(
          planGroups[plan].maxDiskSize!,
          maxDiskSize
        );
        planGroups[plan].minDiskSizeUnit = minDiskSizeUnit!;
        planGroups[plan].maxDiskSizeUnit = maxDiskSizeUnit!;
      }
      if (minMemory !== undefined && maxMemory !== undefined) {
        planGroups[plan].minMemory = Math.min(
          planGroups[plan].minMemory!,
          minMemory
        );
        planGroups[plan].maxMemory = Math.max(
          planGroups[plan].maxMemory!,
          maxMemory
        );
        planGroups[plan].minMemoryUnit = minMemoryUnit!;
        planGroups[plan].maxMemoryUnit = maxMemoryUnit!;
      }
      planGroups[plan].minNodeNumber = Math.min(
        planGroups[plan].minNodeNumber,
        minNodeNumber
      );
      planGroups[plan].maxNodeNumber = Math.max(
        planGroups[plan].maxNodeNumber,
        maxNodeNumber
      );
    }

    if (backups.available && backups.available === true) {
      planGroups[plan].backups.push(item.backup);
    }

    if (network) {
      planGroups[plan].networks.push(network);
    }

    planGroups[plan].availability.push(item);
  });

  // Ensure backups array contains only unique values
  Object.values(planGroups).forEach((group) => {
    group.backups = Array.from(new Set(group.backups));
    group.networks = Array.from(new Set(group.networks));
  });

  // Order the plan groups based on the order defined in the plans array
  const orderedPlanGroups: Array<DBSGroupAvailability> = capabilities.plans
    .map((plan) => planGroups[plan.name])
    .filter((group) => group !== undefined);

  return {
    planGroups: orderedPlanGroups,
    availableVersions: Array.from(new Set(availableVersions)),
  };
}

export default ServiceCreateDuplicatePage;
