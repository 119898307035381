import { PropsWithChildren, useState } from "react";
import {
  Button,
  ButtonTypes,
  Modal,
  Typography,
  Input,
  Flex,
} from "djuno-design";

export type QuestionModalType = {
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  loading?: boolean;
  onConfirm?: () => void;
  confirmButtonType?: ButtonTypes;
  confirmButtonText?: string;
  contentClassName?: string;
  confirmButtonClassName?: string;
};

export type DeleteModalType = QuestionModalType & {
  confirmString?: string;
  error?: string;
};

const QuestionModal = (props: PropsWithChildren<QuestionModalType>) => {
  const isOpen = props.isOpen;

  const handleConfirm = () => {
    if (props.onConfirm) {
      props.onConfirm();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={props.title || "Are you sure delete this item?"}
      onClose={() => (props.onClose ? props.onClose() : {})}
      contentClassName={props.contentClassName || "!max-w-sm"}
    >
      <Typography.Text size="sm" className="mt-5">
        {props.description ||
          props.children ||
          "You won't be able to revert this!"}
      </Typography.Text>

      <div className="mt-4 flex justify-end gap-2">
        <Button
          uiType="light"
          onClick={() => (props.onClose ? props.onClose() : {})}
        >
          Cancel
        </Button>
        <Button
          className={props.confirmButtonClassName || "w-10"}
          loading={props.loading || false}
          uiType={props.confirmButtonType || "primary"}
          onClick={handleConfirm}
          disabled={props.loading || false}
        >
          {props.confirmButtonText || "Yes!"}
        </Button>
      </div>
    </Modal>
  );
};
export default QuestionModal;

const DeleteModal = (props: PropsWithChildren<DeleteModalType>) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setError(null);
  };

  const handleConfirm = () => {
    if (props.confirmString) {
      // Trim the input value only when validating
      if (inputValue === props.confirmString) {
        if (props.onConfirm) {
          props.onConfirm();
          setInputValue("");
        }
      } else {
        setError(`Please type exactly "${props.confirmString}"`);
      }
    } else {
      if (props.onConfirm) {
        props.onConfirm();
        setInputValue("");
      }
    }
  };

  const handleClose = () => {
    setInputValue("");
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      title={props.title || "Delete item"}
      onClose={handleClose}
      contentClassName={props.contentClassName || "!max-w-sm"}
    >
      <div className="space-y-2">
        <Flex direction="col">
          {props.description}
          {props.confirmString ? (
            <Flex direction="col" className="gap-1">
              <Typography.Text size="xs" className="mt-5">
                To confirm, type{" "}
                <Typography.Text strong size="xs">
                  "{props.confirmString}"
                </Typography.Text>{" "}
                in the box below
              </Typography.Text>
              <Input
                value={inputValue}
                onChange={handleInputChange}
                placeholder={`Type "${props.confirmString}" to confirm`}
                error={error}
              />
            </Flex>
          ) : (
            <div className="py-5">
              <Typography.Text size="sm">
                You won't be able to revert this!
              </Typography.Text>
            </div>
          )}
        </Flex>
      </div>

      <div className="mt-4 flex justify-end gap-2">
        <Button
          uiType="light"
          onClick={(e) => {
            e.preventDefault();
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          className={props.confirmButtonClassName || "w-10"}
          loading={props.loading || false}
          uiType={props.confirmButtonType || "primary"}
          onClick={handleConfirm}
          disabled={
            props.loading ||
            (props.confirmString ? inputValue !== props.confirmString : false)
          }
        >
          {props.confirmButtonText || "Confirm"}
        </Button>
      </div>
    </Modal>
  );
};

export { DeleteModal };
