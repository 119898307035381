import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import { ToastClasses } from "../../components/modals/alerts";
import {
  createS3NetworkApi,
  deleteS3NetworkApi,
  getS3NetworksApi,
  setAsDefaultS3NetworkApi,
  updateS3NetworkApi,
} from "../../apis/s3API";
import { S3Network, S3SettingsState } from "../../types/s3-settings";
import { uuid } from "../../utils/uuid";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

const dummyNetworks: S3Network[] = [
  {
    Id: 1,
    NetworkName: "Solana Mainnet",
    RpcUrl: "https://api.mainnet-beta.solana.com",
    ChainID: 101,
    CurrencySymbol: "SOL",
    ExplorerURL: "https://explorer.solana.com",
    Icon: "https://cdn.djuno.cloud/chains/solana.svg",
    Lock: true,
    Selected: true,
  },
  {
    Id: 2,
    NetworkName: "Ethereum Mainnet",
    RpcUrl: "https://mainnet.infura.io/v3/",
    ChainID: 1,
    CurrencySymbol: "ETH",
    ExplorerURL: "https://etherscan.io",
    Icon: "https://cdn.djuno.cloud/chains/eth.svg",
    Lock: true,
    Selected: false,
  },
  {
    Id: 3,
    NetworkName: "Binance Smart Chain",
    RpcUrl: "https://bsc-dataseed.binance.org",
    ChainID: 56,
    CurrencySymbol: "BNB",
    ExplorerURL: "https://bscscan.com/",
    Icon: "https://cdn.djuno.cloud/chains/bnb.svg",
    Lock: true,
    Selected: false,
  },
];

const initialState: S3SettingsState = {
  networks: [...dummyNetworks],
  loading: false,
  actionLoading: false,
  onStageNetwork: null,
};

export const getS3NetworksAsync = createAsyncThunk<
  { networks: S3Network[] },
  undefined,
  IThunkRejectValue
>("s3-settings/networks", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getS3NetworksApi();
    const networks = response.data.Result;
    return fulfillWithValue({ networks });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createS3NetworkAsync = createAsyncThunk<
  boolean,
  any,
  IThunkRejectValue
>("s3-settings/networks/create", async (data, { rejectWithValue }) => {
  try {
    await createS3NetworkApi(data);
    return true;
  } catch (e) {
    if (isAxiosError(e))
      return rejectWithValue({ message: e.response?.data.message });
    return rejectWithValue({ message: "Bad Request" });
  }
});

export const updateS3NetworkAsync = createAsyncThunk<
  boolean,
  { id: number; data: any },
  IThunkRejectValue
>("s3-settings/networks/update", async ({ id, data }, { rejectWithValue }) => {
  try {
    await updateS3NetworkApi(id, data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteS3NetworkAsync = createAsyncThunk<
  boolean,
  { id: number },
  IThunkRejectValue
>("s3-settings/networks/delete", async ({ id }, { rejectWithValue }) => {
  try {
    await deleteS3NetworkApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const setAsDefaultS3NetworkAsync = createAsyncThunk<
  boolean,
  { id: number },
  IThunkRejectValue
>("s3-settings/networks/set-default", async ({ id }, { rejectWithValue }) => {
  try {
    await setAsDefaultS3NetworkApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const s3SettingsSlice = createSlice({
  name: "s3-settings",
  initialState,
  reducers: {
    handleChangeOnStageNetwork: (
      state,
      action: { payload: S3Network | null }
    ) => {
      state.onStageNetwork = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getS3NetworksAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getS3NetworksAsync.fulfilled, (state, action) => {
        const { networks } = action.payload;

        state.networks = networks;
        state.loading = false;
      })
      .addCase(getS3NetworksAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createS3NetworkAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createS3NetworkAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createS3NetworkAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;

        //for test
        state.networks = [
          ...state.networks,
          {
            Id: Math.random(),
            ChainID: Math.random(),
            Icon: null,
            CurrencySymbol: uuid(3),
            ExplorerURL: uuid(10),
            Lock: false,
            Selected: false,
            NetworkName: uuid(13),
            RpcUrl: uuid(25),
          },
        ];

        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateS3NetworkAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateS3NetworkAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateS3NetworkAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteS3NetworkAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteS3NetworkAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteS3NetworkAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(setAsDefaultS3NetworkAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(setAsDefaultS3NetworkAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(setAsDefaultS3NetworkAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectS3Networks = (state: RootState) => state.s3Networks.networks;
export const selectLoading = (state: RootState) => state.s3Networks.loading;
export const selectActionLoading = (state: RootState) =>
  state.s3Networks.actionLoading;

export const selectS3OnStageNetwork = (state: RootState) =>
  state.s3Networks.onStageNetwork;

export const { handleChangeOnStageNetwork } = s3SettingsSlice.actions;
export default s3SettingsSlice.reducer;
