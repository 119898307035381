import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InstanceSshKey, InstancesSSHkeysState } from "../../types/instance";
import { IThunkRejectValue, RootState } from "../../types";
import {
  createInstancesSshKeysApi,
  deleteInstancesSshKeysApi,
  getInstancesSshKeysApi,
  updateInstancesSshKeysApi,
} from "../../apis/instancesAPI";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import toast from "react-hot-toast";

const initialState: InstancesSSHkeysState = {
  sshKeys: [],
  sshKeysLoading: false,
  sshKeysActionLoading: false,

  selectedSshKey: null,
  showEditor: false,

  instanceShowDeleteSshKeyModal: false,
};

export const getInstancesSshKeysAsync = createAsyncThunk<
  { sshKeys: InstanceSshKey[] },
  undefined,
  IThunkRejectValue
>("instances/ssh-keys", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getInstancesSshKeysApi();
    const sshKeys = response.data.Result;
    return fulfillWithValue({ sshKeys });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createInstancesSshKeysAsync = createAsyncThunk<
  boolean,
  { data: any },
  IThunkRejectValue
>("instances/ssh-keys/create", async ({ data }, { rejectWithValue }) => {
  try {
    await createInstancesSshKeysApi(data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

// export const updateInstancesSshKeysAsync = createAsyncThunk<
//   boolean,
//   { id: any; data: any },
//   IThunkRejectValue
// >("instances/ssh-keys/update", async ({ id, data }, { rejectWithValue }) => {
//   try {
//     await updateInstancesSshKeysApi(id, data);
//     return true;
//   } catch (e) {
//     return rejectWithValue({ message: getExtractErrors(e) });
//   }
// });

export const deleteInstancesSshKeysAsync = createAsyncThunk<
  boolean,
  { id: string },
  IThunkRejectValue
>("instances/ssh-keys/delete", async ({ id }, { rejectWithValue }) => {
  try {
    await deleteInstancesSshKeysApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

const instancesSSHkeysSlice = createSlice({
  name: "instances-ssh-keys",
  initialState,
  reducers: {
    handleInstancesSshKeyEditor: (
      state,
      {
        payload: { status, sshKey },
      }: PayloadAction<{ status: boolean; sshKey?: InstanceSshKey }>
    ) => {
      state.showEditor = status;
      state.selectedSshKey = sshKey || null;
    },
    handleInstanceDeleteSshKeyShowModal: (
      state,
      action: PayloadAction<InstanceSshKey>
    ) => {
      state.instanceShowDeleteSshKeyModal = true;
      state.selectedSshKey = action.payload;
    },
    handleInstanceDeleteSshKeyHideModal: (state) => {
      state.instanceShowDeleteSshKeyModal = false;
      // state.instance = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInstancesSshKeysAsync.pending, (state) => {
        state.sshKeysLoading = true;
      })
      .addCase(getInstancesSshKeysAsync.fulfilled, (state, action) => {
        state.sshKeysLoading = false;
        state.sshKeys = action.payload.sshKeys;
      })
      .addCase(getInstancesSshKeysAsync.rejected, (state, { payload }) => {
        state.sshKeysLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createInstancesSshKeysAsync.pending, (state) => {
        state.sshKeysActionLoading = true;
      })
      .addCase(createInstancesSshKeysAsync.fulfilled, (state, action) => {
        state.sshKeysActionLoading = false;
      })
      .addCase(createInstancesSshKeysAsync.rejected, (state, { payload }) => {
        state.sshKeysActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    // builder
    //   .addCase(updateInstancesSshKeysAsync.pending, (state) => {
    //     state.sshKeysActionLoading = true;
    //   })
    //   .addCase(updateInstancesSshKeysAsync.fulfilled, (state, action) => {
    //     state.sshKeysActionLoading = false;
    //   })
    //   .addCase(updateInstancesSshKeysAsync.rejected, (state, { payload }) => {
    //     state.sshKeysActionLoading = false;
    //     if (payload?.message)
    // toast.error(() => CustomErrorToast(payload?.message));
    //   });

    builder
      .addCase(deleteInstancesSshKeysAsync.pending, (state) => {
        state.sshKeysActionLoading = true;
      })
      .addCase(deleteInstancesSshKeysAsync.fulfilled, (state, action) => {
        state.sshKeysActionLoading = false;
      })
      .addCase(deleteInstancesSshKeysAsync.rejected, (state, { payload }) => {
        state.sshKeysActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectInstancesSshKeys = (state: RootState) =>
  state.instancesSshKeys.sshKeys;
export const selectInstancesSshKeysLoading = (state: RootState) =>
  state.instancesSshKeys.sshKeysLoading;
export const selectInstancesSshKeysActionLoading = (state: RootState) =>
  state.instancesSshKeys.sshKeysActionLoading;
export const selectInstancesSelectedSshKey = (state: RootState) =>
  state.instancesSshKeys.selectedSshKey;

export const selectShowInstancesSshKeysEditor = (state: RootState) =>
  state.instancesSshKeys.showEditor;

export const selectShowInstancesSshKeysModal = (state: RootState) =>
  state.instancesSshKeys.instanceShowDeleteSshKeyModal;

export const {
  handleInstancesSshKeyEditor,
  handleInstanceDeleteSshKeyShowModal,
  handleInstanceDeleteSshKeyHideModal,
} = instancesSSHkeysSlice.actions;
export default instancesSSHkeysSlice.reducer;
