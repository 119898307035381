import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getWebAppDiskAsync,
  getWebAppManualScalingAsync,
  selectWebApp,
  selectWebAppDisk,
  selectWebAppDiskLoading,
  selectWebAppLoading,
  selectWebAppManualScaling,
  selectWebAppScaling,
  selectWebAppScalingLoading,
  selectWebAppScalingUpdateLoading,
  updateWebAppManualScalingAsync,
} from "../../../store/web-app/webAppSlice";
import Slider from "../../general/Slider";
import { Button, Card, Flex, Input, Loading } from "djuno-design";

const WebAppScalingTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const disk = useAppSelector(selectWebAppDisk);
  const diskLoading = useAppSelector(selectWebAppDiskLoading);

  const scaling = useAppSelector(selectWebAppScaling);
  const scalingLoading = useAppSelector(selectWebAppScalingLoading);
  const scalingUpdateLoading = useAppSelector(selectWebAppScalingUpdateLoading);
  const manualScaling = useAppSelector(selectWebAppManualScaling);

  const [changedScaling, setChangedScaling] = useState<number>(scaling);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (webApp) {
      dispatch(getWebAppDiskAsync({ webAppId: webApp.Id.toString() }));
    }
  }, [dispatch, webApp]);

  useEffect(() => {
    if (webApp) {
      dispatch(getWebAppManualScalingAsync({ webAppId: webApp.Id.toString() }));
    }
  }, [dispatch, webApp]);

  const handleChangeScallingInput = (e: ChangeEvent<HTMLInputElement>) => {
    let val = Number(e.target.value);
    if (val < 0) val = 0;
    if (val > 100) val = 100;
    setChangedScaling(val);
  };

  const handleUpdateManualScaling = () => {
    if (webApp) {
      dispatch(
        updateWebAppManualScalingAsync({
          webAppId: webApp.Id.toString(),
          value: changedScaling,
        })
      );
    }
  };
  // if (disk) {
  //   return (
  //     <div className="mt-5">
  //       <Text className="text-sm text-center">
  //         Scaling is not supported for services with disks.
  //       </Text>
  //     </div>
  //   );
  // }
  return (
    <>
      {(scalingLoading || diskLoading) && (
        <Card className="flex justify-center items-center min-h-[200px]">
          <Loading borderSize={2} />
        </Card>
      )}
      {!scalingLoading && !diskLoading && (
        <Card
          title="Manual Scaling"
          description="Scale your app horizontally by creating multiple instances that are automatically load balanced by Render. All instances use the same instance type and are billed accordingly"
        >
          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-1">
              <Input
                value={changedScaling}
                // type="number"
                onChange={handleChangeScallingInput}
              />
            </div>
            <div className="col-span-11 flex items-center">
              <Slider
                range
                min={1}
                max={100}
                onChange={(value) =>
                  setChangedScaling(Array.isArray(value) ? value[0] : value)
                }
                value={changedScaling}
              />
            </div>
          </div>
          <Flex justify="end" className="w-full">
            <Button
              uiType="primary"
              disabled={changedScaling === scaling}
              onClick={handleUpdateManualScaling}
              loading={scalingUpdateLoading || diskLoading}
            >
              Save Changes
            </Button>
          </Flex>
        </Card>
      )}
    </>
  );
};

export default WebAppScalingTab;
